import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  > section {
    display: block;
    justify-content: space-between;
    font-family: 'Inter';
    padding: 0 2.4rem 5.5rem 2.4rem;

    h1 {
      font-size: 1.3rem;
      margin-bottom: 2rem;
    }
  }

  small {
    color: var(--primary-main);
    bottom: 0;
    margin: 0.25rem;
  }
`;

export const InputContent = styled.div`
  width: 18rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .emailInput {
    color: var(--secondary-main);
    background: var(--white);
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const PaymentMethods = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  img:first-child {
    margin-right: 2rem;
  }
`;

export const DocumentContent = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin: 0.25rem;
    margin-bottom: 0.5rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  strong {
    margin: 0.25rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    font-family: 'Nunito Sans';
    margin-top: 0.13rem;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  padding-inline: 2rem;
  position: relative;
  margin-top: 2rem;
  bottom: 0;
`;
