import React, { useState } from 'react';
import { LabDsButton, LabDsTextField } from 'v4web-components-react';

import * as S from './styles';
import { useWithDraw } from '../../../../contexts/withdraw';
import { convertCentsToBRL } from '../../../../utils/convertCentsToBRL';
import { useAuctions } from '../../../../contexts';
import { useBalance } from '../../../../hooks/wallet';

interface AmountPageProps {
  nextButton: (amount: number) => void;
}

const Amount: React.FC<AmountPageProps> = ({ nextButton }) => {
  const { amount, setAmount } = useWithDraw();
  const { unitInfos } = useAuctions();
  const { balance, totalBonusAmount, availableWithdraw } = useBalance();

  const [amountState, setAmountState] = useState('' as string);

  const totalBalance = balance;

  const amountFormatted = Number(amount.toString().replace(',', '.'));
  const totalAmount = Number(amountFormatted) * 100;

  const checkBalance = () => Number(totalAmount) > availableWithdraw;

  const onSubmit = () => {
    setAmount(Number(amountFormatted));
    return nextButton(Number(amountFormatted));
  };

  return (
    <S.Container
      placeholder=""
      onSubmit={onSubmit}
      onPointerEnterCapture={() => null}
      onPointerLeaveCapture={() => null}
    >
      <S.ContentAmount>
        {unitInfos.unitName && (
          <S.UnitContent>
            <S.CaseText>Realizando saque como</S.CaseText>

            <S.UnitCase>{unitInfos?.unitName}</S.UnitCase>
          </S.UnitContent>
        )}

        <h3>Qual o valor de saque?</h3>
        <LabDsTextField
          value={amountState}
          type="number"
          label="00.00"
          onChangeInput={({ detail }) => {
            setAmountState(detail);
            setAmount(Number(detail.replace('.', '').replace(',', '.')));
          }}
          state={checkBalance() ? 'error' : 'default'}
          helperText={
            checkBalance() ? 'Valor acima do disponível para saque' : ''
          }
          mask="currency"
        />

        <S.TotalBalance>
          <h4>Saldo total</h4>
          <S.Balances>{convertCentsToBRL(balance)}</S.Balances>
        </S.TotalBalance>

        <S.withdrawalAmount>
          <div>
            <h4>Saque</h4>
            <S.LowerCaseText>Valor depositado para saque</S.LowerCaseText>
          </div>
          <S.Balances>{convertCentsToBRL(availableWithdraw)}</S.Balances>
        </S.withdrawalAmount>

        <S.BonusCredit>
          <div>
            <h4>Crédito bônus</h4>

            <S.LowerCaseText>
              Crédito com data de expiração <br /> e sem possibilidade de saque
            </S.LowerCaseText>
          </div>

          <S.Balances>{convertCentsToBRL(totalBonusAmount)}</S.Balances>
        </S.BonusCredit>
      </S.ContentAmount>

      <S.Footer>
        <LabDsButton
          label="Seguinte"
          type="submit"
          disabled={
            amount <= 0 ||
            totalAmount > totalBalance ||
            String(totalAmount).replace(/[^0-9]/g, '') === ''
          }
          onHandleButton={() => {
            onSubmit();
          }}
        />
      </S.Footer>
    </S.Container>
  );
};

export default Amount;
