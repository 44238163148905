import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  overflow-x: auto;
`;

export const Invoice = styled.div<{ invoiceColor: string }>`
  padding: 1rem 0rem 0rem 0rem;
  display: flex;
  align-items: center;

  > p {
    margin-right: 1rem;
    min-width: 20rem;
    text-align: center;
    font-weight: bold;
  }

  img {
    margin-right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 1rem;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0rem 1rem;
    min-width: 13rem;
    text-align: center;
    padding: 0.25rem;
    border-radius: 1.5rem;
    color: var(--white);
    background: ${props => props.invoiceColor};
  }
`;

export const Table = styled.table`
  margin: 2rem 0;
  min-width: 10rem;
`;

export const Row = styled.tr`
  display: flex;
  width: 100%;
  background-color: #e6e6ef;
  border-radius: 3px;

  :not(:first-child):hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  &.disabled:hover {
    box-shadow: none;
    cursor: auto;
  }

  :first-child {
    td {
      font-weight: normal;
      font-family: 'Inter', sans-serif;
    }
  }

  :first-of-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  .transaction-id {
    width: 20%;
    min-width: 240px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-type {
    width: 20%;
    min-width: 140px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-value {
    width: 20%;
    min-width: 120px;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-author {
    width: 20%;
    min-width: 170px;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-author-name {
    width: 20%;
    min-width: 130px;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-author-email {
    width: 20%;
    min-width: 180px;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-date {
    width: 20%;
    min-width: 150px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-status {
    width: 20%;
    min-width: 100px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Field = styled.td`
  display: flex;
  align-items: center;
  margin: 1rem 1rem;
  text-align: center;
  font-size: 16px;

  img {
    margin-right: 8px;
  }
`;

export const RowBody = styled.tr<{ invoiceColor: string }>`
  display: flex;
  width: 100%;

  :not(:first-child):hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  &.disabled:hover {
    box-shadow: none;
    cursor: auto;
  }

  :first-child {
    td {
      font-weight: normal;
      font-family: 'Inter', sans-serif;
    }
  }

  :first-of-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  .transaction-id {
    width: 20%;
    min-width: 240px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-type {
    width: 20%;
    min-width: 140px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-value {
    width: 20%;
    min-width: 120px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-author {
    width: 20%;
    min-width: 170px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-author-name {
    width: 20%;
    min-width: 130px;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-author-email {
    width: 20%;
    min-width: 180px;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-date {
    width: 20%;
    min-width: 150px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-status {
    width: 20%;
    min-width: 100px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${props => props.invoiceColor};
  }
`;
