import { setCookie } from 'nookies';
import api from '../api';

export async function getUserUnit(token: string) {
  const twoDays = 60 * 60 * 24 * 2;

  try {
    const response = await api
      .get('/users/unit', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        localStorage.setItem('v4company.unitInfo', JSON.stringify(data));
        setCookie(undefined, 'v4company.unitInfo', JSON.stringify(data), {
          maxAge: twoDays,
          path: '/',
          domain: process.env.REACT_APP_DOMAIN_NAME,
        });
      });

    return response;
  } catch (err) {
    console.error('Erro ao salvar informação da unidade', err);
    return false;
  }
}
