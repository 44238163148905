import { PaginatedStatementExtract } from '../../../../types/wallet';
import api from '../api';

export async function statementExtract(query: string) {
  try {
    const response = await api.get(`/wallet/statementExtract${query}`);
    const paginatedStatementExtract: PaginatedStatementExtract = response.data;

    return paginatedStatementExtract;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao pegar os dados de sua carteira, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}
