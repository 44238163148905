import unhappyAnimation01 from '../../../../assets/lotties/unhappypath01.png';
import unhappyAnimation02 from '../../../../assets/lotties/unhappypath02.png';
import unhappyAnimation03 from '../../../../assets/lotties/unhappypath03.png';
import unhappyAnimation04 from '../../../../assets/lotties/unhappypath04.png';
import unhappyAnimation05 from '../../../../assets/lotties/unhappypath05.png';
import unhappyAnimation06 from '../../../../assets/lotties/unhappypath06.png';

import * as S from './styles';

interface Props {
  assetIndex?: number;
  descriptionItems?: string[];
  title?: string;
  styleContainer?: React.CSSProperties;
}

export function UnHappyPath({
  assetIndex = 0,
  descriptionItems,
  title,
  styleContainer,
}: Props) {
  const unhappyPathAnimations = [
    unhappyAnimation01,
    unhappyAnimation02,
    unhappyAnimation03,
    unhappyAnimation04,
    unhappyAnimation05,
    unhappyAnimation06,
  ];

  return (
    <S.Container style={styleContainer}>
      <S.Img src={unhappyPathAnimations[assetIndex]} />

      <h3>{title}</h3>

      {descriptionItems && (
        <ul>
          {descriptionItems.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )}
    </S.Container>
  );
}
