import AvatarImage from '../../../assets/logos/V4-Logo.svg';

export const transactions = [
  {
    _id: 'AF23873D2',
    transaction: 'Deposito',
    value: 500,
    user: {
      _id: 'A5F7D555D7',
      name: 'Marlon Ferreira',
      ocupation: 'Analista de Growth',
      canBid: true,
      picture:
        'https://lh3.googleusercontent.com/a-/AOh14GjoHJkBom6Q6juILeOBNQ9jPD3MHO5Rizt2Aj0VmA=s96-c',
    },
    status: 'Concluído',
  },
  {
    _id: 'AF23873D2',
    transaction: 'Saque',
    value: 500,
    user: {
      _id: 'A5F7D555D7',
      name: 'Marlon Ferreira',
      ocupation: 'Analista de Growth',
      canBid: true,
      picture:
        'https://lh3.googleusercontent.com/a-/AOh14GjoHJkBom6Q6juILeOBNQ9jPD3MHO5Rizt2Aj0VmA=s96-c',
    },
    status: 'Concluído',
  },
  {
    _id: 'AF23873D2',
    transaction: 'Lead',
    value: 500,
    user: {
      _id: 'A5F7D555D7',
      name: 'Marlon Ferreira',
      ocupation: 'Analista de Growth',
      canBid: true,
      picture:
        'https://lh3.googleusercontent.com/a-/AOh14GjoHJkBom6Q6juILeOBNQ9jPD3MHO5Rizt2Aj0VmA=s96-c',
    },
    status: 'Concluído',
  },
  {
    _id: 'AF23873D2',
    transaction: 'Deposito',
    value: 500,
    user: {
      _id: 'A5F7D555D7',
      name: 'Marlon Ferreira',
      ocupation: 'Analista de Growth',
      canBid: true,
      picture:
        'https://lh3.googleusercontent.com/a-/AOh14GjoHJkBom6Q6juILeOBNQ9jPD3MHO5Rizt2Aj0VmA=s96-c',
    },
    status: 'Processando',
  },
  {
    _id: 'AF23873D2',
    transaction: 'Deposito',
    value: 500,
    user: {
      _id: 'A5F7D555D7',
      name: 'Marlon Ferreira',
      ocupation: 'Analista de Growth',
      canBid: true,
      picture:
        'https://lh3.googleusercontent.com/a-/AOh14GjoHJkBom6Q6juILeOBNQ9jPD3MHO5Rizt2Aj0VmA=s96-c',
    },
    status: 'Cancelado',
  },
];

export const accounts = [
  {
    id: 1,
    image: AvatarImage,
    name: 'Ana Paula',
  },
  {
    id: 2,
    image: AvatarImage,
    name: 'Ana Paula',
  },

  {
    id: 3,
    image: AvatarImage,
    name: 'Ana Paula',
  },
];
