import { useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import BidCard from '../../../Default/BidCard';
import { CustomLoading } from '../../../Default/Loadings/CustomLoading';
import { UnHappyPath } from '../../atoms/UnHappyPath';

import * as S from './styles';
import { useAuctions } from '../../../../contexts';
import { getRefurbishedAuctionsFederation } from '../../../../services/requests/leadbroker/auctions/getAuctionsFederation';

export const AuctionRefurbishedContainer = ({
  isLastVisible,
}: {
  isLastVisible: boolean;
}) => {
  const { actualTab } = useAuctions();

  const { data, fetchNextPage, isLoading, refetch } = useInfiniteQuery({
    queryKey: ['unit-auctions-refurbished'],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const response = ((await getRefurbishedAuctionsFederation(
        pageParam
      )) as unknown) as AuctionResponse;

      return response;
    },
    getNextPageParam: lastPage => {
      return lastPage.data?.length > 0
        ? Number(lastPage?.page || 0) + 1
        : undefined;
    },
  });

  useEffect(() => {
    if (isLastVisible) {
      fetchNextPage();
    }
  }, [isLastVisible, fetchNextPage]);

  useEffect(() => {
    refetch();
  }, [refetch, actualTab]);

  return (
    <>
      {isLoading ? (
        <CustomLoading />
      ) : (
        <S.Container>
          <S.Bids>
            {data?.pages?.map(page =>
              page?.data
                ?.sort((a, b) => (a?.expiresAt < b?.expiresAt ? -1 : 1))
                ?.map(auction => {
                  return (
                    <>
                      {auction?._id && (
                        <BidCard
                          key={auction?._id}
                          auction={auction as Auction}
                        />
                      )}
                    </>
                  );
                })
            )}
          </S.Bids>
          {!isLoading && data?.pages[0]?.data?.length === 0 && (
            <S.UnhappyPath>
              <UnHappyPath
                title="Ofertas ainda não disponíveis"
                assetIndex={0}
              />
            </S.UnhappyPath>
          )}
        </S.Container>
      )}
    </>
  );
};
