import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4rem;
`;

export const FilterButton = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  border-radius: 1.5rem;
  padding: 0.5rem;
  border: none;
  margin-left: 0.75rem;
  font-size: 0.625rem;
  color: var(--white);
  background-color: var(--grayscale-6);

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: var(--primary-light);
    `}
`;
