/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SectionTitle } from 'v4components-react';
import { LabDsButton, LabDsTextField } from 'v4web-components-react';
import { schema } from './utils/schema';

import * as S from './styles';
import { useDeposit } from '../../../../contexts';
import { getCardTypeIcon } from '../../../../utils/validateCard';
import { removeFirstWord } from '../../../../utils/removeFistWord';

interface Card {
  name: string;
  number: string;
  month: string;
  year: string;
  code: string;
}

export function Card() {
  const { setStageDeposit, creditCard } = useDeposit();
  const { control, handleSubmit, formState } = useForm<Card>({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
  const onSubmit: SubmitHandler<Card> = async data => {
    // @ts-ignore
    const cc = Iugu.CreditCard(
      data.number,
      data.month,
      data.year,
      data.name.split(' ')[0],
      removeFirstWord(data.name),
      data.code
    );
    creditCard.setNameCard(data.name);
    creditCard.setNumberCard(data.number);
    creditCard.setMonth(data.month);
    creditCard.setYear(data.year);
    creditCard.setSecurityCode(data.code);
    // @ts-ignore
    // eslint-disable-next-line func-names
    Iugu.createPaymentToken(cc, function(response) {
      if (response.errors) {
        console.error(response.errors);
      } else {
        creditCard.setCardToken(response.id);
        setStageDeposit('installment');
      }
    });
  };

  return (
    <S.Container
      placeholder=""
      onSubmit={handleSubmit(onSubmit)}
      onPointerEnterCapture={() => null}
      onPointerLeaveCapture={() => null}
    >
      <section>
        <SectionTitle text="Dados do Cartão" />

        <S.Row>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="48%">
                <LabDsTextField
                  {...field}
                  className="nameCard"
                  titleInput="Nome no cartão*"
                  helperText={errors.name && errors.name.message}
                  state={errors.name ? 'error' : 'default'}
                  type="text"
                  label="Digite seu nome"
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />

          <Controller
            name="number"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="48%">
                <LabDsTextField
                  {...field}
                  className="cardNumber"
                  titleInput="Número do cartão*"
                  helperText={errors.number && errors.number.message}
                  mask="credit_card"
                  maxLength={19}
                  state={errors.number ? 'error' : 'default'}
                  label="3123 xxxx xxxx xxxx"
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />

                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  {field.value && (
                    <img
                      style={{
                        position: 'absolute',
                        right: '3.5%',
                        top: '-1.1rem',
                        transform: 'translateY(-120%)',
                        width: '1.875rem',
                      }}
                      src={getCardTypeIcon(
                        field.value.replace(/\s/g, '').slice(0, 6)
                      )}
                      alt=""
                      className="card-type-icon"
                    />
                  )}
                </div>
              </S.InputContent>
            )}
          />
        </S.Row>

        <S.Row>
          <S.DataContainer>
            <p>Data de vencimento*</p>
            <S.MainContainerData>
              <Controller
                name="month"
                control={control}
                render={({ field }) => (
                  <S.InputContent maxWidth="45%">
                    <LabDsTextField
                      {...field}
                      className="mes"
                      helperText={errors.month && errors.month.message}
                      state={errors.month ? 'error' : 'default'}
                      type="text"
                      label="Mês"
                      maxLength={2}
                      onChangeInput={({ detail }) => {
                        field.onChange(detail);
                      }}
                    />
                  </S.InputContent>
                )}
              />
              <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <S.InputContent maxWidth="45%">
                    <LabDsTextField
                      {...field}
                      className="year"
                      maxLength={4}
                      helperText={errors.year && errors.year.message}
                      state={errors.year ? 'error' : 'default'}
                      type="text"
                      label="Ano"
                      onChangeInput={({ detail }) => {
                        field.onChange(detail);
                      }}
                    />
                  </S.InputContent>
                )}
              />
            </S.MainContainerData>
          </S.DataContainer>

          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="48%">
                <LabDsTextField
                  {...field}
                  className="complement"
                  titleInput="Código de segurança*"
                  helperText={errors.code && errors.code.message}
                  state={errors.code ? 'error' : 'default'}
                  label="Código de segurança*"
                  maxLength={4}
                  type="text"
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />
        </S.Row>
      </section>

      <S.Footer>
        <LabDsButton
          label="Próximo"
          size="medium"
          variant="primary"
          color="green"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        />
      </S.Footer>
    </S.Container>
  );
}
