import {
  SetStateAction,
  Dispatch,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { FiX } from 'react-icons/fi';
import { PaymentFields } from './paymentFields';
import { PaymentInfo } from './paymentInfo';
import { Payment } from './payment';
import * as S from './styles';
import { useToast } from '../../../contexts/toast';
import { Info } from '../Withdraw/info';
import { Amount } from './amount';
import { Address } from './address';
import { useDeposit } from '../../../contexts';
import Summary from './summary';
import { Card } from './card';
import { Installment } from './installment';
import { LoadingAddBonus } from '../../../components/Default/Loadings/LoadingAddBonus';
import { PaymentError } from './paymentError';
import { CardPayment } from './cardPaymnet';
import {
  IAddFundsDTO,
  PAYABLE_WITH,
} from '../../../services/requests/leadbroker/wallet/interfaces/addFoundsInterface';
import { addFunds } from '../../../services/requests/leadbroker/wallet/addFunds';
import { Payer } from './payer';
import { interestRates } from './utils/interestRate';

interface Props {
  handleToggleModal: () => void;
  title?: string;
  backButton?: () => void;
  arrowLeftButton?: boolean;
  setStageProps?: Dispatch<SetStateAction<Stages | undefined>>;
}

type Stages =
  | 'amount'
  | 'firstTime'
  | 'info'
  | 'fields'
  | 'summary'
  | 'payment'
  | 'charge'
  | 'card'
  | 'error'
  | 'cardPayment';

export function Deposit({
  title,
  backButton,
  handleToggleModal,
  arrowLeftButton,
  setStageProps,
}: Props) {
  const {
    stageDeposit,
    setStageDeposit,
    paymentType,
    amount,
    expireDate,
    creditCard,
  } = useDeposit();
  const [errorCode, setErrorCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({} as PaymentDetails);
  const { addToast } = useToast();

  function parseMoneyToCents(value: number) {
    return value * 100;
  }

  const calculateTotalValue = useCallback(() => {
    const rates = interestRates[Number(creditCard.installement.key) - 1];
    const newAmount = (amount * rates) / 100;
    return amount + Number(newAmount.toFixed(2));
  }, [amount, creditCard.installement]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const paymentObject: IAddFundsDTO = {
      value: parseMoneyToCents(Number(amount.toString().replace(',', '.'))),
      dueDate: expireDate,
      valueWithIncrease: 1,
      payableWith: paymentType as PAYABLE_WITH,
      useUnitAddress: true,
      useAuthenticatedUser: true,
    };

    let paymentObjectCard: IAddFundsDTO = null;

    if (paymentType === 'credit_card') {
      const creditCardToken = creditCard?.cardToken;

      paymentObjectCard = {
        valueWithIncrease: Math.trunc(calculateTotalValue() * 100),
        value: Math.trunc(amount * 100),
        dueDate: new Date().toISOString(),
        payableWith: paymentType as PAYABLE_WITH,
        installments: Number(creditCard.installement.key),
        maxInstallmentsValue: Number(creditCard.installement.key),
        useUnitAddress: false,
        useAuthenticatedUser: false,
        payer: creditCard.payer,
        billingAddress: {
          city: creditCard.city,
          complement: creditCard.complement,
          country: 'Brasil',
          state: creditCard.state,
          number: creditCard.numberHouse,
          district: creditCard.district,
          street: creditCard.street,
          zipCode: creditCard.zipCode,
        },
        card: {
          data: { creditCardToken },
        },
      };
    }

    let paymentDetails: {
      error: Record<string, unknown> | null;
      data: PaymentDetails;
    } = { error: null, data: null };

    if (paymentType !== 'credit_card') {
      paymentDetails = await addFunds(paymentObject);
    }

    if (paymentObjectCard) {
      paymentDetails = await addFunds(paymentObjectCard);
    }

    if (paymentDetails.data) {
      if (paymentType === 'credit_card') {
        setPaymentData(paymentDetails.data);
        setStageDeposit('cardPayment');
      } else {
        setPaymentData(paymentDetails.data);
        setStageDeposit('payment');
      }
    }

    if (paymentDetails.error || !paymentDetails.data) {
      setStageDeposit('summary');

      addToast({
        type: 'error',
        description: `Falha ao gerar pagamento, tente novamente`,
      });
    }

    if (paymentDetails?.error?.errorCode) {
      setErrorCode(paymentDetails?.error?.errorCode as string);
      setStageDeposit('error');
    }

    setLoading(false);
  }, [
    addToast,
    amount,
    expireDate,
    paymentType,
    setStageDeposit,
    calculateTotalValue,
    creditCard.city,
    creditCard.complement,
    creditCard.installement,
    creditCard.numberHouse,
    creditCard.state,
    creditCard.street,
    creditCard.district,
    creditCard.payer,
    creditCard.zipCode,
    creditCard?.cardToken,
  ]);

  useEffect(() => {
    document.title = 'Carteira - V4 LB';
  }, []);

  const formPages = {
    firstTime: (
      <Info
        backButton={handleToggleModal}
        nextButton={() => {
          setStageDeposit('info');
          if (setStageProps) setStageProps('info');
        }}
      />
    ),
    amount: <Amount />,
    info: <PaymentInfo />,
    adress: <Address />,
    card: <Card />,
    payer: <Payer />,
    installment: <Installment />,
    fields: <PaymentFields />,
    summary: <Summary handleNextModal={handleSubmit} />,
    cardPayment: (
      <CardPayment
        handleToggleModal={handleToggleModal}
        paymentDetails={paymentData}
        loading={loading}
      />
    ),
    error: (
      <PaymentError
        handleToggleModal={handleToggleModal}
        paymentDetails={paymentData}
        loading={loading}
        errorCode={errorCode}
      />
    ),
    payment: (
      <Payment
        handleToggleModal={handleToggleModal}
        paymentDetails={paymentData}
        loading={loading}
      />
    ),
  };

  return (
    <S.Container>
      <S.ModalHeader>
        {arrowLeftButton && (
          <MdOutlineArrowBackIosNew size={15} onClick={() => backButton()} />
        )}
        {stageDeposit !== 'cardPayment' ? (
          <h4 className="titleModal">{title}</h4>
        ) : (
          <h4
            className="titleModal"
            style={{
              marginLeft: '0',
            }}
          >
            Depósito com cartão de crédito
          </h4>
        )}
        <FiX
          className="closeButton"
          color="var(--secondary-main)"
          size={19}
          onClick={handleToggleModal}
        />
      </S.ModalHeader>
      {loading ? (
        <>
          <S.LoadingContainer>
            <LoadingAddBonus />
            <p>Carregando</p>
            <span>A confirmação pode demorar até 01 minuto</span>
          </S.LoadingContainer>
          <footer />
        </>
      ) : (
        <>{formPages[stageDeposit || 'amount']}</>
      )}
    </S.Container>
  );
}
