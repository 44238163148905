import { useField } from '@unform/core';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from 'react';

import { RiCalendar2Fill } from 'react-icons/ri';
import * as S from './styles';

interface CalendarProps extends ReactDatePickerProps {
  name: string;
  containerStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  label?: string;
  description?: string;
  selected?: Date | null | undefined;
  onChange: (date: Date) => void;
  dateFormat?: string;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  dropdownMode?: 'select' | 'scroll' | undefined;
}

export function CalendarPicker({
  name,
  description,
  containerStyle,
  labelStyle,
  label,
  selected,
  onChange,
  dateFormat,
  ...props
}: CalendarProps) {
  const [isFilled] = useState(false);

  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
    });
  }, [registerField, fieldName]);

  return (
    <S.Container style={containerStyle} isErrored={!!error} isFilled={isFilled}>
      <label htmlFor={name} style={labelStyle}>
        {label}
      </label>
      {description && <p>{description}</p>}
      <S.SelectContainer isErrored={!!error}>
        <DatePicker
          selected={selected}
          dateFormat={dateFormat}
          onChange={onChange}
          className="form-control"
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale="pt-BR"
          placeholderText="Selecione uma data"
          {...props}
        />
        <RiCalendar2Fill color="var(--grayscale-60)" />
        {/* <DatePicker
          id={name}
          name={name}
          style={{ border: 0, width: '13.6rem' }}
          placeholder="Selecione uma data"
          locale={locale}
          bordered={false}
        /> */}
      </S.SelectContainer>

      {error && <small>{error}</small>}
    </S.Container>
  );
}
