import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FaChevronRight,
  FaChevronLeft,
  FaRegCheckCircle,
} from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  LabDsButton,
  LabDsTextField,
  LabDsTooltip,
} from 'v4web-components-react';
import { BiMedal } from 'react-icons/bi';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import { Form } from '@unform/web';
import { Dialog } from '@reach/dialog';
import { parseCookies } from 'nookies';
import { ClipBoardCopy } from '../../Default/ClipBoardCopy';
import '@reach/dialog/styles.css';

import { useQueryReadOneAuctionPack } from '../../../services/requests/leadbroker/auctions/readOneAuction';
import * as S from './styles';
import { getValidationErrors } from '../../../utils/getValidationErrors';
import { executeBidPack } from '../../../services/requests/leadbroker/auctions/bidPack';
import { useAuctions } from '../../../contexts/auctions';
import { formatDateWithHours } from '../../../utils/dateFunctions';
import { useTicker } from '../../../hooks/useTicker';
import { Button } from '../../Default/Button';
import { useBalance } from '../../../hooks/wallet';
import { convertCentsToBRL } from '../../../utils/convertCentsToBRL';
import { ErrorModal } from '../../AtomicDesign/molecules/ErrorModal';
import { useAuth } from '../../../contexts/auth';
import { Deposit } from '../../../pages/Wallet/Deposit';
import { CustomLoading } from '../../Default/Loadings/CustomLoading';
import { ModalLoading } from '../../Default/Loadings/ModalLoading';
import { useDeposit } from '../../../contexts';

interface BidPackDetails {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const BidPackDetails: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const previousUrl = '/';
  const [currentLead, setCurrentLead] = useState<number>(0);

  const formatDate = (date: Date) => {
    const today = new Date();
    const newDate = new Date(date);

    if (
      newDate.getDate() === today.getDate() &&
      newDate.getMonth() === today.getMonth() &&
      newDate.getFullYear() === today.getFullYear()
    ) {
      return 'Hoje';
    }
    return newDate.toLocaleDateString();
  };

  const formatNumber = (number: number) => {
    return (number / 100).toFixed(2).replace('.', ',');
  };

  const minimumValueToBid = 900;
  const initialBidValue = minimumValueToBid + 100;
  const [isCopyEmail, setIsCopyEmail] = useState(false);
  const {
    unitInfos,
    auctionPack: auction,
    setAuctionPack: setAuction,
  } = useAuctions();

  const { stageDeposit, checkGoBackDeposit, checkArrowStage } = useDeposit();

  const [isOpenBuyModal, setIsOpenBuyModal] = useState(false);
  const [isOpenPermissionErrorModal, setIsOpenPermissionErrorModal] = useState(
    false
  );

  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];

  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [userCanBid, setUserCanBid] = useState(false);
  const [bidDisabled, setBidDisabled] = useState(false);

  const [isOpenDepositModal, setIsOpenDepositModal] = useState(false);
  const [valueBid, setValueBid] = useState<string>(
    formatNumber(auction?.winner?.value + initialBidValue)
  );

  const [loading, setLoading] = useState(false);
  const [isLoadingBidTime, setIsLoadingBidTime] = useState(false);

  const [isLoadingBid, setIsLoadingBid] = useState(false);
  const bidFormRef = useRef<FormHandles>(null);
  const { balance, totalBonusAmount } = useBalance();
  const sumBalance = balance + totalBonusAmount;
  const { user } = useAuth();
  const { permissions } = user;
  const { hours, minutes, seconds, isTimeUp } = useTicker(
    auction.expiresAt ? new Date(auction?.expiresAt) : new Date()
  );
  const [labelBuyNow, setLabelBuyNow] = useState('Compre já');

  const location = useLocation();
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const balanceErrorList = useMemo(
    () => [
      'You do not have enough balance.',
      'Available balance is less than value.',
      'Insufficient funds',
      'Wallet not found',
    ],
    []
  );
  const hasBalanceError = useCallback(
    newAuction =>
      balanceErrorList.includes(newAuction[1]?.response.data.message),
    [balanceErrorList]
  );

  const permissionError = useMemo(
    () => [
      'You cannot bid on this auction.',
      'You do not have permission to bid.',
    ],
    []
  );
  const hasPermissionError = useCallback(
    error => permissionError.includes(error[1]?.response.data.message),
    [permissionError]
  );

  const onDismiss = useCallback(() => {
    if (location.state === null) {
      return navigate(previousUrl);
    }

    return navigate(-1);
  }, [location, navigate]);

  const timeawaitingBid = Number(process.env.REACT_APP_TIME_AWAITING_BID);

  const disableBidTime = useCallback(() => {
    setIsLoadingBidTime(true);

    setTimeout(() => {
      setIsLoadingBidTime(false);
    }, timeawaitingBid);
  }, [timeawaitingBid]);

  const handleBid = useCallback(
    async (data: { value: number }) => {
      try {
        disableBidTime();
        setIsLoadingBid(true);
        bidFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          value: Yup.number()
            .required()
            .moreThan(
              auction?.winner?.value + minimumValueToBid,
              'Você deve cobrir o lance atual em até pelo menos R$10,00'
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (auction) {
          const newData = {
            _id: auction._id,
            auction: 'pack',
            value: data.value,
          };

          const newAuction = await executeBidPack(newData);
          setIsLoadingBid(false);

          if (hasBalanceError(newAuction)) {
            setIsOpenDepositModal(true);
            return;
          }

          if (hasPermissionError(newAuction)) {
            setIsOpenPermissionErrorModal(true);
            return;
          }

          if (id && newAuction.bids) {
            setAuction(newAuction);
          }
        }
        setIsLoadingBid(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          setIsLoadingBid(false);

          bidFormRef.current?.setErrors(errors);
        }
      }
    },
    [
      disableBidTime,
      auction,
      hasBalanceError,
      hasPermissionError,
      id,
      setAuction,
    ]
  );

  const { isLoading, error } = useQueryReadOneAuctionPack(
    id as string,
    setAuction,
    onDismiss,
    token,
    handleBid
  );

  useEffect(() => {
    if (error) {
      navigate('/');
    }
  }, [error, navigate]);

  useEffect(() => {
    setValueBid(formatNumber(auction?.winner?.value + initialBidValue));
  }, [auction?.winner?.value, initialBidValue, isLoading]);

  const handleBidDisabled = useCallback(() => {
    if (!auction?.winner) return;

    if (auction?.winner?.unitId === user?.unitId) {
      setBidDisabled(true);
    } else {
      setBidDisabled(false);
    }
  }, [auction?.winner, user?.unitId]);

  useEffect(() => {
    handleBidDisabled();
  }, [handleBidDisabled]);

  const handleBuy = async (bidId: string) => {
    setLoading(true);
    const data = {
      _id: bidId,
      value: auction?.winner?.value,
      auction: 'pack',
      buyNow: true,
    };
    const wasBought = await executeBidPack(data);

    if (
      wasBought[1]?.response?.status === 500 &&
      !hasBalanceError(wasBought) &&
      !hasPermissionError(wasBought)
    ) {
      setLoading(false);
      return;
    }

    if (hasBalanceError(wasBought)) {
      setIsOpenDepositModal(true);
      setLoading(false);
      return;
    }
    if (hasPermissionError(wasBought)) {
      setIsOpenPermissionErrorModal(true);
      setLoading(false);
      return;
    }

    navigate('/');

    setLoading(false);
  };

  async function clipboardCopy(text: string) {
    await navigator.clipboard.writeText(text);
    setIsCopyEmail(true);
    setTimeout(() => {
      setIsCopyEmail(false);
    }, 1000);
  }

  const setCanBid = useCallback(async () => {
    if (user) {
      if (permissions && permissions.leadbroker.bid) {
        setUserCanBid(true);
      }
    }
  }, [permissions, user]);

  useEffect(() => {
    setCanBid();
  }, [setCanBid]);

  return (
    <>
      {showDeposit ? (
        <Dialog
          aria-labelledby="label"
          initialFocusRef={buttonRef}
          className="modalDeposit"
        >
          <Deposit
            title="Depositar"
            arrowLeftButton={checkArrowStage(stageDeposit as string)}
            backButton={() => checkGoBackDeposit(stageDeposit as string)}
            handleToggleModal={() => setShowDeposit(!showDeposit)}
          />
        </Dialog>
      ) : (
        <Dialog
          aria-labelledby="label"
          onDismiss={onDismiss}
          initialFocusRef={buttonRef}
          style={{
            width: '75rem',
            maxWidth: '71.25rem',
            borderRadius: '0.5rem',
            padding: '2.5rem',
            marginTop: 'calc(2rem)',
            marginBottom: 'calc(2rem)',
          }}
        >
          {loading || !auction.createdAt ? (
            <ModalLoading rows={5} />
          ) : (
            <S.Container>
              <header>
                <div>
                  <h2>
                    {auction?.level}
                    {' #'}
                    {auction?.packSequencial}{' '}
                  </h2>
                  <div className="created-at">
                    <p>
                      Gerado em:&nbsp;
                      <span>{formatDate(auction.createdAt)}</span>
                    </p>
                  </div>
                  <S.Row style={{ justifyContent: 'flex-start' }}>
                    <small className="qtd">
                      {' '}
                      <b>Faturamento total:</b> {auction?.revenue}
                    </small>
                  </S.Row>
                  <S.Row style={{ justifyContent: 'flex-start' }}>
                    <small className="qtd">
                      {' '}
                      <b>Quantidade:</b> {auction?.leads?.length || 0} leads
                    </small>
                  </S.Row>
                </div>

                <div>
                  <h2>
                    Lance atual:{' '}
                    <span>{convertCentsToBRL(auction?.winner?.value)}</span>
                  </h2>
                  <S.Row style={{ justifyContent: 'flex-start' }}>
                    <small>Tempo:</small>
                    <p
                      style={{
                        color: 'var(--primary-main)',
                        fontWeight: '900',
                      }}
                    >
                      {isTimeUp
                        ? 'Finalizado'
                        : `${String(hours).padStart(2, '0')}:${String(
                            minutes
                          ).padStart(2, '0')}:${String(seconds).padStart(
                            2,
                            '0'
                          )}`}
                    </p>
                  </S.Row>
                </div>
                <S.CloseModalButton onClick={onDismiss} />
              </header>
              <S.ContentLeads>
                <S.SliderLead>
                  <S.BtnCurrent
                    left={false}
                    onClick={() => setCurrentLead(currentLead + 1)}
                    disabled={auction?.leads?.length - 1 === currentLead}
                  >
                    <FaChevronRight />
                  </S.BtnCurrent>
                  <S.Lead>
                    <>
                      {auction?.leads !== undefined &&
                      auction?.leads[currentLead]?.bestLead ? (
                        <>
                          <S.MainLead>
                            <BiMedal size={20} color="var(--warning)" />
                          </S.MainLead>
                        </>
                      ) : null}
                    </>
                    <S.LeadTitle>
                      {auction?.leads !== undefined
                        ? auction.leads[currentLead].lead?.company
                        : ''}
                    </S.LeadTitle>
                    <S.LeadFooter>
                      <>
                        <S.Column className="footer">
                          <small>Faturamento</small>
                          <p>
                            {auction?.leads !== undefined
                              ? auction.leads[currentLead].lead?.revenue
                              : ''}
                          </p>
                        </S.Column>
                        <S.Column className="footer">
                          <small>Canal</small>
                          <p>
                            {auction?.leads !== undefined
                              ? auction.leads[currentLead].lead?.channel
                              : ''}
                          </p>
                        </S.Column>
                      </>
                    </S.LeadFooter>
                  </S.Lead>
                  <S.Row>
                    {auction?.leads?.map((item: AuctionPack, index: number) => {
                      return (
                        <S.MarkSlider
                          current={index === currentLead}
                          onClick={() => setCurrentLead(index)}
                        />
                      );
                    })}
                  </S.Row>
                  <S.BtnCurrent
                    left
                    onClick={() => setCurrentLead(currentLead - 1)}
                    disabled={currentLead === 0}
                  >
                    <FaChevronLeft />
                  </S.BtnCurrent>
                </S.SliderLead>

                <S.Details>
                  <S.Column>
                    <small>Campanha</small>
                    <p
                      title={
                        auction?.leads !== undefined
                          ? auction.leads[currentLead].lead?.campaign
                          : ''
                      }
                    >
                      {auction?.leads !== undefined
                        ? auction.leads[currentLead].lead?.campaign
                        : ''}
                    </p>
                  </S.Column>

                  <S.Column>
                    <small>Canal</small>
                    <p>
                      {auction?.leads !== undefined
                        ? auction.leads[currentLead].lead?.channel
                        : ''}
                    </p>
                  </S.Column>

                  <S.Column>
                    <small>E-mail</small>
                    <p>
                      @
                      {auction?.leads !== undefined
                        ? auction.leads[currentLead].lead?.email
                        : ''}
                      <ClipBoardCopy
                        active={isCopyEmail}
                        onClick={() =>
                          clipboardCopy(auction.leads[currentLead].lead?.email)
                        }
                      />
                    </p>
                  </S.Column>

                  <S.Column>
                    <small>DDD</small>
                    <p>{auction.leads[currentLead].lead?.tel || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Nome do contato</small>
                    <p>{auction.leads[currentLead].lead?.name || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Urgência para iniciar</small>
                    <p
                      title={
                        auction?.leads !== undefined
                          ? auction.leads[currentLead].lead?.urgencyToStart
                          : '-'
                      }
                    >
                      {auction.leads[currentLead].lead?.urgencyToStart
                        ? auction.leads[currentLead].lead?.urgencyToStart
                        : '-'}
                    </p>
                  </S.Column>

                  <S.Column>
                    <small>Segmento</small>
                    <p>
                      {auction.leads[currentLead].lead?.segment
                        ? auction.leads[currentLead].lead?.segment
                        : '-'}
                    </p>
                  </S.Column>

                  <S.Column>
                    <small>Cargo</small>
                    <p>
                      {auction.leads[currentLead].lead?.companyPosition
                        ? auction.leads[currentLead].lead?.companyPosition
                        : '-'}
                    </p>
                  </S.Column>
                  <S.Column>
                    <small>CNPJ</small>
                    <p>
                      {auction.leads[currentLead].lead?.cnpj
                        ?.replace(/\D/g, '')
                        ?.slice(0, 3) || 'CNPJ não informado'}
                    </p>
                  </S.Column>
                  <S.Column>
                    <small>Produtos de marketing</small>
                    <p>
                      {auction.leads[currentLead].lead?.marketingProduct ||
                        'Assessoria'}
                    </p>
                  </S.Column>
                </S.Details>
              </S.ContentLeads>
              {auction.leads[currentLead].lead?.description && (
                <S.Description>
                  <S.Column>
                    <small>Descrição</small>
                    <S.InputDescription
                      value={auction.leads[currentLead].lead?.description}
                      disabled
                    />
                  </S.Column>
                </S.Description>
              )}
              <S.BidHistory
                isGreaterThanFour={
                  auction?.bids ? auction?.bids?.length > 4 : false
                }
              >
                <div>
                  <p>Último Lance</p>
                  <p>Valor</p>
                  <p> </p>
                </div>

                {auction?.bids?.length === 0 ? (
                  <div>
                    <p>Não foi feito nenhum lance nesse lead ainda</p>
                  </div>
                ) : (
                  auction?.bids
                    ?.slice(0)
                    .reverse()
                    .map((bid, index) => (
                      <div key={bid?._id}>
                        <p>{formatDateWithHours(bid?.time)}</p>
                        <p>{convertCentsToBRL(bid?.value)}</p>
                        <p>
                          {index === 0 && bid?.unitId === user?.unitId ? (
                            <>
                              <p className="info__bid">
                                O último lance foi realizado pela sua unidade!
                              </p>{' '}
                            </>
                          ) : null}
                        </p>
                      </div>
                    ))
                )}
              </S.BidHistory>
              <footer>
                <Form
                  placeholder=""
                  onSubmit={handleBid}
                  ref={bidFormRef}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                  onPointerEnterCapture={() => null}
                  onPointerLeaveCapture={() => null}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '4rem',
                    }}
                  >
                    <S.Input>
                      <LabDsTextField
                        type={userCanBid ? 'number' : 'text'}
                        mask="currency"
                        titleInput={`Dar lance como unidade ${unitInfos.unitName} & Co.`}
                        label="Valor"
                        value={valueBid}
                        state={
                          !userCanBid ||
                          bidFormRef?.current?.getFieldError('value')
                            ? 'error'
                            : 'default'
                        }
                        helperText={
                          bidFormRef?.current?.getFieldError('value')
                            ? bidFormRef?.current?.getFieldError('value')
                            : ''
                        }
                        disabled={
                          (balance < auction?.winner?.value + minimumValueToBid,
                          !userCanBid) ||
                          isLoadingBid ||
                          bidDisabled
                        }
                        onChangeInput={({ detail }) => {
                          setValueBid(detail);
                        }}
                      />
                    </S.Input>

                    {!userCanBid || bidDisabled ? (
                      <S.TooltipContainer>
                        <LabDsTooltip
                          className="bidTooltipButton"
                          label={
                            bidDisabled
                              ? 'Um novo lance só pode ser realizado caso o seu seja superado'
                              : 'Você não está apto a dar lances. Caso necessário, solicite a permissão para o administrador da sua unidade.'
                          }
                        >
                          <LabDsButton
                            label="Realizar lance"
                            type="submit"
                            variant="outlined"
                            disabled
                          />
                        </LabDsTooltip>
                      </S.TooltipContainer>
                    ) : (
                      <S.ButtonContainer>
                        <LabDsButton
                          label="Realizar lance"
                          type="submit"
                          variant="outlined"
                          loading={isLoadingBid || isLoadingBidTime}
                          onHandleButton={() => {
                            handleBid({
                              value: Number(valueBid.replace(/\D/g, '')),
                            });
                          }}
                          disabled={
                            sumBalance <
                              auction?.winner?.value + minimumValueToBid ||
                            !userCanBid ||
                            isLoadingBid ||
                            !(valueBid.length > 0)
                          }
                        />
                      </S.ButtonContainer>
                    )}
                  </div>

                  {userCanBid ? (
                    <>
                      <p>Digite o valor aqui</p>
                    </>
                  ) : (
                    <p className="errorBid">
                      Você não tem permissão para dar lances
                    </p>
                  )}
                </Form>
                <LabDsTooltip
                  label="Na opção compre já, você pode arrematar o lead antes do tempo
                acabar, por apenas 1.8x o valor!"
                >
                  <LabDsButton
                    variant="cta"
                    onMouseEnter={() => {
                      setLabelBuyNow(
                        `Compre já R$ ${(
                          (auction?.winner?.value * 1.8) /
                          100
                        ).toFixed(2)}`
                      );
                    }}
                    onMouseOut={() => {
                      setLabelBuyNow('Compre já');
                    }}
                    disabled={!userCanBid}
                    label={labelBuyNow}
                    onHandleButton={() => setIsOpenBuyModal(true)}
                  />
                </LabDsTooltip>
              </footer>{' '}
              {isOpenBuyModal && (
                <Dialog
                  aria-labelledby="label"
                  onDismiss={() => setIsOpenBuyModal(false)}
                  initialFocusRef={buttonRef}
                  style={{ marginTop: '20vh', width: '70vw' }}
                >
                  {loading ? (
                    <S.Modal>
                      <CustomLoading style={{ left: 0 }} />
                    </S.Modal>
                  ) : (
                    <S.Modal>
                      <h2>
                        Deseja comprar esse Lead por {` `}
                        {convertCentsToBRL(auction?.winner?.value * 1.8)}
                      </h2>
                      <p>
                        Você pode arrematar essa Oferta agora pagando 1.8x o
                        valor do lance atual. <br />
                        Deseja prosseguir com a compra?
                      </p>
                      <S.ButtonsContainer>
                        <LabDsButton
                          variant="danger-outlined"
                          size="small"
                          label="Cancelar"
                          onHandleButton={() => setIsOpenBuyModal(false)}
                        />
                        <LabDsButton
                          variant="danger-primary"
                          size="small"
                          label="Comprar"
                          onHandleButton={() => {
                            handleBuy(auction._id);
                          }}
                        />
                      </S.ButtonsContainer>
                    </S.Modal>
                  )}
                </Dialog>
              )}
              {isOpenConfirmationModal && (
                <Dialog
                  aria-labelledby="label"
                  onDismiss={() => setIsOpenConfirmationModal(false)}
                  initialFocusRef={buttonRef}
                  style={{ marginTop: '20vh', width: '70vw' }}
                >
                  <S.Modal>
                    <FaRegCheckCircle />
                    <h2>Lance realizado com sucesso!</h2>
                    <S.ButtonsContainer>
                      <Button
                        outline
                        small
                        type="button"
                        onClick={() => setIsOpenConfirmationModal(false)}
                      >
                        Fechar
                      </Button>
                      <Button type="button" small onClick={onDismiss}>
                        Ver todos os lances
                      </Button>
                    </S.ButtonsContainer>
                  </S.Modal>
                </Dialog>
              )}
              {isOpenDepositModal && (
                <ErrorModal
                  ref={buttonRef}
                  closeModal={() => setIsOpenDepositModal(false)}
                  actionButton={() => {
                    setShowDeposit(true);
                    setIsOpenDepositModal(false);
                    setIsOpenBuyModal(false);
                  }}
                  actionTitle="Depositar agora"
                  title="Ação não concluida por falta de saldo"
                  description={`Faltam ${convertCentsToBRL(
                    Number(valueBid.replace(/\D/g, '')) - balance
                  )} reais para conseguir realizar esse lance`}
                />
              )}
              {isOpenPermissionErrorModal && (
                <ErrorModal
                  ref={buttonRef}
                  closeModal={() => setIsOpenPermissionErrorModal(false)}
                  title="Você não tem permissão para dar lances"
                  description="Entre em contato com o administrador da franquia para mais informações."
                />
              )}
            </S.Container>
          )}
        </Dialog>
      )}
    </>
  );
};

export default BidPackDetails;
