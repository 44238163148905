import { SectionTitle } from 'v4components-react';
import { LabDsButton } from 'v4web-components-react';
import { useCallback, useState } from 'react';
import * as S from './styles';
import { CustomLoading } from '../../../../components/Default/Loadings/CustomLoading';
import { useToast } from '../../../../contexts/toast';
import { withdraw } from '../../../../services/requests/leadbroker/wallet/withdraw';
import { useWithDraw } from '../../../../contexts/withdraw';
import {
  convertValueToBRL,
  convertCentsToBRL,
} from '../../../../utils/convertCentsToBRL';
import { formatPhone } from '../../../../utils/formatPhone';
import { useAuth } from '../../../../contexts/auth';

interface SummaryPageProps {
  loading: boolean;
}

const Summary: React.FC<SummaryPageProps> = () => {
  const { addToast } = useToast();
  const { bankInformation, amount, setPage, walletInfo } = useWithDraw();
  const { user } = useAuth();
  const { data: userInformation } = bankInformation;

  const [loading, setLoading] = useState(false);

  const handleWithdrawSubmit = useCallback(async () => {
    const parseAmountToCents =
      Number(amount.toString().replace(',', '.')) * 100;
    setLoading(true);

    await withdraw(parseAmountToCents).then(res => {
      setTimeout(() => {
        if (res) {
          addToast({
            type: 'success',
            description: `Saque de ${convertCentsToBRL(
              parseAmountToCents
            )} feito com sucesso!`,
          });
          setPage('result');
        }

        if (res === false) {
          setLoading(false);
          addToast({
            type: 'error',
            description: 'Falha ao sacar carteira, tente novamente',
          });
        }
        setLoading(false);
      }, 1500);
    });
  }, [addToast, amount, setPage]);

  const staticMask = 'xxxxxxxxxxx';

  return (
    <S.Container>
      <S.Content>
        <>
          <SectionTitle text="Confira os dados para realizar o saque" />

          <S.Balances>{convertValueToBRL(amount) || staticMask}</S.Balances>

          <S.WithdrawalData>
            <div>
              <h4 className="bankTitle">Banco</h4>
              <S.LowerCaseText>
                <>{walletInfo?.bank || staticMask}</>
              </S.LowerCaseText>
            </div>

            <div>
              <h4>Tipo de conta</h4>
              <S.LowerCaseText>
                Conta {walletInfo?.accountType || staticMask}
              </S.LowerCaseText>
            </div>

            <div>
              <h4>Agência</h4>
              <S.LowerCaseText>
                {walletInfo?.agency || staticMask}
              </S.LowerCaseText>
            </div>

            <div>
              <h4>Nº da conta</h4>
              <S.LowerCaseText>
                {walletInfo?.account || staticMask}
              </S.LowerCaseText>
            </div>
          </S.WithdrawalData>

          <S.WithdrawalData>
            <div>
              <h4>Nome</h4>
              <S.LowerCaseText className="userName">
                {user?.name || staticMask}
              </S.LowerCaseText>
            </div>

            <div>
              <h4>Saque da unidade:</h4>
              <S.LowerCaseText>
                {walletInfo?.unitName || staticMask}
              </S.LowerCaseText>
            </div>
          </S.WithdrawalData>

          <S.WithdrawalData>
            <div>
              <h4>Telefone</h4>
              <S.LowerCaseText>
                {walletInfo?.phone ? formatPhone(walletInfo.phone) : staticMask}
              </S.LowerCaseText>
            </div>

            <div>
              <h4>Email</h4>
              <S.LowerCaseText>
                {userInformation?.email || user?.email}
              </S.LowerCaseText>
            </div>
          </S.WithdrawalData>
        </>
      </S.Content>

      <S.Footer>
        <LabDsButton
          label="Sacar"
          loading={loading}
          onHandleButton={() => handleWithdrawSubmit()}
        />
      </S.Footer>

      {loading && (
        <CustomLoading style={{ zIndex: 3000, left: '45%', top: '5px' }} />
      )}
    </S.Container>
  );
};

export default Summary;
