import {
  LabDsButton,
  LabDsIconSelectable,
  LabDsIconNotSelectable,
  LabDsProgressBar,
} from 'v4web-components-react';
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';

import { useLeads, useToast } from '../../../../../contexts';
import { sendFile } from '../../../../../services/requests/storage-service/sendFile';
import * as S from './styles';

export interface IFileList {
  type?: string;
  size?: number;
  name: string;
}

export function UploadFiles({
  reasonFiles,
  setReasonFiles,
}: {
  reasonFiles: IFileList[] | null;
  setReasonFiles: (files: IFileList[]) => void;
}) {
  const { addToast } = useToast();
  const {
    setLoadingUploadFile,
    reasonFileError,
    setFilesSent,
    setReasonFileError,
    uploadProgress,
    setUploadProgress,
    filesSent,
  } = useLeads();

  const updateProgress = useCallback(
    key => {
      return (
        uploadProgress?.name === reasonFiles[key]?.name &&
        uploadProgress?.progress
      );
    },
    [reasonFiles, uploadProgress.name, uploadProgress.progress]
  );

  function deleteFile(key) {
    const newFiles = reasonFiles.filter((file, index) => index !== Number(key));
    setReasonFiles(newFiles);

    filesSent.filter((file, index) => {
      if (index === Number(key)) {
        const filesCopy = Array.from(filesSent);
        filesCopy.splice(index, 1);
        return setFilesSent(filesCopy);
      }
      return false;
    });
  }

  const isSizeAccepted = checkFileSize => {
    return checkFileSize < 1000000;
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      const setFile = new Set();
      const file: IFileList[] = [];
      setReasonFileError('');

      Object.keys(acceptedFiles)?.forEach(key => {
        const fileSize =
          acceptedFiles?.length <= 1
            ? acceptedFiles[key].size
            : acceptedFiles?.map(x => x.size).reduce((a, b) => a + b);

        if (
          reasonFiles?.length > 0 &&
          reasonFiles[key]?.name === acceptedFiles[key]?.name
        ) {
          addToast({
            type: 'error',
            description: 'Esse arquivo já foi adicionado!',
          });
          return;
        }

        if (reasonFiles?.length >= 5) {
          addToast({
            type: 'error',
            description: 'Máximo de 5 arquivos',
          });
          return;
        }

        if (isSizeAccepted(fileSize)) {
          file.push(acceptedFiles[key]);

          const filterFile = file.filter(x => {
            const duplicateFile = setFile.has(x.name);
            setFile.add(x.name);

            return !duplicateFile;
          });

          setLoadingUploadFile(true);
          filterFile?.map(fileArray =>
            sendFile(fileArray, setUploadProgress)
              .then(data => {
                const filesCopy = Array.from(filesSent);
                filesCopy.push({
                  filename: data.fileName,
                  url: data.url,
                  type: fileArray.type,
                  size: fileArray.size,
                });
                setFilesSent(filesCopy);

                setLoadingUploadFile(false);
              })
              .catch(() => {
                setLoadingUploadFile(false);
                setReasonFileError(
                  'Ocorreu um erro ao enviar o arquivo, atualize a página e tente novamente'
                );
                setReasonFiles([]);
                setFilesSent([]);
              })
          );
        } else {
          addToast({
            type: 'error',
            description: `O arquivo ${acceptedFiles[key]?.name} é maior que 1MB`,
          });
        }
      });

      setReasonFiles([...(reasonFiles || []), ...file]);
    },
    [
      addToast,
      filesSent,
      reasonFiles,
      setFilesSent,
      setLoadingUploadFile,
      setReasonFileError,
      setReasonFiles,
      setUploadProgress,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'document/pdf': ['.pdf'],
      'audio/mp3': ['.mp3'],
      'video/mp4': ['.mp4'],
    },
    maxFiles: 5,
  });

  return (
    <S.Container>
      <S.Body>
        <S.Title>Provas para o reembolso</S.Title>
        <S.Card reasonFileError={reasonFileError} {...getRootProps()}>
          <LabDsIconNotSelectable size="x-large" icon="cloud_upload" />
          <p>Arraste e solte os arquivos aqui</p>
          <span>Arquivos suportados: .pdf, .jpeg, .png, .mp3 e .mp4</span>
          <LabDsButton
            label="Escolher arquivos"
            leadingIcon="add"
            type="button"
          />
          <input
            title="Escolher Arquivos"
            placeholder="Escolher Arquivos"
            type="file"
            id="selectfile"
            name="reasonFiles"
            {...getInputProps()}
          />
          <span>Tamanho máximo: 1mb. Máximo de 5 arquivos</span>
        </S.Card>
      </S.Body>
      <S.Body>
        {reasonFiles && <S.Title>Uploading</S.Title>}
        <S.FileList>
          {reasonFiles &&
            Object.keys(reasonFiles)?.map(key => (
              <S.FileItem>
                <S.FileItemName>
                  <span>{reasonFiles[key]?.name}</span>
                  <LabDsIconSelectable
                    icon="close"
                    size="small"
                    onClick={() => deleteFile(key)}
                  />
                </S.FileItemName>
                <LabDsProgressBar progress={updateProgress(key)} />
              </S.FileItem>
            ))}
        </S.FileList>
      </S.Body>
    </S.Container>
  );
}
