import Time from './icons/time.svg';
import Save from './icons/save.svg';
import IsSave from './icons/is-save.svg';
import DEFAULT_IMG_USER from './default-user-image.png';
import Pencil from './icons/icon-check.svg';
import Affirm from './icons/Affirm.svg';
import AlipayCard from './icons/Alipay.svg';
import AmazonCard from './icons/Amazon.svg';
import Bancontact from './icons/Bancontact.svg';
import DinersClub from './icons/DinersClub.svg';
import Discover from './icons/Discover.svg';
import Elo from './icons/Elo.svg';
import Etherium from './icons/Etherium.svg';
import FaceBookPay from './icons/FacebookPay.svg';
import GiroPay from './icons/giropay.svg';
import GooglePay from './icons/GooglePay.svg';
import Ideal from './icons/Ideal.svg';
import Interac from './icons/Interac.svg';
import Jcb from './icons/JCB.svg';
import Maestro from './icons/Maestro.svg';
import Payouneer from './icons/Payoneer.svg';
import Paypal from './icons/PayPal.svg';
import PaySafe from './icons/Paysafe.svg';
import Poli from './icons/Poli.svg';
import Sepa from './icons/SEPA.svg';
import Amex from './icons/Amex.svg';
import ApplePay from './icons/ApplePay.svg';
import Bitcoin from './icons/Bitcoin.svg';
import BitcoinCash from './icons/BitcoinCash.svg';
import BitPay from './icons/Bitpay.svg';
import CitaDele from './icons/Citadele.svg';
import Forbrugsforeningen from './icons/Forbrugsforeningen.svg';
import Klarna from './icons/Klarna.svg';
import LightCoin from './icons/Lightcoin.svg';
import MasterCard from './icons/Mastercard.svg';
import Qiwi from './icons/Qiwi.svg';
import Skrilll from './icons/Skrill.svg';
import Sofort from './icons/Sofort.svg';
import Stripe from './icons/Stripe.svg';
import Visa from './icons/Visa,.svg';
import Wechat from './icons/WeChat.svg';
import Yandex from './icons/Yandex.svg';
import Verifone from './icons/Verifone.svg';
import WebMoney from './icons/Webmoney.svg';
import CreditCard from './icons/creditCard.svg';
import Check from './icons/check.svg';
import Error from './icons/Icon-close.svg';
import LogoV4 from './logos/V4-Logo.svg';

const ICON = {
  Error,
  Check,
  Time,
  Save,
  IsSave,
  Pencil,
  Affirm,
  AlipayCard,
  AmazonCard,
  Bancontact,
  DinersClub,
  Discover,
  Elo,
  Etherium,
  FaceBookPay,
  GiroPay,
  GooglePay,
  Ideal,
  Interac,
  Jcb,
  Maestro,
  Payouneer,
  Paypal,
  PaySafe,
  Poli,
  Sepa,
  Amex,
  ApplePay,
  Bitcoin,
  BitcoinCash,
  BitPay,
  CitaDele,
  Forbrugsforeningen,
  Klarna,
  LightCoin,
  MasterCard,
  Qiwi,
  Skrilll,
  Sofort,
  Stripe,
  Visa,
  Wechat,
  Yandex,
  Verifone,
  WebMoney,
  CreditCard,
  LogoV4,
};

const IMG = {
  DEFAULT_IMG_USER,
};

export { ICON, IMG };
