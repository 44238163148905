import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import BidCardPack from '../../../Default/BidCardPack';
import { CustomLoading } from '../../../Default/Loadings/CustomLoading';
import { UnHappyPath } from '../../atoms/UnHappyPath';
import * as S from './styles';
import { getAuctionsPackFederation } from '../../../../services/requests/leadbroker/auctions/getAuctionsFederation';
import { useAuctions } from '../../../../contexts';

export const AuctionPackContainer = ({
  isLastVisible,
}: {
  isLastVisible: boolean;
}) => {
  const { expPurchasedAuctions, actualTab } = useAuctions();

  const filterPackAuctions = () => {
    return expPurchasedAuctions.filter(
      auc => auc.leads !== undefined
    ) as AuctionPack[];
  };

  const { data, fetchNextPage, isLoading, refetch } = useInfiniteQuery({
    queryKey: ['pack-auctions'],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const response = ((await getAuctionsPackFederation(
        pageParam
      )) as unknown) as AuctionPackResponse;

      return response;
    },
    getNextPageParam: lastPage => {
      return lastPage.data?.length > 0
        ? Number(lastPage.page || 0) + 1
        : undefined;
    },
  });

  useEffect(() => {
    if (isLastVisible) {
      fetchNextPage();
    }
  }, [isLastVisible, fetchNextPage]);

  useEffect(() => {
    refetch();
  }, [refetch, actualTab]);

  return (
    <>
      {isLoading ? (
        <CustomLoading />
      ) : (
        <S.Container>
          <S.Bids>
            {filterPackAuctions()?.map(auction => {
              return (
                <BidCardPack
                  pack={auction}
                  hash={auction?.packSequencial as string}
                  pack_id={auction?._id}
                  key={auction?._id}
                  auction={auction?.leads[0]}
                  expressPurchased
                />
              );
            })}
            {data?.pages?.map(page =>
              page?.data
                ?.sort((a, b) => (a?.expiresAt < b?.expiresAt ? -1 : 1))
                ?.map(auction => {
                  return (
                    <>
                      <BidCardPack
                        pack={auction}
                        hash={auction?.packSequencial as string}
                        pack_id={auction?._id}
                        key={auction?._id}
                        auction={auction?.leads[0]}
                      />
                    </>
                  );
                })
            )}
          </S.Bids>
          {!isLoading && data?.pages[0]?.data?.length === 0 && (
            <S.UnhappyPath>
              <UnHappyPath
                assetIndex={0}
                title="Infelizmente não temos packs disponíveis hoje"
              />
            </S.UnhappyPath>
          )}
        </S.Container>
      )}
    </>
  );
};
