import React, { InputHTMLAttributes, useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import * as S from './styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  text: string;
  containerStyle?: React.CSSProperties;
}

export function Checkbox({
  name,
  text,
  containerStyle,
  ...rest
}: CheckboxProps) {
  const checkbox = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkbox.current,
      path: 'value',
    });
  }, [registerField, fieldName]);

  return (
    <S.CheckboxContainer style={containerStyle}>
      <input
        defaultValue={defaultValue}
        id={name}
        name={name}
        type="checkbox"
        ref={checkbox}
        {...rest}
      />
      <span>{text}</span>
    </S.CheckboxContainer>
  );
}
