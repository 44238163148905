import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 0.7rem;
  background: var(--white);

  footer {
    width: 100%;
    height: 6.6rem;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
    background: var(--white);
    box-shadow: 0 -0.25rem 0.5rem 0.19rem rgba(0, 0, 0, 0.06),
      0 -0.07rem 0.19rem rgba(0, 0, 0, 0.12);
    button:first-of-type {
      width: max-content;
      border-color: transparent;
      margin: 1.8rem 1.8rem;
    }

    @media (max-width: 450px) {
      position: fixed;
      bottom: 0;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  padding: 2.5rem;
  height: 37rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-shrink: 0;

  padding: 5rem;

  p {
    color: var(--secondary-main, #333);

    font-family: Nunito Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }

  span {
    color: var(--secondary-main, #333);
    text-align: center;
    width: 39rem;
    font-family: Nunito Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  width: 44rem;
  height: 6rem;

  padding: 0.75rem 29.0625rem 0.75rem 1.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: relative;

  svg {
    cursor: pointer;
  }

  .titleModal {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--secondary-main);
    margin-right: -18rem;
    margin-left: 1rem;
  }

  .closeButton {
    position: absolute;
    right: 2rem;
    top: 1.2rem;
  }
`;
