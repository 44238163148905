import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
`;

export const NoneNotificationText = styled.small`
  padding: 0.5rem 1rem;
`;
