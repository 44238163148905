import { LabDsButton } from 'v4web-components-react';
import { Dialog } from '@reach/dialog';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import BidCard from '../../../../Default/BidCard';
import { deleteLead } from '../../../../../services/requests/leadbroker/auctions/deleteLead';
import { useToast } from '../../../../../contexts';

export function ModalDeleteLead({
  setShowModalDeleteLead,
  auction,
}: {
  setShowModalDeleteLead: (boolean) => void;
  auction: Auction;
}) {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const handleDeleteLead = async () => {
    const leadDelete = await deleteLead(auction._id);
    if (!leadDelete) {
      addToast({
        type: 'error',
        description: 'Erro ao excluir o lead.',
      });

      return;
    }
    setShowModalDeleteLead(false);
    navigate('/');
    addToast({
      type: 'success',
      description: 'Lead excluído com sucesso.',
    });
  };
  return (
    <Dialog
      style={{
        width: '55rem',
        borderRadius: '0.5rem',
        padding: '0',
      }}
      onDismiss={() => setShowModalDeleteLead(false)}
    >
      <S.Modal>
        <S.ModalBody>
          <S.CloseModalContainer>
            <S.CloseModalButton onClick={() => setShowModalDeleteLead(false)} />
          </S.CloseModalContainer>
          <S.TitleModalContainer>
            <S.TitleModal>
              Tem certeza que deseja excluir este Lead?
            </S.TitleModal>
          </S.TitleModalContainer>

          <S.TextContainer>
            <S.TextModal>
              Todo lead excluído é direcionado para o CRM e não poderão retornar
              para o Lead Broker. Leads que receberam lances não poderão ser
              excluídos da plataforma.
            </S.TextModal>
          </S.TextContainer>

          <BidCard auction={auction} deleteModal key={`${auction._id}delete`} />
        </S.ModalBody>
        <S.ModalFooter>
          <LabDsButton
            variant="danger-outlined"
            label="Cancelar"
            onHandleButton={() => {
              setShowModalDeleteLead(false);
            }}
          />
          <LabDsButton
            label="Excluir"
            onHandleButton={() => {
              handleDeleteLead();
            }}
          />
        </S.ModalFooter>
      </S.Modal>
    </Dialog>
  );
}
