import styled, { css } from 'styled-components';

export const Container = styled.button<{
  small?: boolean;
  secondary?: boolean;
  outline?: boolean;
}>`
  height: 3.5rem;
  border-radius: 0.5rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  border: 0;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  background: var(--primary-dark);
  color: var(--white);

  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 700;

  &:hover {
    opacity: 0.5;
  }

  ${({ small }) =>
    small &&
    css`
      height: 2.5rem;
      font-size: 0.875rem;
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      background: transparent;
      opacity: 0.8;
      color: var(--primary-dark);
      border: 2px solid var(--primary-dark);

      &:hover {
        opacity: 1;
        background: transparent;
        color: var(--primary-main);
        border: 2px solid var(--primary-main);
      }
    `}

  ${({ outline }) =>
    outline &&
    css`
      background: transparent;
      color: var(--primary-dark);
      border: 2px solid var(--primary-dark);
    `}
`;
