import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { getWalletInfo } from '../services/requests/leadbroker/wallet/withdraw';

export type BankData = {
  bankName: string;
  bankType: string;
  email: string;
  agency: string;
  accountNumber: string;
  cnpj: string;
  phone: string;
  name: string;
  baskMasks: {
    name: string;
    maxAccount: number;
    maxAgency: number;
  };
};

interface BankInfo {
  data?: BankData;
  selectedBankId?: InputItensSelect[];
  bankAccountType?: InputTypeAccount[];
  bankMasks?: InputTypeAccount[];
}

interface WalletInfo {
  accountType: string;
  bank: string;
  account: string;
  agency: string;
  cnpj: string;
  phone: string;
  unitName: string;
}

export interface InputItensSelect {
  label: string;
  value: {
    id: string;
    maxAgency: number;
    maxAccount: number;
    placeholderAgency: string;
    placeholderAccount: string;
    icon?: string;
  };
  info?: string;
}

export interface InputTypeAccount {
  label: string;
  value: string;
  info?: string;
}

type WithDrawContextData = {
  bankInformation: BankInfo;
  setBankInformation: Dispatch<SetStateAction<BankInfo>>;
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  frequentAccounts: boolean;
  setFrequentAccounts: Dispatch<SetStateAction<boolean>>;
  page: string;
  setPage: Dispatch<SetStateAction<string>>;
  checkStageWithdraw(page_title: string): boolean;
  checkGoBack(page_title: string): void;
  isVerify: boolean;
  setIsVerify: Dispatch<SetStateAction<boolean>>;
  readWalletInfo: () => void;
  walletInfo: WalletInfo;
};

export const WithDrawContext = createContext({} as WithDrawContextData);

type WithDrawContextProviderProps = {
  children: ReactNode;
};

export const WithDrawProvider: React.FC<WithDrawContextProviderProps> = ({
  children,
}: WithDrawContextProviderProps) => {
  const [bankInformation, setBankInformation] = useState({} as BankInfo);
  const [amount, setAmount] = useState(0);
  const [frequentAccounts, setFrequentAccounts] = useState(false);
  const [isVerify, setIsVerify] = useState(false);

  const [walletInfo, setWalletInfo] = useState({} as WalletInfo);

  const [page, setPage] = useState<
    'info' | 'amount' | 'accounts' | 'summary' | 'result' | 'cancel'
  >('amount');

  const checkStageWithdraw = (page_title: string) => {
    switch (page_title) {
      case 'amount':
        return false;
      case 'info':
        return true;
      case 'summary':
        return true;
      case 'result':
        return false;
      default:
        return true;
    }
  };

  const checkGoBack = (page_title: string) => {
    switch (page_title) {
      case 'info':
        return setPage('amount');
      case 'summary':
        return setPage('amount');
      default:
        return setPage('amount');
    }
  };

  const readWalletInfo = useCallback(() => {
    getWalletInfo().then(data => {
      setWalletInfo(data);
    });
  }, []);

  return (
    <WithDrawContext.Provider
      value={{
        bankInformation,
        setBankInformation,
        amount,
        setAmount,
        frequentAccounts,
        setFrequentAccounts,
        page,
        setPage,
        checkStageWithdraw,
        checkGoBack,
        isVerify,
        setIsVerify,
        readWalletInfo,
        walletInfo,
      }}
    >
      {children}
    </WithDrawContext.Provider>
  );
};

export const useWithDraw = (): WithDrawContextData => {
  return useContext(WithDrawContext);
};
