import api from '../api';

export async function withdraw(amount: number) {
  try {
    const response = await api.post('wallet/withdraw', { amount });

    const withdrawDetails: WithdrawDetails = response.data;

    return withdrawDetails;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao sacar o saldo, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}

export async function getWalletInfo() {
  try {
    const response = await api.get('/wallet/info');

    return response.data;
  } catch (err) {
    console.error(`Ocorreu algum problema ao listar os dados da carteira`, err);
    return false;
  }
}
