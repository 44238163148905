import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { format } from 'date-fns';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { SectionTitle } from 'v4components-react';
import {
  LabDsButton,
  LabDsDatePicker,
  LabDsTextField,
} from 'v4web-components-react';

import { useToast } from '../../../../contexts/toast';
import * as S from './styles';
import { useAuctions, useDeposit } from '../../../../contexts';
import { useAuth } from '../../../../contexts/auth';

interface FormData {
  expireDate: string;
}

const schema = Yup.object().shape({
  expireDate: Yup.date()
    .min(new Date(), 'Data deve ser posterior ao dia atual')
    .required('Data necessaria'),
});

export function PaymentFields() {
  const { user } = useAuth();
  const { amount, setStageDeposit, setExpireDate } = useDeposit();
  const { unitInfos } = useAuctions();
  const initialDueDate = new Date();
  const minimunDueDate = format(
    initialDueDate.setDate(initialDueDate.getDate() + 1),
    'yyyy-MM-dd'
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      expireDate: minimunDueDate,
    },
    resolver: yupResolver(schema),
  });
  const { addToast } = useToast();

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      setExpireDate(data.expireDate);

      setStageDeposit('summary');
    } catch (err) {
      addToast({
        type: 'error',
        description:
          'Ocorreu um erro na geração do boleto. Por favor, verifidade sua conexão',
      });
    }
  };

  return (
    <S.Container
      placeholder=""
      onSubmit={handleSubmit(onSubmit)}
      onPointerEnterCapture={() => null}
      onPointerLeaveCapture={() => null}
    >
      <section>
        <SectionTitle text="Preencha seus dados" />

        <S.InputContent>
          <LabDsTextField value={user.email} titleInput="E-mail" disabled />
        </S.InputContent>

        <S.InputContent style={{ marginBottom: 15 }}>
          <Controller
            name="expireDate"
            control={control}
            defaultValue={minimunDueDate}
            rules={{ required: true, min: minimunDueDate }}
            render={({ field }) => {
              return (
                <LabDsDatePicker
                  titleInput="Data de vencimento"
                  state={errors.expireDate ? 'error' : 'default'}
                  style={{ zIndex: '20' }}
                  value={field.value}
                  helperText={errors.expireDate?.message}
                  onChangeDatePicker={({ detail }) => {
                    field.onChange(detail);
                  }}
                  onHandleConfirmDatePicker={() => {
                    handleSubmit(onSubmit)();
                  }}
                />
              );
            }}
          />
        </S.InputContent>
        <S.DocumentContent>
          <label>CNPJ</label>
          <strong>{unitInfos?.cnpj}</strong>
        </S.DocumentContent>
      </section>

      <S.Footer>
        <LabDsButton
          label="Seguir para o resumo da transação"
          onHandleButton={() => handleSubmit(onSubmit)()}
          disabled={amount <= 0}
        />
      </S.Footer>
    </S.Container>
  );
}
