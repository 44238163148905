import { useAuth } from '../contexts/auth';

import LoggedInRoutes from './loggedIn';
import LoggedOutRoutes from './loggedOut';

export default function AppRoutes() {
  const { user } = useAuth();

  const signed = !!user?._id;

  return signed ? <LoggedInRoutes /> : <LoggedOutRoutes />;
}
