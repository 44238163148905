import api from '../api';

interface VerifyAccount {
  verified: boolean;
}

export async function verifyAccount() {
  try {
    const response = await api.get(`/wallet/verify`);
    const isVerify: VerifyAccount = response.data.verified;

    return isVerify;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao verificar sua conta, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}
