import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SectionTitle } from 'v4components-react';
import { useCallback, useEffect, useState } from 'react';
import { LabDsButton, LabDsTextField } from 'v4web-components-react';
import { IOptionsSelect } from 'v4web-components';
import { schema } from './utils/schema';
import * as S from './styles';
import { useDeposit } from '../../../../contexts';
import { clearString } from '../../../../hooks/clearString';
import { OptionsSelect } from './utils/states';

interface FormAdress {
  zipcode: string;
  street: string;
  number: string;
  complement?: string;
  city: string;
  state: string;
  district: string;
}

export function Address() {
  const { setStageDeposit, creditCard } = useDeposit();
  const [addressValues, setaddressValues] = useState<IOptionsSelect[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormAdress>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormAdress> = async data => {
    creditCard.setZipCode(clearString(data.zipcode));
    creditCard.setState(data.state);
    creditCard.setCity(data.city);
    creditCard.setDistrict(data.district);
    creditCard.setStreet(data.street);
    creditCard.setNumberHouse(data.number);
    creditCard.setComplement(data.complement);

    setStageDeposit('payer');
  };

  useEffect(() => {
    const states = OptionsSelect();
    setaddressValues(states);
  }, []);

  const getAddressbyCEP = useCallback(async () => {
    const response = await fetch(
      `https://viacep.com.br/ws/${clearString(watch('zipcode'))}/json/`
    );
    const data = await response.json();

    if (response.ok) {
      setValue('city', data.localidade);
      setValue('district', data.bairro);
      setValue('complement', data.complemento);
      setValue('state', addressValues.find(uf => uf.key === data.uf).label);
      setValue('street', data.logradouro);
    }
  }, [setValue, addressValues, watch]);

  useEffect(() => {
    getAddressbyCEP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('zipcode'), getAddressbyCEP]);

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <section>
        <SectionTitle text="Dados de Cobrança" />

        <S.Row>
          <Controller
            name="zipcode"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="48%">
                <LabDsTextField
                  {...field}
                  className="cepInput"
                  titleInput="CEP*"
                  label="00000-000"
                  helperText={errors.zipcode && errors.zipcode.message}
                  mask="CEP"
                  state={errors.zipcode ? 'error' : 'default'}
                  type="number"
                  maxLength={8}
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />

          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <S.EstateContainer>
                <LabDsTextField
                  {...field}
                  className="stateInput"
                  titleInput="Estado*"
                  label="UF"
                  helperText={errors.state && errors.state.message}
                  state={errors.state ? 'error' : 'default'}
                  maxLength={8}
                  disabled
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.EstateContainer>
            )}
          />
        </S.Row>

        <S.Row>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="48%">
                <LabDsTextField
                  {...field}
                  className="city"
                  titleInput="Cidade*"
                  label="Digite sua cidade"
                  helperText={errors.city && errors.city.message}
                  state={errors.city ? 'error' : 'default'}
                  type="text"
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />

          <Controller
            name="district"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="48%">
                <LabDsTextField
                  {...field}
                  className="district"
                  titleInput="Bairro*"
                  label="Digite o seu Bairro"
                  helperText={errors.district && errors.district.message}
                  state={errors.district ? 'error' : 'default'}
                  type="text"
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />
        </S.Row>
        <Controller
          name="street"
          control={control}
          render={({ field }) => (
            <S.InputContent maxWidth="100%">
              <LabDsTextField
                {...field}
                className="streetInput"
                titleInput="Rua*"
                helperText={errors.street && errors.street.message}
                label="Informe seu endereço"
                state={errors.street ? 'error' : 'default'}
                type="text"
                onChangeInput={({ detail }) => {
                  field.onChange(detail);
                }}
              />
            </S.InputContent>
          )}
        />

        <S.Row>
          <Controller
            name="number"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="30%">
                <LabDsTextField
                  {...field}
                  className="numberHouse"
                  titleInput="Número*"
                  helperText={errors.number && errors.number.message}
                  state={errors.number ? 'error' : 'default'}
                  label="Digite o número"
                  type="text"
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />

          <Controller
            name="complement"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="65%">
                <LabDsTextField
                  {...field}
                  className="complement"
                  titleInput="Complemento"
                  label="Digite o complemento"
                  state={errors.complement ? 'error' : 'default'}
                  helperText={errors.complement && errors.complement.message}
                  type="text"
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />
        </S.Row>
      </section>

      <S.Footer>
        <LabDsButton
          label="Próximo"
          size="medium"
          variant="primary"
          color="green"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        />
      </S.Footer>
    </S.Container>
  );
}
