import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { addMinutes, format } from 'date-fns';

import {
  Container,
  SectionContent,
  SectionTitle,
  Section,
} from 'v4components-react';
import {
  LabDsTable,
  LabDsTab,
  LabDsBreadcrumb,
  LabDsButton,
} from 'v4web-components-react';
import { IData } from 'v4web-components';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { MdOutlineOpenInNew, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { GrClose } from 'react-icons/gr';
import { AiOutlineExclamationCircle, AiOutlineCheck } from 'react-icons/ai';

import Dialog from '@reach/dialog';
import React from 'react';
import { parseCookies } from 'nookies';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnHappyPath';

import Withdraw from './Withdraw';
import { Deposit } from './Deposit';

import { convertCentsToBRL } from '../../utils/convertCentsToBRL';
import { useBalance } from '../../hooks/wallet';
import * as S from './styles';
import { statementExtract } from '../../services/requests/leadbroker/wallet/statementExtract';
import { StatementExtract } from '../../types/wallet';
import { Payment } from './Deposit/payment';
import { useAuth } from '../../contexts/auth';
import { IMG } from '../../assets';
import { formatDate } from '../../utils/dateFunctions';
import { useDeposit } from '../../contexts/deposit';
import { useWithDraw } from '../../contexts';
import { verifyAccount } from '../../services/requests/leadbroker/wallet/verifyAccount';
import { getUserUnit } from '../../services/requests/leadbroker/users/getUserUnit';

type TabsTypes = 'all' | 'withdraw' | 'deposit' | 'leads' | 'leadsPack';

export function Wallet() {
  const {
    setPaymentType,
    stageDeposit,
    setStageDeposit,
    setAmount,
    checkGoBackDeposit,
    checkArrowStage,
  } = useDeposit();
  const {
    page: pageWithdraw,
    setPage,
    checkStageWithdraw,
    checkGoBack,
    isVerify: verifiedAccount,
    setIsVerify,
    readWalletInfo,
  } = useWithDraw();

  const cookies = parseCookies();
  const unitInfo = cookies['v4company.unitInfo'];
  const token = cookies['v4company.token'];

  const navigate = useNavigate();
  const { depositmb } = useParams();

  const [loading, setLoading] = useState(true);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TabsTypes>('all');
  const [statementExtracts, setStatementExtracts] = useState([
    {} as StatementExtract,
  ]);
  const [actualPage, setActualPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [selectStatementExtract, setSelectStatementExtract] = useState(
    {} as StatementExtract
  );
  const [isAdmin, setIsAdmin] = useState(false);

  const [showWalletDetails, setShowWalletDetails] = useState(false);
  const [showBalanceDetails, setShowBalanceDetails] = useState(false);
  const [showHistoryCredit, setShowHistoryCredit] = useState(false);

  const [breadcrumbs] = useState([
    {
      label: 'Página inicial',
      key: 'startPage',
      link: '/',
      event: () => {
        navigate('/');
      },
    },
    {
      label: 'Carteira',
      key: 'wallet',
      link: '/carteira',
      event: () => {
        navigate('/carteira');
      },
    },
  ]);

  const [tabs] = useState([
    {
      title: 'Todas',
      key: 'all',
      event: () => {
        setSelectedTab('all');
      },
    },
    {
      title: 'Saques',
      key: 'withdraw',
      event: () => {
        setSelectedTab('withdraw');
      },
    },
    {
      title: 'Depósitos',
      key: 'deposit',
      event: () => {
        setSelectedTab('deposit');
      },
    },
    {
      title: 'Leads',
      key: 'leads',
      event: () => {
        setSelectedTab('leads');
      },
    },
    {
      title: 'Pack de Leads',
      key: 'leadsPack',
      event: () => {
        setSelectedTab('leadsPack');
      },
    },
  ]);

  const [tableData, setTableData] = useState<IData>();

  const { user } = useAuth();
  const { permissions } = user;

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const expiresAtBonus = expired => {
    if (!expired.expiresAt) return new Date();
    return addMinutes(
      new Date(expired.expiresAt),
      new Date(expired.expiresAt).getTimezoneOffset()
    );
  };

  const {
    balance,
    availableWithdraw,
    bonusAmount,
    availableBonus,
    expiredBonus,
    lastBalanceUpdate,
  } = useBalance();

  const filteredStatementExtracts = useMemo(() => {
    if (selectedTab === 'withdraw') {
      return statementExtracts.filter(value => value.type === 'Saque');
    }

    if (selectedTab === 'deposit') {
      return statementExtracts.filter(value => value.type === 'Depósito');
    }

    if (selectedTab === 'leads') {
      return statementExtracts.filter(value => value.type === 'Lead');
    }

    if (selectedTab === 'leadsPack') {
      return statementExtracts.filter(value => value.type === 'leadsPack');
    }

    return statementExtracts;
  }, [selectedTab, statementExtracts]);

  const arrayOfTotalPages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePaymentModal = useCallback(
    (value: StatementExtract) => {
      setPaymentType('all');
      setSelectStatementExtract(value);

      setOpenPaymentModal(true);
    },
    [setPaymentType]
  );

  const setExtractInfo = useCallback(
    async (page = 1) => {
      setLoading(true);

      const newStatementExtract = await statementExtract(
        `?page=${page}&limit=10`
      );

      if (newStatementExtract) {
        setStatementExtracts(newStatementExtract.data);
        setActualPage(newStatementExtract.page);
        setTotalPages(newStatementExtract.totalPages);
      }

      setLoading(false);
    },
    [setStatementExtracts, setActualPage, setTotalPages]
  );

  const getName = (name: string): string => {
    const viewName = name?.split(' ');

    if (viewName?.length > 1) {
      return `${viewName[0]} ${viewName[1]}`;
    }

    return viewName ? viewName[0] : '--';
  };
  const setUserPermissions = useCallback(async () => {
    if (permissions?.leadbroker?.admin) {
      setIsAdmin(true);
    }
  }, [permissions?.leadbroker?.admin]);

  useEffect(() => {
    setExtractInfo();
    setUserPermissions();
  }, [setExtractInfo, setUserPermissions]);

  const twoDays = 60 * 60 * 24 * 2;

  useEffect(() => {
    if (!unitInfo && token) {
      getUserUnit(token);
    }
  }, [token, twoDays, unitInfo]);

  const setWalletInfo = useCallback(
    async (amountValue?: number) => {
      setLoading(true);
      setAmount(amountValue);

      const isVerify = await verifyAccount();

      setPage('amount');
      setStageDeposit('amount');
      setLoading(false);

      if (depositmb) {
        setShowDeposit(true);
      }

      if (isVerify) {
        setIsVerify(true);
        return;
      }

      if (!isVerify) {
        setIsVerify(false);
      }
    },
    [setAmount, setIsVerify, setPage, setStageDeposit, depositmb]
  );

  useEffect(() => {
    setWalletInfo();
    readWalletInfo();
  }, [setWalletInfo, readWalletInfo]);

  useEffect(() => {
    const headers: IData['headers'] = [
      {
        colKey: 'transactionId',
        title: 'ID de transação',
        type: 'text',
      },
      {
        colKey: 'transactionType',
        title: 'Tipo de transação',
        type: 'text',
      },
      {
        colKey: 'transactionValue',
        title: 'Valor da transação',
        type: 'text',
      },
      {
        colKey: 'transactionAuthor',
        title: 'Autor da transação',
        type: 'text',
      },
      {
        colKey: 'transactionDate',
        title: 'Data da transação',
        type: 'text',
      },
      {
        colKey: 'transactionStatus',
        title: 'Status',
        type: 'badge',
      },
    ];
    setTableData({
      headers,
      rows: [],
    });

    if (filteredStatementExtracts.length === 0) return;

    const status = {
      Processando: 'warning',
      Concluído: 'success',
      Cancelado: 'error',
    };

    const handleRowClick = (value: StatementExtract) => {
      // if (value.invoiceTypes?.includes('credit_card') && value?.creditCard) {
      //   window.open(value?.creditCard?.secureUrl, '_blank');
      //   return;
      // }

      if (!value.pix.qrcode && !value.bankSlip.barcode) return;

      value.status === 'Processando' && handlePaymentModal(value);
    };

    const data = filteredStatementExtracts?.map((value: StatementExtract) => ({
      transactionId: {
        text: { title: value._id },
        handleRow: () => {
          handleRowClick(value);
        },
      },
      transactionType: {
        text: { title: value.type },
        handleRow: () => {
          handleRowClick(value);
        },
      },
      transactionValue: {
        text: { title: convertCentsToBRL(value.value) },
        handleRow: () => {
          handleRowClick(value);
        },
      },
      transactionAuthor: {
        text: {
          title: getName(value.author_transaction?.name),
          avatar: true,
          avatarImgSRC:
            value.author_transaction?.picture || IMG.DEFAULT_IMG_USER,
        },
        handleRow: () => {
          handleRowClick(value);
        },
      },
      transactionDate: {
        text: { title: new Date(value.createdAt).toLocaleDateString() },
        handleRow: () => {
          handleRowClick(value);
        },
      },
      transactionStatus: {
        badge: { label: value.status, state: status[value.status] },
        handleRow: () => {
          handleRowClick(value);
        },
      },
    }));

    setTableData({
      headers,
      rows: data,
    });
  }, [filteredStatementExtracts, handlePaymentModal]);

  return (
    <>
      <Container>
        <LabDsBreadcrumb breadcrumbs={breadcrumbs} />
        <Section>
          <SectionContent>
            <S.Title balance={balance / 100}>
              <header>
                <h1>Carteira</h1>
                <p>
                  Saldo atualizado em {format(lastBalanceUpdate, 'dd/MM/yyyy')}{' '}
                  às {format(lastBalanceUpdate, 'HH:mm')}
                </p>
              </header>

              <S.TitleAndButton>
                <h1 className="avaiable-value">
                  <S.TitleAndIcon>
                    Saldo total:
                    <MdOutlineOpenInNew
                      className="open-icon"
                      onClick={() => setShowWalletDetails(true)}
                    />
                  </S.TitleAndIcon>
                  <br /> <span>{convertCentsToBRL(balance)}</span>
                </h1>
              </S.TitleAndButton>
            </S.Title>
            <S.Buttons>
              {isAdmin && (
                <LabDsButton
                  maxWidth
                  label="Sacar"
                  variant="outlined"
                  onHandleButton={() => {
                    setShowWithdraw(true);
                  }}
                />
              )}

              {showWithdraw && (
                <Dialog
                  aria-labelledby="label"
                  initialFocusRef={buttonRef}
                  className="modalWithdraw"
                >
                  <Withdraw
                    arrowLeftButton={checkStageWithdraw(pageWithdraw as string)}
                    backButton={() => checkGoBack(pageWithdraw as string)}
                    handleToggleModal={() => {
                      setShowWithdraw(!showWithdraw);
                      setPage('amount');
                    }}
                  />
                </Dialog>
              )}

              <LabDsButton
                maxWidth
                label="Depositar"
                onHandleButton={() => {
                  setShowDeposit(true);
                }}
              />

              {showDeposit && (
                <Dialog
                  aria-labelledby="label"
                  initialFocusRef={buttonRef}
                  className="modalDeposit"
                >
                  <Deposit
                    title="Depósito"
                    arrowLeftButton={checkArrowStage(stageDeposit as string)}
                    backButton={() =>
                      checkGoBackDeposit(stageDeposit as string)
                    }
                    handleToggleModal={() => {
                      setShowDeposit(!showDeposit);
                      verifiedAccount
                        ? setStageDeposit('amount')
                        : setStageDeposit('firstTime');
                    }}
                  />
                </Dialog>
              )}
            </S.Buttons>
          </SectionContent>
        </Section>

        <Section style={{ overflowX: 'auto' }}>
          <SectionContent style={{ position: 'relative', minWidth: '500px' }}>
            <SectionTitle text="Extrato" />
            <S.Tabs>
              <LabDsTab value={selectedTab} tabs={tabs} />
            </S.Tabs>
            <LabDsTable loading={loading} data={tableData} />

            {arrayOfTotalPages.length > 0 && (
              <S.Pagination>
                <FaAngleLeft onClick={() => setExtractInfo(1)} />
                {arrayOfTotalPages.map(page => (
                  <button
                    key={page}
                    onClick={() => setExtractInfo(page)}
                    className={page === actualPage ? 'isActive' : ''}
                    type="button"
                  >
                    {page}
                  </button>
                ))}
                <FaAngleRight
                  onClick={() => setExtractInfo(arrayOfTotalPages.length)}
                />
              </S.Pagination>
            )}
            {!loading && statementExtracts.length <= 0 && (
              <S.UnHappyPath>
                <UnHappyPath
                  title="Você ainda não realizou transações"
                  assetIndex={3}
                />
              </S.UnHappyPath>
            )}
          </SectionContent>
        </Section>
      </Container>

      {openPaymentModal && (
        <Dialog
          className="modalPayment"
          aria-labelledby="label"
          initialFocusRef={buttonRef}
        >
          <Payment
            handleToggleModal={() => setOpenPaymentModal(!openPaymentModal)}
            paymentDetails={selectStatementExtract}
          />
        </Dialog>
      )}

      {showDeposit && (
        <Dialog
          aria-labelledby="label"
          initialFocusRef={buttonRef}
          className="modalDeposit"
        >
          <Deposit
            title="Depositar"
            arrowLeftButton={checkArrowStage(stageDeposit as string)}
            backButton={() => checkGoBackDeposit(stageDeposit as string)}
            handleToggleModal={() => {
              setShowDeposit(!showDeposit);
              verifiedAccount
                ? setStageDeposit('amount')
                : setStageDeposit('firstTime');
            }}
          />
        </Dialog>
      )}

      {showWalletDetails && (
        <Dialog
          aria-labelledby="label"
          onDismiss={() => setShowWalletDetails(false)}
          initialFocusRef={buttonRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '24px 16px',
            gap: '16px',
            isolation: 'isolate',
            position: 'absolute',
            width: '448px',
            height: 'auto',
            right: '0',
            margin: '0',
            background: '#ffffff',
          }}
        >
          <S.DialogHeader>
            <p>Detalhamento de saldo</p>
            <GrClose
              className="header-icon"
              onClick={() => setShowWalletDetails(false)}
            />
          </S.DialogHeader>
          <S.Hr />
          <S.DialogMessage>
            <AiOutlineExclamationCircle
              className="message-icon"
              onClick={() => setShowWalletDetails(false)}
            />
            <p>
              Ao realizar ofertas no LeadBroker, primeiramente será descontado o
              saldo em créditos e depois em saques.
            </p>
          </S.DialogMessage>
          <S.Hr />
          <S.TotalBalanceContainer>
            <div className="total-balance">
              <p className="main-title">Saldo total</p>
              <div className="value-and-icon">
                <p className="total-value">{convertCentsToBRL(balance)}</p>
                <MdOutlineKeyboardArrowUp
                  size={20}
                  className="icon-details"
                  onClick={() => setShowBalanceDetails(!showBalanceDetails)}
                  style={{
                    transform: showBalanceDetails ? 'rotate(180deg)' : '',
                  }}
                />
              </div>
            </div>
          </S.TotalBalanceContainer>
          {showBalanceDetails && (
            <>
              <S.TotalBalanceDetailsContainer>
                <div className="withdraw-title">Saque</div>
                <div className="title-value">
                  <p className="title">Valor depositado para saque</p>
                  <p className="value">
                    {convertCentsToBRL(availableWithdraw)}
                  </p>
                </div>
              </S.TotalBalanceDetailsContainer>
              <S.HrSmall />
              <S.CreditDetailsContainer>
                <div className="credit-title">Crédito bônus</div>
                <div className="title-value">
                  <p className="title">
                    Crédito com data de expiração e sem possibilidade de saque
                  </p>
                  <p className="value">{convertCentsToBRL(bonusAmount)}</p>
                </div>
              </S.CreditDetailsContainer>
              {availableBonus.length === 0 ? (
                <div>
                  <p>Você não possui bonus</p>
                </div>
              ) : (
                availableBonus.map(available => (
                  <S.BonusContainer>
                    <div className="bonus-container">
                      <div className="bonus-title">Bônus</div>
                      <p className="bonus-expiration">
                        <p className="bold-text">Vencimento: </p>
                        {formatDate(available.expiresAt as Date)}
                      </p>
                    </div>
                    <p className="bonus-value">
                      <p className="bold-text" style={{ fontSize: '16px' }}>
                        Valor:
                      </p>
                      {convertCentsToBRL(available.initialAmount as number)}
                    </p>
                  </S.BonusContainer>
                ))
              )}
            </>
          )}
          <S.Hr />
          <S.CreditHistoryContainer>
            <div className="credit-history">
              <p className="main-title">Histórico de créditos</p>
              <div className="icon">
                <MdOutlineKeyboardArrowUp
                  size={20}
                  className="icon-details"
                  onClick={() => setShowHistoryCredit(!showHistoryCredit)}
                  style={{
                    transform: showHistoryCredit ? 'rotate(180deg)' : '',
                  }}
                />
              </div>
            </div>
          </S.CreditHistoryContainer>
          {showHistoryCredit && (
            <>
              {expiredBonus.length === 0 ? (
                <div>
                  <p>Você não possui bonus expirados</p>
                </div>
              ) : (
                expiredBonus.map(expired => (
                  <S.ExpiredBonusContainer>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '11px',
                      }}
                    >
                      <AiOutlineCheck color="#00C49A" />
                      <div className="bonus-title">Bônus vencido</div>
                    </div>
                    <S.Hr />
                    <div className="bonus-container">
                      <div className="bonus-title">Bônus</div>
                      <div className="avaiable-expiration">
                        <div className="avaiable-text">
                          Disponibilidade:{' '}
                          <span>{formatDate(expired.createdAt as Date)}</span>
                        </div>
                        <div className="expiration-text">
                          Vencimento:{' '}
                          <span>{formatDate(expiresAtBonus(expired))}</span>
                        </div>
                      </div>
                    </div>
                    <div className="bonus-value">
                      <p className="bold-text" style={{ fontSize: '16px' }}>
                        Valor:
                      </p>
                      {convertCentsToBRL(expired.amount as number)}
                    </div>
                  </S.ExpiredBonusContainer>
                ))
              )}
            </>
          )}
        </Dialog>
      )}
    </>
  );
}
