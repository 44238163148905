import { useCallback, useEffect, useState } from 'react';
import { LabDsButton } from 'v4web-components-react';
import { NotificiationIC } from '../Icons';

import { CustomLoading } from '../Loadings/CustomLoading';
import { CardsContainer } from './CardsContainer';
import { NotificationTabs } from './NotificationTabs';
import { getNotificationsByUser } from '../../../services/requests/leadbroker/notifications/getNotificationsByUser';
import { useNotifications } from '../../../contexts/notifications';
import * as S from './styles';
import { unreadNotifications } from '../../../utils/unreadNotifications';

const tabs = {
  tab1: 'Todas',
  tab2: 'Não lidas',
};

export function Notification() {
  const LIMIT = 20;
  const DEFAULT_PAGE = 1;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const {
    notifications,
    setNotifications,
    totalUnreadNotifications,
    setTotalUnreadNotifications,
  } = useNotifications();

  const [actualTab, setActualTab] = useState<string>(tabs.tab1);

  const setNotificationsInfo = useCallback(async () => {
    try {
      setLoading(true);

      const newNotifications:
        | NotificationsObject
        | false = await getNotificationsByUser({
        limit: LIMIT,
        page: DEFAULT_PAGE,
      });

      if (newNotifications) {
        setTotalPages(newNotifications.totalPages);
        setNotifications(newNotifications.data);
        setCurrentPage(newNotifications.page);
        setTotalUnreadNotifications(
          unreadNotifications(newNotifications.data)?.length
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setNotifications, setTotalUnreadNotifications]);

  const joinNotifications = async (page: number) => {
    if (currentPage !== totalPages) {
      const fetchData = async () => {
        const newNotifications = await getNotificationsByUser({
          limit: LIMIT,
          page,
        });

        if (newNotifications) {
          setTotalPages(newNotifications.totalPages);
          setCurrentPage(newNotifications.page);

          setNotifications(prevNotifications => [
            ...prevNotifications,
            ...newNotifications.data,
          ]);
        }
      };

      fetchData();
    }
  };

  useEffect(() => {
    setNotificationsInfo();
  }, [setNotificationsInfo]);

  return (
    <S.Container>
      <S.Wrapper onClick={() => setOpen(!open)}>
        {loading || totalUnreadNotifications === 0 ? (
          ''
        ) : (
          <S.Count>{totalUnreadNotifications}</S.Count>
        )}
        <NotificiationIC />
      </S.Wrapper>
      {open && (
        <>
          <S.Background onClick={() => setOpen(false)} />
          <S.NotificationContainer>
            <S.NotificationHeader>
              <small>Notificações</small>
            </S.NotificationHeader>
            <NotificationTabs
              onChange={newTab => setActualTab(newTab)}
              tabs={[tabs.tab1, tabs.tab2]}
            />
            {loading ? (
              <CustomLoading />
            ) : (
              <>
                {actualTab === tabs.tab1 && (
                  <CardsContainer
                    notifications={notifications}
                    setTotalUnreadNotifications={setTotalUnreadNotifications}
                  />
                )}
                {actualTab === tabs.tab2 && (
                  <CardsContainer
                    notifications={unreadNotifications(notifications)}
                    setTotalUnreadNotifications={setTotalUnreadNotifications}
                  />
                )}

                {totalPages !== currentPage && (
                  <LabDsButton
                    className="mt-1 mb-1"
                    variant="link-button"
                    size="small"
                    label=" ver mais"
                    onHandleButton={() => {
                      joinNotifications(currentPage + 1);
                    }}
                  />
                )}
              </>
            )}
          </S.NotificationContainer>
        </>
      )}
    </S.Container>
  );
}
