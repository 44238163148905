import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ContainerProps {
  read: boolean;
}

interface isActiveProps {
  isActive: boolean;
}

export const DotsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 1rem;
  border-radius: 0.5rem;
  border: none;
  background: var(--grayscale-6);
  padding: 0 0.5rem;
  margin-right: 1rem;
  transition: all 0.4s;
  opacity: 0;
  pointer-events: none;

  svg {
    color: var(--grayscale-20) !important;
    margin: 0 !important;
    font-size: 1rem;
    width: 1.25rem;
  }
`;

export const NotificationBall = styled.div`
  display: flex;
  width: 0.5rem;
  height: 0.5rem;
  background: var(--primary-light);
  border-radius: 50%;
  opacity: 1;
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  transition: all 0.4s;
  background-color: var(--tertiary-light);
  text-decoration: none;

  ${({ read }) =>
    read &&
    css`
      background-color: transparent;

      ${NotificationBall} {
        opacity: 0;
      }
    `}

  svg {
    width: 1.5rem;
    font-size: 1.5rem;
    margin-right: 0.75rem;
    margin-left: 0;
    color: var(--primary-main);
  }

  strong {
    font-weight: 600;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--secondary-main);
  }

  small {
    color: var(--secondary-light);
    opacity: 0.6;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  :hover {
    background-color: rgba(202, 202, 202, 0.5);

    ${DotsButton} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const CardInfo = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-decoration: none;

  div {
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
    text-decoration: none;

    p {
      font-family: 'Inter';
      color: var(--secondary-main);
      font-weight: normal;
      font-size: 0.75rem;
      text-transform: capitalize;
    }
  }
  svg {
    color: var(--secondary-main);
    margin: 0;
    font-size: 1rem;
    width: 1rem;
    margin-right: 0.25rem;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 4.5rem;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: center;

  small {
    margin-bottom: 1rem;
  }
`;

export const ActionsButtons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;

  svg {
    color: var(--grayscale-20);
  }
`;

export const ReadMenu = styled.button<isActiveProps>`
  position: absolute;
  z-index: 2;
  top: 1.75rem;
  left: -6rem;
  width: max-content;
  display: flex;
  align-items: center;
  height: 1.5rem;
  background: var(--grayscale-2);
  transition: all 0.4s;
  border-radius: 0.5rem;
  border: none;
  padding: 0 0.5rem;
  opacity: 0;
  pointer-events: none;

  svg {
    font-size: 0.75rem;
    margin: 0;
    color: var(--primary-main);
  }

  small {
    color: var(--black);
    font-size: 0.75rem;
    line-height: 0.875rem;
    margin: 0;
    margin-left: 0.25rem;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;

export const Triangle = styled.div<isActiveProps>`
  position: absolute;
  z-index: 2;
  opacity: 0;
  top: 1.25rem;
  left: 0.5rem;
  width: 0;
  height: 0;
  transition: all 0.4s;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.75rem solid var(--grayscale-2);

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
    `}
`;
