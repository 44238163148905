import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type NotificationsContextData = {
  notifications: NotificationMessage[];
  setNotifications: Dispatch<SetStateAction<NotificationMessage[]>>;
  totalUnreadNotifications: number;
  setTotalUnreadNotifications: Dispatch<SetStateAction<number>>;
};

export const NotificationsContext = createContext(
  {} as NotificationsContextData
);

type NotificationsContextProviderProps = {
  children: ReactNode;
};

export const NotificationsProvider: React.FC<NotificationsContextProviderProps> = ({
  children,
}: NotificationsContextProviderProps) => {
  const [notifications, setNotifications] = useState(
    [] as NotificationMessage[]
  );
  const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(0);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
        totalUnreadNotifications,
        setTotalUnreadNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = (): NotificationsContextData => {
  return useContext(NotificationsContext);
};
