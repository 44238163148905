import { parseCookies } from 'nookies';
import apiFederation from '../apiFederation';

// função resposável por retornar os leads do usuário
export async function getAuctions(query: string) {
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.get(`/leads${query}`, {
      headers: { Authorization: `Bearer ${cookies['v4company.token']}` },
    });
    const auctions: AuctionResponse = response.data;

    return auctions;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os auctions, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}

export async function getOneAuction(query: string) {
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.get(`/leads${query}`, {
      headers: { Authorization: `Bearer ${cookies['v4company.token']}` },
    });
    const auctions: Auction = response.data;

    return auctions;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os auctions, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}
