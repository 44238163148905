export const getAuctionStatus = (
  auction: Auction | AuctionPack,
  id: string
) => {
  if (auction.winner.unitId === id) {
    return 'winner';
  }

  if (auction.bids) {
    if (
      auction.winner.unitId !== id &&
      auction.bids.filter(bid => bid.unitId === id).length > 0
    ) {
      return 'overcome';
    }
  }

  return 'available';
};
