import { useState, useCallback, useEffect } from 'react';
import {
  Container,
  SectionContent,
  Breadcrumb,
  SectionTitle,
  Section,
} from 'v4components-react';
import { useAuth } from '../../contexts/auth';
import { CustomLoading } from '../../components/Default/Loadings/CustomLoading';
import { convertIsoToDate } from '../../utils/convertIsoToDate';
import { UserProps } from '../../types/user';
import * as S from './styles';

export function Profile() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [investor, setInvestor] = useState({} as UserProps);

  const setCollaboratorInfo = useCallback(async () => {
    setLoading(true);

    const newUser = user;

    if (newUser) {
      setInvestor(newUser);
    }

    setLoading(false);
  }, [user]);

  useEffect(() => {
    setCollaboratorInfo();
    document.title = 'Perfil - V4 LB';
  }, [setCollaboratorInfo]);

  return (
    <Container>
      <Breadcrumb
        breadcrumbs={[
          {
            title: 'Página inicial',
            link: '',
            redirect: 'https://mktlab.app/',
          },
          { title: 'Perfil', link: '/perfil' },
        ]}
      />

      {loading ? (
        <CustomLoading />
      ) : (
        <Section style={{ flexDirection: 'row' }}>
          <SectionContent>
            <SectionTitle text="Meu Perfil" />
            <S.CollaboratorImage>
              <img src={investor.picture} alt={`Foto do ${investor.name}`} />
            </S.CollaboratorImage>

            <S.CollaboratorInfos>
              <S.Column>
                <span>Nome do investidor(a)</span>
                <strong>{investor.name}</strong>

                <span>E-mail</span>

                <strong style={{ margin: 0 }}>{investor.email}</strong>
              </S.Column>
              <S.CollaboratorButtons>
                <S.StartedDate style={{ marginTop: '1rem' }}>
                  <span>Data de criação</span>
                  <strong style={{ margin: 0 }}>
                    Criado em{' '}
                    {investor.createdAt && convertIsoToDate(investor.createdAt)}
                  </strong>
                </S.StartedDate>
              </S.CollaboratorButtons>
            </S.CollaboratorInfos>
          </SectionContent>
        </Section>
      )}
    </Container>
  );
}
