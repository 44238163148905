import styled from 'styled-components';

export const Container = styled.div`
  height: 38rem;
  overflow-y: hidden;

  h3 {
    font-size: 2.375rem;
    border-bottom: 0.07rem solid var(--grayscale-20);
    padding-bottom: 1rem;
  }

  section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem 1rem;
    padding: 0 2.4rem;
  }

  section strong {
    color: var(--secondary-main);
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  section:nth-child(2) {
    border-bottom: 0.13rem solid var(--tertiary-light);
    display: flex;

    div.after {
      width: 0.32rem;
    }

    > div > div > div:nth-child(2) {
      padding: 0;
    }
  }

  section:nth-child(3) {
    margin-bottom: 1rem;
    display: flex;

    div:nth-child(2) {
      width: 100%;
    }
  }

  section:nth-child(4) {
    margin-bottom: 1rem;
  }

  section:nth-child(5) {
    margin-bottom: 0.4rem;
  }

  small {
    color: var(--primary-main);
    bottom: 0;
    margin: 0.25rem;
  }
  select {
    background: var(--white);
    border: 0.07rem solid var(--grayscale-4);
    box-sizing: border-box;
    border-radius: 0.25rem;
    width: 100%;
    padding: 1.3rem 1.13rem;
    margin-top: 0.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  option:first-child {
    font-size: 1.25rem;
    color: red;
  }

  .reactSelect {
    background: red;
  }

  .agency {
    width: 13.4rem;
  }

  .accountNumber,
  .userName {
    width: 100%;
  }
`;

export const Content = styled.div`
  padding: 0 0 5.5rem 0;
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.footer`
  width: 100%;
  position: relative;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-inline: 2rem;
`;
