import { useCallback, useEffect, useState } from 'react';
import {
  Container,
  SectionContent,
  Breadcrumb,
  SectionTitle,
  Section,
} from 'v4components-react';
import { Button } from '../../components/Default/Button';
import { InviteNewInvestor } from '../../components/Default/inviteNewInvestor';
import { TableUser } from '../../components/AtomicDesign/molecules/TableUser';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnHappyPath';
import { getUsersByUnitId } from '../../services/requests/leadbroker/users/getUsersByUnitId';
import { useAuth } from '../../contexts/auth';
import { CustomLoading } from '../../components/Default/Loadings/CustomLoading';
import { changeUserPermissionBid } from '../../services/requests/leadbroker/users/changeUserPermissionBid';
import { User } from '../../types/user';
import * as S from './styles';

export function Unit() {
  const [users, setUsers] = useState({} as User[]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user: loggedUser } = useAuth();

  const handleCanBid = useCallback(
    async (id: string, canBid: boolean) => {
      const updatedUser = await changeUserPermissionBid(id, canBid);

      if (updatedUser) {
        const newState = users;

        const userIndex = users.findIndex(
          selectedUser => selectedUser.userId === id
        );
        const userBidOption = newState[userIndex].canBid;

        newState[userIndex].canBid = !userBidOption;

        setUsers([...newState]);
      }
    },
    [users]
  );

  const setUnitInfo = useCallback(async () => {
    setLoading(true);

    if (loggedUser) {
      const newUsers = await getUsersByUnitId(loggedUser.unitId);

      if (newUsers) {
        const leadBrokerUsers = newUsers.filter(user => !!user.user);

        setUsers(leadBrokerUsers);
      }
    }

    setLoading(false);
  }, [loggedUser]);

  useEffect(() => {
    setUnitInfo();
    document.title = 'Unidades - V4 LB';
  }, [setUnitInfo]);

  return (
    <>
      <Container>
        <Breadcrumb
          breadcrumbs={[
            {
              title: 'Página inicial',
              link: '',
              redirect: 'https://mktlab.app/',
            },
            { title: 'Unidade', link: '/unidade' },
          ]}
        />
        <Section>
          <SectionContent>
            <SectionTitle text="Unidade" />
            <S.Filters>
              <Button
                small
                onClick={() => setOpenModal(true)}
                style={{ marginLeft: 'auto' }}
              >
                Convidar novo investidor
              </Button>
            </S.Filters>
            <S.Table>
              <S.Row>
                <S.Field>Investidor</S.Field>
                <S.Field>Email</S.Field>
                <S.Field>Apto a dar lances</S.Field>
              </S.Row>

              {loading ? (
                <CustomLoading style={{ alignSelf: 'center' }} />
              ) : (
                users.length > 0 &&
                users.map(user => {
                  return (
                    <TableUser
                      key={user._id}
                      user={user}
                      handleCanBid={handleCanBid}
                    />
                  );
                })
              )}
            </S.Table>

            {!loading && !users.length && (
              <S.UnHappyPath>
                <UnHappyPath
                  assetIndex={3}
                  title="Investidores ainda não cadastrados"
                />
              </S.UnHappyPath>
            )}
          </SectionContent>
        </Section>
      </Container>

      {openModal && (
        <InviteNewInvestor setIsOpen={setOpenModal} isOpen={openModal} />
      )}
    </>
  );
}
