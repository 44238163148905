import { parseCookies } from 'nookies';
import apiFederation from '../apiFederation';
import { IAddFundsDTO } from './interfaces/addFoundsInterface';

export async function addFunds(data: IAddFundsDTO) {
  const obj: {
    error: Record<string, unknown> | null;
    data: PaymentDetails;
  } = { error: null, data: null };
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.post(
      `/brokers-wallet/new-deposit`,
      data,
      { headers: { Authorization: `Bearer ${cookies['v4company.token']}` } }
    );
    obj.data = response.data;

    return obj;
  } catch (err) {
    obj.error = err.response?.data;
    return obj;
  }
}
