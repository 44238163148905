import React, { useCallback, useState } from 'react';

import { FiX } from 'react-icons/fi';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

import { useToast } from '../../../contexts/toast';
import { withdraw } from '../../../services/requests/leadbroker/wallet/withdraw';
import { convertCentsToBRL } from '../../../utils/convertCentsToBRL';

import Amount from './amount';
import { Info } from './info';
import Summary from './summary';
import Result from './result';
import Cancel from './cancel';

import * as S from './styles';
import Accounts from './accounts';
import { useWithDraw } from '../../../contexts/withdraw';

interface Props {
  handleToggleModal: () => void;
  backButton?: () => void;
  arrowLeftButton?: boolean;
}

const Withdraw: React.FC<Props> = ({
  handleToggleModal,
  backButton,
  arrowLeftButton,
}) => {
  const {
    setBankInformation,
    frequentAccounts,
    page,
    setPage,
    amount,
    setAmount,
    isVerify,
  } = useWithDraw();

  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handlerCloseModal = () => {
    handleToggleModal();
    setPage('amount');
  };

  const handleToAccounts = useCallback(
    async (totalAmount: number) => {
      setLoading(true);
      setAmount(totalAmount);

      if (isVerify) {
        setPage('summary');
        return false;
      }

      return setPage('info');
    },
    [isVerify, setAmount, setPage]
  );

  const handleToSummary = useCallback(
    async (data, selectedBankId, bankAccountType, bankMasks) => {
      setLoading(true);
      setBankInformation({ data, selectedBankId, bankAccountType, bankMasks });
      try {
        setPage('summary');
      } catch {
        setLoading(false);
        addToast({
          type: 'error',
          description: 'Não foi possível prosseguir para o resumo da transação',
        });
      }
    },
    [addToast, setBankInformation, setPage]
  );

  const handleWithdrawSubmit = useCallback(async () => {
    setLoading(true);
    const parseAmountToCents = amount * 100;

    try {
      await withdraw(parseAmountToCents);

      addToast({
        type: 'success',
        description: `Saque de ${convertCentsToBRL(
          amount * 100
        )} feito com sucesso!`,
      });

      setLoading(false);
      setPage('result');
    } catch {
      setLoading(false);
      addToast({
        type: 'error',
        description: 'Falha ao sacar carteira, tente novamente',
      });
    }
  }, [addToast, amount, setPage]);

  const formPages = {
    info: <Info backButton={handleToggleModal} nextButton={handleToSummary} />,
    amount: <Amount nextButton={handleToAccounts} />,
    accounts: frequentAccounts && (
      <Accounts {...{ loading, nextButton: handleWithdrawSubmit }} />
    ),
    summary: <Summary loading={loading} />,
    cancel: <Cancel handleToggleModal={handlerCloseModal} />,
    result: <Result handleToggleModal={handlerCloseModal} />,
  };

  return (
    <S.Container>
      <S.ModalHeader>
        {arrowLeftButton && (
          <MdOutlineArrowBackIosNew size={15} onClick={() => backButton()} />
        )}
        <h4 className="titleModal">Saque</h4>

        <FiX
          className="closeButton"
          color="var(--secondary-main)"
          size={19}
          onClick={handleToggleModal}
        />
      </S.ModalHeader>
      {formPages[page || 'amount']}
    </S.Container>
  );
};

export default Withdraw;
