import styled, { css } from 'styled-components';

interface statusProps {
  value: string;
}

export const Title = styled.div<{ balance: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  header p {
    margin-top: 0.5rem;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: var(--secondary-light);
  }

  span {
    color: var(--primary-dark);
    font-size: 26px;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    header p {
      margin-bottom: 2rem;
    }
  }

  ${({ balance }) =>
    balance > 409.99 &&
    css`
      span {
        color: var(--success-2);
      }
    `}

  ${({ balance }) =>
    balance === 0 &&
    css`
      span {
        color: var(--black);
      }
    `}
`;

export const TitleAndButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 24px;

  width: 224px;
  height: 104px;

  background: #f4f4f8;

  border: 1px solid #c1c1d7;
  border-radius: 4px;

  flex: none;
  order: 1;
  flex-grow: 0;

  h1 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
`;

export const TitleAndIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .open-icon {
    left: 89.06%;
    right: 1.56%;
    top: 12.5%;
    bottom: 12.5%;
    color: #8383af;

    &:hover {
      color: black;
      cursor: pointer;
      transition: color 0.2s ease-in-out;
    }
  }
`;

export const Buttons = styled.div`
  width: 420px;
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  align-items: center;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;

  lab-ds-button {
    display: flex;
    width: 100%;
    max-width: 50%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
    max-width: 100%;
  }
`;

export const Table = styled.table`
  margin: 2rem 0;
  min-width: 10rem;
`;

export const Row = styled.tr`
  display: flex;
  width: 100%;
  background-color: #e6e6ef;
  border-radius: 3px;

  :not(:first-child):hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  &.disabled:hover {
    box-shadow: none;
    cursor: auto;
  }

  :first-child {
    td {
      font-weight: normal;
      font-family: 'Inter', sans-serif;
    }
  }

  :first-of-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  .transaction-id {
    width: 20%;
    min-width: 240px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-type {
    width: 20%;
    min-width: 140px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-value {
    width: 20%;
    min-width: 120px;
    // overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-author {
    width: 20%;
    min-width: 170px;
    // overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-date {
    width: 20%;
    min-width: 150px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-status {
    width: 20%;
    min-width: 100px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const RowBody = styled.tr`
  display: flex;
  width: 100%;

  :not(:first-child):hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  &.disabled:hover {
    box-shadow: none;
    cursor: auto;
  }

  :first-child {
    td {
      font-weight: normal;
      font-family: 'Inter', sans-serif;
    }
  }

  :first-of-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  .transaction-id {
    width: 20%;
    min-width: 240px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-type {
    width: 20%;
    min-width: 140px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-value {
    width: 20%;
    min-width: 120px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-author {
    width: 20%;
    min-width: 170px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-date {
    width: 20%;
    min-width: 150px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction-status {
    width: 20%;
    min-width: 100px;
    overflow: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Field = styled.td`
  display: flex;
  align-items: center;
  margin: 1rem 1rem;
  text-align: center;
  font-size: 16px;

  img {
    margin-right: 8px;
  }
`;

export const Status = styled.div<statusProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 12px;
  color: ${props =>
    props.value === 'Processando' ? 'var(--white)' : 'var(--black)'};
  background-color: ${props => {
    if (props.value === 'Concluído') {
      return 'var(--success-2)';
    }
    if (props.value === 'Cancelado') {
      return 'var(--primary-light)';
    }
    if (props.value === 'Processando') {
      return 'var(--warning)';
    }
    return 'var(--tertiary-main)';
  }};
`;

export const Tabs = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const Pagination = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;

  svg {
    font-size: 0.625rem;
    color: var(--secondary-dark);
    margin: 0 0.25rem;
    cursor: pointer;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    color: var(--primary-dark);
    background-color: transparent;
    font-size: 0.75rem;
    border: none;

    &.isActive {
      background-color: var(--primary-dark);
      color: var(--white);
    }
  }

  button + button {
    margin-left: 0.2rem;
  }
`;

export const UnHappyPath = styled.div`
  align-self: center;
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    width: 248px;
    height: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: #333333;
    margin-right: 154px;
  }

  .header-icon {
    left: 16.67%;
    right: 16.66%;
    top: 16.67%;
    bottom: 16.66%;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const DialogMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .message-icon {
    margin-right: 10px;
    left: 16.67%;
    right: 16.66%;
    top: 16.67%;
    bottom: 16.66%;
  }

  p {
    width: 382px;
    height: 32px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
  }
`;

export const TotalBalanceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .main-title {
    width: 84px;
    height: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  .total-value {
    width: 100%;
    height: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-align: right;
    color: #00c49a;
  }

  .icon-details {
    left: 20.84%;
    right: 50%;
    top: 66.67%;
    bottom: -25%;

    &:hover {
      cursor: pointer;
    }
  }

  .total-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 132px;
  }

  .value-and-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
  }
`;

export const TotalBalanceDetailsContainer = styled.div`
  margin-top: 7px;

  .withdraw-title {
    width: 47px;
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 8px;
  }

  .title-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 70px;

    .title {
      width: 210px;
      height: 24px;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }

    .value {
      width: 120px;
      height: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: #00c49a;
    }
  }
`;

export const CreditDetailsContainer = styled.div`
  margin-top: 7px;

  .credit-title {
    width: 105px;
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 8px;
  }

  .title-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    .title {
      width: 230px;
      height: 48px;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      text-align: justify;
    }

    .value {
      width: 120px;
      height: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: #00c49a;
    }
  }
`;

export const BonusContainer = styled.div`
  margin-top: 7px;

  .bold-text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
  }

  .bonus-value {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 230px;
    height: 48px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    text-align: justify;
  }

  .bonus-container {
    display: flex;
    justify-content: space-between;
    gap: 200px;

    .bonus-expiration {
      display: flex;
      gap: 4px;
      width: 123px;
      height: 16px;
      font-size: 12px;
    }

    .bonus-title {
      width: 63px;
      height: 24px;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }
  }
`;

export const CreditHistoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .main-title {
    width: 164px;
    height: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  .icon-details {
    left: 20.84%;
    right: 50%;
    top: 66.67%;
    bottom: -25%;

    &:hover {
      cursor: pointer;
    }
  }

  .credit-history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 220px;
  }

  .icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
  }
`;

export const ExpiredBonusContainer = styled.div`
  margin-top: 7px;

  .bonus-title {
    font-weight: 700;
    color: #333333;
  }

  .bonus-container {
    display: flex;
    gap: 180px;

    .avaiable-expiration {
      text-align: right;
    }
  }

  .bold-text {
    font-family: 'Nunito Sans';
    font-weight: 700;
  }

  .avaiable-text {
    height: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #333333;
    gap: 4px;

    span {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .expiration-text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    gap: 4px;
    margin-left: 20px;

    span {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .bonus-value {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 230px;
    height: 48px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    text-align: justify;
    margin-top: -15px;
  }
`;

export const Hr = styled.hr`
  width: 100%;
  border-color: #696969;
  border-radius: 0rem;
  opacity: 0.3;
  margin: 0.625rem 0;
`;

export const HrSmall = styled.hr`
  width: 100%;
  border-color: #696969;
  border-radius: 0rem;
  opacity: 0.15;
  margin: 0.625rem 0;
`;
