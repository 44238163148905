import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 1.5rem;
`;

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--primary-light);
  padding: 0.5rem;
  border-radius: 40%;
  color: var(--white);
  width: 1.25rem;
  height: 1rem;
  font-size: 0.625rem;
  font-weight: 400;
  top: -5px;
  right: -8px;
`;

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 24rem;
  justify-content: initial;
  align-items: center;
  position: absolute;
  top: 3rem;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  z-index: 2;
  border-radius: 0.625rem;
  transform: translateX(-25%);

  > .mt-1 {
    margin-top: 1rem;
  }
  > .mb-1 {
    margin-bottom: 1rem;
  }
`;

export const NotificationHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2rem;
  border-bottom: 2px solid var(--tertiary-light);
  border-radius: 0.625rem 0 0 0.625rem;

  small {
    color: var(--secondary-light);
    font-weight: 700;
    line-height: 1rem;
    font-size: 0.8125rem;
    opacity: 0.6;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
`;

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: default;
`;
