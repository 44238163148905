import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Bids = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 1rem 1.75rem;
`;

export const SeeMore = styled.li`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between;
  margin: 2rem 0;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    color: var(--primary-dark);

    svg {
      font-size: 1.5rem;
      margin-left: 0.25rem;
    }
  }

  p {
    font-size: 22px;
    font-weight: bold;
  }
`;

export const UnhappyPath = styled.div`
  align-self: center;
`;
