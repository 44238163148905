import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.form`
  > section {
    display: block;
    justify-content: space-between;
    font-family: 'Inter';
    margin: 2.5rem;
    height: 37rem;

    h1 {
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  small {
    color: var(--primary-main);
    bottom: 0;
    margin: 0.25rem;
  }
`;

export const InputContent = styled.div<{ maxWidth: string }>`
  width: ${({ maxWidth }) => maxWidth};
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const EstateContainer = styled.div`
  width: 48%;
  display: flex;
  margin-bottom: 2rem;
  color: var(--secondary-main);
  background: var(--white);
  justify-content: space-between;
  flex-direction: column;
`;

export const Footer = styled.footer`
  padding: 1rem;
`;
