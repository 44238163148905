export const statusRefund = {
  WAITING: { label: 'Enviado', state: 'warning' },
  UNDER_ANALYSIS: { label: 'Em análise', state: 'warning' },
  REJECTED: { label: 'Negado', state: 'error' },
  APPROVED: { label: 'Efetuado', state: 'success' },
};

export const leadStatus = {
  LEAD_PURCHASED: { label: 'Comprado', state: 'success' },
  IN_PROSPECTING: { label: 'Prospecção', state: 'warning' },
  MEETING_SCHEDULED: { label: 'Reunião marcada', state: 'warning' },
  CONTRACT_SENT: { label: 'Contrato enviado', state: 'success' },
  ACTIVE_CONTRACT: { label: 'Contrato ativo', state: 'success' },
  LOST_LEAD: { label: 'Lead perdido', state: 'error' },
  RETURN_MEETING: { label: 'Reunião marcada', state: 'warning' },
};
