import styled from 'styled-components';
import BackgroundImage from './assets/IMG_1568.webp';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
`;

export const Login = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  background-color: var(--terciary-light);
  z-index: 2;

  strong {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 26px;
    color: var(--secondary-main);
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  > button {
    margin: 1rem 0;
  }

  p {
    margin-top: 1rem;
    color: var(--primary-main);
  }
`;

export const Background = styled.div`
  position: absolute;
  right: 0;
  width: 60%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;

  background-repeat: no-repeat;
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
  padding-right: 10rem;
  margin-top: 1rem;

  svg {
    width: 14rem !important;
    height: 14rem !important;
  }
`;
