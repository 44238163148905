import { parseCookies } from 'nookies';
import { unitsApi as api } from '../api';

export async function putUseTerms(unitId: string, acceptedUseTerms: boolean) {
  try {
    const cookies = parseCookies(undefined);
    const token = cookies['v4company.token'];

    const response = await api.put(
      '/units',
      {
        _id: unitId,
        acceptedUseTerms,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response?.data;
  } catch (err) {
    console.error('Ocorreu algum erro ao aceitar os termos de uso', err);
    return false;
  }
}
