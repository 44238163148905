import { CSSProperties } from 'styled-components';

import { Container, Spinner } from './styles';

interface LoadingBidProps {
  style?: CSSProperties;
  backgroundColor?: string;
}

export function LoadingAddBonus({
  style,
  backgroundColor,
}: LoadingBidProps): JSX.Element {
  return (
    <Container
      className="loadingspinner"
      data-testid="loadingbid-container"
      style={style}
      backgroundColor={backgroundColor}
    >
      <Spinner />
    </Container>
  );
}
