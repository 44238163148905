import styled, { keyframes } from 'styled-components';

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1.5rem, 0);
  }
`;

export const Container = styled.div<{
  backgroundColor?: string;
}>`
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 5rem;
  left: 50%;
  transform: translateX(-50%, -50%);

  div {
    position: absolute;
    top: 2.06rem;
    width: 0.81rem;
    height: 0.81rem;
    border-radius: 50%;
    background: ${({ backgroundColor }) =>
      backgroundColor || 'var(--primary-main)'};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 0.5rem;
      animation: ${ellipsis1} 0.6s infinite;
    }
    &:nth-child(2) {
      left: 0.5rem;
      animation: ${ellipsis2} 0.6s infinite;
    }
    &:nth-child(3) {
      left: 2rem;
      animation: ${ellipsis2} 0.6s infinite;
    }
    &:nth-child(4) {
      left: 3.5rem;
      animation: ${ellipsis3} 0.6s infinite;
    }
  }
`;
