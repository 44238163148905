import { Dispatch, SetStateAction } from 'react';
import Card from '../Card';
import * as S from './styles';

interface CardsContainerProps {
  notifications: NotificationMessage[];
  setTotalUnreadNotifications: Dispatch<SetStateAction<number>>;
}

export function CardsContainer({
  notifications,
  setTotalUnreadNotifications,
}: CardsContainerProps) {
  return (
    <S.CardsContainer>
      {!notifications?.length ? (
        <S.NoneNotificationText>Sem nenhuma notificação</S.NoneNotificationText>
      ) : (
        notifications.map((notification: NotificationMessage) => (
          <Card
            key={notification._id}
            _id={notification._id}
            read={notification.read}
            status={notification.module}
            title={notification.title}
            message={notification.message}
            time={new Date(notification.createdAt)}
            setTotalUnreadNotifications={setTotalUnreadNotifications}
          />
        ))
      )}
    </S.CardsContainer>
  );
}

export default Notification;
