import React from 'react';
import { Link } from 'react-router-dom';
import { ImCancelCircle } from 'react-icons/im';

import { LabDsButton } from 'v4web-components-react';
import * as S from './styles';

interface Props {
  handleToggleModal: () => void;
}

const Cancel: React.FC<Props> = ({ handleToggleModal }) => {
  return (
    <S.Container>
      <ImCancelCircle size={45} color="#E92E30" />

      <h3>Solicitação de saque cancelada com sucesso!</h3>

      <S.Footer>
        <LabDsButton
          variant="danger-outlined"
          label="Fechar"
          onHandleButton={() => handleToggleModal()}
        />

        <Link to="/">
          <LabDsButton
            label="Voltar para página inicial"
            onHandleButton={() => null}
          />
        </Link>
      </S.Footer>
    </S.Container>
  );
};

export default Cancel;
