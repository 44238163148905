import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: var(--lab-ds-semantic-selectable-space-gap-xl);
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 0 auto;
  background-color: var(--white);

  svg {
    font-size: 5rem;
    color: var(--primary-main);
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  button + button {
    margin-left: 2rem;
    background-color: var(--primary-dark);
    border: none;
    color: var(--white);
  }

  button {
    color: var(--primary-dark);
    border-color: var(--primary-dark);
    border-radius: 0.5rem;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

export const Icon = styled.div<{ color: string }>`
  ${props => `
    color: ${props.color};
  `}
`;

export const TitleModal = styled.span`
  font: var(--lab-ds-semantic-typography-heading-h4);
`;

export const TextModal = styled.span`
  font-size: 1rem;
  color: var(--lab-ds-core-color-black-10);
  font-weight: 700;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
`;
