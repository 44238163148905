import styled, { css } from 'styled-components';
import { FiX } from 'react-icons/fi';

export const Container = styled.div`
  display: grid;
  position: relative;
  background: var(--white);
  border-radius: 0.5rem;
  width: 100%;

  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    border-bottom: 0.12rem solid var(--tertiary-dark);

    h2 {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 20ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        font-size: 1.777rem;
        font-weight: 700;
        color: var(--success-3);
      }
    }

    @media (min-width: 101.5rem) {
      flex-direction: row;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    form {
      display: flex;
      align-items: center;
      position: relative;

      small {
        position: absolute;
        bottom: -0.5rem;
      }
    }
  }

  .created-at {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;

    span {
      color: var(--success-3);
    }
  }

  .errorBid {
    color: var(--error);
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .bidTooltipButton {
    width: 10.25rem;
    margin-left: 3.5rem;
    span {
      background-color: var(--white);
      left: 15%;
      width: 18.7rem;
      height: 4.5rem;
      border: 0.063rem solid var(--grayscale-20);
      border-color: var(--grayscale-20);
      color: var(--secondary-main);
      display: flex;
      align-items: center;
      box-shadow: var(--grayscale-20) 0.1em 0.1em 0.2em;
      font-size: 0.75rem;
      ::before {
        content: '';
        border-style: solid;
        border-color: var(--grayscale-20) transparent;
        border-width: 0.65rem 0.65rem 0 0.65rem;
        top: 100%;
        position: absolute;
        left: 85%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        filter: drop-shadow(0rem 0.1rem 0.063rem var(--grayscale-20));
      }
      ::after {
        content: '';
        border-style: solid;
        border-color: var(--white) transparent;
        border-width: 0.55rem 0.55rem 0 0.55rem;
        top: 100%;
        position: absolute;
        left: 85%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
  .buy-now-button {
    &[disabled] {
      background: #bbbbbb;
      color: #333333;
      transition: none;
      cursor: pointer;
    }
  }

  .bidButton {
    margin-bottom: 1.2rem;
    margin-left: 4rem;
    width: 11rem;
    height: 2.89rem;
    border: 0.13rem solid var(--success-2);
    font-weight: 600;
  }
`;

export const CloseModalButton = styled(FiX).attrs({
  size: 32,
})`
  right: -2rem;
  top: -2rem;
  position: absolute;
  cursor: pointer;
  color: var(--grayscale-60);
`;

export const ContentLeads = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 0.5rem;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;
export const SliderLead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 0.32rem;
  height: 100%;
  position: relative;
`;

export const Lead = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 21.9rem;
  height: 7.5rem;
  background-color: var(--grayscale-2);
  border: 0.06rem solid #c1c1d7;
  padding: 0.5rem;
  border-radius: 0.5rem;

  position: relative;
`;

export const MarkSlider = styled.button<{ current: boolean }>`
  width: 0.63rem;
  height: 0.63rem;
  border-radius: 0.63rem;
  background-color: ${({ current }) =>
    current ? 'var(--grayscale-10)' : 'var(--grayscale-2)'};
  margin-right: 0.32rem;
  border: none;
`;

export const Row = styled.div`
  margin-top: 0.63rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  small {
    color: var(--secondary-main);
    font-weight: 700;
    font-size: 1.4rem;
  }

  .qtd {
    font-size: 1rem;
    b {
      color: var(--secondary-dark);
    }
  }

  p {
    margin: 0;
    margin-left: 0.5rem;
    font-size: 1.4rem;
    color: var(--secondary-black);
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 100;
  }
`;

export const LeadTitle = styled.h1`
  font-size: 1.25rem;
  max-width: 23ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MainLead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.2rem;
  height: 2.2rem;

  border-radius: 2.2rem;
  border: 0.06rem solid var(--grayscale-10);
  background-color: var(--tertiary-light);

  box-shadow: 0 0.06rem 0.12rem rgba(0, 0, 0, 0.12),
    0 0.12rem 0.38rem 0.12rem rgba(0, 0, 0, 0.06);

  position: absolute;
  top: -0.94rem;
  right: -0.94rem;
`;

export const LeadFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  bottom: 0;

  padding: 0.32rem;
  .footer {
    > small {
      font-size: 0.57rem;
    }
    > p {
      margin-top: 0.12rem;
      font-size: 0.88rem;
    }
  }
`;

export const BtnCurrent = styled.button<{ left: boolean }>`
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 2.2rem;
  background-color: transparent;
  border: 0.06rem solid var(--grayscale-20);
  box-shadow: 0 0.06rem 0.12rem rgba(0, 0, 0, 0.12),
    0 0.12rem 0.38rem 0.12rem rgba(0, 0, 0, 0.06);
  position: absolute;
  bottom: 45%;
  ${({ left }) => (left ? 'left: 0.63rem;' : 'right: 0.63rem;')}
`;

export const Details = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  height: 100%;
  width: 50%;

  @media (max-width: 101.5rem) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  small {
    color: var(--secondary-light);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.875rem;
    font-family: 'Inter';
  }

  p {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: var(--secondary-main);
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    max-height: 4.4rem;
    max-width: 11.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
  }
`;

export const BidHistory = styled.ul<{ isGreaterThanFour?: boolean }>`
  border-bottom: 0.12rem solid var(--tertiary-dark);
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  overflow: auto;

  ${props =>
    props.isGreaterThanFour &&
    css`
      max-height: 20vh;
    `}

  .info__bid {
    width: 18.7rem;
    padding: 0.32rem;
    font-size: 0.75rem;
    font-weight: 700;
    background-color: var(--warning);
    color: var(--secondary-dark) !important;
    border-radius: 0.19rem;

    margin: 0;
    align-items: center;
  }

  div {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  div:nth-child(2) {
    background-color: var(--grayscale-2);
  }

  p {
    width: 25rem;
    max-height: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden;

    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
  }

  div:first-child p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
  }

  div:nth-child(2) p {
    color: var(--success-3);
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media (min-width: 101.5rem) {
    justify-content: flex-end;
  }
`;

export const Button = styled.button<{
  isBlocked?: boolean;
  isLoadingBid?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  height: 2.5rem;
  min-width: 10.25rem;
  background: var(--tertiary-light);
  border: 0.12rem solid var(--success-2);
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  color: var(--success-2);
  transition: all 0.3s;

  ${props =>
    props.isBlocked &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: var(--error);
      border-color: var(--error);
    `}

  &:hover {
    background: var(--success-2);
    color: var(--tertiary-light);
  }

  &:hover:not([disabled]) {
    background: var(--success-3);
    color: var(--tertiary-light);
  }

  &[disabled] {
    color: #bbbbbb;
    border: 0;
    transition: none;
    cursor: pointer;
  }

  ${({ isLoadingBid }) =>
    isLoadingBid &&
    css`
      background: var(--success-3);
      color: var(--tertiary-light);
      border: 0;
    `}
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 0 auto;
  background-color: var(--white);

  svg {
    font-size: 5rem;
    color: var(--primary-main);
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  button + button {
    margin-left: 2rem;
    background-color: var(--primary-dark);
    border: none;
    color: var(--white);
  }

  button {
    color: var(--primary-dark);
    border-color: var(--primary-dark);
    border-radius: 0.5rem;
  }
`;

export const TooltipContainer = styled.div`
  height: 2.5rem;
  min-width: 10.25rem;
  margin-top: 2.2rem;
`;

export const ButtonContainer = styled.div`
  height: 2.5rem;
  min-width: 10.25rem;
  margin-top: 2.2rem;
`;

export const Input = styled.div`
  width: 27.875rem;
`;

export const Description = styled.div`
  width: 100%;

  small {
    margin-bottom: 0.7rem;
  }
`;

export const InputDescription = styled.textarea`
  width: 100%;
  height: 6rem;
  padding: 0.6rem 0.6rem;
  border-radius: 0.5rem;
  border: 0.06rem solid var(--tertiary-dark);
  resize: none;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
`;
