import { useCallback, useMemo } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import ReactSelect, {
  Props as SelectProps,
  Theme,
  components,
  DropdownIndicatorProps,
  StylesConfig,
} from 'react-select';

import * as S from './styles';

interface ComponentProps extends SelectProps {
  marginBottom?: string;
  containerStyle?: React.CSSProperties;
  label?: string;
  name?: string;
}

export function Select({
  marginBottom = '0rem',
  containerStyle,
  label,
  name,
  ...rest
}: ComponentProps) {
  const customTheme = useCallback((theme: Theme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: 'var(--grayscale-10)',
        primary25: 'var(--white)',
        primary50: 'var(--white)',
        primary75: 'var(--white)',
        neutral0: 'var(--white)',
        neutral5: 'var(--white)',
        neutral10: 'var(--white)',
        neutral20: 'var(--white)',
        neutral30: 'var(--secondary-light)',
        neutral40: 'var(--secondary-light)',
        neutral50: 'var(--secondary-light)',
        neutral60: 'var(--secondary-light)',
        neutral70: 'var(--secondary-light)',
        neutral80: 'var(--secondary-light)',
        neutral90: 'var(--secondary-light)',
      },
    };
  }, []);

  const customStyles: StylesConfig = useMemo(() => {
    return {
      container: provided => ({
        ...provided,
        marginBottom,
        borderRadius: '0rem',
        borderColor: '1px solid var(--error)',
      }),
      option: provided => ({
        ...provided,
        borderRadius: '0rem',
        borderColor: '1px solid var(--error)',
        cursor: 'pointer',
        color: 'var(--secondary-main)',
        minHeight: '3rem',
      }),
      menu: provided => ({
        ...provided,
        background: 'var(--white)',
        border: '1px solid var(--secondary-light)',
        borderRadius: '0.5rem',
      }),
      singleValue: provided => ({
        ...provided,
        color: 'var(--secondary-main)',
      }),
      input: provided => ({
        ...provided,
        padding: '0rem',
        fontSize: '16px',
        border: '1px solid var(--error)',
        color: 'var(--secondary-main)!important',
      }),
      control: provided => ({
        ...provided,
        padding: '0rem!important',
        height: '3rem',
        fontSize: '1rem',
        color: 'var(--secondary-main)',
        '&:hover': {
          color: 'var(--secondary-main)!important',
        },
      }),
      dropdownIndicator: provided => ({
        ...provided,
        cursor: 'pointer',
        color: 'var(--grayscale-60)',
      }),
    };
  }, [marginBottom]);

  const CaretDownIcon = useCallback(() => {
    return <MdKeyboardArrowDown size={18} />;
  }, []);

  const DropdownIndicator = useCallback(
    (props: DropdownIndicatorProps) => {
      return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon />
        </components.DropdownIndicator>
      );
    },
    [CaretDownIcon]
  );

  return (
    <S.ContainerLabel>
      {label && (
        <label
          htmlFor={name}
          style={{
            fontSize: '1rem',
            fontFamily: 'Inter' || 'Nunito Sans',
            fontWeight: '700',
            fontStyle: 'normal',
          }}
        >
          {label}
        </label>
      )}
      <S.Container style={containerStyle} className="select-container">
        <ReactSelect
          theme={customTheme}
          styles={customStyles}
          components={{ DropdownIndicator }}
          {...rest}
        />
      </S.Container>
    </S.ContainerLabel>
  );
}
