import styled from 'styled-components';

const colors = {
  LEAD_PURCHASED: 'var(--lab-ds-core-color-green-100)',
  IN_PROSPECTING: 'var(--lab-ds-core-color-green-90)',
  MEETING_SCHEDULED: 'var(--lab-ds-core-color-green-80)',
  CONTRACT_SENT: 'var(--lab-ds-core-color-green-70)',
  ACTIVE_CONTRACT: 'var(--lab-ds-core-color-green-60)',
  LOST_LEAD: 'var(--lab-ds-core-color-red-50)',
};

const textColor = { LOST_LEAD: 'var(--lab-ds-core-color-red-50)' };

export const Container = styled.div`
  display: flex;
  gap: var(--lab-ds-semantic-selectable-space-padding-m);
`;

export const ArrowLeft = styled.div`
  width: 0;
  height: 0;
  border-top: 1.335rem solid var(--lab-ds-semantic-color-bg-disabled);
  border-bottom: 1.335rem solid var(--lab-ds-semantic-color-bg-disabled);

  border-left: 1.335rem solid transparent;
`;

export const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 1.335rem solid transparent;
  border-bottom: 1.335rem solid transparent;

  border-left: 1.335rem solid var(--lab-ds-semantic-color-bg-disabled);
`;

export const ProgressLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.61rem;
  width: 100%;
  background-color: var(--lab-ds-semantic-color-bg-disabled);
  color: var(--lab-ds-core-color-black-10);
  font-weight: 700;
  font-size: 0.9rem;
`;

export const Progress = styled.div<{
  active: boolean;
  progress: string;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;

  ${props =>
    props.active &&
    `
    & .arrow-left {
      border-top-color: ${colors[props.progress]};
      border-bottom-color: ${colors[props.progress]};
    }
    & .arrow-right {
      border-left-color: ${colors[props.progress]};
    }
    & .progress-label {
      color: ${
        textColor[props.progress]
          ? '#FFFFFF'
          : 'var(--lab-ds-semantic-color-fg-default)'
      };
        background-color: ${colors[props.progress]};
    }
  `}
`;
