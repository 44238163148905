import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: var(--lab-ds-semantic-selectable-space-gap-xl);
`;

export const Card = styled.div<{
  reasonFileError?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--lab-ds-semantic-selectable-space-gap-s);
  border-style: dashed;
  border-width: var(--lab-ds-semantic-placeholder-border-width-s);
  border-color: var(--lab-ds-semantic-selectable-color-border-default);
  padding: var(--lab-ds-semantic-selectable-space-padding-m)
    var(--lab-ds-semantic-selectable-space-padding-xl);
  color: var(--lab-ds-semantic-color-fg-default);

  p {
    font: var(--lab-ds-semantic-typography-heading-h6);
  }
  span {
    font: var(--lab-ds-semantic-typography-body-none-decoration-p2-bold);
    color: var(--lab-ds-core-color-black-10);
  }
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  ${({ reasonFileError }) =>
    reasonFileError &&
    css`
      border-color: var(--lab-ds-core-color-red-arterial-50);
    `}
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--lab-ds-semantic-selectable-space-gap-s);
`;

export const Title = styled.span`
  font: var(--lab-ds-semantic-typography-heading-h7);
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--lab-ds-semantic-selectable-space-gap-m);
`;

export const FileItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--lab-ds-semantic-selectable-space-gap-xs);

  span {
    font: var(--lab-ds-semantic-typography-body-none-decoration-p4-regular);
  }
`;

export const FileItemName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    color: var(--secondary-dark);
    font-size: 0.75rem;
    font-weight: 700;
  }
`;
