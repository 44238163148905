import React, { ReactNode, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LabDsNavbar, NavbarItem } from 'v4web-components-react';
import { InfiniteData } from '@tanstack/react-query';
import addNotification from 'react-push-notification';
import { parseCookies } from 'nookies';
import useWebSocket from 'react-use-websocket';
import { format } from 'date-fns';
import useAsyncQueue from 'use-async-queue';
import { menus } from './menus';
import { Header } from '../../../components/AtomicDesign/molecules/Header';
import { useAuth } from '../../../contexts/auth';
import v4Logo from '../../../assets/logos/V4-Logo.svg';
import * as S from './styles';
import {
  queryClient,
  useAuctions,
  useNotifications,
  useToast,
} from '../../../contexts';
import { useQueryUnitBlock } from '../../../services/requests/leadbroker/unit/getUnit';
import { useBalance } from '../../../hooks/wallet';
import { unreadNotifications } from '../../../utils/unreadNotifications';
import { socketApiAWS } from '../../../services/requests/leadbroker/api';
import { errorsBySocket } from '../constants/errorsBySocket';

export interface DefaultProps extends React.HTMLAttributes<Element> {
  children: ReactNode;
}

interface SubMenu {
  title: string;
  icon: string;
  active: boolean;
  event: () => void;
  conditional?: boolean;
}

export default function Default() {
  const { user, isNowBlocked, setIsNowBlocked, permissionsManager } = useAuth();
  const [isManagerUser, setIsManagerUser] = useState(false);
  const {
    unitInfos,
    actualTab,
    expPurchasedAuctions,
    setExpPurchasedAuctions,
    bidsToAnimate,
    setBidsToAnimate,
    auction,
    setAuction,
    auctionPack,
    setAuctionPack,
  } = useAuctions();
  const { setNotifications, setTotalUnreadNotifications } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToast();
  const { setBalance, updateBalance } = useBalance();

  Object.keys(permissionsManager || {})?.forEach(key => {
    Object.keys(permissionsManager?.[key])?.forEach(subKey => {
      if (permissionsManager?.[key]?.[subKey] && !isManagerUser) {
        setIsManagerUser(true);
      }
    });
  });

  const headquarterId = process.env.REACT_APP_HEADQUARTER_ID;

  const isHeadquarter = user.unitId === headquarterId;

  const { data: unitBlockData, error: unitError } = useQueryUnitBlock(
    user?.unitId
  );

  useEffect(() => {
    if (unitBlockData?.data?.blockedAt) {
      setIsNowBlocked(true);
    }

    if (isNowBlocked && location?.pathname === '/') {
      navigate('/meus-leads');
    }

    if (unitError) {
      return addToast({
        description: 'Erro ao verificar unidade',
        type: 'error',
      });
    }

    return undefined;
  }, [
    unitBlockData,
    unitError,
    isNowBlocked,
    setIsNowBlocked,
    addToast,
    navigate,
    location,
  ]);

  const menusLab = menus({
    unitId: user?.unitId,
    lbUser: true,
    managerUser: isManagerUser,
    isHeadquarter,
    transition: user.permissions?.transition?.view,
    unitBlocked: isNowBlocked,
    navigate,
    location,
  });

  const verifySubmenus = (submenus: SubMenu[]) => {
    return submenus?.filter(submenu => submenu.conditional);
  };

  const updatesBySocket = async event => {
    const actionsBySocket = {
      balanceChanged: (data: { value: number }) => {
        setBalance(data.value);
      },
      newBonus: data => {
        const actualDate = new Date();
        const expiredDate = new Date(data.expiresAt);
        const isExpired = expiredDate < actualDate;

        const sameUnit = data.whoReceived.unitId === user?.unitId;

        if (!isExpired && sameUnit) {
          addNotification({
            title: `Você recebeu ${data.amountBR} de bônus`,
            message: `Use dentro do Lead Broker até ${format(
              expiredDate,
              'dd/MM/yyyy'
            )}`,
            native: true,
            duration: 5000,
            onClick: () => navigate(`/carteira`),
          });
        }
      },
      newAuction: async data => {
        const keys = {
          'Todas as ofertas': 'all-auctions',
          'Ofertas unitárias': 'unit-auctions',
          'Ofertas com desconto': 'refurbished-auctions',
        };
        addNotification({
          title: 'Um novo Lead entrou na plataforma 🚀',
          message:
            'Acabamos de adicionar um Lead novo, vá até o LeadBroker e confira esta oferta!',
          subtitle: 'Leadbroker',
          native: true,
          duration: 5000,
          onClick: () => navigate(`/bid/${data._id}`),
        });

        if (!keys[actualTab]) return;

        await queryClient.setQueryData(
          [keys[actualTab]],
          (oldData: InfiniteData<AuctionAllResponse, unknown>) => {
            return {
              ...oldData,
              pages: oldData.pages.map((page, index) => {
                return {
                  ...page,
                  data:
                    index === oldData.pages.length - 1
                      ? [...page.data, data]
                      : [...page.data],
                };
              }),
            };
          }
        );
      },
      newExpressPurchase: async data => {
        const unitThatBought = data.winner.unitId;
        const haveUserUnitParticipatedInThisAuction = data.bids.some(
          bid => bid.unitId === user?.unitId
        );

        setTimeout(() => {
          const removeCard = expPurchasedAuctions.filter(
            auct => auct._id === data._id
          );
          setExpPurchasedAuctions(removeCard);
        }, 8000);

        const isPack = data?.leads !== undefined;

        const keys = {
          'Todas as ofertas': 'all-auctions',
          'Ofertas unitárias': 'unit-auctions',
          'Ofertas em pack': 'pack-auctions',
          'Ofertas com desconto': 'refurbished-auctions',
          'Meus lances': 'my-bids',
        };

        await queryClient.setQueryData(
          [keys[actualTab]],
          (oldData: InfiniteData<AuctionAllResponse, unknown>) => {
            const isNotBuyerUnit = user?.unitId !== unitThatBought;
            if (!isNotBuyerUnit) {
              addToast({
                type: 'success',
                description: `Parabéns, você comprou a oferta ${
                  isPack ? data.packSequencial : data.lead?.company
                }! Vá até a página meus Leads para mais informações.`,
              });
              queryClient.refetchQueries({
                queryKey: [keys[actualTab]],
              });
              updateBalance();
            }

            if (isNotBuyerUnit && haveUserUnitParticipatedInThisAuction) {
              addToast({
                type: 'error',
                description: `O ${isPack ? 'pack' : 'lead'} ${
                  isPack ? data.packSequencial : data.lead?.company
                } que você deu lance foi arrematado pelo compre já!`,
              });

              const isThereMoreLeads = oldData?.pages[0]?.data?.length > 1;

              addNotification({
                title: `O ${
                  isPack ? 'pack' : 'lead'
                } que você estava disputando foi arrematado 💵`,
                subtitle: 'Leadbroker',
                message: `Acabaram de arrematar o ${
                  isPack ? 'pack' : 'lead'
                } que você estava disputando, mas não desanime, ${
                  isThereMoreLeads
                    ? 'ainda temos outras ofertas'
                    : 'logo teremos mais ofertas'
                } disponíveis!`,
                native: true,
                duration: 5000,
              });
            }

            return {
              ...oldData,
              pages: oldData.pages.map(page => {
                return {
                  data: page.data.filter(auct => {
                    if (auct._id === data._id) {
                      setExpPurchasedAuctions([
                        ...expPurchasedAuctions,
                        data as AuctionAll,
                      ]);
                      return false;
                    }
                    return auct;
                  }),
                };
              }),
            };
          }
        );

        if (auction?._id === data._id || auctionPack?._id === data._id) {
          data?.leads ? setAuctionPack(data) : setAuction(data);
        }
      },
      newAuctionPack: async data => {
        const keys = {
          'Todas as ofertas': 'all-auctions',
          'Ofertas em pack': 'pack-auctions',
        };
        addNotification({
          title: 'Um novo pack entrou na plataforma 🚀',
          subtitle: 'Leadbroker',
          message:
            'Acabamos de adicionar um Pack novo, vá até o LeadBroker e confira esta oferta!',
          native: true,
          duration: 5000,
          onClick: () => navigate(`/bid/pack/${data._id}`),
        });

        if (!keys[actualTab]) return;

        await queryClient.setQueryData(
          [keys[actualTab]],
          (oldData: InfiniteData<AuctionAllResponse, unknown>) => {
            return {
              ...oldData,
              pages: oldData.pages.map((page, index) => {
                return {
                  ...page,
                  data:
                    index === oldData.pages.length - 1
                      ? [...page.data, data]
                      : [...page.data],
                };
              }),
            };
          }
        );
      },
      deleteLead: async data => {
        const keys = {
          'Todas as ofertas': 'all-auctions',
          'Ofertas unitárias': 'unit-auctions',
          'Ofertas com desconto': 'refurbished-auctions',
        };

        await queryClient.setQueryData(
          [keys[actualTab]],
          (oldData: InfiniteData<AuctionAllResponse, unknown>) => {
            return {
              ...oldData,
              pages: oldData.pages.map(page => {
                return {
                  data: page.data.filter(auct => auct._id !== data._id),
                };
              }),
            };
          }
        );

        if (auction?._id === data._id) {
          setAuction(data);
        }
      },
      newBid: async data => {
        const actualDate = new Date();
        const expiredDate = new Date(data.expiresAt);
        const isExpired = expiredDate < actualDate;

        const lastPosition = 1;
        const secondToLastPosition = 2;
        const bidsList = data.bids;

        const isSecondToLastBidFromUserUnit =
          bidsList[bidsList?.length - secondToLastPosition]?.unitId ===
          user?.unitId;
        const isLastBidFromUserUnit =
          bidsList[bidsList?.length - lastPosition]?.unitId === user?.unitId;

        if (
          !isExpired &&
          !isLastBidFromUserUnit &&
          isSecondToLastBidFromUserUnit
        ) {
          addNotification({
            title: 'Seu lance foi superado 😰',
            subtitle: 'Leadbroker',
            message:
              'Acabaram de superar seu lance, corre que ainda dá tempo de levar esse Lead!',
            native: true,
            duration: 5000,
            onClick: () =>
              navigate(
                data.lead ? `/bid/${data._id}` : `/bid/pack/${data._id}`
              ),
          });
        }
        const keys = {
          'Todas as ofertas': 'all-auctions',
          'Ofertas unitárias': 'unit-auctions',
          'Ofertas em pack': 'pack-auctions',
          'Ofertas com desconto': 'unit-auctions-refurbished',
          'Meus lances': 'my-auctions',
        };

        if (!keys[actualTab]) return;

        const buyAuctionAllBySocket = async (newAuction: AuctionAll) => {
          await queryClient.setQueryData(
            [keys[actualTab]],
            (oldData: InfiniteData<AuctionAllResponse, unknown>) => {
              return {
                ...oldData,
                pages: oldData.pages.map(page => {
                  return {
                    data: page.data.map(auc => {
                      if (auc._id === newAuction._id) {
                        return newAuction;
                      }
                      return auc;
                    }),
                  };
                }),
              };
            }
          );
        };

        const bidAuctionAllBySocket = async (newAuction: AuctionAll) => {
          await queryClient.setQueryData(
            [keys[actualTab]],
            (oldData: InfiniteData<AuctionAllResponse, unknown>) => {
              return {
                ...oldData,
                pages: oldData?.pages.map(page => {
                  return {
                    ...page,
                    data: page?.data.map(auc => {
                      if (auc._id === newAuction._id) {
                        setBidsToAnimate([
                          ...bidsToAnimate,
                          {
                            id: newAuction._id,
                            previousBidValue: auc.expiresAt,
                          },
                        ]);
                        return newAuction;
                      }
                      return auc;
                    }),
                  };
                }),
              };
            }
          );
        };

        if (isExpired) {
          await buyAuctionAllBySocket(data);
        } else {
          await bidAuctionAllBySocket(data);
        }

        if (auction?._id === data._id || auctionPack?._id === data._id) {
          data?.leads ? setAuctionPack(data) : setAuction(data);
        }
      },
      notification: (newNotification: NotificationMessage) => {
        setNotifications(state => {
          const newNotifications = [...state, newNotification];

          setTotalUnreadNotifications(
            unreadNotifications(newNotifications)?.length
          );

          return newNotifications;
        });
      },
      error: data => {
        addToast(errorsBySocket[data?.errorCode]);
      },
    };
    await actionsBySocket[event.event]?.(event.body);
  };

  const { add } = useAsyncQueue({
    concurrency: 0,
  });

  const { 'v4company.token': token } = parseCookies();
  useWebSocket(socketApiAWS, {
    queryParams: {
      token,
      userId: user?._id,
      unitId: user?.unitId,
      plataform: 'leadbroker',
    },
    retryOnError: true,
    onMessage: message => {
      const data = JSON.parse(message.data);

      add({
        id: 'updateBySocket',
        task: () => {
          return updatesBySocket(data);
        },
      });
    },
  });

  return (
    <>
      <Header />
      {user?.unitId && (
        <LabDsNavbar
          logo={v4Logo}
          user={{
            name: user.name,
            unitName: unitInfos.unitName,
          }}
          activeProductsV4={location.pathname.includes('/')}
          activeRedeV4={false}
        >
          {menusLab.map(menu => {
            if (menu.conditional) {
              return (
                <NavbarItem
                  slot={menu.slot}
                  submenus={verifySubmenus(menu.subMenus as SubMenu[]) || []}
                  item={{
                    title: menu.title,
                    icon: menu.icon,
                    active: menu.active,
                    event: menu.event,
                  }}
                />
              );
            }
            return null;
          })}
        </LabDsNavbar>
      )}
      <S.Main>
        <Outlet />
      </S.Main>
    </>
  );
}
