import { LabDsButton, LabDsIconNotSelectable } from 'v4web-components-react';
import { Dialog } from '@reach/dialog';
import * as S from './styles';

export function ModalSalesforcePartner({
  setShowModalSalesforcePartner,
}: {
  setShowModalSalesforcePartner: (boolean) => void;
}) {
  return (
    <Dialog
      aria-labelledby="label"
      onDismiss={() => setShowModalSalesforcePartner(false)}
      style={{ marginTop: '20vh', width: '70vw' }}
    >
      <S.Modal>
        <S.ModalBody>
          <S.Icon color="var(--lab-ds-semantic-selectable-color-bg-dark-warning)">
            <LabDsIconNotSelectable icon="warning" size="x-large" />
          </S.Icon>
          <S.TitleModal>Utilize o Partner da Salesforce</S.TitleModal>
          <S.TextModal>
            Esta função está disponível apenas para unidades que não possuem
            CRM.
          </S.TextModal>
          <S.TextModal style={{ marginTop: '-1.2rem' }}>
            Para melhor experiência utilize a Salesforce.
          </S.TextModal>
        </S.ModalBody>
        <S.ModalFooter>
          <LabDsButton
            label="Confirmar"
            onHandleButton={() => {
              setShowModalSalesforcePartner(false);
            }}
          />
        </S.ModalFooter>
      </S.Modal>
    </Dialog>
  );
}
