import api from '../api';

export async function inviteUser(userEmail: string, unitId: string) {
  try {
    const response = await api.post(`/permissions/user/invite`, {
      email: userEmail,
      unitId,
    });
    const user = response.data;

    return user;
  } catch (err) {
    console.error(`Ocorreu algum problema ao convidar este usuário 😕`, err);
    return false;
  }
}
