import apiFederation from '../apiFederation';

export async function getPermissionsByUser() {
  try {
    const response = await apiFederation.get(
      `/manager-broker-permissions/find/by-user`
    );
    const permissions = response.data;

    return permissions;
  } catch (err) {
    return { error: err.response.data };
  }
}
