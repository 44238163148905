import api from '../api';

export async function downloadMyLeadsCsv() {
  try {
    const response = await api({
      url: `${process.env.REACT_APP_HB_LEADS_URL}/auctions/download?won=true`,
      method: 'GET',
      responseType: 'blob', // important
    });

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'me-leads.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    const csv = response.data;

    return csv;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao realizar o download. Atualize a página e tente novamente. 😕`,
      err
    );

    return [false, err];
  }
}
