import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { BiLineChart } from 'react-icons/bi';
import { BsCheck2, BsThreeDots } from 'react-icons/bs';
import { RiWalletLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useNotifications } from '../../../../contexts/notifications';
import { readNotification } from '../../../../services/requests/leadbroker/notifications/readNotification';
import { unreadNotifications } from '../../../../utils/unreadNotifications';
import { MoneyIC, PersonIC, WarningIC, WonIC } from '../../Icons';
import * as S from './styles';

interface cardProps {
  _id: string;
  read: boolean;
  status: string;
  title: string;
  time: Date;
  message: string;
  setTotalUnreadNotifications: Dispatch<SetStateAction<number>>;
}

const Card: FC<cardProps> = ({
  _id,
  read,
  status,
  title,
  time,
  message,
  setTotalUnreadNotifications,
}) => {
  const [activeReadMenu, setActiveReadMenu] = useState(false);

  const { notifications, setNotifications } = useNotifications();

  const handleReadNotification = useCallback(
    async (id: string) => {
      const newNotification = await readNotification(id);

      if (newNotification) {
        const newNotifications = notifications.map(notification => {
          if (notification._id === id) {
            return newNotification;
          }
          return notification;
        });
        setNotifications(newNotifications);

        const totalNotifications = unreadNotifications(newNotifications)
          ?.length;

        setTotalUnreadNotifications(totalNotifications);
      }
    },
    [notifications, setNotifications, setTotalUnreadNotifications]
  );

  const { Icon, type, url } = useMemo(() => {
    if (status === 'wallet') {
      return { Icon: <RiWalletLine />, type: 'Carteira', url: '/carteira' };
    }
    if (status === 'extract') {
      return {
        Icon: <RiWalletLine />,
        type: 'Extrato',
        url: '/extrato',
      };
    }
    if (status === 'user') {
      return { Icon: <PersonIC />, type: 'Investidor', url: '/' };
    }
    if (status === 'auction') {
      return { Icon: <WarningIC />, type: 'Home Broker', url: '/' };
    }
    if (status === 'won') {
      return { Icon: <WonIC />, type: 'Meus Leads', url: '/meus-leads' };
    }
    if (status === 'lost') {
      return { Icon: <MoneyIC />, type: 'Home Broker', url: '/' };
    }
    if (status === 'lostToExpPurchase') {
      return { Icon: <WarningIC />, type: 'Home Broker', url: '/' };
    }
    return { Icon: <WarningIC />, type: 'Geral', url: '/' };
  }, [status]);

  return (
    <S.Container read={read}>
      <Link
        to={url}
        onClick={() => {
          handleReadNotification(_id);
        }}
      >
        {Icon}
      </Link>
      <S.CardInfo
        to={url}
        onClick={() => {
          handleReadNotification(_id);
        }}
      >
        <strong>{title}</strong>
        <div>
          <BiLineChart />
          <p>{type}</p>
        </div>
        <small>{message}</small>
      </S.CardInfo>
      <S.ActionsContainer>
        <S.ActionsButtons>
          <S.DotsButton
            type="button"
            onClick={() => setActiveReadMenu(!activeReadMenu)}
          >
            <BsThreeDots />
          </S.DotsButton>
          <S.NotificationBall />
          <S.Triangle isActive={activeReadMenu} />
          <S.ReadMenu
            type="button"
            isActive={activeReadMenu}
            onClick={() => {
              handleReadNotification(_id);
              setActiveReadMenu(false);
            }}
          >
            <BsCheck2 />
            <small>Marcar como lida</small>
          </S.ReadMenu>
        </S.ActionsButtons>
        <small>
          {formatDistance(time, new Date(), { addSuffix: true, locale: ptBR })}
        </small>
      </S.ActionsContainer>
    </S.Container>
  );
};

export default Card;
