import api from '../api';

export async function createContract(data: Contract) {
  try {
    const response = await api.post(`/auctions/contract`, data);
    const contract = response.data;

    return contract;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar um contrato, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}
