import { useCallback, useState, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Button } from '../Button';
import { Modal } from '../Modal';

import { getValidationErrors } from '../../../utils/getValidationErrors';
import * as S from './styles';
import { Input } from '../Forms/Input';
import { inviteUser } from '../../../services/requests/leadbroker/users/inviteUser';
import { useToast } from '../../../contexts/toast';
import { ModalLoading } from '../Loadings/ModalLoading';
import { useAuth } from '../../../contexts/auth';

interface InviteNewInvestorProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

export function InviteNewInvestor({
  isOpen,
  setIsOpen,
}: InviteNewInvestorProps) {
  const [loading, setLoading] = useState(false);
  const inviteUserForm = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user: loggedUser } = useAuth();

  const cancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(
    async (data: { email: string }) => {
      setLoading(true);
      try {
        inviteUserForm.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Precisa ser um email valido')
            .required('E-mail obrigatório.')
            .max(255, 'No máximo 255 dígitos.')
            .matches(
              RegExp('^[A-Za-z0-9._%+-]+@v4company.com$'),
              'O email deve ser @v4company'
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const invite = await inviteUser(
          data.email,
          loggedUser?.unitId as string
        );

        if (!invite.user) {
          addToast({
            type: 'error',
            description: `Problema ao enviar o convite para ${data.email}.`,
          });
        }

        if (invite.user) {
          addToast({
            type: 'success',
            description: `Convite enviado com sucesso!`,
          });
        }
        setLoading(false);

        setIsOpen(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          inviteUserForm.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          description: `Problema ao enviar o convite.`,
        });
      }
    },
    [addToast, setIsOpen, loggedUser]
  );

  return (
    <Modal modalOpen={isOpen} onBackground={() => setIsOpen(false)}>
      <S.Container
        ref={inviteUserForm}
        onSubmit={handleSubmit}
        placeholder=""
        onPointerEnterCapture={() => null}
        onPointerLeaveCapture={() => null}
      >
        {loading ? (
          <ModalLoading rows={2} />
        ) : (
          <>
            <h2>Cadastrar Novo Investidor</h2>

            <label htmlFor="email">
              <span>E-mail</span>
              <Input name="email" placeholder="E-mail do investidor" />
            </label>

            <S.Buttons>
              <Button small secondary onClick={cancel}>
                Cancelar
              </Button>
              <Button small type="submit">
                Cadastrar
              </Button>
            </S.Buttons>
          </>
        )}
      </S.Container>
    </Modal>
  );
}
