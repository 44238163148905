import Dialog from '@reach/dialog';
import { FiXCircle } from 'react-icons/fi';
import { Button } from '../../../Default/Button';
import * as S from './styles';

interface ErrorModalProps {
  ref?: React.RefObject<HTMLButtonElement>;
  closeModal?: () => void;
  title: string;
  description?: string;
  actionButton?: () => void;
  actionTitle?: string;
}

export function ErrorModal({
  ref,
  closeModal,
  title,
  description,
  actionButton,
  actionTitle,
}: ErrorModalProps) {
  return (
    <Dialog
      aria-labelledby="label"
      onDismiss={closeModal}
      initialFocusRef={ref}
      style={{ marginTop: '20vh', width: '70vw' }}
    >
      <S.Modal>
        <FiXCircle />
        <h2>{title}</h2>
        {description && <p>{description}</p>}
        <S.ButtonsContainer>
          <Button outline small type="button" onClick={closeModal}>
            Fechar
          </Button>
          {actionTitle && (
            <Button outline small type="button" onClick={actionButton}>
              {actionTitle}
            </Button>
          )}
        </S.ButtonsContainer>
      </S.Modal>
    </Dialog>
  );
}
