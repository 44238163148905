import api from '../api';

export async function changeUserPermissionBid(userId: string, canBid: boolean) {
  try {
    const role = canBid ? 'WRITE_AND_READ' : 'READ_ONLY';
    const response = await api.put(`/permissions/bid/${userId}/${role}`);
    const user = response.data;

    return user;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao alterar a permissão de bid do usuário, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}
