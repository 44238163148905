import * as Yup from 'yup';

export const schema = Yup.object().shape({
  zipcode: Yup.string().required('CEP é obrigatório'),
  street: Yup.string().required('Rua é obrigatória'),
  district: Yup.string().required('Bairro é um campo obrigatória'),
  number: Yup.string()
    .typeError('Digite apenas numeros')
    .required('Numero é obrigatório'),
  complement: Yup.string(),
  city: Yup.string().required('Cidade é obrigatória'),
  state: Yup.string()
    .required('Estado é obrigatório')
    .test('is-selected', 'Selecione um estado', value => {
      return value !== null && value !== '';
    }),
});
