import { useRef, useEffect, useCallback, useState } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
import { IconBase, IconBaseProps } from 'react-icons';

import * as S from '../Forms/Input/styles';

interface Props extends InputProps {
  name: string;
  description?: string;
  containerStyle?: React.CSSProperties;
  icon?: React.ComponentType<IconBaseProps>;
  label?: string;
  placeholder?: string;
}

export function InputMask({
  name,
  description,
  placeholder,
  icon: Icon,
  label,
  containerStyle,
  ...rest
}: Props) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <S.Container style={containerStyle} isErrored={!!error}>
      <label htmlFor={name}>{label}</label>
      {description && <p>{description}</p>}
      <S.InputContainer
        isErrored={!!error}
        className="inputContainer"
        isFocused={isFocused}
      >
        <ReactInputMask
          ref={inputRef}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          placeholder={placeholder}
          {...rest}
        />

        {Icon && <IconBase size={20} />}
      </S.InputContainer>
      {error && <small>{error}</small>}
    </S.Container>
  );
}
