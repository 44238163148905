import { federationApi } from '../api';

export async function getRefund(limit: '50', page) {
  try {
    const response = await federationApi.get(
      `/refund-request?limit=${limit}&page=${page}`
    );
    const refund: RefundResponse = response.data;

    return refund;
  } catch (err) {
    return err.data;
  }
}

export async function postRefund(data, leadInformation, filesSent) {
  try {
    const contract = {
      refundReason: data.refundReason,
      automaticReject: data?.automaticReject,
      refundValues: {
        depositAmount: leadInformation.depositAmount,
        bonusAmount: leadInformation.bonusAmount,
      },
      refundItem: leadInformation.refundItem,
      managerUser: {
        _id: data.reasonManager._id,
        name: data.reasonManager.name,
      },
      attachments: filesSent,
      refundJustification: {
        reason: data.refundReason,
        description: data.refundJustification,
      },
      requestingUser: {
        phone: data.reasonPhone,
      },
      purchasedAt: leadInformation.purchasedAt,
    };

    await federationApi.post('manager-broker-refund', contract);

    return {
      success: true,
    };
  } catch (err) {
    return {
      message: err.data,
    };
  }
}
