import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;

  h1 {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    padding-left: 2.4rem;
  }

  > h3 {
    margin-bottom: 2rem;
    font-size: 2.38rem;
  }

  > hr {
    margin-bottom: 1.25rem;
  }

  > section {
    display: flex;
    flex-direction: column;
    padding: 0 2.4rem 14rem 2.4rem;
  }

  > section > div:first-child {
    margin-right: 2rem;
  }
`;

export const PaymentButton = styled.button`
  display: flex;
  align-items: center;
  color: var(--success-2);
  background: transparent;
  border: 0;
  width: max-content;
  height: 2.82rem;

  strong {
    font-size: 0.88rem;
    text-decoration: underline;
    margin-right: 0.2rem;
  }

  :active {
    opacity: 0.8;
  }
`;

export const ContentButton = styled.div`
  display: block;
  margin-bottom: 1rem;
`;

export const LowerCaseText = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 0.88rem;
  line-height: 1rem;
  color: var(--black);
`;
