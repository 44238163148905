import { LabDsButton, LabDsIconNotSelectable } from 'v4web-components-react';
import { Dialog } from '@reach/dialog';
import * as S from './styles';

import { stepsProps } from '../../utils';

export function ModalAlterStatus({
  setShowModalAlterStatus,
  step,
  stepActive,
  setStepActive,
  onDismiss,
  buttonRef,
}: {
  setShowModalAlterStatus: (boolean) => void;
  step: stepsProps[];
  stepActive: stepsProps;
  setStepActive: (string) => void;
  onDismiss: () => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
}) {
  return (
    <Dialog
      aria-labelledby="label"
      onDismiss={onDismiss}
      initialFocusRef={buttonRef}
      style={{ marginTop: '20vh', width: '70vw', borderRadius: '0.38rem' }}
    >
      <S.Modal>
        <S.ModalBody>
          <S.Icon color="var(--lab-ds-semantic-selectable-color-bg-dark-warning)">
            <LabDsIconNotSelectable icon="warning" size="x-large" />
          </S.Icon>
          <S.TitleModal>Alterar status</S.TitleModal>
          <S.TextModal>
            Deseja alterar o status do Lead para &quot;
            <b>{step[stepActive.step].label}</b>&quot;?
          </S.TextModal>
        </S.ModalBody>
        <S.ModalFooter>
          <LabDsButton
            variant="danger-outlined"
            label="Cancelar"
            onHandleButton={() => {
              setShowModalAlterStatus(false);
            }}
          />
          <LabDsButton
            label="Confirmar"
            onHandleButton={() => {
              stepActive?.eventModal
                ? stepActive?.eventModal()
                : setStepActive(step[stepActive.step]);

              setShowModalAlterStatus(false);
            }}
          />
        </S.ModalFooter>
      </S.Modal>
    </Dialog>
  );
}
