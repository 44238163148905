export const unreadNotifications = (values: NotificationMessage[]) => {
  if (values.length > 0) {
    const newNotifications = values.filter(
      (notification: NotificationMessage) => !notification.read
    );

    return newNotifications;
  }
  return [];
};
