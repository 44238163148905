import { useCallback, useMemo } from 'react';

import { Button } from 'v4components-react';
import { convertCentsToBRL } from '../../../../utils/convertCentsToBRL';
import * as S from './styles';
import { useAuctions, useDeposit } from '../../../../contexts';
import { ICON } from '../../../../assets';
import { LoadingAddBonus } from '../../../../components/Default/Loadings/LoadingAddBonus';
import { interestRates } from '../utils/interestRate';

interface Props {
  paymentDetails: PaymentDetails;
  handleToggleModal: () => void;
  loading?: boolean;
}

export function CardPayment({
  paymentDetails,
  handleToggleModal,
  loading,
}: Props) {
  const { paymentType, creditCard, amount } = useDeposit();
  const { unitInfos } = useAuctions();

  const checkPaymentType = payment => {
    switch (payment) {
      case 'pix':
        return 'PIX';
      case 'bank_slip':
        return 'Boleto';
      case 'credit_card':
        return 'Crédito';
      default:
        return 'xxxxxxxxxxx';
    }
  };

  const totalPrice = useMemo(() => {
    return convertCentsToBRL(paymentDetails?.value);
  }, [paymentDetails]);

  const calculateTotalValue = useCallback(() => {
    const rates = interestRates[Number(creditCard.installement.key) - 1];
    const newAmount = (amount * rates) / 100;
    return amount + Number(newAmount.toFixed(2));
  }, [amount, creditCard.installement]);

  return (
    <>
      <S.Container>
        {paymentType === 'all' && (
          <S.ModalHeader>
            <h4 className="titleModal">Depósito</h4>

            <S.CloseButton onClick={handleToggleModal} />
          </S.ModalHeader>
        )}

        {loading && (
          <S.ContainerLoading>
            <LoadingAddBonus />
          </S.ContainerLoading>
        )}

        {paymentDetails && (
          <S.AllPaymentContent>
            {paymentType === 'credit_card' && (
              <S.ContainerIcon>
                <S.Icon src={ICON.Check} />
                <p>Pagamento confirmado com sucesso</p>
              </S.ContainerIcon>
            )}
            {paymentDetails.value > 0 && paymentType !== 'credit_card' ? (
              <S.PriceCard>
                <h5>{totalPrice}</h5>
              </S.PriceCard>
            ) : (
              <S.Price>
                <h4>R$ {calculateTotalValue()}</h4>
                <S.priceInstallement>
                  {creditCard.installement.key}x (
                  {(
                    calculateTotalValue() / Number(creditCard.installement.key)
                  ).toFixed(2)}
                  )
                </S.priceInstallement>
              </S.Price>
            )}

            {paymentType === 'credit_card' && (
              <S.CreditSection paymentType={paymentType}>
                <S.WithdrawalData>
                  <S.ContainerUnidade>
                    <h4>Unidade</h4>
                    <S.LowerCaseText>
                      <>{unitInfos?.unitName}</>
                    </S.LowerCaseText>
                  </S.ContainerUnidade>

                  <S.ContainerDeposit>
                    <h4>Tipo de depósito</h4>
                    <S.LowerCaseText>
                      {checkPaymentType(paymentType)}
                    </S.LowerCaseText>
                  </S.ContainerDeposit>

                  <S.ContainerEmail>
                    <h4>Email</h4>
                    <S.LowerCaseText>{creditCard.payer.email}</S.LowerCaseText>
                  </S.ContainerEmail>

                  <S.ContainerCnpj>
                    <h4>CPF ou CNPJ</h4>
                    <S.LowerCaseText>
                      {creditCard.payer.cpfCnpj}
                    </S.LowerCaseText>
                  </S.ContainerCnpj>
                </S.WithdrawalData>
              </S.CreditSection>
            )}
          </S.AllPaymentContent>
        )}
        {paymentType === 'credit_card' && (
          <footer>
            <Button
              size="default"
              variant="primary"
              color="green"
              type="submit"
              onClick={handleToggleModal}
              width="10.7rem"
            >
              Ir à carteira
            </Button>
          </footer>
        )}
      </S.Container>
    </>
  );
}
