import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 0 auto;
  background-color: var(--white);

  svg {
    font-size: 5rem;
    color: var(--primary-main);
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  button + button {
    margin-left: 2rem;
    background-color: var(--primary-dark);
    border: none;
    color: var(--white);
  }

  button {
    color: var(--primary-dark);
    border-color: var(--primary-dark);
    border-radius: 0.5rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export const ButtonLink = styled(Link)`
  height: 3.5rem;
  border-radius: 0.5rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  border: 0;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  background: var(--primary-dark);
  color: var(--white);
  margin-left: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: none;
  height: 2.5rem;
  &:hover {
    opacity: 0.5;
  }
`;
