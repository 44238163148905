import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { parseCookies } from 'nookies';
import apiFederation, { apiFederationUrl } from '../apiFederation';

export function useQueryReadOneAuctionPack(
  id: string,
  setAuction: Dispatch<SetStateAction<AuctionPack | Auction>>,
  onDismiss: () => void,
  token: string,
  handleBid?: (data: { value: number }) => Promise<void>
): UseQueryResult<AuctionPack, AuctionPack> {
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const queryResult = useQuery<AuctionPack, AuctionPack>({
    queryKey: ['auction-pack', id, onDismiss, handleBid],
    queryFn: () =>
      fetch(`${apiFederationUrl}/packs/${id}`, {
        method: 'GET',
        headers: token ? { Authorization: `Bearer ${token}` } : undefined,
      }).then(async r => {
        if (r.ok) return r.json();
        return true;
      }),
    refetchOnWindowFocus: true,
    retry: true,
    refetchOnMount: true,
    refetchInterval: shouldRefetch ? 5000 : undefined,
    refetchIntervalInBackground: shouldRefetch,
  });

  useEffect(() => {
    if (queryResult.data) {
      const auction = queryResult.data;
      setAuction(auction);

      const expiresIn = new Date(auction.expiresAt).getTime() - Date.now();
      if (expiresIn <= 60000) {
        setShouldRefetch(true);
      }
      return;
    }
    if (queryResult.error) {
      onDismiss();
      console.error(
        `Ocorreu algum problema ao carregar, atualize a página e tente novamente 😕`
      );
    }
  }, [queryResult.data, queryResult.error, setAuction, onDismiss]);

  return queryResult;
}

export function useQueryReadOneAuction(
  id: string,
  onDismiss: () => void,
  setAuction: Dispatch<SetStateAction<AuctionPack | Auction>>
): UseQueryResult<AuctionPack> {
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const queryResult = useQuery<AuctionPack>({
    queryKey: ['auction-unit', id],
    queryFn: () => {
      const cookies = parseCookies(undefined);

      return apiFederation
        .get(`/leads/${id}`, {
          headers: { Authorization: `Bearer ${cookies['v4company.token']}` },
        })
        .then(r => {
          if (r.data) return r.data;
          throw new Error('Auction not found');
        });
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: shouldRefetch ? 5000 : undefined,
    refetchIntervalInBackground: shouldRefetch,
  });

  useEffect(() => {
    if (queryResult.data) {
      const auction = queryResult.data;
      setAuction(auction);

      const expiresIn = new Date(auction.expiresAt).getTime() - Date.now();
      if (expiresIn <= 60000) {
        setShouldRefetch(true);
      }
    }
    if (queryResult.error) {
      onDismiss();
      console.error(
        `Ocorreu algum problema ao carregar, atualize a página e tente novamente 😕`
      );
    }
  }, [queryResult.data, queryResult.error, onDismiss, setAuction]);

  return queryResult;
}
