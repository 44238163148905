import { useEffect } from 'react';

import { FiAlertCircle, FiAlertTriangle, FiInfo } from 'react-icons/fi';

import { ToastMessage, useToast } from '../../../../contexts/toast';

import * as S from './styles';

interface ToastProps {
  message: ToastMessage;
  style: Record<string, unknown>;
}

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertTriangle size={24} style={{ color: 'var(--white)' }} />,
  success: <FiAlertCircle size={24} style={{ color: 'var(--success-2)' }} />,
};

export function Toast({ message, style }: ToastProps) {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  return (
    <S.Container
      style={style}
      type={message.type}
      hasdescription={Number(!!message.description)}
    >
      {icons[message.type || 'info']}

      <div>{message.description && <p>{message.description}</p>}</div>
    </S.Container>
  );
}
