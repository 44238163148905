import { fileApi } from '../leadbroker/api';

export async function sendFile(files, setUploadProgress) {
  try {
    const formData = new FormData();
    formData.append('file', files);

    const response = await fileApi.post('/storage', formData, {
      onUploadProgress: progressEvent => {
        const progress: number = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setUploadProgress({ name: files.name, progress });
      },
    });

    return response.data;
  } catch (error) {
    return error.data;
  }
}
