import { useState, useCallback } from 'react';

import * as S from './styles';

interface ClientTabsProps {
  tabs: string[];
  onChange: (newValue: string) => void;
  style?: React.CSSProperties;
}

export function NotificationTabs({
  tabs,
  onChange,
  ...props
}: ClientTabsProps) {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const handleTabChange = useCallback(
    (tabName: string) => {
      setSelectedTab(tabName);
      onChange(tabName);
    },
    [onChange]
  );

  return (
    <S.Container {...props}>
      {tabs.map(tab => (
        <S.FilterButton
          onClick={() => handleTabChange(tab)}
          isActive={tab === selectedTab}
          type="button"
          key={tab}
        >
          {tab}
        </S.FilterButton>
      ))}
    </S.Container>
  );
}
