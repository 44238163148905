import { useEffect, useRef, useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import {
  Container,
  SectionContent,
  SectionTitle,
  Section,
} from 'v4components-react';
import { LabDsBreadcrumb, LabDsButton, LabDsTab } from 'v4web-components-react';
import { format } from 'date-fns';
import Dialog from '@reach/dialog';
import { FiXCircle } from 'react-icons/fi';
import { parseCookies } from 'nookies';
import { useAuth } from '../../contexts/auth';
import { Search, Select } from '../../components/Default/Fields';
import { AuctionUnitContainer } from '../../components/AtomicDesign/molecules/AuctionUnitContainer';
import { AuctionContainer } from '../../components/AtomicDesign/molecules/AuctionContainer';
import { AuctionPackContainer } from '../../components/AtomicDesign/molecules/AuctionPackContainer';
import { AuctionAllContainer } from '../../components/AtomicDesign/molecules/AuctionAllContainer';
import { AuctionRefurbishedContainer } from '../../components/AtomicDesign/molecules/AuctionRefurbishedContainer';
import { useAuctions } from '../../contexts/auctions';
import * as S from './styles';
import version from '../../../package.json';
import { useIdleTimeout } from '../../components/AtomicDesign/molecules/IdleDetector';
import { getUserUnit } from '../../services/requests/leadbroker/users/getUserUnit';
import { ModalTermsUse } from '../../components/Default/ModalTermsUse';
import { ITabs, tabs } from './constants/tabs';
import { convertCentsToBRL } from '../../utils/convertCentsToBRL';
import { useBalance } from '../../hooks/wallet';
import useIsElementVisible from '../../hooks/useIsElementVisible';
import { ModalDeleteLead } from '../../components/RouteComponents/LeadDetails/components/ModalDeleteLead';
import { ModalReload } from './components';
import { distanceInMinutes } from '../../utils/dateFunctions';

export function Home() {
  const {
    actualTab,
    setActualTab,
    showModalDeleteLead,
    setShowModalDeleteLead,
    auctionDelete,
  } = useAuctions();
  const { user, permissionsManager } = useAuth();
  const [showModalReload, setShowModalReload] = useState(false);
  // const [lastUpdate, setLastUpdate] = useState(new Date());

  // Below you should use the time values in milliseconds.
  const idle = useIdleTimeout(600000, 600000);

  const tabsState = tabs({ setActualTab }) as ITabs[];

  const now = new Date();

  const mktlabUrl = process.env.REACT_APP_MKTLAB_URL;

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay(); // 0 (domingo) a 6 (sábado)
  const currentHour = currentDate.getHours();

  if (currentDayOfWeek === 4 && currentHour >= 8 && currentHour <= 23) {
    const availableAtElement = document.querySelector('.available-at');
    if (availableAtElement) {
      availableAtElement.textContent = 'Já está disponível!';
    }
  }

  const cookies = parseCookies();
  const unitInfo = cookies['v4company.unitInfo'];
  const token = cookies['v4company.token'];
  const userUnitInfo = localStorage.getItem('v4company.unitInfo');
  const { balance } = useBalance();
  const lastRef = useRef(null);
  const isLastVisible = useIsElementVisible(lastRef.current);

  const hasTermsUse = () => {
    const dateTermsUse = new Date(user?.unit?.lastAcceptUseTerms);
    const dateFormatted = date => format(date, 'dd/MM/yyyy');

    if (dateFormatted(dateTermsUse) !== dateFormatted(now)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      `%c Amaterasu Version: ${version.version}`,
      'color: black; background-color: white; padding: 5px 10px;'
    );
  }, []);

  useEffect(() => {
    localStorage.setItem('lastUpdate', new Date().toString());

    window.addEventListener('keydown', e => {
      const lastUpdate = new Date(localStorage.getItem('lastUpdate'));
      const { key } = e;
      if (key === 'F5') {
        e.preventDefault();
        if (Number(distanceInMinutes(lastUpdate)) < 5) {
          return setShowModalReload(true);
        }

        return window.location.reload();
      }
      return true;
    });
  }, []);

  useEffect(() => {
    if ((!unitInfo || !userUnitInfo) && token) {
      getUserUnit(token);
    }
  }, [token, unitInfo, userUnitInfo]);

  return (
    <Container>
      <S.HeaderSwitch>
        <LabDsBreadcrumb
          breadcrumbs={[
            {
              label: 'Pagina inicial',
              link: '/',
              key: 'home',
              event: () => {
                window.location.href = mktlabUrl;
              },
            },
          ]}
        />
        <LabDsButton
          size="large"
          variant="cta"
          label="Acessar novo LB"
          onHandleButton={() => {
            window.location.href = 'https://lead.brokers.mktlab.app';
          }}
        />
      </S.HeaderSwitch>
      <Section>
        {hasTermsUse() && <ModalTermsUse />}
        <SectionContent style={{ padding: '2rem' }}>
          <S.InformationContainer>
            <SectionTitle text="Lead Broker" />

            <S.Balance>
              <h4>Saldo:</h4>
              <strong>
                {balance ? convertCentsToBRL(balance) : 'R$ 0,00'}
              </strong>
            </S.Balance>
          </S.InformationContainer>
          <S.Tabs>
            <LabDsTab value="tab1" tabs={tabsState} />
          </S.Tabs>
          {/* <HomeFilters onContinue={handleGetFiltersInfo} /> */}
          <S.FilterTabs>
            <S.Filter>
              <BiFilterAlt />
              Filtros
            </S.Filter>
            <Search style={{ marginRight: '2.5rem', width: '14.5rem' }} />
            <Select
              placeholder="Tempo restante"
              options={[
                { name: 'Maior', value: 'bigger' },
                { name: 'Menor', value: 'tiner' },
              ]}
            />
          </S.FilterTabs>
          <>
            {actualTab === tabsState[0]?.title && (
              <AuctionAllContainer isLastVisible={isLastVisible} />
            )}
            {actualTab === tabsState[1]?.title && (
              <AuctionUnitContainer isLastVisible={isLastVisible} />
            )}
            {actualTab === tabsState[2]?.title && (
              <AuctionPackContainer isLastVisible={isLastVisible} />
            )}
            {actualTab === tabsState[3]?.title && (
              <AuctionRefurbishedContainer isLastVisible={isLastVisible} />
            )}
            {actualTab === tabsState[4]?.title && (
              <AuctionContainer isLastVisible={isLastVisible} />
            )}
            <div ref={lastRef} />
          </>
          <>
            {idle && (
              <Dialog
                aria-labelledby="label"
                style={{
                  marginTop: '20vh',
                  width: 'fit-content',
                  padding: '64px 48px',
                }}
              >
                <S.Modal>
                  <FiXCircle />
                  <h2>Você ficou muito tempo longe :(</h2>
                  <p>
                    Precisamos que você recarregue a página do Leadbroker para
                    sincroniza-lo!
                  </p>
                  <S.ButtonsContainer>
                    <LabDsButton
                      label="Recarregar LeadBroker"
                      onHandleButton={() => window.location.reload()}
                    />
                  </S.ButtonsContainer>
                </S.Modal>
              </Dialog>
            )}
          </>
          {showModalDeleteLead &&
            permissionsManager?.leadbrokerPermission?.deleteLead && (
              <ModalDeleteLead
                setShowModalDeleteLead={setShowModalDeleteLead}
                key={auctionDelete._id}
                auction={auctionDelete as Auction}
              />
            )}
          {showModalReload && (
            <ModalReload setShowModalReload={setShowModalReload} />
          )}
        </SectionContent>
      </Section>
    </Container>
  );
}
