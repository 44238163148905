import axios from 'axios';
import { parseCookies, destroyCookie } from 'nookies';

interface ErrorCondition {
  status: number;
  message: string;
}

const cookies = parseCookies(undefined);
const token = cookies['v4company.token'];

const headers = token
  ? {
      Authorization: `Bearer ${cookies['v4company.token']}`,
    }
  : undefined;

function invalidTokenConditions({ status, message }: ErrorCondition): boolean {
  const invalidTokenMessages = [
    'Invalid JWT token',
    'Expired JWT token',
    'JWT token is missing',
    'Unit is not active.',
  ];

  if (status === 401 && invalidTokenMessages.includes(message)) return true;

  if (status === 403 && invalidTokenMessages.includes(message)) return true;

  return false;
}

export const apiFederation = axios.create({
  baseURL: process.env.REACT_APP_FEDERATION_URL,
  headers,
});

export const apiQueueBid = axios.create({
  baseURL: process.env.REACT_APP_QUEUE_BID_URL,
  headers,
});

apiFederation.interceptors.response.use(
  response => response,
  err => {
    const { status } = err.response;
    const { message } = err.response.data;

    if (invalidTokenConditions({ status, message })) {
      destroyCookie(undefined, 'v4company.token', {
        domain: process.env.REACT_APP_DOMAIN_NAME,
        path: '/',
      });

      localStorage.clear();
      window.location.href = '/';
    }

    return Promise.reject(err);
  }
);

export const unitsApi = axios.create({
  baseURL: process.env.REACT_APP_UNITS_URL,
  headers,
});

export const socketApi = process.env.REACT_APP_HB_LEADS_URL;
export const apiUrl = process.env.REACT_APP_HB_LEADS_URL;
export const apiFederationUrl = process.env.REACT_APP_FEDERATION_URL;

export default apiFederation;
