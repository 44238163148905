import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, SectionTitle } from 'v4components-react';
import { useState, useCallback, useMemo } from 'react';
import { LabDsSelect, LabDsTextField } from 'v4web-components-react';
import { IOptionsSelect } from 'v4web-components';
import * as S from './styles';
import { useDeposit } from '../../../../contexts';
import { interestRates } from '../utils/interestRate';

interface Installment {
  installment: string;
  numero: string;
}

export function Installment() {
  const { amount, setStageDeposit, setAmount, creditCard } = useDeposit();
  const [amountState, setAmountState] = useState(String(amount || ''));
  const [isAmountEnabled, setIsAmountEnabled] = useState(false);

  const schema = Yup.object().shape({
    numero: Yup.string()
      .typeError('Digite um número')
      .default(amountState)
      .required('Número é obrigatório'),
    installment: Yup.string().required('Selecione o número de parcelas'),
  });

  const { control, formState, reset, handleSubmit, trigger } = useForm<
    Installment
  >({
    resolver: yupResolver(schema),
  });

  const handleNextButtonClick = async () => {
    const isValid = await trigger();

    if (isValid) {
      setStageDeposit('summary');
    }
  };

  const onSubmit: SubmitHandler<Installment> = async data => {
    try {
      await schema.validate(data, { abortEarly: false });
      setStageDeposit('card');
    } catch (validationErrors) {
      console.error(validationErrors);
    }
  };
  const { errors } = formState;
  const calculateInstallment = useCallback(() => {
    if (amount / 3 >= 5) {
      return [1, 2, 3];
    }
    if (amount / 2 >= 5) {
      return [1, 2];
    }
    return [1];
  }, [amount]);

  const vigenceTimes: IOptionsSelect[] = useMemo(() => {
    const installments = calculateInstallment();
    const vigenceTimes1 = installments.map((numInstallments, index) => {
      const installmentAmount = (
        (amount * (1 + interestRates[index] / 100)) /
        numInstallments
      ).toFixed(2);

      const totalValue =
        index > 0
          ? `(R$ ${(amount * (1 + interestRates[index] / 100)).toFixed(2)})`
          : '';

      return {
        key: String(numInstallments),
        label: `${numInstallments}x R$ ${installmentAmount} ${totalValue}`,
        isChecked: false,
      };
    });
    return vigenceTimes1;
  }, [amount, calculateInstallment]);

  const handleInstallmentChange = useCallback(
    selectedOption => {
      creditCard.setInstallement(selectedOption);
    },
    [creditCard]
  );

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <section>
        <SectionTitle text="Parcelamento" />
        <Controller
          name="numero"
          control={control}
          render={({ field }) => (
            <S.CardContent>
              <LabDsTextField
                className="CardNumber"
                titleInput="Confirme o valor*"
                label={amountState}
                value={amountState}
                helperText={errors.numero && errors.numero.message}
                state={errors.numero ? 'error' : 'default'}
                type="text"
                mask="currency"
                disabled={!isAmountEnabled}
                labelIcon="edit"
                {...field}
                onClick={() => {
                  if (isAmountEnabled) return;
                  setIsAmountEnabled(!isAmountEnabled);
                  reset({ installment: '' });
                }}
                onChangeInput={({ detail }) => {
                  setAmountState(detail);
                  setAmount(Number(detail.replace('.', '').replace(',', '.')));
                }}
              />
            </S.CardContent>
          )}
        />
        <Controller
          name="installment"
          control={control}
          render={({ field }) => (
            <S.InputContent>
              <LabDsSelect
                className="nameCard"
                label="Selecione"
                titleInput="Quantidade de parcelas*"
                options={vigenceTimes}
                avatar={false}
                state={errors.installment?.message ? 'error' : 'default'}
                helperText={errors.installment && errors.installment.message}
                type="radioButton"
                size="medium"
                isSearchable={false}
                {...field}
                onChangeInputOptions={({ detail }) => {
                  handleInstallmentChange(detail[0]);
                  creditCard.setInstallement(detail[0]);
                  field.onChange(detail[0].label);
                }}
              />
            </S.InputContent>
          )}
        />
      </section>

      <footer>
        <Button
          size="default"
          variant="primary"
          color="green"
          type="submit"
          onClick={handleNextButtonClick}
          disabled={amount <= 0}
          width="10.7rem"
        >
          Próximo
        </Button>
      </footer>
    </S.Container>
  );
}
