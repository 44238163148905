import { parseCookies } from 'nookies';
import { apiQueueBid as api } from '../apiFederation';

interface BidProps {
  _id: string;
  auction: string;
  value: number;
  buyNow?: boolean;
}
export async function executeBidPack(data: BidProps) {
  try {
    const cookies = parseCookies(undefined);

    const response = await api.post(`/brokers/bid`, data, {
      headers: { Authorization: `Bearer ${cookies['v4company.token']}` },
    });

    const bid = response.data;

    return bid;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao realizar um lance(bid), atualize a página e tente novamente 😕`,
      err
    );

    return [false, err];
  }
}
