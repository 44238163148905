import React from 'react';
import { MdWarning } from 'react-icons/md';

import { IoMdClose } from 'react-icons/io';
import * as S from './styles';

interface Props {
  handleToggleModal: () => void;
}

const Result: React.FC<Props> = ({ handleToggleModal }) => {
  return (
    <S.Container>
      <S.CloseButton onClick={handleToggleModal}>
        <IoMdClose size={22} color="var(--grayscale-60)" />
      </S.CloseButton>
      <MdWarning size={45} color="var(--warning)" />

      <h3>Fique atento!</h3>

      <h4>O valor do saque estará disponível em até 3 dias úteis</h4>
    </S.Container>
  );
};

export default Result;
