import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  ::-webkit-scrollbar-track {
    background: none;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  label {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
  }

  label strong {
    color: #8a9499;
    font-size: 1.25rem;
  }

  label input {
    background: var(--white);
    box-sizing: border-box;
    border-radius: 0.19rem;
    margin-top: 0.5rem;
    width: 100%;
    padding: 1.25rem 1.13rem;
    font-size: 1.125rem;
  }

  input:nth-child(1) {
    margin-bottom: 0;
  }

  small {
    color: var(--primary-main);
    bottom: 0;
  }

  > div {
    :nth-child(3) {
      margin: 0 0;
    }

    :nth-child(4) {
      margin: 0 0;
    }
  }

  > div h4 {
    font-weight: 700;
    font-size: 1rem;
  }

  > div p {
    font-size: 1.25rem;
  }

  section {
    padding: 2rem;
    border: 0.13rem solid var(--tertiary-light);
    width: 100%;
    font-size: 1.125rem;
    border-radius: 1rem;
  }

  section li {
    margin-top: 1rem;
  }
`;

export const ContentAmount = styled.div`
  padding: 0 2rem;
`;

export const LowerCaseText = styled.label`
  font-weight: 400;
  font-size: 1rem;
  font-style: normal;
  margin-bottom: 1.5rem;
`;

export const UnitContent = styled.div`
  display: block;
  margin-bottom: 1.5rem;
`;
export const UnitCase = styled.div`
  width: 100%;
  height: 4rem;
  background: var(--grayscale-4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CaseText = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  font-family: Inter, 'Nunito Sans';

  :nth-child(1) {
    margin-bottom: 1.3rem;
  }
`;

export const TotalBalance = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 1.8rem 0;

  > strong {
    font-size: 1.4rem;
  }
`;

export const Balances = styled.strong`
  color: var(--success-2);
`;

export const withdrawalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.07rem solid #b4b4cf;

  div {
    display: block;

    h4 {
      margin-bottom: 0.7rem;
    }
  }

  strong {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
`;

export const BonusCredit = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-top: 1.2rem;

  div {
    display: block;

    h4 {
      margin-bottom: 0.7rem;
    }

    p {
      line-height: 1.5rem;
    }
  }

  strong {
    font-size: 1rem;
    margin-top: 1rem;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  position: relative;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2rem;
`;
