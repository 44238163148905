import React from 'react';
import { SectionTitle } from 'v4components-react';
import { BsArrowRightShort } from 'react-icons/bs';
import * as S from './styles';
import { useDeposit } from '../../../../contexts';

export function PaymentInfo() {
  const { setPaymentType, setStageDeposit } = useDeposit();

  const onSubmit = (value: string) => {
    if (value === 'pix') {
      setPaymentType('pix');
    }

    if (value === 'bank_slip') {
      setPaymentType('bank_slip');
    }

    if (value === 'credit_card') {
      setPaymentType('credit_card');
      setStageDeposit('adress');
    } else {
      setStageDeposit('fields');
    }
  };

  return (
    <S.Container>
      <SectionTitle text="Escolha a forma de depósito" />

      <section>
        <S.ContentButton>
          <S.PaymentButton onClick={() => onSubmit('pix')}>
            <strong>PIX</strong> <BsArrowRightShort size={20} />
          </S.PaymentButton>
        </S.ContentButton>

        <S.ContentButton>
          <S.PaymentButton onClick={() => onSubmit('bank_slip')}>
            <strong>Boleto</strong> <BsArrowRightShort size={20} />
          </S.PaymentButton>
        </S.ContentButton>

        <S.ContentButton>
          <S.PaymentButton onClick={() => onSubmit('credit_card')}>
            <strong>Credito</strong> <BsArrowRightShort size={20} />
          </S.PaymentButton>
        </S.ContentButton>
      </section>

      <footer style={{ width: '100%' }} />
    </S.Container>
  );
}
