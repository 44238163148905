import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import * as S from './styles';

type Props = {
  modalOpen: boolean;
  children: ReactNode;
  onBackground?: () => void;
};

// Legacy component dont use this anymore
export function Modal({ modalOpen, children, onBackground }: Props) {
  if (!modalOpen) return null;

  return createPortal(
    <>
      <S.Wrapper>{children}</S.Wrapper>
      <S.Background onClick={() => onBackground && onBackground()} />
    </>,
    document.body
  );
}
