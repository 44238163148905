import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Dialog } from '@reach/dialog';

import * as S from './styles';
import { sanctionsTermsUse } from '../../../utils/termsOfUserLB';
import { Button } from '../Button';
import { putUseTerms } from '../../../services/requests/leadbroker/unit/putUseTerms';
import { useAuth } from '../../../contexts/auth';
import { useToast } from '../../../contexts';
import { LoadingBid } from '../Loadings/LoadingBid';

export function ModalTermsUse() {
  const {
    user,
    handleUpdateUserState,
    setUser,
    signOut,
    setLoadingUser,
    setErrorMessage,
  } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const urlMktLab = process.env.REACT_APP_MKTLAB_FRONT_URL;

  const handleSubmit = () => {
    setLoading(true);

    return putUseTerms(user?.unitId, true).then(responseData => {
      if (!responseData) {
        addToast({
          type: 'error',
          description:
            'Erro ao aceitar os termos, tente novamente em alguns minutos',
        });
      }
      if (responseData) {
        handleUpdateUserState(
          setUser,
          signOut,
          setLoadingUser,
          setErrorMessage
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 600);
    });
  };

  return (
    <Dialog
      style={{
        padding: '3.2rem',
        width: '55rem',
        borderRadius: '0.5rem',
      }}
    >
      <S.TermsUseModal>
        <h2>Termos de uso do Lead Broker</h2>
        <a href={`${urlMktLab}/dashboards`} aria-label="go to dashboards">
          <S.CloseModalButton />
        </a>

        <S.ContentTerms>
          <h4>
            Ao utilizar o Lead Broker, concordo em respeitar as seguintes
            diretrizes e restrições:
          </h4>

          <div className="description">
            <p className="number">1.</p>
            <p>
              Comprometo-me a não prospectar nenhum lead disponibilizado na
              plataforma sem adquiri-lo previamente. Estou ciente de que a
              violação desta regra resultará nas seguintes sanções:
            </p>
          </div>

          {sanctionsTermsUse.map(({ label }) => (
            <p className="sanctions">{label}</p>
          ))}

          <div className="description">
            <p className="number">2.</p>
            <p>
              É permitido entrar em contato apenas com os leads disponíveis na
              seção {`"Meus Leads"`} referenciado no seguinte link:
              <Link to="/meus-leads" target="_blank" rel="noreferrer">
                https://leadbroker.mktlab.app/meus-leads
              </Link>
              . Qualquer contato realizado com leads que não estejam nessa lista
              estará sujeito automaticamente às restrições mencionadas
              anteriormente.
            </p>
          </div>

          <div className="description">
            <p className="number">3.</p>
            <p>
              Todos os usuários que clicarem no card de leads serão registrados
              em nosso banco de dados. A matriz entrará em contato com todos os
              leads prospectados por outra unidade, para identificação e
              aplicação das sanções cabíveis ao infrator.
            </p>
          </div>

          <div className="description">
            <p className="number">4.</p>
            <p>
              Na eventualidade da compra de um lead não ser apresentada na tela
              do Lead Broker, entre em contato com o suporte para solução:
              <a
                href="https://app.pipefy.com/public/form/zsc8VbvF"
                target="_blank"
                rel="noreferrer"
              >
                https://app.pipefy.com/public/form/zsc8VbvF
              </a>
              .
            </p>
          </div>
        </S.ContentTerms>
        <S.ButtonsContainer>
          <Button onClick={handleSubmit}>
            {loading ? (
              <LoadingBid
                backgroundColor="var(--white)"
                style={{ left: '0.3rem' }}
              />
            ) : (
              'Aceitar e acessar'
            )}
          </Button>
        </S.ButtonsContainer>
      </S.TermsUseModal>
    </Dialog>
  );
}
