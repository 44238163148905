import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-top: 1rem;
  }

  ul {
    margin-top: 1rem;
  }

  li {
    font-family: 'Nunito Sans', sans-serif;
  }

  .animation {
    height: 250px;
    width: 250px;
  }
`;

export const Img = styled.img`
  width: 10rem;
  height: 100%;
  object-fit: cover;
`;
