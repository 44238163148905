import styled from 'styled-components';

export const Container = styled.div`
  > section {
    display: block;
    justify-content: space-between;
    font-family: 'Inter';
    margin: 2.5rem;
    height: 37rem;

    h1 {
      font-size: 1.375rem;
      line-height: 2rem;
    }
  }

  small {
    color: var(--primary-main);
    bottom: 0;
    margin: 0.25rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 25.125rem;
  justify-content: flex-start;
  color: var(--secondary-main);
  background: var(--white);

  .error {
    border: 0.0625rem solid var(--error);
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  span {
    color: var(--error);
    font-family: Nunito Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }

  .error {
    border: 0.0625rem solid var(--error);
  }

  .nameCard {
    width: 19.4375rem;
    margin-top: 0.625rem;
  }

  .cardNumber {
    width: 20.125rem;
  }

  .cnpj {
    width: 18.5rem;
  }

  lab-ds-text-field {
    cursor: pointer;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;
