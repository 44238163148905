import { Routes, Route } from 'react-router-dom';
import { NotFound } from 'v4components-react';
import Login from '../pages/Login';
import NotFoundImg from '../assets/not-found.svg';

export default function LoggedOutRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<NotFound img={NotFoundImg} />} />
    </Routes>
  );
}
