import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  margin-top: 2.5rem;
  label {
    :nth-child(1) {
      margin-bottom: 1.2rem;
    }
  }

  small {
    color: var(--primary-main);
    bottom: 0;
    margin: 0.25rem;
  }

  > section {
    display: flex;
    flex-direction: column;
    padding: 0 2.4rem 14.5rem 2.4rem;
    height: 37rem;
  }
`;

export const Content = styled.div`
  align-items: center;
  margin-bottom: 12rem;
`;

export const CaseText = styled.h4`
  font-weight: 700;
  font-size: 1rem;
  font-family: Inter, 'Nunito Sans';

  :nth-child(1) {
    margin-bottom: 1.2rem;
  }
`;

export const UnitContent = styled.div`
  display: block;
  margin-bottom: 1.5rem;
`;
export const UnitCase = styled.div`
  width: 100%;
  height: 4rem;
  background: var(--grayscale-4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.footer`
  width: 100%;
  position: relative;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  padding: 0 2rem;
`;
