import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { intervalToDuration } from 'date-fns';

import { AiOutlineStar } from 'react-icons/ai';
import { MdOutlineTimer } from 'react-icons/md';
import { LabDsIconSelectable } from 'v4web-components-react';
import * as S from './styles';
import { useAuth } from '../../../contexts/auth';
import { getAuctionStatus } from '../../../utils/getAuctionState';
import { useTicker } from '../../../hooks/useTicker';
import { convertCentsToBRL } from '../../../utils/convertCentsToBRL';
import { useAuctions } from '../../../contexts/auctions';

interface bidCardProps {
  auction: Auction;
  expressPurchased?: boolean;
  deleteModal?: boolean;
}

const BidCard: FC<bidCardProps> = ({
  auction,
  expressPurchased = false,
  deleteModal,
}) => {
  const location = useLocation();
  const { user, permissionsManager } = useAuth();
  const [isTimeFinish, setIsTimeFinish] = useState(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const {
    setAuctions,
    auctions,
    bidsToAnimate,
    setBidsToAnimate,
    setAuctionDelete,
    setShowModalDeleteLead,
  } = useAuctions();

  const [timeUpdateAnimation, setTimeUpdateAnimation] = useState(false);
  const [updateToTimeUpdateColor, setUpdateToTimeUpdateColor] = useState(false);
  const [freezedTimer, setFreezedTimer] = useState(new Date());

  const futureDate = new Date(auction?.expiresAt || new Date());

  const { hours, minutes, seconds, isTimeUp } = useTicker(futureDate);

  const displayTimeValue = useMemo(() => {
    const timeValue = isTimeUp
      ? 'Finalizado'
      : `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
          2,
          '0'
        )}:${String(seconds).padStart(2, '0')}`;

    if (timeUpdateAnimation) {
      const interval = intervalToDuration({
        start: new Date(),
        end: new Date(freezedTimer),
      });

      return `${String(interval.hours).padStart(2, '0')}:${String(
        interval.minutes
      ).padStart(2, '0')}:${String(interval.seconds).padStart(2, '0')}`;
    }

    return timeValue;
  }, [hours, isTimeUp, minutes, seconds, freezedTimer, timeUpdateAnimation]);

  const handleBidUpdateAnimation = useCallback(async () => {
    setTimeUpdateAnimation(true);

    const bidToAnimate = bidsToAnimate.find(bid => bid.id === auction._id);

    if (!bidToAnimate) {
      return;
    }

    setFreezedTimer(bidToAnimate.previousBidValue);

    const filterUpdatedAuctions = bidsToAnimate.filter(
      bid => bid.id !== auction._id
    );

    setBidsToAnimate(filterUpdatedAuctions);

    // awaits 1s to run the animation
    await new Promise(resolve => setTimeout(resolve, 1000));

    setTimeUpdateAnimation(false);
    setUpdateToTimeUpdateColor(true);

    await new Promise(resolve => setTimeout(resolve, 3001));

    setUpdateToTimeUpdateColor(false);
  }, [auction._id, bidsToAnimate, setBidsToAnimate]);

  const valueColor = useMemo(() => {
    const status = getAuctionStatus(auction, user!.unitId);

    if (status === 'available') return 'yellow';
    if (status === 'winner') return 'green';
    if (status === 'overcome') return 'red';
    return 'red';
  }, [auction, user]);

  const statusText = (text: string) => {
    switch (text) {
      case 'yellow':
        return 'Faça sua oferta';
      case 'green':
        return 'Vencendo';
      default:
        return 'Lance superado';
    }
  };

  const formatDate = (date: Date) => {
    const today = new Date();
    const newDate = new Date(date);

    if (
      newDate.getDate() === today.getDate() &&
      newDate.getMonth() === today.getMonth() &&
      newDate.getFullYear() === today.getFullYear()
    ) {
      return 'Hoje';
    }
    return newDate.toLocaleDateString();
  };

  const hideBidCard = useCallback(() => {
    isTimeUp &&
      setTimeout(() => {
        setIsTimeFinish(true);
        setTimeout(() => {
          setDeleteCard(true);
          let newAuctions = [];
          if (auctions?.length > 0) {
            newAuctions = auctions.filter(value => value._id !== auction._id);
          }
          setAuctions(newAuctions);
        }, 2500);
      }, 7000);
  }, [isTimeUp, auction._id, setAuctions, auctions]);

  useEffect(() => {
    hideBidCard();
  }, [hideBidCard]);

  useEffect(() => {
    const hasToBeUpdated = bidsToAnimate.some(bid => bid.id === auction._id);

    if (hasToBeUpdated) handleBidUpdateAnimation();
  }, [auction._id, bidsToAnimate, handleBidUpdateAnimation]);

  return (
    <S.Container
      to={!expressPurchased || !deleteModal ? `/bid/${auction._id}` : null}
      state={
        !expressPurchased || !deleteModal
          ? { backgroundLocation: location }
          : null
      }
      hide={isTimeFinish}
      deleted={deleteCard}
      deleteModal={deleteModal}
      refurbished={!!auction.refurbished}
      expressPurchased={!expressPurchased}
    >
      <S.TopContent>
        <S.HeaderCard>
          <S.TitleAndCreatedAtContainer>
            <S.HeaderCardContainer>
              <S.TooltipFixe
                refurbished={auction.refurbished}
                bids={auction?.bids?.length}
              >
                <S.CreatedAt refurbished={auction.refurbished}>
                  <p className="created-at">
                    Gerado em:&nbsp;
                    <span>{formatDate(auction.createdAt)}</span>
                  </p>
                </S.CreatedAt>
                {auction?.bids?.length ? (
                  <S.TooltipArrowLeft refurbished={auction.refurbished}>
                    {`${auction?.bids?.length} LANCES`}
                  </S.TooltipArrowLeft>
                ) : null}
              </S.TooltipFixe>
              <S.MarketingProduct>
                {auction?.lead?.marketingProduct || 'Assessoria'}
              </S.MarketingProduct>
              {!auction?.bids?.length &&
              !deleteModal &&
              permissionsManager?.leadbrokerPermission?.deleteLead ? (
                <S.CloseIcon>
                  <LabDsIconSelectable
                    icon="close"
                    size="s-medium"
                    onClick={event => {
                      setAuctionDelete(auction);
                      setShowModalDeleteLead(true);
                      event.preventDefault();
                    }}
                  />
                </S.CloseIcon>
              ) : null}
            </S.HeaderCardContainer>
            <S.TitleLeadCompany>
              {auction.refurbished && auction.descriptionRefurb && (
                <AiOutlineStar size={26} color="#231F2" />
              )}
              <S.CardTitle>
                <strong>{auction.lead.company}</strong>
              </S.CardTitle>
            </S.TitleLeadCompany>
          </S.TitleAndCreatedAtContainer>
        </S.HeaderCard>
        <S.Row className="rowContainer">
          <S.Row style={{ flexWrap: 'wrap' }}>
            <S.Row style={{ marginBottom: '0.5rem' }}>
              <small>
                <strong>Faturamento</strong>:{' '}
                <span> {auction.lead.revenue} </span>
              </small>
            </S.Row>
            <S.Row className="leadEmail">
              <S.LeadEmail>
                <strong>E-mail</strong>: <span> {auction.lead?.email} </span>
              </S.LeadEmail>
            </S.Row>
            <S.Row style={{ marginBottom: '0.25rem' }}>
              <small>
                <strong>Canal</strong>: <span> {auction.lead.channel} </span>
              </small>
            </S.Row>
          </S.Row>
          <S.Row style={{ width: 'max-content', flexWrap: 'wrap' }}>
            <S.Status color={valueColor}>
              <span> {statusText(valueColor)} </span>
            </S.Status>
          </S.Row>
        </S.Row>
      </S.TopContent>

      <S.CardFooter refurbished={auction.refurbished}>
        <S.TimeContent refurbished={auction.refurbished}>
          <MdOutlineTimer color="var(--primary-main)" size={21} />
          <S.Time>
            <h3 className={`${timeUpdateAnimation}`}>
              <S.Time updateToTimeUpdateColor={updateToTimeUpdateColor}>
                {displayTimeValue}
              </S.Time>
            </h3>
          </S.Time>
        </S.TimeContent>

        <S.PriceContent refurbished={auction.refurbished}>
          {auction.refurbished && (
            <S.OldPriceContent>
              <S.OldValue>
                {convertCentsToBRL(auction.winner.initialValue)}
              </S.OldValue>
            </S.OldPriceContent>
          )}

          <S.Value refurbished={!!auction.refurbished}>
            {convertCentsToBRL(auction.winner.value)}
          </S.Value>
        </S.PriceContent>
      </S.CardFooter>

      {expressPurchased && (
        <S.ExpPurchaseOuterContainer>
          <S.ExpPurchaseInnerContainer>
            <p>Arrematado pelo compre já</p>
          </S.ExpPurchaseInnerContainer>
        </S.ExpPurchaseOuterContainer>
      )}
    </S.Container>
  );
};

export default BidCard;
