import {
  ReactNode,
  useEffect,
  useCallback,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { Loading } from 'v4components-react';

import { googleLogout } from '@react-oauth/google';
import { Loading as LoadingStyle } from '../pages/Login/styles';
import { IUnit } from '../types/unit';
import { UserProps } from '../types/user';
import api, {
  customerApi,
  federationApi,
  fileApi,
  unitsApi,
  usersApi,
} from '../services/requests/leadbroker/api';
import { getPermissionsByUser } from '../services/requests/leadbroker/users/managerUsers';
import { managerPermissions } from '../types/managerPermissions';

interface SignInCredentials {
  tokenId: string;
}

interface AuthContextData {
  updateUser: (userData: UserProps) => void;
  user: UserProps;
  signIn({ tokenId }: SignInCredentials): Promise<void>;
  signOut(): void;
  errorMessage: string;
  isNowBlocked: boolean;
  setIsNowBlocked: Dispatch<SetStateAction<boolean>>;
  setUser: React.Dispatch<React.SetStateAction<UserProps>>;
  handleUpdateUserState(
    setUser,
    signOut,
    setLoadingUser,
    setErrorMessage
  ): Promise<void>;
  setLoadingUser: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  permissionsManager: managerPermissions;
  setPermissionsManager: Dispatch<SetStateAction<managerPermissions>>;
}

export interface AuthProps {
  children: ReactNode;
}

interface ISignInRoute {
  token: string;
  unit: IUnit;
  user: UserProps;
}

const AuthContext = createContext({} as AuthContextData);

async function handleSetApiTokens(token: string) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  unitsApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  usersApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  customerApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  fileApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  federationApi.defaults.headers.common.Authorization = `Bearer ${token}`;
}

async function handleUpdateUserState(
  setUser,
  signOut: () => void,
  setLoadingUser,
  setErrorMessage
) {
  try {
    setLoadingUser(true);

    const userRequest = await usersApi.get<{ user: UserProps; unit: IUnit }>(
      '/user/me'
    );

    if (!userRequest.data) {
      const message = 'Usúario não encontrado';

      setUser({} as UserProps);
      setErrorMessage(message);
      setLoadingUser(false);
      return;
    }

    const { user, unit } = userRequest.data;

    if (!unit?._id) {
      const message =
        'Unidade não encontrada, verifique suas permissões do leadbroker';

      setUser({} as UserProps);
      setErrorMessage(message);
      setLoadingUser(false);
      return;
    }

    user.unit = unit;
    user.unitId = unit._id;

    // Remove that in the future please
    const isFranchiseAdmin = user?.permissions?.unit?.admin;
    user.franchiseAdmin = !!isFranchiseAdmin;

    setUser(user);
    setErrorMessage('');
    setLoadingUser(false);
  } catch {
    setLoadingUser(false);
    signOut();
  }
}

async function handleUpdatePermissionsState(setPermissionsManager) {
  const permissionsRequest = await getPermissionsByUser();

  setPermissionsManager(permissionsRequest?.data?.permissions);
}

function AuthProvider({ children }: AuthProps): JSX.Element {
  const [user, setUser] = useState<UserProps>({} as UserProps);
  const [loadingUser, setLoadingUser] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isNowBlocked, setIsNowBlocked] = useState(false);
  const [permissionsManager, setPermissionsManager] = useState<
    managerPermissions
  >();

  function signOut() {
    destroyCookie(undefined, 'v4company.token', {
      domain: process.env.REACT_APP_DOMAIN_NAME,
    });

    destroyCookie(undefined, 'v4company.termsUse', {
      domain: process.env.REACT_APP_DOMAIN_NAME,
    });

    localStorage.removeItem('v4company.termsUse');

    googleLogout();

    setLoadingUser(false);
    setUser({} as UserProps);
  }

  useEffect(() => {
    const { 'v4company.token': token } = parseCookies();

    if (!token) {
      setLoadingUser(false);
      return;
    }

    handleUpdateUserState(setUser, signOut, setLoadingUser, setErrorMessage);
    handleSetApiTokens(token);
    setErrorMessage('');
  }, []);

  useEffect(() => {
    if (!user?._id) {
      return;
    }
    handleUpdatePermissionsState(setPermissionsManager);
  }, [user]);

  async function signIn({ tokenId }: SignInCredentials) {
    const twoDays = 60 * 60 * 24 * 2;
    setLoadingUser(true);

    const response = await usersApi.post<ISignInRoute>('/auth', {
      tokenId,
    });

    const { token, user: requestUser, unit } = response.data;

    if (!requestUser) {
      const message = 'Usúario não encontrado';

      setErrorMessage(message);
      setUser({} as UserProps);
      setLoadingUser(false);
      return;
    }

    if (!unit?._id) {
      const message =
        'Unidade não encontrada, verifique suas permissões do leadbroker';

      setUser({} as UserProps);
      setErrorMessage(message);
      setLoadingUser(false);

      return;
    }

    requestUser.unitId = unit._id;
    requestUser.unit = unit;

    // Remove that in the future please
    const isFranchiseAdmin = user?.permissions?.unit?.admin;
    user.franchiseAdmin = !!isFranchiseAdmin;

    setUser(requestUser);

    setCookie(undefined, 'v4company.token', token, {
      maxAge: twoDays,
      path: '/',
      domain: process.env.REACT_APP_DOMAIN_NAME,
    });

    handleSetApiTokens(token);
    setErrorMessage('');
    setLoadingUser(false);
  }

  const updateUser = useCallback(async (userData: UserProps) => {
    const { 'v4company.token': token } = parseCookies();
    if (token) setUser(userData);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        updateUser,
        errorMessage,
        isNowBlocked,
        setIsNowBlocked,
        setUser,
        handleUpdateUserState,
        setErrorMessage,
        setLoadingUser,
        permissionsManager,
        setPermissionsManager,
      }}
    >
      {loadingUser ? (
        <LoadingStyle style={{ height: '95vh', marginLeft: '6rem' }}>
          <Loading />
        </LoadingStyle>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth, AuthContext };
