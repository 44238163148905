import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

export const TermsUseModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: fit-content;
  width: fit-content;
  margin: 0 auto;
  background-color: var(--white);
  color: var(--secondary-main);

  h2 {
    font-size: 1.4rem;
    font-weight: 700;
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

export const ContentTerms = styled.div`
  display: block;

  p {
    font-size: 1rem;
    line-height: 1.1rem;
    margin-bottom: 0.8rem;
    font-family: 'Inter';

    a {
      color: var(--secondary-main);
      font-weight: 500;
      padding-left: 0.2rem;
    }
  }

  .description {
    display: flex;
  }

  .number {
    margin-right: 0.3rem;
  }

  .sanctions {
    margin-left: 1.5rem;
    margin-bottom: 0.1rem;

    &:nth-child(6) {
      margin-bottom: 0.8rem;
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 1.5rem;

  button {
    width: 13rem;
    background: var(--primary-dark-2);
    border-radius: 0.3rem;

    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 0.7;
    }
  }
`;

export const ErrorCheckbox = styled.p`
  display: flex;
`;

export const CloseModalButton = styled(FiX).attrs({
  size: 21,
})`
  right: -25rem;
  top: -4.5rem;
  position: relative;
  cursor: pointer;
  color: var(--secondary-main);
`;
