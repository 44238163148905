import { parseCookies } from 'nookies';
import apiFederation from '../apiFederation';

export async function getAuctionsAllFederation(pageParam = 1) {
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.get(
      `/auction/listLeadsAndPacks?page=${pageParam}&limit=16`,
      { headers: { Authorization: `Bearer ${cookies['v4company.token']}` } }
    );
    const auctions: AuctionAllResponse = response.data;

    return auctions;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os auctions, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}

export async function getAuctionsPackFederation(pageParam = 1) {
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.get(
      `/packs?page=${pageParam}&limit=16`,
      { headers: { Authorization: `Bearer ${cookies['v4company.token']}` } }
    );
    const auctions: AuctionResponse = response.data;

    return auctions;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os auctions, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}

export async function getAuctionsUnitFederation(pageParam = 1) {
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.get(
      `/leads?page=${pageParam}&limit=16`,
      { headers: { Authorization: `Bearer ${cookies['v4company.token']}` } }
    );
    const auctions: AuctionResponse = response.data;

    return auctions;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os auctions, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}

export async function getMyAuctionsFederation(pageParam = 1) {
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.get(
      `/auction/listLeadsAndPacks?bade=true&page=${pageParam}&limit=16`,
      { headers: { Authorization: `Bearer ${cookies['v4company.token']}` } }
    );
    const auctions: AuctionAllResponse = response.data;

    return auctions;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os auctions, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}

export async function getRefurbishedAuctionsFederation(pageParam = 1) {
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.get(
      `/leads?onlyRefurbished=true&page=${pageParam}&limit=16`,
      { headers: { Authorization: `Bearer ${cookies['v4company.token']}` } }
    );
    const auctions: AuctionResponse = response.data;

    return auctions;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os auctions, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}
