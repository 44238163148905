import styled from 'styled-components';
import { FiX } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 0.5rem;
  overflow: auto;
  position: relative;
  height: 80vh;
  border-radius: 0.5rem;
`;

export const ModalHeader = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 2rem;
  height: 6rem;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);

  h2 {
    span {
      font-size: 1.777rem;
      font-weight: 700;
      color: var(--success-3);
    }
  }

  svg {
    cursor: pointer;
    font-size: 1.5rem;
  }
`;

export const FormDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  p {
    margin-bottom: 1.5rem;
  }

  h5 {
    display: flex;
    align-items: center;

    svg {
      color: var(--secondary-main);
      margin-right: 1.25rem;
      width: 1.375rem;
      height: 1.375rem;
    }
    img {
      color: var(--secondary-main);
      margin-right: 1.25rem;
      width: 1.375rem;
      height: 1.375rem;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  input {
    width: 32.5rem;
  }
  .short {
    width: 15.25rem;
  }
  .form-control {
    width: 100%;
    height: 2.5rem;
    // border: 1px solid var(--grayscale-20);
    border-radius: 0.25rem;
    padding: 0.375rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
  }

  .error {
    color: var(--error);
  }

  small {
    margin-left: 0.25rem;
    color: var(--error);
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
  }

  .error {
    color: var(--error);
  }

  small {
    margin-left: 0.25rem;
    color: var(--error);
  }
`;

export const FinancialContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  border-left: 1px solid var(--grayscale-06);
  padding-left: 1rem;
  margin-bottom: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
    margin-left: 1rem;
  }

  div {
    display: flex;
    align-self: flex-end;
  }
`;

export const Button = styled.button`
  align-self: flex-end;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid var(--tertiary-dark);
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  color: var(--tertiary-dark);
  transition: all 0.3s;
  margin-bottom: 1rem;
  width: 10.25rem;
  height: 2.5rem;
`;

export const ConfirmationModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 0 auto;
  background-color: var(--white);
  border-radius: 0.375rem;

  svg {
    font-size: 5rem;
    color: var(--primary-main);
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 1rem;
    align-items: center;
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  button + button {
    margin-left: 2rem;
    background-color: var(--primary-dark);
    border: none;
    color: var(--white);
  }
`;

export const CalendarModal = styled.div`
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  background-color: rgb(0, 0, 0, 0.3);
  cursor: initial;
  position: absolute;

  & div:first-child {
    position: relative;
    z-index: 150;
  }
`;

export const CloseModalButton = styled(FiX).attrs({
  size: 32,
})`
  cursor: pointer;
  color: var(--grayscale-60);
`;
