import { useAuth } from '../../../../contexts/auth';
import { User } from '../../../../types/user';
import { ToggleSwitch } from '../../../Default/Fields';

import * as S from './styles';

interface UserProps {
  user: User;
  handleCanBid: (id: string, canBid: boolean) => void;
}

export function TableUser({ user, handleCanBid }: UserProps) {
  const { user: loggedUser } = useAuth();
  const isSameUser = loggedUser?._id === user.userId;
  return (
    <S.Row key={user.userId} disableRow={isSameUser}>
      <S.Field>
        {user.user && (
          <img
            src={
              user.user.picture
                ? `${user.user.picture}-tmp.jpg`
                : 'https://via.placeholder.com/150'
            }
            alt={user.user.picture ? user.user.name : '?'}
          />
        )}
        <p>{user.user.name}</p>
      </S.Field>

      <S.Field>{user.user.email}</S.Field>

      <S.Field>
        <ToggleSwitch
          setIsOn={() => handleCanBid(user.userId, !user.canBid)}
          isOn={user.canBid}
          id={user.userId}
        />
      </S.Field>
    </S.Row>
  );
}
