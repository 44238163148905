export const sanctionsTermsUse = [
  {
    label: 'a. Exclusão permanente do usuário no Lead Broker;',
  },
  {
    label:
      'b. Suspensão da unidade por um período de seis meses a contar da data da constatação da violação. Em caso de reincidência, a unidade será excluída permanentemente da plataforma;',
  },
  {
    label:
      'c. Durante a suspensão, a unidade ficará impedida de receber atribuições por período igual ao da suspensão;',
  },
  {
    label:
      'd. A unidade será notificada pelo jurídico da matriz, nos termos do Contrato de Franquia.',
  },
];
