export interface ITabs {
  title: string;
  key: string;
  event: () => void;
}

interface TabsProps {
  tabs: ITabs[];
}

export function ListTabs({ tabs }: TabsProps) {
  return tabs.map(tab => ({
    ...tab,
  }));
}
