import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaRegCheckCircle } from 'react-icons/fa';

import {
  LabDsButton,
  LabDsTextField,
  LabDsTooltip,
} from 'v4web-components-react';

import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import { Form } from '@unform/web';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { ClipBoardCopy } from '../../Default/ClipBoardCopy';

import { useQueryReadOneAuction } from '../../../services/requests/leadbroker/auctions/readOneAuction';
import { ModalLoading } from '../../Default/Loadings/ModalLoading';
import * as S from './styles';
import { getValidationErrors } from '../../../utils/getValidationErrors';
import { executeBid } from '../../../services/requests/leadbroker/auctions/bid';
import { useAuctions } from '../../../contexts/auctions';
import { formatDateWithHours } from '../../../utils/dateFunctions';
import { useTicker } from '../../../hooks/useTicker';
import { Button } from '../../Default/Button';
import { useBalance } from '../../../hooks/wallet';
import { convertCentsToBRL } from '../../../utils/convertCentsToBRL';
import { ErrorModal } from '../../AtomicDesign/molecules/ErrorModal';
import { useAuth } from '../../../contexts/auth';
import { Deposit } from '../../../pages/Wallet/Deposit';
import { CustomLoading } from '../../Default/Loadings/CustomLoading';
import { ModalDeleteLead } from '../LeadDetails/components/ModalDeleteLead';
import { useDeposit } from '../../../contexts';

interface BidDetails {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const BidDetails: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const previousUrl = '/';
  const [showModalDeleteLead, setShowModalDeleteLead] = useState(false);

  const formatDate = (date: Date) => {
    const today = new Date();
    const newDate = new Date(date);

    if (
      newDate.getDate() === today.getDate() &&
      newDate.getMonth() === today.getMonth() &&
      newDate.getFullYear() === today.getFullYear()
    ) {
      return 'Hoje';
    }
    return newDate.toLocaleDateString();
  };

  const formatNumber = (number: number) => {
    return (number / 100).toFixed(2).replace('.', ',');
  };

  const minimumValueToBid = 900;
  const initialBidValue = minimumValueToBid + 100;
  const { unitInfos, auction, setAuction } = useAuctions();

  const [isOpenBuyModal, setIsOpenBuyModal] = useState(false);
  const [isOpenPermissionErrorModal, setIsOpenPermissionErrorModal] = useState(
    false
  );
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [userCanBid, setUserCanBid] = useState(false);
  const [bidDisabled, setBidDisabled] = useState(false);

  const [isOpenDepositModal, setIsOpenDepositModal] = useState(false);
  const bidFormRef = useRef<FormHandles>(null);
  const { balance, totalBonusAmount } = useBalance();
  const sumBalance = balance + totalBonusAmount;
  const {
    setStageDeposit,
    stageDeposit,
    checkGoBackDeposit,
    checkArrowStage,
  } = useDeposit();

  const { hours, minutes, seconds, isTimeUp } = useTicker(
    auction.expiresAt ? new Date(auction?.expiresAt) : new Date()
  );
  const { user, permissionsManager } = useAuth();
  const { permissions } = user;
  const [labelBuyNow, setLabelBuyNow] = useState('Compre já');

  const location = useLocation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isCopyEmail, setIsCopyEmail] = useState(false);
  const [isCopyTitle, setIsCopyTitle] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isLoadingBid, setIsLoadingBid] = useState(false);
  const [isLoadingBidTime, setIsLoadingBidTime] = useState(false);

  const balanceErrorList = useMemo(
    () => [
      'You do not have enough balance.',
      'Available balance is less than value.',
      'Insufficient funds',
      'Wallet not found',
    ],
    []
  );

  const hasBalanceError = useCallback(
    error => balanceErrorList.includes(error[1]?.response.data.message),
    [balanceErrorList]
  );

  const permissionError = useMemo(
    () => [
      'You cannot bid on this auction.',
      'You do not have permission to bid.',
    ],
    []
  );

  const hasPermissionError = useCallback(
    error => permissionError.includes(error[1]?.response.data.message),
    [permissionError]
  );

  const onDismiss = useCallback(() => {
    if (location.state === null) {
      return navigate(previousUrl);
    }

    return navigate(-1);
  }, [location, navigate]);

  const { isLoading: loadingPackAuctions, error } = useQueryReadOneAuction(
    id as string,
    onDismiss,
    setAuction
  );
  const [valueBid, setValueBid] = useState<string>(
    formatNumber(auction?.winner?.value + initialBidValue)
  );

  useEffect(() => {
    if (error) {
      navigate('/');
    }
  }, [error, navigate]);

  useEffect(() => {
    setValueBid(formatNumber(auction?.winner?.value + initialBidValue));
  }, [auction?.winner?.value, initialBidValue, loadingPackAuctions]);

  const handleBidDisabled = useCallback(() => {
    if (!auction?.winner) return;

    if (auction?.winner?.unitId === user?.unitId) {
      setBidDisabled(true);
    } else {
      setBidDisabled(false);
    }
  }, [auction?.winner, user?.unitId]);

  useEffect(() => {
    handleBidDisabled();
  }, [handleBidDisabled]);

  const timeawaitingBid = Number(process.env.REACT_APP_TIME_AWAITING_BID);

  const disableBidTime = useCallback(() => {
    setIsLoadingBidTime(true);

    setTimeout(() => {
      setIsLoadingBidTime(false);
    }, timeawaitingBid);
  }, [timeawaitingBid]);

  const handleBid = useCallback(
    async (data: { value: number }) => {
      try {
        disableBidTime();
        setIsLoadingBid(true);
        bidFormRef?.current?.setErrors({});

        const schema = Yup.object().shape({
          value: Yup.number()
            .required()
            .moreThan(
              auction?.winner?.value + minimumValueToBid,
              'Você deve cobrir o lance atual em até pelo menos R$10,00'
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (auction) {
          const newData = {
            _id: auction?._id,
            auction: 'lead',
            value: data.value,
          };

          const newAuction = await executeBid(newData);
          if (newAuction) {
            setIsLoadingBid(false);
          }

          if (hasBalanceError(newAuction)) {
            setIsOpenDepositModal(true);
            return;
          }

          if (
            newAuction[1]?.response?.data?.message ===
            'You cannot bid on this auction.'
          ) {
            setIsOpenPermissionErrorModal(true);
            return;
          }

          if (id && newAuction.bids) {
            setAuction(newAuction);
          }
        }
        setIsLoadingBid(false);
      } catch (err) {
        setIsLoadingBid(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          bidFormRef?.current?.setErrors(errors);
        }
      }
    },
    [auction, disableBidTime, hasBalanceError, id, setAuction]
  );

  const handleBuy = async (bidId: string) => {
    setLoading(true);
    const data = {
      _id: bidId,
      value: auction?.winner?.value,
      auction: 'lead',
      buyNow: true,
    };

    const wasBought = await executeBid(data);

    if (
      wasBought[1]?.response?.status === 500 &&
      !hasBalanceError(wasBought) &&
      !hasPermissionError(wasBought)
    ) {
      setLoading(false);
      return;
    }

    if (hasBalanceError(wasBought)) {
      setIsOpenDepositModal(true);
      setLoading(false);
      return;
    }

    if (hasPermissionError(wasBought)) {
      setIsOpenPermissionErrorModal(true);
      setLoading(false);
      return;
    }

    navigate('/');

    if (wasBought) {
      navigate(previousUrl);
    }
    setIsOpenBuyModal(false);
    setLoading(false);
  };

  async function clipboardCopy(text: string, local: string) {
    await navigator.clipboard.writeText(text);
    if (local === 'title') {
      setIsCopyTitle(true);
      setTimeout(() => {
        setIsCopyTitle(false);
      }, 1000);
      return;
    }
    setIsCopyEmail(true);
    setTimeout(() => {
      setIsCopyEmail(false);
    }, 1000);
  }

  const setCanBid = useCallback(async () => {
    if (user) {
      if (permissions && permissions.leadbroker.bid) {
        setUserCanBid(true);
      }
    }
  }, [permissions, user]);

  useEffect(() => {
    setCanBid();
  }, [setCanBid]);

  return (
    <>
      {showDeposit ? (
        <Dialog
          aria-labelledby="label"
          initialFocusRef={buttonRef}
          className="modalDeposit"
        >
          <Deposit
            title="Depositar"
            arrowLeftButton={checkArrowStage(stageDeposit as string)}
            backButton={() => checkGoBackDeposit(stageDeposit as string)}
            handleToggleModal={() => setShowDeposit(!showDeposit)}
          />
        </Dialog>
      ) : (
        <Dialog
          aria-labelledby="label"
          onDismiss={onDismiss}
          initialFocusRef={buttonRef}
          style={{
            width: '75rem',
            maxWidth: '71.25rem',
            borderRadius: '0.5rem',
            padding: '2.5rem',
            marginTop: 'calc(2rem)',
            marginBottom: 'calc(2rem)',
          }}
        >
          {loadingPackAuctions || !auction?.createdAt ? (
            <ModalLoading rows={5} />
          ) : (
            <S.Container>
              <header>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '500px',
                  }}
                >
                  <div className="title-wrapper">
                    <h2>{auction?.lead?.company}</h2>
                    <h2>
                      <ClipBoardCopy
                        active={isCopyTitle}
                        onClick={() =>
                          clipboardCopy(auction?.lead?.company, 'title')
                        }
                      />
                    </h2>
                  </div>
                  <div className="created-at">
                    <p>
                      Gerado em:&nbsp;
                      <span>{formatDate(auction.createdAt)}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h2>
                    <div className="actualBidContainer">
                      Lance atual:{' '}
                      {auction.refurbished && (
                        <S.OldPriceContent>
                          <S.OldValue>
                            <p>
                              De:&nbsp;
                              {convertCentsToBRL(auction.winner.initialValue)}
                            </p>
                          </S.OldValue>
                        </S.OldPriceContent>
                      )}
                      <span>{convertCentsToBRL(auction?.winner?.value)}</span>
                    </div>
                  </h2>
                  <div style={{ float: 'right' }}>
                    <S.Row>
                      <small>Tempo:</small>
                      <p
                        style={{
                          color: 'var(--primary-main)',
                          fontWeight: '900',
                        }}
                      >
                        {isTimeUp
                          ? 'Finalizado'
                          : `${String(hours).padStart(2, '0')}:${String(
                              minutes
                            ).padStart(2, '0')}:${String(seconds).padStart(
                              2,
                              '0'
                            )}`}
                      </p>
                    </S.Row>
                  </div>
                </div>
                <S.CloseModalButton onClick={onDismiss} />
              </header>

              <S.Details>
                <S.ContentDetails>
                  <S.Column>
                    <small>Faturamento</small>
                    <p>{auction?.lead?.revenue}</p>
                  </S.Column>

                  <S.Column>
                    <small>Canal</small>
                    <p>{auction?.lead?.channel}</p>
                  </S.Column>

                  <S.Column>
                    <small>E-mail</small>
                    <p>
                      {auction?.lead?.email}{' '}
                      <ClipBoardCopy
                        active={isCopyEmail}
                        onClick={() =>
                          clipboardCopy(auction?.lead?.email, 'email')
                        }
                      />
                    </p>
                  </S.Column>

                  {auction?.location && (
                    <S.Column>
                      <small>Localização</small>
                      <p>
                        {auction?.location?.city || '-'} /{' '}
                        {auction?.location?.state || '-'}
                      </p>
                    </S.Column>
                  )}

                  <S.Column>
                    <small>Campanha</small>
                    <p>{auction?.lead?.campaign || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Urgência para iniciar</small>
                    <p>{auction?.lead?.urgencyToStart || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Segmento</small>
                    <p>{auction?.lead?.segment}</p>
                  </S.Column>

                  <S.Column>
                    <small>Cargo</small>
                    <p>{auction?.lead?.companyPosition || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Nome do contato</small>
                    <p>{auction?.lead?.name}</p>
                  </S.Column>

                  <S.Column>
                    <small>DDD</small>
                    <p>({auction?.lead?.tel})</p>
                  </S.Column>
                  <S.Column>
                    <small>CNPJ</small>
                    <p>
                      {auction?.lead?.cnpj?.replace(/\D/g, '')?.slice(0, 3) ||
                        'CNPJ não informado'}
                    </p>
                  </S.Column>
                  <S.Column>
                    <small>Produtos de marketing</small>
                    <p>{auction?.lead?.marketingProduct || 'Assessoria'}</p>
                  </S.Column>
                </S.ContentDetails>
                {auction?.lead?.description && (
                  <S.Description>
                    <S.Column>
                      <small>Descrição</small>
                      <S.InputDescription
                        value={auction?.lead?.description}
                        disabled
                      />
                    </S.Column>
                  </S.Description>
                )}
              </S.Details>

              <S.BidHistory
                isGreaterThanFour={
                  auction?.bids ? auction?.bids?.length > 4 : false
                }
              >
                <div>
                  <p>Último Lance</p>
                  <p>Valor</p>
                  <p> </p>
                </div>
                {auction?.bids ? (
                  <>
                    {auction?.bids?.length === 0 ? (
                      <div>
                        <p>Não foi feito nenhum lance nesse lead ainda</p>
                      </div>
                    ) : (
                      auction?.bids
                        ?.slice(0)
                        .reverse()
                        .map((bid, index) => (
                          <div key={bid?._id}>
                            <p>{formatDateWithHours(bid?.time)}</p>
                            <p>{convertCentsToBRL(bid?.value)}</p>
                            <p>
                              {index === 0 && bid?.unitId === user?.unitId ? (
                                <>
                                  <p className="info__bid">
                                    O último lance foi realizado pela sua
                                    unidade!
                                  </p>{' '}
                                </>
                              ) : null}
                            </p>
                          </div>
                        ))
                    )}
                  </>
                ) : null}
              </S.BidHistory>
              <Form
                onSubmit={handleBid}
                ref={bidFormRef}
                placeholder=""
                onPointerEnterCapture={() => null}
                onPointerLeaveCapture={() => null}
              >
                <S.Input>
                  <LabDsTextField
                    type={userCanBid ? 'number' : 'text'}
                    mask="currency"
                    titleInput={`Dar lance como unidade ${unitInfos.unitName} & Co.`}
                    label="Valor"
                    value={valueBid}
                    state={
                      !userCanBid || bidFormRef?.current?.getFieldError('value')
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      bidFormRef?.current?.getFieldError('value')
                        ? bidFormRef?.current?.getFieldError('value')
                        : ''
                    }
                    disabled={
                      (balance < auction?.winner?.value + minimumValueToBid,
                      !userCanBid) ||
                      isLoadingBid ||
                      bidDisabled
                    }
                    onChangeInput={({ detail }) => setValueBid(detail)}
                  />
                  {userCanBid ? (
                    <>
                      <p>Digite o valor aqui</p>
                    </>
                  ) : (
                    <p className="errorBid">
                      Você não tem permissão para dar lances
                    </p>
                  )}
                </S.Input>

                {!userCanBid || bidDisabled ? (
                  <LabDsTooltip
                    label={
                      bidDisabled
                        ? 'Um novo lance só pode ser realizado caso o seu seja superado'
                        : 'Você não está apto a dar lances. Caso necessário, solicite a permissão para o administrador da sua unidade.'
                    }
                  >
                    <LabDsButton
                      label="Realizar lance"
                      type="submit"
                      variant="outlined"
                      disabled
                    />
                  </LabDsTooltip>
                ) : (
                  <S.ButtonContainer>
                    <LabDsButton
                      label="Realizar lance"
                      type="submit"
                      variant="outlined"
                      loading={isLoadingBid || isLoadingBidTime}
                      onHandleButton={() => {
                        handleBid({
                          value: Number(valueBid.replace(/\D/g, '')),
                        });
                      }}
                      disabled={
                        sumBalance <
                          auction?.winner?.value + minimumValueToBid ||
                        !userCanBid ||
                        isLoadingBid ||
                        !(valueBid.length > 0)
                      }
                    />
                  </S.ButtonContainer>
                )}
                {!auction.refurbished && (
                  <LabDsTooltip
                    label="Na opção compre já, você pode arrematar o lead antes do tempo
                acabar, por apenas 1.8x o valor!"
                  >
                    <LabDsButton
                      variant="cta"
                      className="buy-now"
                      onMouseEnter={() => {
                        setLabelBuyNow(
                          `Compre já R$ ${(
                            (auction?.winner?.value * 1.8) /
                            100
                          ).toFixed(2)}`
                        );
                      }}
                      onMouseOut={() => {
                        setLabelBuyNow('Compre já');
                      }}
                      disabled={!userCanBid}
                      label={labelBuyNow}
                      onHandleButton={() => setIsOpenBuyModal(true)}
                    />
                  </LabDsTooltip>
                )}
              </Form>
              {!auction.bids.length &&
                permissionsManager?.leadbrokerPermission?.deleteLead && (
                  <S.DeleteContainer>
                    <LabDsButton
                      label="Excluir Lead"
                      leadingIcon=""
                      stepIcon=""
                      variant="link-button"
                      onClick={() => setShowModalDeleteLead(true)}
                    />
                  </S.DeleteContainer>
                )}
              {showModalDeleteLead && (
                <ModalDeleteLead
                  setShowModalDeleteLead={setShowModalDeleteLead}
                  auction={auction as Auction}
                />
              )}
              {isOpenBuyModal && (
                <Dialog
                  aria-labelledby="label"
                  onDismiss={() => setIsOpenBuyModal(true)}
                  initialFocusRef={buttonRef}
                  style={{ marginTop: '20vh', width: '70vw' }}
                >
                  {loading ? (
                    <S.Modal>
                      <CustomLoading style={{ left: 0 }} />
                    </S.Modal>
                  ) : (
                    <S.Modal>
                      <h2>
                        Deseja comprar esse Lead por {` `}
                        {convertCentsToBRL(auction?.winner?.value * 1.8)}
                      </h2>
                      <p>
                        Você pode arrematar essa Oferta agora pagando 1.8x o
                        valor do lance atual. <br />
                        Deseja prosseguir com a compra?
                      </p>
                      <S.ButtonsContainer>
                        <LabDsButton
                          variant="danger-outlined"
                          size="small"
                          label="Cancelar"
                          onHandleButton={() => setIsOpenBuyModal(false)}
                        />
                        <LabDsButton
                          variant="danger-primary"
                          size="small"
                          label="Comprar"
                          onHandleButton={() => {
                            handleBuy(auction?._id);
                          }}
                        />
                      </S.ButtonsContainer>
                    </S.Modal>
                  )}
                </Dialog>
              )}
              {isOpenConfirmationModal && (
                <Dialog
                  aria-labelledby="label"
                  onDismiss={() => setIsOpenConfirmationModal(false)}
                  initialFocusRef={buttonRef}
                  style={{ marginTop: '20vh', width: '70vw' }}
                >
                  <S.Modal>
                    <FaRegCheckCircle />
                    <h2>Lance realizado com sucesso!</h2>
                    <S.ButtonsContainer>
                      <Button
                        outline
                        small
                        type="button"
                        onClick={() => setIsOpenConfirmationModal(false)}
                      >
                        Fechar
                      </Button>
                      <Button type="button" small onClick={onDismiss}>
                        Ver todos os lances
                      </Button>
                    </S.ButtonsContainer>
                  </S.Modal>
                </Dialog>
              )}

              {isOpenDepositModal && (
                <ErrorModal
                  ref={buttonRef}
                  closeModal={() => setIsOpenDepositModal(false)}
                  actionButton={() => {
                    setShowDeposit(true);
                    setStageDeposit('amount');
                    setIsOpenDepositModal(false);
                    setIsOpenBuyModal(false);
                  }}
                  actionTitle="Depositar agora"
                  title="Ação não concluida por falta de saldo"
                  description={`Faltam ${convertCentsToBRL(
                    Number(valueBid.replace(/\D/g, '')) - balance
                  )} reais para conseguir realizar esse lance`}
                />
              )}
              {isOpenPermissionErrorModal && (
                <ErrorModal
                  ref={buttonRef}
                  closeModal={() => setIsOpenPermissionErrorModal(false)}
                  title="Você não tem permissão para dar lances"
                  description="Entre em contato com o administrador da franquia para mais informações."
                />
              )}
            </S.Container>
          )}
        </Dialog>
      )}
    </>
  );
};

export default BidDetails;
