import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    font-size: 20px;
  }

  footer {
    z-index: 1;
    background: var(--tertiary-light);
    width: 100%;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0px -4px 8px 3px rgba(0, 0, 0, 0.06),
      0px -1px 3px rgba(0, 0, 0, 0.12);
    button {
      width: max-content;
      border-color: transparent !important;
      margin-right: 1.875rem !important;
    }
  }
`;

export const AccountContents = styled.div`
  display: flex;
`;

export const BoxAvatar = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  padding-top: 1rem;
  padding-bottom: 15rem;

  button {
    display: flex;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
    width: 7.563rem;
    height: 100%;
    background: none;
    border: 0;

    :first-of-type {
      margin-right: 0;
    }

    :active {
      background: var(--grayscale-4);
      border-radius: 4px;
    }

    img {
      width: 2rem;
      height: 2rem;
      background-size: cover;
      border-radius: 50%;
      margin-bottom: 1rem;
    }
  }
`;

export const AvatarAccount = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  padding: 1rem;
  flex-direction: column;
  text-align: center;
  width: 7.563rem;
  height: 100%;
  cursor: pointer;

  :active {
    background: var(--grayscale-4);
    border-radius: 4px;
  }

  img {
    width: 2rem;
    height: 2rem;
    background-size: cover;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
`;

export const LowerCaseText = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

export const AvatarIcon = styled.div`
  width: 2.188rem;
  height: 2.188rem;
  border: 1px solid var(--grayscale-10);
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;
