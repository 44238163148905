import { FC, InputHTMLAttributes } from 'react';
import { SearchIC } from '../Icons';
import * as S from './styles';

interface selectProps {
  placeholder?: string;
  options: { name: string; value: string }[];
}

export const Select: FC<selectProps> = ({ placeholder, options, ...props }) => {
  return (
    <S.Select {...props}>
      <option value="" key="0">
        {placeholder}
      </option>
      {options.map(option => (
        <option value={option.value} key={option.value}>
          {option.name}
        </option>
      ))}
    </S.Select>
  );
};

interface searchProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

export const Search: FC<searchProps> = ({
  placeholder = 'Pesquisar',
  ...props
}) => {
  <SearchIC />;
  return <S.Search type="text" {...props} placeholder={placeholder} />;
};

interface buttonProps {
  text: string;
  isPrimary?: boolean;
  width?: string;
  height?: string;
  onClick?: () => void;
}

export const Button: FC<buttonProps> = ({
  text,
  isPrimary = false,
  width = '168px',
  height = '40px',
  onClick,
}) => {
  return (
    <S.Button
      width={width}
      height={height}
      isPrimary={isPrimary}
      onClick={onClick}
      type="button"
    >
      {text}
    </S.Button>
  );
};

interface toggleSwitchProps {
  setIsOn: (isOn: boolean) => void;
  isOn: boolean;
  id: string;
}

export const ToggleSwitch: FC<toggleSwitchProps> = ({ setIsOn, isOn, id }) => {
  return (
    <>
      <S.SwitchInput
        className="switch-checkbox"
        id={id}
        type="checkbox"
        checked={isOn}
        onChange={() => setIsOn(!isOn)}
      />
      <S.SwitchLabel className="switch-label" htmlFor={id}>
        <S.SwitchButton className="switch-button" />
      </S.SwitchLabel>
    </>
  );
};
