import styled, { css } from 'styled-components';
import { FiX } from 'react-icons/fi';

interface ReasonFooterProps {
  reasonError?: boolean;
  reasonDescriptionError?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--white);
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  form {
    gap: 1.5rem;
  }

  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  h4 {
    font-size: 1rem;
    font-weight: 700;
    color: var(--lab-ds-core-color-black-10);
  }

  header {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 0.3rem;

    .leadCompany {
      font-size: 2.38rem;
    }

    h2 {
      font-size: 1.75rem;
      color: var(--lab-ds-core-color-black-10);

      span {
        font-size: 1.777rem;
        font-weight: 700;
        color: var(--success-3);
      }
    }

    svg {
      cursor: pointer;
    }
  }

  .lostLead {
    border-bottom: 0.07rem solid var(--lab-ds-core-color-gray-30);
    margin-bottom: 1.6rem;
  }

  footer {
    margin-top: 1rem;
    display: flex;

    form {
      display: flex;
      width: 60%;

      strong {
        color: var(--secondary-dark);
        text-transform: uppercase;
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
      }
      small {
        color: var(--lab-ds-core-color-gray-70);
        margin-top: 0.25rem;
      }

      textarea {
        margin-top: 2.25rem;
        margin-bottom: 3.625rem;
        width: 100%;
        height: 12.5rem;
        padding: 1rem 1.5rem;
        color: var(--secondary-light);
        border-radius: 0.25rem;
        border: 1px solid var(--grayscale-4);
        resize: none;

        &::placeholder {
          font-size: 1rem;
          color: var(--secondary-light);
          font-weight: 400;
        }
      }
    }
  }
`;

export const Icon = styled.div<{ color: string }>`
  ${props => `
    color: ${props.color};
  `}
`;

export const Inputs = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1.5rem;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
`;

export const Row = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 1rem;

  small {
    color: var(--grayscale-100);
    font-weight: 700;
    font-size: 1rem;
    color: var(--success-3);

    b {
      color: var(--secondary-dark);
    }
  }

  .qtd {
    font-size: 1rem;
    b {
      color: var(--secondary-dark);
    }
  }

  p {
    margin: 0;
    margin-left: 0.5rem;
    font-size: 22px;
    color: var(--secondary-black);
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 100;
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--lab-ds-semantic-color-fg-default);
  font: var(--lab-ds-semantic-typography-heading-h5);
`;

export const Hour = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1rem;
  color: var(--lab-ds-semantic-color-fg-default);
  font: var(--lab-ds-semantic-typography-heading-h5);
`;

export const ReasonFooter = styled.footer<ReasonFooterProps>`
  form {
    gap: 1rem;
  }
`;

export const CloseModalButton = styled(FiX).attrs({
  size: 23,
})`
  right: -2rem;
  top: -2.5rem;
  position: absolute;
  cursor: pointer;
  color: var(--grayscale-60);
`;

export const Details = styled.div<{
  refundRequired?: boolean;
  refund?: Auction;
}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-top: ${({ refund }) => (refund?._id ? 0 : '2rem')};
  border-top: 0.07rem solid var(--tertiary-dark);
  border-bottom: 0.07rem solid var(--tertiary-dark);
  padding-top: ${({ refund }) => (refund?._id ? 0 : '2rem')};
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  ${props =>
    props.refundRequired &&
    `
    small{
      filter: blur(7px);
      -webkit-filter: blur(7px);
    }
    p {
      filter: blur(7px);
      -webkit-filter: blur(7px);
    }
  `}

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
  }

  ${({ refund }) =>
    refund?._id &&
    refund?.status !== 'REJECT' &&
    css`
      border-bottom: 1px solid var(--grayscale-20);
      opacity: 0.7;
      background: var(--lab-ds-core-color-white-0);

      filter: blur(4px);
    `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  small {
    color: var(--lab-ds-semantic-color-fg-enabled);
    text-transform: uppercase;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
  }

  p {
    margin-top: 0.5rem;
    color: var(--lab-ds-semantic-color-fg-default);
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
  }
`;

export const TextRefund = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 5.88rem;
  left: 0rem;
  right: 0rem;
  z-index: 1;
  filter: none;

  span {
    text-align: center;
    background-color: var(--lab-ds-semantic-selectable-color-bg-pure);
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Nunito Sans';
    color: var(--lab-ds-core-color-black-10);
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: flex-end;

  a {
    text-decoration: none;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 0 auto;
  background-color: var(--white);

  svg {
    font-size: 5rem;
    color: var(--primary-main);
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  button + button {
    margin-left: 2rem;
    background-color: var(--primary-dark);
    border: none;
    color: var(--white);
  }

  button {
    color: var(--primary-dark);
    border-color: var(--primary-dark);
    border-radius: 0.5rem;
  }
`;

export const TitleModal = styled.span`
  font: var(--lab-ds-semantic-typography-heading-h4);
`;
export const TextModal = styled.span`
  font-size: 1rem;
`;

export const PackName = styled.p`
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 700;
`;

export const PriceDetails = styled.div`
  background-color: var(--lab-ds-core-color-gray-0);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 40px;

  h3 {
    max-width: 131px;
    span {
      position: relative;
      top: 1rem;
      font-size: 1.38rem;
      font-weight: 700;
      color: var(--lab-ds-core-color-green-50);
    }
  }
`;

export const PriceType = styled.div`
  background-color: var(--lab-ds-core-color-white-0);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  p {
    font-weight: 700;
  }
  span {
    font-weight: 700;
  }
`;

export const PriceHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Description = styled.div`
  width: 100%;

  small {
    margin-bottom: 0.7rem;
  }
`;

export const InputDescription = styled.textarea`
  width: 100%;
  height: 6rem;
  padding: 0.6rem 0.6rem;
  border-radius: 0.5rem;
  border: 0.06rem solid var(--tertiary-dark);
  resize: none;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  font: var(--lab-ds-semantic-typography-body-none-decoration-p3-regular);
  gap: var(--lab-ds-semantic-selectable-space-gap-xs);
  color: var(--lab-ds-core-color-red-arterial-50);
`;
