import { SectionTitle } from 'v4components-react';
import { LabDsButton } from 'v4web-components-react';
import * as S from './styles';
import { convertValueToBRL } from '../../../../utils/convertCentsToBRL';
import { useAuctions, useDeposit } from '../../../../contexts';
import { convertIsoToDate } from '../../../../utils/convertIsoToDate';
import { useAuth } from '../../../../contexts/auth';

interface SummaryPageProps {
  handleNextModal: () => void;
}

const Summary = ({ handleNextModal }: SummaryPageProps) => {
  const { amount, paymentType, expireDate, creditCard } = useDeposit();
  const { unitInfos } = useAuctions();
  const { user } = useAuth();
  const staticMask = '';

  const labels = {
    pix: 'Gerar pix',
    bank_slip: 'Gerar boleto',
    credit_card: 'Efetuar pagamento',
  };

  const checkPaymentType = payment => {
    switch (payment) {
      case 'pix':
        return 'PIX';
      case 'bank_slip':
        return 'Boleto';
      case 'credit_card':
        return 'Crédito';
      default:
        return 'xxxxxxxxxxx';
    }
  };

  return (
    <S.Container>
      <S.Content>
        <>
          <SectionTitle text="Confira os dados para realizar o depósito" />

          <S.Balances>{convertValueToBRL(amount || 0)}</S.Balances>

          <S.WithdrawalData>
            <div>
              <h4>Unidade</h4>
              <S.LowerCaseText>
                <>{unitInfos?.unitName || staticMask}</>
              </S.LowerCaseText>
            </div>

            <div>
              <h4>Tipo de depósito</h4>
              <S.LowerCaseText>{checkPaymentType(paymentType)}</S.LowerCaseText>
            </div>

            <div>
              <h4>Email</h4>
              <S.LowerCaseText>
                {paymentType === 'credit_card'
                  ? creditCard.payer.email
                  : user.email}
              </S.LowerCaseText>
            </div>

            <div>
              <h4>CPF ou CNPJ</h4>
              <S.LowerCaseText>
                {paymentType === 'credit_card'
                  ? creditCard.payer.cpfCnpj
                  : user.unit.cnpj}
              </S.LowerCaseText>
            </div>
          </S.WithdrawalData>
          {paymentType !== 'credit_card' && (
            <S.WithdrawalData>
              <div>
                <h4>Data de vencimento</h4>
                <S.LowerCaseText>
                  {expireDate ? convertIsoToDate(`${expireDate}`) : staticMask}
                </S.LowerCaseText>
              </div>
            </S.WithdrawalData>
          )}
        </>
      </S.Content>

      <S.Footer>
        <LabDsButton
          label={labels[paymentType]}
          onHandleButton={() => {
            handleNextModal();
            // submit();
          }}
        />
      </S.Footer>
    </S.Container>
  );
};

export default Summary;
