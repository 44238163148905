import {
  LabDsButton,
  LabDsDatePicker,
  LabDsIconNotSelectable,
  LabDsTextField,
} from 'v4web-components-react';
import { Dialog } from '@reach/dialog';
import * as S from './styles';

import { stepsProps } from '../../utils';

export function ModalMeetingSchedule({
  meetingDate,
  setMeetingDate,
  meetingHour,
  setMeetingHour,
  setShowModalMeetingSchedule,
  step,
  setStepActive,
  onDismiss,
  buttonRef,
}: {
  meetingDate: string;
  setMeetingDate: (string) => void;
  meetingHour: string;
  setMeetingHour: (string) => void;
  setShowModalMeetingSchedule: (boolean) => void;
  step: stepsProps[];
  setStepActive: (string) => void;
  onDismiss: () => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
}) {
  return (
    <Dialog
      aria-labelledby="label"
      onDismiss={onDismiss}
      initialFocusRef={buttonRef}
      style={{ marginTop: '20vh', width: '70vw' }}
    >
      <S.Modal>
        <S.ModalBody>
          <S.Icon color="var(--lab-ds-semantic-selectable-color-primary-default)">
            <LabDsIconNotSelectable icon="check" size="x-large" />
          </S.Icon>
          <S.TitleModal>Reunião marcada</S.TitleModal>

          <S.TextModal>
            Indique a data e o horário na qual a reunião foi marcada
          </S.TextModal>

          <S.Inputs>
            <LabDsDatePicker
              titleInput="Selecione a data da reunião*"
              value={meetingDate}
              size="medium"
              onChangeDatePicker={({ detail }) => {
                setMeetingDate(detail);
              }}
              onHandleConfirmDatePicker={() => null}
            />
            <LabDsTextField
              titleInput="Horário"
              value={meetingHour}
              mask="hour"
              maxLength={4}
              label="xx:xx"
              onChangeInput={({ detail }) => {
                setMeetingHour(detail);
              }}
            />
          </S.Inputs>
        </S.ModalBody>
        <S.ModalFooter>
          <LabDsButton
            variant="outlined"
            label="Cancelar"
            onHandleButton={() => {
              setShowModalMeetingSchedule(false);
            }}
          />
          <LabDsButton
            label="Confirmar"
            disabled={meetingDate === '' || meetingHour === ''}
            onHandleButton={() => {
              setStepActive(step[2]);
              setShowModalMeetingSchedule(false);
            }}
          />
        </S.ModalFooter>
      </S.Modal>
    </Dialog>
  );
}
