import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { api } from '../api';

interface UnitBlock {
  blockedAt: string;
}

export function useQueryUnitBlock(
  unitId: string
): UseQueryResult<{ data: UnitBlock }, { data: UnitBlock }> {
  return useQuery<{ data: UnitBlock }, { data: UnitBlock }>({
    queryKey: ['UnitBlock', unitId],
    queryFn: () => api.get<UnitBlock>(`/blockments/${unitId}`),
  });
}
