import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: var(--white);
  border-radius: 0.5rem;

  ::-webkit-scrollbar-track {
    background: none;
  }

  > svg {
    position: absolute;
    color: var(--primary-dark);
    margin: 1rem;
    right: 0;
    cursor: pointer;
  }

  > header h3 {
    color: var(--primary-dark);
    font-size: 1.13rem;
  }
`;

export const CloseButton = styled(FiX)`
  position: absolute;
  right: 2rem;
  top: 1.2rem;
  size: 1.1875rem;
  color: var(--secondary-main);
`;

export const ContainerLoading = styled.div`
  margin-bottom: 2rem;
`;

export const priceInstallement = styled.p`
  margin-top: 0.625rem;
`;

export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 2.2rem 2.2rem 2.2rem 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 1.12px 3.04px 0 var(--grayscale-3);
  position: relative;

  svg {
    cursor: pointer;
  }

  .titleModal {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--secondary-main);
    margin-left: 1rem;
  }

  .closeButton {
    position: absolute;
    right: 2rem;
    top: 1.2rem;
  }
`;

export const AllPaymentContent = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  height: 38rem;
  justify-content: center;
  @media (max-width: 450px) {
    padding-bottom: 5rem;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
  p {
    color: var(--secondary-main);
    text-align: center;

    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
  }
`;

export const Icon = styled.img`
  width: 3rem;
  height: 3rem;
`;

export const PriceCard = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 0;
  border: 0.13rem solid var(--tertiary-light);
  border-radius: 0.5rem;

  h5 {
    color: var(--success-2);
    font-size: 1.44rem;
  }
`;

export const Price = styled.div`
  margin-top: 2.5rem;
  gap: 0.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: stretch;
  border: 0.13rem solid var(--tertiary-light);
  border-radius: 0.5rem;

  h4 {
    color: var(--success-2);
    text-align: center;
    font-family: Inter;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
  }

  p {
    color: var(--success-2);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    text-transform: capitalize;
  }
`;

export const LowerCaseText = styled.p`
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const WithdrawalData = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  column-gap: 2rem;
  justify-content: end;
  div {
    margin-top: 2rem;
    width: 18.5rem;

    p {
      margin-top: 1.3rem;
    }
  }
`;

export const ContainerUnidade = styled.div``;
export const ContainerDeposit = styled.div``;
export const ContainerEmail = styled.div``;
export const ContainerCnpj = styled.div``;

export const CreditSection = styled.section<{
  paymentType: string;
}>``;

export const BoletoImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
  }

  p {
    margin-bottom: 1.5rem;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.88rem;
    text-align: center;
  }
`;

export const Digitableline = styled.div`
  display: flex;
  max-width: 65ch;
  word-break: break-word;
  padding-left: 1.3rem;

  button {
    height: 1.88rem;
    background: transparent;
    align-items: center;
    border: 0;
    padding-left: 1rem;
    :first-of-type {
      margin-left: 0.5rem;
      margin-right: 0rem !important;
    }

    &:active {
      opacity: 0.7;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    p {
      font-size: 1rem;
    }
    button:first-of-type {
      margin-right: 0 !important;
    }
  }
`;

export const BoletoSection = styled.section<{
  paymentType: string;
}>`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ paymentType }) =>
    paymentType === 'all' && '0.13rem solid var(--primary-dark)'};

  .view-barcode {
    color: var(--primary-main);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }

  h3 {
    align-self: flex-start;
    color: var(--primary-dark);
  }

  button {
    font-family: 'Inter', sans-serif;
  }

  button svg {
    margin-right: 0.5rem;
  }

  strong {
    font-weight: 500;
    font-size: 0.875rem;
    margin: 0.875rem 0;
  }

  > p {
    text-align: center;
    margin: 1rem;
    margin-bottom: ${({ paymentType }) =>
      paymentType === 'all' ? '3rem' : '5rem'};
    max-width: 50rem;

    @media (max-width: 450px) {
      text-align: left;
      margin-left: 0;
    }
  }
`;

export const PixSection = styled.section<{
  paymentType?: string;
}>`
  width: 100%;
  margin-top: ${({ paymentType }) => (paymentType === 'all' ? '3rem' : '1rem')};
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    width: 100%;
    color: var(--primary-dark);
    text-align: center;
  }

  header {
    width: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: column;

    p {
      align-self: flex-start;
      margin-top: 0.5rem;
      font-weight: 500;
      width: 100%;
      text-align: center;
    }
  }

  img {
    width: 15rem;
    height: 15rem;
  }

  strong {
    text-align: center;
    margin: 1rem 0;
  }

  > p {
    text-align: center;
    line-height: 1.38rem;
    font-size: 0.75rem;
    padding-bottom: 1.5rem;
    font-weight: 500;
  }
`;

export const PixCode = styled.div`
  display: flex;
  width: 90%;
  word-break: break-word;

  > p {
    text-align: center;
    line-height: 1.38rem;
    font-weight: 550;
    font-size: 0.82rem;
  }

  button {
    font-family: 'Inter', sans-serif;
    height: 2.2rem;
    background: transparent;
    align-items: center;
    border: 0;

    :first-of-type {
      padding-left: 1rem;
      margin-right: 0 !important;
    }

    &:active {
      opacity: 0.7;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    overflow: auto;
  }
`;
