import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: var(--lab-ds-semantic-selectable-space-gap-xl);
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 100% !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 15px;
  margin: 0 auto;
  gap: 24px;
  background-color: var(--white);
`;

export const ModalBody = styled.div`
  display: flex;
  width: 100%;
  border-radius: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.div<{ color: string }>`
  ${props => `
    color: ${props.color};
  `}
`;

export const TitleModalContainer = styled.div`
  display: flex;
  width: auto;
  padding-top: -10px;
  margin-bottom: 1.5rem;
  height: 2.5rem;
`;

export const TitleModal = styled.span`
  font-size: 1.9rem !important;
  font-weight: 700 !important;
  line-height: 2.5rem !important;
  font: var(--lab-ds-semantic-typography-heading-h4);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 39rem;
  height: 3rem;

  margin-bottom: 96px;
`;

export const TextModal = styled.span`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--lab-ds-semantic-color-fg-default) !important;
  margin-bottom: 0.6rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 44rem;
  margin-bottom: 1.5rem;
`;

export const CloseModalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  width: 100%;
`;

export const CloseModalButton = styled(FiX).attrs({
  size: 24,
})`
  right: 38.3rem;
  top: 7.5rem;

  cursor: pointer;
  color: var(--grayscale-60) !important;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1.5rem;

  lab-ds-date-picker {
    width: 60%;
  }
  lab-ds-text-field {
    width: 60%;
  }
`;
