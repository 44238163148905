import { CSSProperties } from 'styled-components';

import { Container } from './styles';

interface LoadingBidProps {
  style?: CSSProperties;
  backgroundColor?: string;
}

export function LoadingBid({
  style,
  backgroundColor,
}: LoadingBidProps): JSX.Element {
  return (
    <Container
      className="ellipsis"
      data-testid="loadingbid-container"
      style={style}
      backgroundColor={backgroundColor}
    >
      {Array.from({ length: 4 }).map(() => (
        <div />
      ))}
    </Container>
  );
}
