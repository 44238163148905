import { LabDsButton } from 'v4web-components-react';
import { Dialog } from '@reach/dialog';
import * as S from './styles';

export function ModalReload({
  setShowModalReload,
}: {
  setShowModalReload: (boolean) => void;
}) {
  const handleReloadPage = async () => {
    setShowModalReload(false);
    window.location.reload();
  };
  return (
    <Dialog
      style={{
        width: '55rem',
        borderRadius: '0.5rem',
        padding: '0',
      }}
      onDismiss={() => setShowModalReload(false)}
    >
      <S.Modal>
        <S.ModalBody>
          <S.CloseModalContainer>
            <S.CloseModalButton onClick={() => setShowModalReload(false)} />
          </S.CloseModalContainer>
          <S.TitleModalContainer>
            <S.TitleModal>
              Tem certeza que deseja atualizar a página?
            </S.TitleModal>
          </S.TitleModalContainer>

          <S.TextContainer>
            <S.TextModal>
              A plataforma é atualizada <b>automaticamente</b>, mas caso você
              tenha ficado muito tempo sem acessar, é importante recarregar a
              página!
            </S.TextModal>
          </S.TextContainer>
        </S.ModalBody>
        <S.ModalFooter>
          <LabDsButton
            variant="danger-outlined"
            label="Voltar ao Lead Broker"
            onHandleButton={() => {
              setShowModalReload(false);
            }}
          />
          <LabDsButton
            label="Sim, recarregar página"
            onHandleButton={() => {
              handleReloadPage();
            }}
          />
        </S.ModalFooter>
      </S.Modal>
    </Dialog>
  );
}
