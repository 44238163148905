import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { IOptionsSelect } from 'v4web-components';
import { getUsersByMandate } from '../services/requests/leadbroker/users/getMandates';
import { IFileList } from '../components/RouteComponents/LeadDetails/components/UploadFiles';
import { headersMyLeads } from '../pages/MyLeads/utils/table';

export interface IOptions extends IOptionsSelect {
  refundable?: boolean;
}

export interface FilesData {
  filename: string;
  url: string;
  type?: string;
  size?: number;
}

export interface IDataHeader {
  colKey: string;
  title?: string;
  type?: 'number' | 'text' | 'select' | 'badge' | 'quantify';
}

export interface UploadProps {
  name: string;
  progress: number;
}

interface LeadsContextData {
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
  readManagers(mandateId?: string): void;
  reason: IOptions;
  setReason: Dispatch<SetStateAction<IOptions>>;
  reasonFiles: IFileList[];
  setReasonFiles: Dispatch<SetStateAction<IFileList[]>>;
  showLostLeadModal: boolean;
  setShowLostLeadModal: Dispatch<SetStateAction<boolean>>;
  showModalSalesforcePartner: boolean;
  setShowModalSalesforcePartner: Dispatch<SetStateAction<boolean>>;
  showModalAlterStatus: boolean;
  setShowModalAlterStatus: Dispatch<SetStateAction<boolean>>;
  showModalMeetingSchedule: boolean;
  setShowModalMeetingSchedule: Dispatch<SetStateAction<boolean>>;
  showModalRefund: boolean;
  setShowModalRefund: Dispatch<SetStateAction<boolean>>;
  showModalNotifyLoss: boolean;
  setShowModalNotifyLoss: Dispatch<SetStateAction<boolean>>;
  handleMeeting: boolean;
  setHandleMeeting: Dispatch<SetStateAction<boolean>>;
  optionsReasonsManager: IOptionsSelect[];
  setOptionsReasonManager: Dispatch<SetStateAction<IOptionsSelect[]>>;
  refundRequests: Auction[];
  setRefundRequests: Dispatch<SetStateAction<Auction[]>>;
  reasonFileError: string;
  setReasonFileError: Dispatch<SetStateAction<string>>;
  loadingUploadFile: boolean;
  setLoadingUploadFile: Dispatch<SetStateAction<boolean>>;
  filesSent: FilesData[];
  setFilesSent: Dispatch<SetStateAction<FilesData[]>>;
  headers: IDataHeader[];
  setHeaders: Dispatch<SetStateAction<IDataHeader[]>>;
  uploadProgress: UploadProps;
  setUploadProgress: Dispatch<SetStateAction<UploadProps>>;
}

const LeadsContext = createContext({} as LeadsContextData);

type LeadsContextProviderProps = {
  children: ReactNode;
};

export const LeadsProvider: FC<LeadsContextProviderProps> = ({
  children,
}: LeadsContextProviderProps) => {
  const [tab, setTab] = useState('myLeads');
  const [reason, setReason] = useState<IOptions>();
  const [reasonFiles, setReasonFiles] = useState<IFileList[]>();
  const [showLostLeadModal, setShowLostLeadModal] = useState(false);
  const [showModalSalesforcePartner, setShowModalSalesforcePartner] = useState(
    false
  );
  const [showModalAlterStatus, setShowModalAlterStatus] = useState(false);
  const [showModalMeetingSchedule, setShowModalMeetingSchedule] = useState(
    false
  );
  const [showModalNotifyLoss, setShowModalNotifyLoss] = useState(false);
  const [showModalRefund, setShowModalRefund] = useState(false);
  const [handleMeeting, setHandleMeeting] = useState(false);
  const [optionsReasonsManager, setOptionsReasonManager] = useState<
    IOptionsSelect[]
  >([]);

  const [refundRequests, setRefundRequests] = useState({} as Auction[]);
  const [reasonFileError, setReasonFileError] = useState('');
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [filesSent, setFilesSent] = useState<FilesData[]>([]);
  const [headers, setHeaders] = useState(headersMyLeads);
  const [uploadProgress, setUploadProgress] = useState({
    name: '',
    progress: 0,
  });

  const readManagers = useCallback(mandateId => {
    const options: IOptionsSelect[] = [];
    let usersFinded = [];
    getUsersByMandate({ mandatesId: [mandateId || ''] }).then(response => {
      usersFinded = usersFinded.concat(response.users);
      response.users.map(user => {
        options.push({
          key: user._id,
          label: user.name,
          isChecked: false,
        });
        return { key: user._id, label: user.name, isChecked: false };
      });
    });

    setOptionsReasonManager(options);
  }, []);

  return (
    <LeadsContext.Provider
      value={{
        tab,
        setTab,
        readManagers,
        reason,
        setReason,
        reasonFiles,
        setReasonFiles,
        showLostLeadModal,
        setShowLostLeadModal,
        showModalSalesforcePartner,
        setShowModalSalesforcePartner,
        showModalAlterStatus,
        setShowModalAlterStatus,
        showModalMeetingSchedule,
        setShowModalMeetingSchedule,
        showModalRefund,
        setShowModalRefund,
        handleMeeting,
        setHandleMeeting,
        optionsReasonsManager,
        setOptionsReasonManager,
        refundRequests,
        setRefundRequests,
        reasonFileError,
        setReasonFileError,
        loadingUploadFile,
        setLoadingUploadFile,
        filesSent,
        setFilesSent,
        headers,
        setHeaders,
        uploadProgress,
        setUploadProgress,
        showModalNotifyLoss,
        setShowModalNotifyLoss,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};

export const useLeads = (): LeadsContextData => {
  return useContext(LeadsContext);
};
