import React, { InputHTMLAttributes, useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import * as S from './styles';

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  text: string;
  containerStyle?: React.CSSProperties;
}

export function RadioButton({
  name,
  text,
  containerStyle,
  ...rest
}: RadioButtonProps) {
  const radioButtonRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: radioButtonRef.current,
      path: 'value',
    });
  }, [registerField, fieldName]);

  return (
    <S.RadioContainer style={containerStyle}>
      <input
        id={name}
        name={name}
        type="radio"
        {...rest}
        ref={radioButtonRef}
      />
      <span>{text}</span>
    </S.RadioContainer>
  );
}
