import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { LabDsHeader } from 'v4web-components-react';

import Logo from '../../../../assets/mkt-logo.svg';
import { Notification } from '../../../Default/Notification';
import { useAuth } from '../../../../contexts/auth';
import { useBalance } from '../../../../hooks/wallet';

export function Header() {
  const [selectedPage, setSelectedPage] = useState(1);
  const navigate = useNavigate();

  const mktlabUrl = process.env.REACT_APP_MKTLAB_URL;

  const { user, signOut } = useAuth();
  const { updateBalance } = useBalance();
  const pathName = window.location.pathname;

  useEffect(() => {
    if (pathName === '/' && selectedPage !== 1) {
      setSelectedPage(1);
    } else if (pathName === '/meus-leads' && selectedPage !== 2) {
      setSelectedPage(2);
    } else if (pathName === '/unidade' && selectedPage !== 3) {
      setSelectedPage(3);
    } else if (pathName === '/carteira' && selectedPage !== 4) {
      setSelectedPage(4);
    }
  }, [selectedPage, pathName]);

  useEffect(() => {
    updateBalance();
  }, [updateBalance]);

  return (
    <LabDsHeader
      logoHeader={Logo}
      eventMyProfile={() => {
        window.location.href = `${mktlabUrl}/perfil/${user?._id}`;
      }}
      eventLogout={() => {
        signOut();
        navigate('/');
      }}
      user={
        user
          ? {
              name: user?.name,
              imageSRC: user?.picture,
              unitId: user?.unitId,
              id: user?._id,
            }
          : undefined
      }
      eventWallet={() => {
        navigate('/carteira');
      }}
      eventLogo={() => {
        window.location.href = mktlabUrl;
      }}
    >
      <div slot="notifications">
        <Notification />
      </div>
    </LabDsHeader>
  );
}
