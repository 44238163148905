import { format, differenceInDays, differenceInMinutes } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export const formatDate = (date: Date) => {
  const formatedDate = format(new Date(date || Date()), 'dd/MM/yyyy', {
    locale: ptBR,
  }).toString();
  return formatedDate;
};

export const formatDateWithHours = (date: Date) => {
  const formatedDate = format(new Date(date || Date()), 'dd/MM/yyyy kk:mm:ss', {
    locale: ptBR,
  }).toString();
  return formatedDate;
};

export const distanceInDays = (date: Date) => {
  const formatedDate = differenceInDays(
    new Date(),
    new Date(date || Date())
  ).toString();
  return formatedDate;
};

export const distanceInMinutes = (date: Date) => {
  const formatedDate = differenceInMinutes(
    new Date(),
    new Date(date || Date())
  ).toString();
  return formatedDate;
};
