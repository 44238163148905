import { Routes, Route, useLocation } from 'react-router-dom';
import { NotFound } from 'v4components-react';
import BidDetails from '../components/RouteComponents/BidDetails';
import ContractDetails from '../components/RouteComponents/ContractDetails';
import LeadDetails from '../components/RouteComponents/LeadDetails';
import { Home } from '../pages/Home';
import MyLeads from '../pages/MyLeads';
import { Profile } from '../pages/Profile';
import { Unit } from '../pages/Unit';
import { Wallet } from '../pages/Wallet';
import Default from '../styles/layouts/default';
import NotFoundImg from '../assets/not-found.svg';
import BidPackDetails from '../components/RouteComponents/BidPackDetails';
import { HeadquarterWalletsReview } from '../pages/HeadquarterWalletsReview';
import { Extract } from '../pages/Extract';

export default function LoggedInRoutes() {
  const location = useLocation();

  const state = location.state as { backgroundLocation?: Location };

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<Default />}>
          <Route index element={<Home />} />
          <Route path="/meus-leads" element={<MyLeads />} />
          <Route path="/unidades" element={<Unit />} />
          <Route path="/carteira" element={<Wallet />} />
          <Route path="/carteira/:depositmb" element={<Wallet />} />
          <Route path="/perfil" element={<Profile />} />
          <Route path="/bid/:id" element={<BidDetails />} />
          <Route path="/bid/pack/:id" element={<BidPackDetails />} />
          <Route path="/lead/:id" element={<LeadDetails />} />
          <Route path="/refund/:id" element={<LeadDetails />} />
          <Route path="/contract/:id" element={<ContractDetails />} />
          <Route
            path="/headquarter/carteira"
            element={<HeadquarterWalletsReview />}
          />
          <Route path="/extract" element={<Extract />} />
          <Route path="*" element={<NotFound img={NotFoundImg} />} />
        </Route>
      </Routes>

      {state?.backgroundLocation && (
        <Routes>
          <Route path="/bid/:id" element={<BidDetails />} />
          <Route path="/bid/pack/:id" element={<BidPackDetails />} />
          <Route path="/lead/:id" element={<LeadDetails />} />
          <Route path="/refund/:id" element={<LeadDetails />} />
          <Route path="/contract/:id" element={<ContractDetails />} />
          <Route path="*" element={<NotFound img={NotFoundImg} />} />
        </Routes>
      )}
    </>
  );
}
