import { useEffect, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import BidCard from '../../../Default/BidCard';
import BidCardPack from '../../../Default/BidCardPack';
import { CustomLoading } from '../../../Default/Loadings/CustomLoading';
import { UnHappyPath } from '../../atoms/UnHappyPath';
import * as S from './styles';
import { useAuctions } from '../../../../contexts';
import { getAuctionsAllFederation } from '../../../../services/requests/leadbroker/auctions/getAuctionsFederation';

export const AuctionAllContainer = ({
  isLastVisible,
}: {
  isLastVisible: boolean;
}) => {
  const { expPurchasedAuctions, actualTab } = useAuctions();
  const [orderData, setOrderData] = useState<AuctionAll[] | null>(null);

  const { data, fetchNextPage, isLoading, refetch } = useInfiniteQuery({
    queryKey: ['all-auctions'],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const response = ((await getAuctionsAllFederation(
        pageParam
      )) as unknown) as AuctionAllResponse;

      return response;
    },
    getNextPageParam: lastPage => {
      return lastPage.data?.length > 0
        ? Number(lastPage?.page || 0) + 1
        : undefined;
    },
  });

  useEffect(() => {
    if (isLastVisible) {
      fetchNextPage();
    }
  }, [isLastVisible, fetchNextPage]);

  useEffect(() => {
    refetch();
  }, [refetch, actualTab]);

  useEffect(() => {
    if (data?.pages) {
      const allData: AuctionAll[] = data?.pages?.map(page => page.data).flat();
      setOrderData(
        allData?.sort((a, b) => (a.expiresAt < b.expiresAt ? -1 : 1))
      );
    }
  }, [data]);

  return (
    <>
      <S.Container>
        <S.Bids>
          {expPurchasedAuctions &&
            expPurchasedAuctions.map(auction => {
              if (auction?.leads) {
                return (
                  <BidCardPack
                    pack={auction as AuctionPack}
                    key={auction._id}
                    auction={auction.leads[0] as Auction}
                    pack_id={auction._id}
                    hash={auction.packSequencial.toString()}
                    expressPurchased
                  />
                );
              }
              return (
                <BidCard
                  key={auction._id}
                  auction={auction as Auction}
                  expressPurchased
                />
              );
            })}
          {orderData?.map(auction => {
            return (
              <>
                {auction?.lead ? (
                  <BidCard
                    key={auction._id}
                    auction={auction as Auction}
                    deleteModal={false}
                  />
                ) : (
                  auction?.leads && (
                    <BidCardPack
                      pack={auction as AuctionPack}
                      key={auction._id}
                      auction={auction?.leads[0] as Auction}
                      pack_id={auction._id}
                      hash={auction.packSequencial.toString()}
                    />
                  )
                )}
              </>
            );
          })}
        </S.Bids>
        {!isLoading &&
          data?.pages[0]?.data?.length === 0 &&
          !expPurchasedAuctions?.length && (
            <S.UnhappyPath>
              <UnHappyPath
                title="Ofertas ainda não disponíveis"
                assetIndex={0}
              />
            </S.UnhappyPath>
          )}
      </S.Container>
      {isLoading && <CustomLoading />}
    </>
  );
};
