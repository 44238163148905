import styled from 'styled-components';

import SearchIC from '../../../assets/search.svg';

export const Select = styled.select`
  display: flex;
  color: var(--secondary-light);
  padding: 1rem 1.5rem;
  border: 1px solid var(--tertiary-main);
  border: 1px solid var(--tertiary-main);
  border-radius: 0.5rem;
  box-sizing: border-box;
  background: transparent;

  option {
    color: var(--black);
  }
`;

export const Search = styled.input`
  display: flex;
  color: var(--secondary-light);
  padding: 1rem 1.5rem;
  border: 1px solid var(--tertiary-main);
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: url(${SearchIC}) no-repeat;
  background-position: center right 10px;
  background-size: 18px;

  &::placeholder {
    color: var(--secondary-light);
  }
`;

interface buttonProps {
  width: string;
  height: string;
  isPrimary: boolean;
}

export const Button = styled.button<buttonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props =>
    props.isPrimary ? 'var(--primary-light)' : 'transparent'};
  border: 1px solid var(--secondary-main);
  box-sizing: border-box;
  border-radius: 8px;
  color: ${props =>
    props.isPrimary ? 'var(--white)' : 'var(--primary-light)'};
  :hover {
    background-color: ${props =>
      !props.isPrimary ? 'var(--primary-light)' : 'transparent'};
    color: ${props =>
      !props.isPrimary ? 'var(--white)' : 'var(--primary-light)'};
  }
`;

export const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 34px;
  height: 14px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.33);

  position: relative;
  transition: background-color 0.2s;
  ${SwitchInput}:checked + & {
    background-color: var(--primary-dark-2);
  }
`;

export const SwitchButton = styled.span`
  content: '';
  position: absolute;
  left: -2px;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% + 2px);
    transform: translateX(-100%);
    background: var(--primary-main);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }

  ${SwitchLabel}:active & {
    width: 45px;
  }
`;
