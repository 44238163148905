import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SectionTitle } from 'v4components-react';
import { LabDsButton, LabDsTextField } from 'v4web-components-react';
import { schema } from './utils/schema';

import * as S from './styles';
import { useDeposit } from '../../../../contexts';
import { useAuth } from '../../../../contexts/auth';

interface PayerProps {
  name: string;
  cpfCnpj: string;
  phone?: string;
  email: string;
}

export function Payer() {
  const { user } = useAuth();

  const { setStageDeposit, creditCard } = useDeposit();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<PayerProps>({
    defaultValues: {
      name: user.name,
      email: user.email,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<PayerProps> = async data => {
    creditCard.setPayer(data);
    setStageDeposit('card');
  };

  return (
    <S.Container
      placeholder=""
      onSubmit={handleSubmit(onSubmit)}
      onPointerEnterCapture={() => null}
      onPointerLeaveCapture={() => null}
    >
      <section>
        <SectionTitle text="Dados do proprietário do cartão" />

        <S.Row>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="48%">
                <LabDsTextField
                  {...field}
                  titleInput="Nome completo*"
                  helperText={errors.name && errors.name.message}
                  state={errors.name ? 'error' : 'default'}
                  type="text"
                  label="Digite seu nome completo"
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="48%">
                <LabDsTextField
                  {...field}
                  titleInput="E-mail*"
                  helperText={errors.email && errors.email.message}
                  state={errors.email ? 'error' : 'default'}
                  label="Digite seu e-mail"
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />
        </S.Row>

        <S.Row>
          <Controller
            name="cpfCnpj"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="45%">
                <LabDsTextField
                  {...field}
                  helperText={errors.cpfCnpj && errors.cpfCnpj.message}
                  state={errors.cpfCnpj ? 'error' : 'default'}
                  type="text"
                  label="Digite seu CPF ou CNPJ"
                  mask={watch('cpfCnpj')?.length < 11 ? 'CPF' : 'CNPJ'}
                  titleInput="CPF ou CNPJ*"
                  maxLength={18}
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <S.InputContent maxWidth="48%">
                <LabDsTextField
                  {...field}
                  className="complement"
                  titleInput="Telefone"
                  helperText={errors.phone && errors.phone.message}
                  state={errors.phone ? 'error' : 'default'}
                  label="(00) 00000-0000"
                  mask="phone"
                  type="text"
                  maxLength={14}
                  onChangeInput={({ detail }) => {
                    field.onChange(detail);
                  }}
                />
              </S.InputContent>
            )}
          />
        </S.Row>
      </section>

      <S.Footer>
        <LabDsButton
          label="Próximo"
          size="medium"
          variant="primary"
          color="green"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        />
      </S.Footer>
    </S.Container>
  );
}
