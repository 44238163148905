import { formatDate } from '../../../../utils/dateFunctions';
import { Tooltippy } from '../../atoms/Tooltippy';
import * as S from './styles';

interface HistoricProps {
  events: Historic[];
}

export function Historic({ events }: HistoricProps) {
  return (
    <S.Container>
      <S.Title>Histórico</S.Title>
      <S.EventsWrapper>
        {events
          .slice(0)
          .reverse()
          .map((event: Historic) => (
            <S.Event key={event.id}>
              <S.EventDate>{formatDate(event.updatedAt)}</S.EventDate>
              <S.EventMessage
                className={
                  event?.status !== undefined ? event.status.toLowerCase() : ''
                }
              >
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: event.message || event.body,
                  }}
                />
                {event.description && <Tooltippy message={event.description} />}
              </S.EventMessage>
            </S.Event>
          ))}
      </S.EventsWrapper>
    </S.Container>
  );
}
