import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useAuth } from '../../contexts/auth';
import api from '../../services/requests/leadbroker/api';

import * as S from './styles';
import { convertCentsToBRL } from '../../utils/convertCentsToBRL';

interface Invoice {
  iuguId: string;
  status: string;
  date: string;
  price: string;
  user: {
    email: string;
    picture: string;
    name: string;
  };
}

export function HeadquarterWalletsReview() {
  const { user } = useAuth();
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const getInvoicesList = useCallback(async () => {
    const invoicesRequest = await api.get<Invoice[]>('/invoices/list');
    setInvoices(invoicesRequest.data);
  }, []);

  const formatDate = useCallback((date: string) => {
    try {
      return format(new Date(date), 'dd/MM/yyyy');
    } catch (err) {
      return 'erro na data';
    }
  }, []);

  useEffect(() => {
    getInvoicesList();
  }, [getInvoicesList]);

  return (
    <S.Container>
      <h1>Pagamentos leadbroker</h1>{' '}
      <S.Table>
        <S.Row>
          <S.Field className="transaction-author">Autor da transação</S.Field>
          <S.Field className="transaction-author-name">Nome do Autor</S.Field>
          <S.Field className="transaction-author-email">Email do Autor</S.Field>
          <S.Field className="transaction-status">Status</S.Field>
          <S.Field className="transaction-id">ID da transação</S.Field>
          <S.Field className="transaction-date">Data da transação</S.Field>
          <S.Field className="transaction-value">Valor</S.Field>
        </S.Row>
      </S.Table>
      {invoices.map(invoice => {
        return (
          <>
            <S.RowBody
              className=""
              invoiceColor={
                invoice.status === 'paid'
                  ? 'var(--success-1)'
                  : 'var(--primary-main)'
              }
            >
              <S.Field className="transaction-author">
                {' '}
                {user.picture ? (
                  <section>
                    <img src={invoice.user.picture} alt={user.name} />
                  </section>
                ) : (
                  <p>Sem foto</p>
                )}
              </S.Field>
              <S.Field className="transaction-author-name">
                {invoice.user.name || 'Indefinido'}
              </S.Field>
              <S.Field className="transaction-author-email">
                {invoice.user.email || 'Indefinido'}
              </S.Field>
              <S.Field className="transaction-status">{invoice.status}</S.Field>
              <S.Field className="transaction-id">{invoice.iuguId}</S.Field>
              <S.Field className="transaction-date">
                {formatDate(invoice?.date)}
              </S.Field>
              <S.Field className="transaction-value">
                {convertCentsToBRL(Number(invoice.price))}
              </S.Field>
            </S.RowBody>
          </>
        );
      })}
    </S.Container>
  );
}
