import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface valueProps {
  color?: string;
  refurbished?: boolean;
}

interface ContainerProps {
  hide?: boolean;
  deleted?: boolean;
  refurbished?: boolean;
  bids?: number;
  expressPurchased?: boolean;
  deleteModal?: boolean;
}

interface TimeProps extends React.HTMLAttributes<HTMLSpanElement> {
  updateToTimeUpdateColor?: boolean;
  refurbished?: boolean;
}

export const Icon = styled.img`
  width: 1rem;
`;

export const PriceContent = styled.div<{
  refurbished?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ refurbished }) =>
    refurbished &&
    css`
      display: grid;
    `}
`;

export const OldPriceContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TimeContent = styled.div<TimeProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h3 {
    font-size: 1.365rem;
  }

  ${({ refurbished }) =>
    refurbished &&
    css`
      margin-top: 0.3rem;
    `}
`;

export const HeaderCard = styled.div`
  width: 100%;
  height: 4rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.7rem;

  .flag {
    position: relative;
    right: 1.5rem;
    top: -1.25rem;
    width: 2.75rem;
    height: 2.875rem;
    background: var(--tertiary-main);
    border: 0.8px solid var(--secondary-light);
    border-radius: 0 0 0.25rem 0.25rem;
    p {
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      margin-top: 8px;
    }
  }
`;

export const TitleAndCreatedAtContainer = styled.div`
  display: grid;
`;

export const TitleLeadCompany = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const CardTitle = styled.span`
  font-size: 22px;
  font-weight: 700;
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--secondary-main);
`;

export const Container = styled(Link)<ContainerProps>`
  position: relative;
  width: 23rem;
  max-width: 23rem;
  min-height: 13.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--success-3);
  border-radius: 16px;
  cursor: pointer;
  text-decoration: none;
  color: unset;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px 1px rgba(0, 0, 0, 0.06);

  .created-at {
    font-size: 11px;
    font-weight: 700;
    font-family: 'Nunito Sans';
    font-style: normal;
    span {
      color: var(--tertiary-light);
      text-transform: uppercase;
    }
  }

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s 2s, opacity 2s linear;
    `}

  ${({ deleted, deleteModal }) =>
    deleted && deleteModal
      ? css`
          display: none;
        `
      : css`
          transition: 300ms;
        `}

  &:hover {
    background-color: var(--grayscale-2);

    ${({ deleteModal }) =>
      deleteModal &&
      css`
        background-color: none;
      `}

    ${({ expressPurchased }) =>
      expressPurchased &&
      css`
        transform: scale(1.035);
      `}
  }

  ${({ deleteModal }) =>
    deleteModal &&
    css`
      pointer-events: none;
      cursor: default;
    `}

  ${({ expressPurchased }) =>
    !expressPurchased &&
    css`
      pointer-events: none;
      cursor: default;
    `}

  ${({ refurbished }) =>
    refurbished &&
    css`
      border-width: 1px;
      border-bottom: 0;
      border-color: var(--primary-main);
    `}

  .leadEmail {
    display: flex;
    margin-bottom: 0.5rem;
  }
`;

export const TopContent = styled.div`
  padding: 1rem 1rem 0 1rem;

  .rowContainer {
    align-items: flex-end;
    margin-top: 0.5rem;
    margin-left: 0.1rem;

    &:last-child {
      margin-bottom: 1rem;
    }
  }
`;

export const SaveBtn = styled.button`
  background-color: transparent;
  border: none;

  z-index: 1;
`;

export const Row = styled.div<{ newPriceAnimation?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .containerRow {
    align-items: flex-end;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 0.1rem;
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12rem;
  }

  strong {
    color: var(--secondary-main);
    font-size: 0.83rem;
  }

  span {
    font-size: 0.83rem;
    font-weight: lighter;
    color: var(--secondary-main);
  }
  a {
    text-decoration: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const CardFooter = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  height: 3.44rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  border: 1px solid var(--success-3);
  border-bottom: 0;
  padding: 0.4rem 1.3rem 0 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  bottom: 0;

  ${({ refurbished }) =>
    refurbished &&
    css`
      border: 1px solid var(--primary-main);
      padding: 0 1.3rem 0 1.3rem;
    `}

  h4 {
    font-size: 1.125rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12rem;
  }

  strong {
    color: var(--secondary-main);
    font-size: 14px;

    span {
      font-size: 12px;
    }
  }

  span {
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    transition: 0.4s;
    transition-timing-function: ease-in;
  }

  .true {
    transition: 0.4s;
    transition-timing-function: ease-in;
    transform: translateY(1.25rem);
  }
`;

export const Value = styled.div<valueProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: var(--secondary-main);
  font-weight: 700;
  font-size: 21px;
`;

export const OldValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: var(--secondary-light);
  font-weight: 400;
  line-height: 24px;
  font-size: 15px;
  text-decoration: line-through;
`;

export const Status = styled.span<valueProps>`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.6rem;
  margin: 0;
  margin-bottom: 0.5rem;
  height: 24px;
  background-color: transparent;

  span{
    padding: 4px 16px;
    font-size: 0.7rem;
    font-weight: 700;
    color: var(--secondary-main);
  }

  ${({ color }) =>
    color === `yellow` &&
    css`
      border: 2px solid var(--warning);
    `}

  ${({ color }) =>
    color === `red` &&
    css`
      border: 2px solid var(--error);
    `}
  ${({ color }) =>
    color === `green` &&
    css`
      border: 2px solid var(--success-3);
    `}
`;

export const SeeMore = styled.button`
  display: flex;
  align-items: center;
  color: var(--secondary-dark);
  border: none;
  background: transparent;

  svg {
    font-size: 1.25rem;
  }
`;

export const Time = styled.span<TimeProps>`
  transition: 0.4s;
  color: var(--primary-main);
  margin-left: 0.1rem;
`;

export const CreatedAt = styled.strong<ContainerProps>`
  background: ${({ refurbished }) =>
    refurbished ? 'var(--primary-main)' : 'var(--success-3)'};
  width: max-content;
  padding: 1rem;
  height: 100%;
  align-items: center;
  display: flex;
  color: var(--tertiary-light);
  border-radius: 2px 0px 0px 2px;
  font-size: 14px;

  span {
    color: var(--tertiary-light);
  }

  p {
    font-size: 12px;
    padding-top: 3px;
    font-weight: 500;
  }
`;

export const TooltipFixe = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  border: 1px solid var(--success-3);
  border-radius: 4px;
  height: 2.2rem;
  background-color: var(--white);

  ${({ refurbished }) =>
    refurbished &&
    css`
      border: 1px solid var(--primary-main);
    `}

  ${({ bids }) =>
    !bids &&
    css`
      width: max-content;
    `}
`;

export const HeaderCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseIcon = styled.div`
  display: flex;
  align-items: center;
  border: none;
  justify-content: flex-end;
  width: 2.5rem;
  height: 100%;
  border-radius: 4px;
  font-size: 1.25rem;
`;

export const TooltipArrowLeft = styled.div<ContainerProps>`
  margin-left: 0rem;
  width: max-content;
  height: 100%;
  align-items: center;
  display: flex;
  padding: 1rem;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-family: 'Nunito Sans';
  color: var(--secondary-main);

  :before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    top: 0rem;
    right: 0;
    left: -1.2rem;
    bottom: -20.54rem;
    transform: rotate(225deg);
    border-color: var(--grayscale-2) var(--grayscale-2) transparent transparent;
    background-color: var(--white);
    position: relative;
    margin-right: -5px;
  }

  ${({ refurbished }) =>
    refurbished &&
    css`
      color: var(--primary-main);
    `}
`;

export const LeadEmail = styled.strong`
  font-size: 12px;
  font-weight: 400;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ExpPurchaseOuterContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem;
  pointer-events: none;
`;

export const ExpPurchaseInnerContainer = styled.div`
  background-color: var(--error);
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Nunito Sans';
    color: var(--tertiary-light);
  }
`;

export const MarketingProduct = styled.p`
  text-align: right;
  font-weight: 600;
  font-size: 0.75rem;
`;
