import styled from 'styled-components';

export const ContentTabs = styled.div`
  width: 20rem;
  margin-bottom: 2rem;
`;

export const ContainerFilter = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 2rem;
  white-space: nowrap;
  scrollbar-color: transparent transparent;
`;

export const FilterSelect = styled.div<{ isSelected: boolean }>`
  border-radius: 2rem;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: var(--secondary-dark);
  background-color: ${({ isSelected }) =>
    isSelected ? 'var(--success-2)' : 'transparent'};
  cursor: pointer;
  border: 0.063rem solid var(--grayscale-6);
  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? 'var(--success-2)' : 'var(--tertiary-main)'};
  }
`;

export const Pagination = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;

  svg {
    font-size: 0.625rem;
    color: var(--lab-ds-semantic-color-fg-default);
    margin: 0 0.25rem;
    cursor: pointer;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.32rem;
    text-decoration: none;
    color: var(--lab-ds-semantic-selectable-color-secondary-default);
    background-color: transparent;
    font-size: 0.75rem;
    border: none;

    &.isActive {
      background-color: var(--lab-ds-semantic-selectable-color-primary-default);
      color: var(--lab-ds-semantic-selectable-color-bg-pure);
    }
  }

  button + button {
    margin-left: 0.2rem;
  }
`;
