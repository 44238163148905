import styled, { keyframes } from 'styled-components';

const loadingSpinner = keyframes`
 0%{
    transform:rotate(0deg)
  }
  100%{
    transform:rotate(360deg)
  }
`;

export const Container = styled.div<{
  backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    width: 6.5rem;
    height: 6.5rem;
    border: 0.4rem solid var(--success-2);
    border-radius: 50%;
    border-top-color: var(--tertiary-main);
    display: inline-block;
    animation: ${loadingSpinner} 0.7s linear infinite;
  }
`;
export const Spinner = styled.div`
  width: 6.5rem;
  height: 6.5rem;
  border: 0.4rem solid var(--success-2);
  border-radius: 50%;
  border-top-color: var(--tertiary-main);
  display: inline-block;
  animation: ${loadingSpinner} 0.7s linear infinite;
`;
