import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  > section {
    display: block;
    justify-content: space-between;
    font-family: 'Inter';
    margin: 2.5rem;
    height: 37rem;

    h1 {
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  small {
    color: var(--primary-main);
    bottom: 0;
    margin: 0.25rem;
  }
`;

export const InputContent = styled.div<{ maxWidth: string }>`
  width: ${({ maxWidth }) => maxWidth};
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  svg {
    width: 2.91669rem;
    height: 2rem;
  }

  .card-type-icon {
    position: absolute;
    right: 39.5%;
    top: 68%;
    transform: translateY(-120%);

    width: 1.875rem;
    height: 1.1875rem;
    flex-shrink: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HiddenContainer = styled.div`
  position: relative;
  top: 1.125rem;
  right: 7.1875rem;
  width: 0.0625rem;
  height: 0.0625rem;
  overflow: visible;
  white-space: nowrap;
`;

export const MaskContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  gap: 2rem;
  .error {
    border: 0.0625rem solid var(--error);
  }

  p {
    color: var(--secondary-main);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    padding-top: 0.375rem;
  }

  span {
    color: var(--error);
    font-family: Nunito Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }

  .complement {
    width: 12.25rem;
  }
`;

export const DateContainer = styled.div`
  justify-content: flex-start;
  flex-direction: column;
  display: flex;
  width: 6.875rem;
`;

export const MonthContainer = styled.div`
  .mes {
    width: 10.125rem;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 48%;
  p {
    font-weight: 700;
    font-size: 1rem;
    color: var(--secondary-main);
    font-family: 'Inter', 'Nunito Sans';
  }
`;

export const MainContainerData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 1rem;
`;

export const YearContainer = styled.div`
  width: 6.875rem;
`;

export const InputPairContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  width: 16.25rem;
  justify-content: flex-start;
  color: var(--secondary-main);
  background: var(--white);
  flex-direction: row;

  .error {
    border: 0.0625rem solid red;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const Codigo = styled.div`
  display: flex;
  color: var(--secondary-main);
  background: var(--white);
  flex-direction: column;
  margin-top: 0.78rem;
  margin-left: 1.1rem;
  width: 12.25rem;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const Footer = styled.footer`
  padding: 1rem;
`;
