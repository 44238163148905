import styled, { css } from 'styled-components';

import { Tooltip } from '../../Tooltip';

interface InputContainerProps {
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
}

export const Container = styled.div<InputContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: var(--secondary-main);

    h4 {
      text-transform: uppercase;
    }
  }

  p {
    font-size: 0.875rem;
    color: var(--grayscale-20);
    margin-bottom: 0.5rem;
  }

  small {
    color: var(--primary-main);
    bottom: 0;
    margin: 0.25rem;
  }

  ${props =>
    props.isErrored &&
    css`
      label {
        color: var(--primary-main);
      }
      border-color: var(--error);
    `}

  ${props =>
    props.isFilled &&
    css`
      label {
        color: var(--black);
      }
      border-color: var(--tertiary-dark);
    `}
`;

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  background: var(--white);
  border: 2px solid var(--grayscale-4);
  color: var(--secondary-light);
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  transition: 0.5s;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.04));

  ${props =>
    props.isErrored &&
    css`
      label {
        color: var(--primary-main);
      }
      border-color: var(--error);
    `}

  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: var(--secondary-light);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--grayscale-10);
    }

    &:focus {
      outline: 0;
    }
  }

  & + div {
    margin-top: 1rem;
  }

  svg {
    margin-left: 1rem;
  }

  p {
    margin-right: 0.5rem;
    color: var(--white);
  }

  ${props =>
    props.isErrored &&
    css`
      color: var(--error);
      border-color: var(--error);
    `}

  input:-webkit-autofill::first-line,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px var(--white) inset !important;
    -webkit-text-fill-color: var(--secondary-light) !important;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }
`;

export const Error = styled(Tooltip)`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  svg {
    margin: 0;
  }

  span {
    background: var(--primary-main);
    color: var(--white);

    &::before {
      border-color: var(--primary-main) transparent;
    }
  }
`;
