import { useEffect, useState } from 'react';
import { Button } from '../../../Default/Button';

import * as S from './styles';

export function CookiesAdvice() {
  const [isVisible, setIsVisible] = useState(false);

  function handleAccept() {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  }

  function handleDecline() {
    localStorage.setItem('cookiesAccepted', 'false');
    setIsVisible(false);
  }

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');

    if (cookiesAccepted) return setIsVisible(false);

    return setIsVisible(true);
  }, []);

  return isVisible ? (
    <S.Container>
      <section>
        <h3>Esta plataforma utiliza Cookies!</h3>

        <p>
          Armazenamos dados para melhorar a experiência de navegação na
          plataforma. Ao aceitar, você concorda com a nossa política de
          privacidade.
        </p>

        <a
          href="https://v4company.com/politica-de-privacidade/"
          target="_blank"
          rel="noreferrer"
        >
          Acesse a nossa Política de Privacidade
        </a>
      </section>

      <S.Buttons>
        <Button onClick={() => handleAccept()}>Aceitar todos os Cookies</Button>
        <Button secondary onClick={() => handleDecline()}>
          Rejeitar todos os Cookies
        </Button>
      </S.Buttons>
    </S.Container>
  ) : (
    <></>
  );
}
