import { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  small?: boolean;
  secondary?: boolean;
  outline?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

export function Button({
  children,
  loading,
  small = false,
  secondary = false,
  outline = false,
  type = 'button',
  ...rest
}: ButtonProps) {
  return (
    <Container
      small={small}
      secondary={secondary}
      outline={outline}
      type={type}
      {...rest}
    >
      {loading ? 'Carregando...' : children}
    </Container>
  );
}
