import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  LabDsButton,
  LabDsTextField,
  LabDsSelect,
  LabDsTextArea,
  LabDsIconSelectable,
} from 'v4web-components-react';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BiCoin } from 'react-icons/bi';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { FaRegCheckCircle } from 'react-icons/fa';

import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import * as S from './styles';
import { ModalLoading } from '../../Default/Loadings/ModalLoading';
import { formatDate, distanceInDays } from '../../../utils/dateFunctions';
import { convertCentsToBRL } from '../../../utils/convertCentsToBRL';
import { Historic } from '../../AtomicDesign/molecules/Historic';
import { ProgressLead } from './components/ProgressLead';
import { useToast } from '../../../contexts/toast';
import { options, steps, stepsProps } from './utils';
import { UploadFiles } from './components/UploadFiles';
import { ModalAlterStatus } from './components/ModalAlterStatus';
import { ModalMeetingSchedule } from './components/ModalMeetingSchedule';
import { ModalSalesforcePartner } from './components/ModalSalesforcePartner';
import { ModalRefund } from './components/ModalRefund';
import { useLeads } from '../../../contexts';
import { postRefund } from '../../../services/requests/leadbroker/auctions/refound';
import {
  getLead,
  getLeadRefund,
} from '../../../services/requests/leadbroker/auctions/leads';
import { errorsValidate } from './utils/errorsValidate';
import { updateStep } from '../../../services/requests/leadbroker/auctions/updateSteps';
import { ModalNotifyLoss } from './components/ModalNotifyLoss';
import { headersRefund } from '../../../pages/MyLeads/utils/table';

export interface ReasonData {
  refundReason: string;
  reasonManager: string;
  reasonPhone: string;
  refundJustification: string;
  automaticReject: boolean;
}

const schema = Yup.object().shape({
  refundReason: Yup.string().required('Informe o motivo da perda'),
  reasonManager: Yup.object()
    .required('Informe o gestor matriz')
    .shape({
      _id: Yup.string(),
      name: Yup.string(),
    }),
  reasonPhone: Yup.string()
    .required('Informe o telefone para contato')
    .min(10, 'Número deve ser conter no minimo 10 caracteres'),
  refundJustification: Yup.string().required(
    'Informe a descrição da negociação'
  ),
  automaticReject: Yup.boolean(),
});

const LeadDetails: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const {
    setReason,
    reasonFiles,
    setReasonFiles,
    filesSent,
    setFilesSent,
    showLostLeadModal,
    setShowLostLeadModal,
    showModalSalesforcePartner,
    setShowModalSalesforcePartner,
    showModalAlterStatus,
    setShowModalAlterStatus,
    showModalMeetingSchedule,
    setShowModalMeetingSchedule,
    showModalRefund,
    setShowModalRefund,
    setShowModalNotifyLoss,
    showModalNotifyLoss,
    handleMeeting,
    setHandleMeeting,
    optionsReasonsManager,
    setReasonFileError,
    loadingUploadFile,
    refundRequests,
    tab,
    setTab,
    setHeaders,
    reasonFileError,
  } = useLeads();

  const [lead, setLead] = useState({} as Auction);
  const [meetingDate, setMeetingDate] = useState('');
  const [meetingHour, setMeetingHour] = useState('');
  const [salesForcePartner] = useState(false); // will be reactivated soon
  const [refundRequired] = useState(false);
  const [refundSent, setRefundSent] = useState(false);
  const { addToast } = useToast();
  const [refund, setRefund] = useState({} as Auction);

  const { readManagers, setUploadProgress } = useLeads();
  const mandateId = process.env.REACT_APP_MANAGER_MANDATE_ID;

  const [stepActive, setStepActive] = useState<stepsProps>({
    label: 'Lead comprado',
    buttonLabel: 'Informar Prospecção',
    buttonEnabled: true,
    step: 1,
    event: () => {
      setShowModalAlterStatus(true);
    },
  });

  const [isShowLostForm, setIsShowLostForm] = useState(false);
  const { id } = useParams<'id'>();
  const myBidsNavigate = '/meus-leads';
  const navigate = useNavigate();
  const toastContext = useToast();
  const location = useLocation();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [optionsReasons] = useState(options);

  const onDismiss = useCallback(() => {
    setReasonFiles([]);
    if (location.state === null) {
      return navigate(myBidsNavigate);
    }

    return navigate(-1);
  }, [location.state, navigate, setReasonFiles]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<ReasonData>({
    resolver: yupResolver(schema),
  });

  const expiredDeadline =
    Number(distanceInDays(lead?.winner?.boughtAt || lead?.expiresAt)) > 90;

  const onSubmit = useCallback(
    async (data: ReasonData) => {
      try {
        if (!reasonFiles?.length || reasonFiles.length < 1) {
          setReasonFileError('Adicione provas para o reembolso');
          return;
        }

        if (expiredDeadline) {
          setShowModalRefund(false);
          setIsShowLostForm(false);
          onDismiss();
          setTab('refundRequests');
          setHeaders(headersRefund);
          return;
        }

        const leadInformation = {
          depositAmount: lead.winner.realValue || 0,
          bonusAmount: lead.winner.bonus,
          refundItem: {
            auctionId: lead._id,
            externalId: lead.externalId,
            packId: lead.packId,
            leadName: lead.lead.company,
            purchasedType: lead.packId ? 'Pack' : 'Lead',
            purchasedAt: lead.winner.boughtAt,
          },
          purchasedAt: lead.winner.boughtAt,
        };

        setLoading(true);
        await schema.validate(data, {
          abortEarly: false,
        });

        if (!reasonFiles?.length || reasonFiles === undefined) {
          setReasonFileError('Adicione arquivos para prova do reembolso');
        }

        if (data) {
          const registered = await postRefund(data, leadInformation, filesSent);

          if (registered.success) {
            setShowModalRefund(true);
            setRefundSent(true);
            setReasonFiles([]);
            setFilesSent([]);
            setShowModalRefund(false);
            setIsShowLostForm(false);
            onDismiss();
            setTab('refundRequests');
            setHeaders(headersRefund);
          } else {
            setUploadProgress({ name: '', progress: 100 });
            toastContext.addToast({
              type: 'error',
              description:
                errorsValidate[
                  registered?.message || registered?.message?.title
                ],
            });
          }
        }

        setLoading(false);
      } catch (err) {
        toastContext.addToast({
          type: 'error',
          description: err.message,
        });
      }
    },
    [
      expiredDeadline,
      filesSent,
      lead,
      onDismiss,
      reasonFiles,
      setFilesSent,
      setHeaders,
      setReasonFileError,
      setReasonFiles,
      setShowModalRefund,
      setTab,
      setUploadProgress,
      toastContext,
    ]
  );

  const handleValidateRefundable = useCallback(() => {
    if (expiredDeadline || getValues()?.automaticReject) {
      setShowModalRefund(true);
      return;
    }

    handleSubmit(onSubmit)();
  }, [expiredDeadline, getValues, handleSubmit, onSubmit, setShowModalRefund]);

  const setLeadDetailInfo = useCallback(async () => {
    setLoading(true);

    if (location.pathname.includes('lead')) {
      getLead(id).then(newMyLeads => {
        if (newMyLeads) {
          setLead(newMyLeads);
        }
        if (newMyLeads?.message === 'Lead not found.') {
          toastContext.addToast({
            type: 'error',
            description: 'Lead não encontrado',
          });
        }
      });
    }
    if (location.pathname.includes('refund')) {
      if (refundRequests) {
        const newRefunds = await getLeadRefund(id);
        setRefund(newRefunds);
      }
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refundRequests, tab]);

  const handleUpdateStep = useCallback(
    async (body: UpdateStep) => {
      const sendStatus: UpdateStep = {
        id: lead._id,
        step: body.step,
      };

      const response = await updateStep(sendStatus);

      if (!response) {
        addToast({
          type: 'error',
          description: 'Não foi possivel atualizar o status',
        });

        return;
      }
      setLeadDetailInfo();
    },
    [addToast, lead._id, setLeadDetailInfo]
  );

  const step = steps({
    setShowModalAlterStatus,
    setShowModalMeetingSchedule,
    setHandleMeeting,
    handleUpdateStep,
    leadId: lead.salesforceId,
  });

  const updateStepActive = useCallback(() => {
    step?.forEach((item, index) => {
      if (lead?.steps === item.key) {
        setStepActive(step[index]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  const handleNotifyLoss = useCallback(
    async (notifyLoss, description) => {
      const sendStatus: UpdateStep = {
        id: lead._id,
        step: 'LOST_LEAD',
        lostReason: notifyLoss === 'Outro' ? description : notifyLoss,
      };
      const response = await updateStep(sendStatus);
      if (!response) {
        addToast({
          type: 'error',
          description: 'Não foi possivel atualizar o status',
        });

        return;
      }
      setLeadDetailInfo();
      setShowModalNotifyLoss(false);
    },
    [addToast, lead._id, setLeadDetailInfo, setShowModalNotifyLoss]
  );

  useEffect(() => {
    updateStepActive();
  }, [updateStepActive]);

  useEffect(() => {
    setLeadDetailInfo();
  }, [setLeadDetailInfo]);

  const handleRequestContract = useCallback(() => {
    setStepActive(step[3]);
    setHandleMeeting(false);
  }, [setHandleMeeting, step]);

  useEffect(() => {
    if (handleMeeting) {
      handleRequestContract();
    }
  }, [handleMeeting, handleRequestContract]);

  useEffect(() => {
    if (isShowLostForm && mandateId) {
      readManagers(mandateId);
    }
  }, [isShowLostForm, mandateId, readManagers]);

  const goBack = () => {
    setIsShowLostForm(false);
    setReasonFiles([]);
    setReasonFileError('');
    reset();
  };

  const totalLeadValue = lead?.winner?.realValue + lead?.winner?.bonus;
  const totalRefundValue = refund?.winner?.realValue + refund?.winner?.bonus;
  const hasBonus = lead?.winner?.bonus || refund.winner?.bonus || 0;

  return (
    <Dialog
      aria-labelledby="label"
      onDismiss={onDismiss}
      initialFocusRef={buttonRef}
      style={{
        width: '85vw',
        maxWidth: '1140px',
        padding: '2.66rem',
        borderRadius: '0.38rem',
      }}
    >
      {loading ? (
        <ModalLoading rows={5} />
      ) : (
        lead && (
          <S.Container>
            {!isShowLostForm ? (
              <>
                <header>
                  <div>
                    <h2 className="leadCompany">
                      {lead?.lead?.company || refund?.refundItem?.leadName}
                    </h2>
                    {lead.packName && <S.PackName>{lead.packName}</S.PackName>}
                  </div>
                  <div>
                    <S.PriceDetails>
                      <h3>
                        Valor total pago:{' '}
                        <span>
                          {convertCentsToBRL(
                            refund?._id
                              ? Math.abs(totalRefundValue)
                              : Math.abs(totalLeadValue)
                          )}
                        </span>
                      </h3>
                      <div>
                        <S.PriceType>
                          <S.PriceHeader>
                            <p>Saque:</p>
                          </S.PriceHeader>
                          <span>
                            {convertCentsToBRL(
                              Math.abs(lead?.winner?.realValue) ||
                                Math.abs(refund?.winner?.realValue) ||
                                0
                            )}
                          </span>
                        </S.PriceType>
                        <S.PriceType>
                          <S.PriceHeader>
                            <BiCoin
                              style={{
                                color: 'var(--lab-ds-core-color-yellow-30)',
                                fontSize: '1.2em',
                              }}
                            />
                            <p>Créditos:</p>
                          </S.PriceHeader>
                          <span>{convertCentsToBRL(hasBonus)}</span>
                        </S.PriceType>
                      </div>
                    </S.PriceDetails>
                  </div>
                  <S.CloseModalButton onClick={onDismiss} />
                </header>

                <ProgressLead
                  stepActive={lead?.steps}
                  progress={stepActive.step} // alterar para o progresso do lead
                  steps={step}
                />
              </>
            ) : (
              <>
                <header className="lostLead">
                  <MdKeyboardArrowLeft
                    style={{
                      marginRight: '1rem',
                      fontSize: '2.19rem',
                      color: 'var(--lab-ds-core-color-black-10)',
                    }}
                    onClick={() => goBack()}
                  />
                  <h2 style={{ marginRight: 'auto' }}>
                    Informar perda do Lead
                  </h2>

                  <S.CloseModalButton onClick={onDismiss} />
                </header>
                <h4 style={{ marginBottom: '1.5rem' }}>
                  {lead?.lead?.company}
                </h4>
              </>
            )}

            {!isShowLostForm && (
              <>
                {refund?._id && (
                  <S.TextRefund>
                    <span>
                      Você não pode mais ver informações do lead após solicitar
                      reembolso
                    </span>
                  </S.TextRefund>
                )}

                <S.Details
                  refundRequired={refundRequired}
                  className="leadDetails"
                  refund={refund}
                >
                  <S.Column>
                    <small>Faturamento</small>
                    <p>{lead?.lead?.revenue || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Canal</small>
                    <p>{lead?.lead?.source || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Email</small>
                    <p>{lead?.lead?.email || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Funcionários</small>
                    <p>{lead?.lead?.employees || '-'}</p>
                  </S.Column>

                  {lead?.location && (
                    <S.Column>
                      <small>Localização</small>
                      <p>
                        {lead?.location?.city || '-'} /{' '}
                        {lead?.location?.state || '-'}
                      </p>
                    </S.Column>
                  )}

                  <S.Column>
                    <small>Campanha</small>
                    <p>{lead?.lead?.campaign || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Página de cadastro</small>
                    <p>{lead?.lead?.page || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Segmento</small>
                    <p>{lead?.lead?.segment || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Estratégia</small>
                    <p>{lead?.lead?.channel || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Nome do contato</small>
                    <p>{lead?.lead?.name || '-'}</p>
                  </S.Column>

                  <S.Column>
                    <small>Data de compra</small>
                    <p>
                      {formatDate(lead?.winner?.boughtAt || lead?.expiresAt)}
                    </p>
                  </S.Column>

                  <S.Column>
                    <small>Telefone</small>
                    <p>{lead?.lead?.tel || '-'}</p>
                  </S.Column>

                  {lead?.lead?.cnpj && (
                    <S.Column>
                      <small>CNPJ</small>
                      <p>{lead?.lead?.cnpj || '-'}</p>
                    </S.Column>
                  )}
                </S.Details>
              </>
            )}
            {lead?.lead?.description && (
              <S.Description>
                <S.Column>
                  <small>Descrição</small>
                  <S.InputDescription
                    value={lead?.lead?.description}
                    disabled
                  />
                </S.Column>
              </S.Description>
            )}

            {!isShowLostForm && (
              <S.Footer>
                {(lead?.historic || refund?.historic) && (
                  <Historic events={lead?.historic || refund?.historic} />
                )}
                <S.ButtonsContainer
                  style={{ flex: 1, justifyContent: 'flex-end', gap: '1.5rem' }}
                >
                  {((stepActive.buttonEnabled &&
                    refund?.status === 'APPROVED') ||
                    !refund?._id) && (
                    <LabDsButton
                      disabled={
                        (!stepActive.buttonEnabled &&
                          lead.steps !== 'MEETING_SCHEDULED') ||
                        (stepActive.buttonEnabled && lead.steps === 'LOST_LEAD')
                      }
                      variant="danger-outlined"
                      label={
                        lead.steps === 'MEETING_SCHEDULED' ||
                        lead.steps === 'RETURN_MEETING'
                          ? 'Avisar perda'
                          : 'Solicitar Reembolso'
                      }
                      onHandleButton={() => {
                        lead.steps === 'MEETING_SCHEDULED' ||
                        lead.steps === 'RETURN_MEETING'
                          ? setShowModalNotifyLoss(true)
                          : setIsShowLostForm(true);
                      }}
                    />
                  )}
                  {!refund?.status && (
                    <LabDsButton
                      // disabled
                      /* TO DO: functions will be reactivated soon */
                      disabled={!stepActive.buttonEnabled}
                      label={stepActive.buttonLabel}
                      /* TO DO: functions will be reactivated soon */
                      onHandleButton={() => {
                        salesForcePartner
                          ? setShowModalSalesforcePartner(true)
                          : stepActive.event();
                      }}
                    />
                  )}

                  {tab === 'refundRequests' && (
                    <LabDsButton
                      disabled={!stepActive.buttonEnabled}
                      label="Voltar"
                      onHandleButton={() => onDismiss()}
                    />
                  )}
                </S.ButtonsContainer>
              </S.Footer>
            )}
            {showModalAlterStatus && (
              <ModalAlterStatus
                setShowModalAlterStatus={setShowModalAlterStatus}
                step={step}
                stepActive={stepActive}
                setStepActive={setStepActive}
                onDismiss={onDismiss}
                buttonRef={buttonRef}
              />
            )}
            {showModalMeetingSchedule && (
              <ModalMeetingSchedule
                meetingDate={meetingDate}
                setMeetingDate={setMeetingDate}
                meetingHour={meetingHour}
                setMeetingHour={setMeetingHour}
                setShowModalMeetingSchedule={setShowModalMeetingSchedule}
                step={step}
                setStepActive={setStepActive}
                onDismiss={onDismiss}
                buttonRef={buttonRef}
              />
            )}
            {showModalNotifyLoss && (
              <ModalNotifyLoss
                onDismiss={() => setShowModalNotifyLoss(false)}
                buttonRef={buttonRef}
                handleNotifyLoss={handleNotifyLoss}
              />
            )}
            {showModalSalesforcePartner && (
              <ModalSalesforcePartner
                setShowModalSalesforcePartner={setShowModalSalesforcePartner}
              />
            )}
            {isShowLostForm && (
              <form
                onSubmit={() => handleSubmit(onSubmit)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Controller
                  name="reasonManager"
                  control={control}
                  render={({ field }) => {
                    return (
                      <LabDsSelect
                        style={{ width: '38%' }}
                        label="Selecione o gestor"
                        titleInput="Gestor Matriz"
                        size="medium"
                        type="radioButton"
                        options={optionsReasonsManager}
                        state={errors.reasonManager ? 'error' : 'default'}
                        helperText={errors.reasonManager?.message}
                        onChangeInputOptions={({ detail }) => {
                          field.onChange({
                            name: detail[0].label,
                            _id: detail[0].key,
                          });
                        }}
                      />
                    );
                  }}
                />

                <S.Row style={{ justifyContent: 'left' }}>
                  <Controller
                    name="refundReason"
                    control={control}
                    render={({ field }) => {
                      return (
                        <LabDsSelect
                          style={{ width: '38%' }}
                          label="Selecione o motivo"
                          titleInput="Motivo"
                          size="medium"
                          type="radioButton"
                          options={optionsReasons}
                          helperText={errors.refundReason?.message}
                          state={errors.refundReason ? 'error' : 'default'}
                          onChangeInputOptions={({ detail }) => {
                            field.onChange(detail[0].label);
                            setReason(detail[0]);
                            optionsReasons.forEach(option => {
                              if (option.label === detail[0].label) {
                                setValue(
                                  'automaticReject',
                                  option.automaticReject
                                );
                              }
                            });
                          }}
                        />
                      );
                    }}
                  />
                  <Controller
                    name="reasonPhone"
                    control={control}
                    render={({ field }) => {
                      return (
                        <LabDsTextField
                          style={{ width: '38%', marginLeft: '5rem' }}
                          value={field.value}
                          titleInput="Telefone para contato"
                          label="Digite seu telefone"
                          mask="phone"
                          maxLength={11}
                          helperText={errors?.reasonPhone?.message}
                          state={errors?.reasonPhone ? 'error' : 'default'}
                          onChangeInput={({ detail }) => {
                            field.onChange(detail);
                          }}
                        />
                      );
                    }}
                  />
                </S.Row>

                <Controller
                  name="refundJustification"
                  control={control}
                  render={({ field }) => {
                    return (
                      <LabDsTextArea
                        style={{
                          paddingBottom: '1rem',
                        }}
                        titleInput="Descreva como foi a negociação"
                        label="Escreva aqui com detalhes como foi a negociação"
                        value={field.value}
                        maxLength={1000}
                        state={errors.refundJustification ? 'error' : 'default'}
                        helperText={errors.refundJustification?.message}
                        rows={4}
                        cols={130}
                        onChangeInput={({ detail }) => {
                          field.onChange(detail);
                        }}
                      />
                    );
                  }}
                />
                <UploadFiles
                  reasonFiles={reasonFiles}
                  setReasonFiles={setReasonFiles}
                />
                {reasonFileError && (
                  <S.Error>
                    <LabDsIconSelectable
                      size="x-small"
                      icon="info"
                      color="var(--lab-ds-core-color-red-arterial-50)"
                    />
                    {reasonFileError}
                  </S.Error>
                )}
                <S.ButtonsContainer
                  style={{ flex: 1, justifyContent: 'flex-end' }}
                >
                  <LabDsButton
                    label="Cancelar"
                    variant="danger-outlined"
                    onClick={onDismiss}
                  />
                  <LabDsButton
                    label="Enviar"
                    type="submit"
                    disabled={loadingUploadFile}
                    onClick={handleValidateRefundable}
                  />
                </S.ButtonsContainer>
              </form>
            )}
            {showModalRefund && (
              <ModalRefund
                expiredDeadline={expiredDeadline}
                setShowModalRefund={setShowModalRefund}
                setIsShowLostForm={setIsShowLostForm}
                refundSent={refundSent}
                handleSendRefund={() => {
                  handleSubmit(onSubmit)();
                }}
              />
            )}
            {showLostLeadModal && (
              <Dialog
                aria-labelledby="label"
                onDismiss={onDismiss}
                initialFocusRef={buttonRef}
                style={{ marginTop: '20vh', width: '70vw' }}
              >
                <S.Modal>
                  <FaRegCheckCircle />
                  <h2>Formulário enviado com sucesso!</h2>
                  <S.ButtonsContainer>
                    <LabDsButton
                      label="Fechar"
                      onHandleButton={() => {
                        onDismiss();
                        setShowLostLeadModal(false);
                      }}
                    />
                  </S.ButtonsContainer>
                </S.Modal>
              </Dialog>
            )}
          </S.Container>
        )
      )}
    </Dialog>
  );
};

export default LeadDetails;
