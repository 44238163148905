import { federationApi as api } from '../api';

export async function getLead(leadId: string) {
  try {
    const response = await api.get(`/leads/${leadId}?won=true`);

    return response.data;
  } catch (err) {
    const { response } = err;

    return {
      message: response?.data?.message,
    };
  }
}

export async function getLeadRefund(leadId: string) {
  try {
    const response = await api.get(`/refund-request/${leadId}`);

    return response.data;
  } catch (err) {
    const { response } = err;

    return {
      message: response?.data?.message,
    };
  }
}
