import { Button } from 'v4components-react';
import * as S from './styles';
import { ICON } from '../../../../assets';
import { LoadingAddBonus } from '../../../../components/Default/Loadings/LoadingAddBonus';
import { getPaymentAttemptErrorMessage } from '../../../../utils/IuguPaymentAttemptErrors';

interface Props {
  paymentDetails: PaymentDetails;
  handleToggleModal: () => void;
  loading?: boolean;
  errorCode?: string;
}

export function PaymentError({
  paymentDetails,
  handleToggleModal,
  loading,
  errorCode,
}: Props) {
  return (
    <>
      <S.Container>
        {loading && (
          <div style={{ marginBottom: '2rem' }}>
            <LoadingAddBonus />
          </div>
        )}

        {paymentDetails && (
          <S.AllPaymentContent>
            <S.ContainerIcon>
              <S.Icon src={ICON.Error} />
              <h4>{getPaymentAttemptErrorMessage(errorCode)?.message}</h4>
            </S.ContainerIcon>
          </S.AllPaymentContent>
        )}

        <footer>
          <Button
            size="default"
            variant="primary"
            color="green"
            type="submit"
            onClick={handleToggleModal}
            width="10.7rem"
          >
            Ir à carteira
          </Button>
        </footer>
      </S.Container>
    </>
  );
}
