import { forwardRef } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';

import * as S from './styles';

interface TooltippyProps {
  message: string;
}

const TooltipIcon = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref}>
      <FiAlertCircle size={24} style={{ color: '#A8A8B3' }} />
    </div>
  );
});

export function Tooltippy({ message }: TooltippyProps) {
  return (
    <S.StyledTippy
      // eslint-disable-next-line react/no-danger
      content={<p dangerouslySetInnerHTML={{ __html: message }} />}
      className="lb-tooltip"
    >
      <TooltipIcon />
    </S.StyledTippy>
  );
}
