import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { parseCookies } from 'nookies';

import { Input, SingleSelect } from 'v4components-react';
import { LabDsButton } from 'v4web-components-react';

import { bankList } from '../../../../utils/iuguBankList';
import apiFederation from '../../../../services/requests/leadbroker/apiFederation';

import * as S from './styles';
import { useToast } from '../../../../contexts/toast';

interface InfoPage {
  nextButton(
    data: BankData,
    selectedBankId: InputItensSelect[],
    bankAccountType: InputTypeAccount[],
    bankName: string
  ): void;
  backButton: () => void;
}
export interface BankData {
  bankName: string;
  bankType: string;
  email: string;
  agency: string;
  accountNumber: string;
  cnpj: string;
  phone: string;
  name: string;
  baskMasks: {
    name: string;
    maxAccount: number;
    maxAgency: number;
  };
}

export interface InputItensSelect {
  label: string;
  value: {
    id: string;
    maxAgency: number;
    maxAccount: number;
    placeholderAgency: string;
    placeholderAccount: string;
    icon?: string;
  };
  info?: string;
}

export interface InputTypeAccount {
  label: string;
  value: string;
  info?: string;
}

export function Info({ nextButton }: InfoPage) {
  const [selectedBankId, setSelectedBankId] = useState<InputItensSelect[]>([
    bankList[0],
  ]);
  const [bankAccountType, setBankAccountType] = useState<InputTypeAccount[]>([
    { value: 'Corrente', label: 'Corrente' },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCuston, setErrorCuston] = useState<{
    bankId: { msg: string };
    typeAccount: { msg: string };
  }>({ bankId: { msg: '' }, typeAccount: { msg: '' } });
  const { addToast } = useToast();

  const bankMasks = useMemo(() => {
    const [selectedBank] = bankList?.filter(
      item => item?.value?.id === selectedBankId[0]?.value?.id
    );
    return selectedBank;
  }, [selectedBankId]);

  const schema = Yup.object().shape({
    phone: Yup.string().required('Telefone obrigatório'),
    name: Yup.string().required('Nome obrigatório'),
    accountNumber: Yup.string()
      .required('Número da conta obrigatório')
      .min(
        bankMasks?.value?.maxAccount,
        'Informe todos os dígitos da conta e, se necessário, coloque zeros à esquerda'
      )
      .max(
        bankMasks?.value.maxAccount,
        'Informe todos os dígitos da conta e, se necessário, coloque zeros à esquerda'
      ),
    agency: Yup.string()
      .required('Agência obrigatória')
      .min(
        bankMasks?.value.maxAgency,
        'Preencha o número da agência com todos os dados'
      )
      .max(
        bankMasks?.value.maxAgency,
        'Preencha o número da agência com todos os dados'
      ),
    email: Yup.string()
      .email('Precisa ser um email valido')
      .required('E-mail obrigatório.')
      .max(255, 'No máximo 255 dígitos.')
      .matches(
        RegExp('^[A-Za-z0-9._%+-]+@v4company.com$'),
        'O email deve ser @v4company'
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<BankData>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<BankData> = async (data: BankData) => {
    try {
      if (selectedBankId.length > 0 && bankAccountType.length > 0) {
        const depositInformation = {
          ...data,
          agency: data.agency.replaceAll('_', ''),
          accountNumber: data.accountNumber.replaceAll('_', ''),
        };
        const telephone = data.phone
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '');

        setIsLoading(true);

        const cookies = parseCookies(undefined);

        await apiFederation.post(
          '/brokers-wallet/configure-iugu-account',
          {
            unitId: '0b62af3b-da1c-4e64-82de-326e86aa48ea',
            telephone,
            bank: bankMasks.label,
            bankAg: depositInformation.agency,
            accountType: bankAccountType[0].value || 'Corrente',
            bankCc: depositInformation.accountNumber,
            respName: depositInformation.name,
            respCpf: depositInformation.cnpj,
          },
          { headers: { Authorization: `Bearer ${cookies['v4company.token']}` } }
        );

        nextButton(data, selectedBankId, bankAccountType, bankMasks.label);
      }

      if (selectedBankId.length === 0) {
        errorCuston.bankId.msg = 'Campo obrigatório!';
        setErrorCuston({ ...errorCuston });
      }
      if (bankAccountType.length === 0) {
        errorCuston.typeAccount.msg = 'Campo obrigatório!';
        setErrorCuston({ ...errorCuston });
      }

      return true;
    } catch (err) {
      addToast({
        type: 'error',
        description:
          'Não foi possível salvar dados bancarios, entre em contato com suporte da plataforma.',
      });

      setIsLoading(false);
      return false;
    }
  };

  return (
    <S.Container>
      <p>{getValues('bankName')}</p>
      <S.Content>
        <section className="bankName">
          <S.InputContent>
            <SingleSelect
              name="bankName"
              label="Qual o seu Banco"
              options={bankList}
              itensSelected={selectedBankId}
              setItensSelected={setSelectedBankId as never}
              placeholder="Selecione seu Banco"
              borderColor={errorCuston.bankId?.msg.length > 0 ? 'red' : ''}
            />
            <small>{errorCuston.bankId?.msg}</small>
          </S.InputContent>

          <S.InputContent>
            <SingleSelect
              label="Tipo de Conta"
              options={[
                { value: 'Corrente', label: 'Corrente' },
                { value: 'Poupança', label: 'Poupança' },
              ]}
              itensSelected={bankAccountType}
              setItensSelected={setBankAccountType as never}
              placeholder="Sua conta é?"
              borderColor={errorCuston.typeAccount?.msg.length > 0 ? 'red' : ''}
              name="bankType"
            />
            <small>{errorCuston.typeAccount?.msg}</small>
          </S.InputContent>
        </section>

        <section>
          <S.InputContent className="agency">
            <Controller
              name="agency"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Input
                    label="Agência"
                    mask={bankMasks?.value.placeholderAgency}
                    min={bankMasks?.value.maxAgency}
                    max={bankMasks?.value.maxAgency}
                    borderColor={errors.agency ? 'red' : ''}
                    placeholder={bankMasks?.value.placeholderAgency}
                    {...field}
                  />
                );
              }}
            />
            <small>{errors.agency?.message}</small>
          </S.InputContent>

          <S.InputContent className="accountNumber">
            <Controller
              name="accountNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Input
                    label="Número da Conta"
                    mask={bankMasks?.value.placeholderAccount}
                    min={bankMasks?.value.maxAccount}
                    max={bankMasks?.value.maxAccount}
                    placeholder={bankMasks?.value.placeholderAccount}
                    borderColor={errors.accountNumber ? 'red' : ''}
                    {...field}
                  />
                );
              }}
            />
            <small>{errors.accountNumber?.message}</small>
          </S.InputContent>
        </section>

        <section>
          <S.InputContent className="userName">
            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    label="Nome"
                    maxLength={150}
                    placeholder="Digite seu nome"
                    borderColor={errors.name ? 'red' : ''}
                    {...field}
                  />
                );
              }}
            />
            <small>{errors.name?.message}</small>
          </S.InputContent>
        </section>

        <section className="contactUser">
          <S.InputContent className="userPhone">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    label="Telefone"
                    mask="(99) 9.9999-9999"
                    placeholder="Digite seu telefone"
                    borderColor={errors.phone ? 'red' : ''}
                    {...field}
                  />
                );
              }}
            />
            <small>{errors.phone?.message}</small>
          </S.InputContent>

          <S.InputContent className="userEmail">
            <Controller
              name="email"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    label="Email"
                    placeholder="Digite seu e-mail"
                    borderColor={errors.email ? 'red' : ''}
                    {...field}
                  />
                );
              }}
            />
            <small>{errors.email?.message}</small>
          </S.InputContent>
        </section>
      </S.Content>

      <S.Footer>
        <LabDsButton
          label="Seguir para o resumo da transação"
          onHandleButton={() => handleSubmit(onSubmit)()}
          loading={isLoading}
        />
      </S.Footer>
    </S.Container>
  );
}
