import * as S from './styles';

interface ProgressLeadProps {
  steps: { label: string; key: string }[];
  progress: number;
  stepActive: string;
}

function shouldReturnNull(stepActive: string, step: { key: string }) {
  const isReturnMeeting = step.key === 'RETURN_MEETING';
  const isLostLead = step.key === 'LOST_LEAD';
  const isMeetingScheduled = step.key === 'MEETING_SCHEDULED';

  return (
    (stepActive !== 'RETURN_MEETING' && isReturnMeeting) ||
    (stepActive !== 'LOST_LEAD' && isLostLead) ||
    (stepActive === 'LOST_LEAD' && isMeetingScheduled) ||
    (stepActive === 'RETURN_MEETING' && isReturnMeeting)
  );
}

export function ProgressLead({
  steps,
  progress,
  stepActive,
}: ProgressLeadProps) {
  return (
    <S.Container>
      {steps?.map((step, index) => {
        if (shouldReturnNull(stepActive, step)) {
          return null;
        }

        return (
          <S.Progress
            key={step.key}
            progress={step.key}
            active={step.key === stepActive || progress > index}
          >
            <S.ArrowLeft className="arrow-left" />
            <S.ProgressLabel className="progress-label">
              {step.label}
            </S.ProgressLabel>
            <S.ArrowRight className="arrow-right" />
          </S.Progress>
        );
      })}
    </S.Container>
  );
}
