interface TabsProps {
  setActualTab: React.Dispatch<React.SetStateAction<string>>;
}

export interface ITabs {
  title: string;
  key: string;
  event: () => void;
}

export function tabs({ setActualTab }: TabsProps) {
  return [
    {
      title: 'Todas as ofertas',
      key: 'tab1',
      event: () => {
        setActualTab('Todas as ofertas');
      },
    },
    {
      title: 'Ofertas unitárias',
      key: 'tab2',
      event: () => {
        setActualTab('Ofertas unitárias');
      },
    },
    {
      title: 'Ofertas em pack',
      key: 'tab3',
      event: () => {
        setActualTab('Ofertas em pack');
      },
    },
    {
      title: 'Ofertas com desconto',
      key: 'tab4',
      event: () => {
        setActualTab('Ofertas com desconto');
      },
    },
    {
      title: 'Meus lances',
      key: 'tab5',
      event: () => {
        setActualTab('Meus lances');
      },
    },
  ];
}
