import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  max-width: 680px !important;

  h3 {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    max-width: 35rem;
    margin: 1.5rem 0;
  }

  footer {
    display: flex;
    a {
      text-decoration: none;
    }

    button {
      width: auto;
      padding: 0 1rem;
      font-weight: bold;
    }

    button {
      min-width: 170px !important;
    }

    button:first-child {
      margin-right: 1rem;
    }
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: 6.5rem;
  position: relative;
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  align-items: center;
  padding: 2rem;
`;
