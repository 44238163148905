import { IBadgeProps } from 'v4web-components/dist/types/components/layout/lab-ds-table/lab-ds-table';
import { TransactionStatus } from './interfaces/transactions.interface';

export const TransactionsStatus: Record<TransactionStatus, IBadgeProps> = {
  FINISHED: {
    label: 'Finalizada',
    state: 'success',
  },
  CANCELED: {
    label: 'Cancelada',
    state: 'error',
  },
  UNDER_ANALYSIS: {
    label: 'Em análise',
    state: 'warning',
  },
};

export const getStatusTransaction = (status: string): IBadgeProps => {
  return TransactionsStatus[status];
};
