import styled from 'styled-components';

export const Container = styled.div`
  > div {
    > div:not(:last-child) {
      border: 2px solid var(--secondary-main);

      font-size: 1rem;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      padding: 0.55rem;
    }
    span {
      opacity: 0;
    }
  }
`;

export const ContainerLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 700;
    h4 {
      text-transform: uppercase;
    }
  }
`;
