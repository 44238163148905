import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 0.7rem;
  background: var(--white);

  footer {
    width: 100%;
    height: 6.6rem;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
    background: var(--white);
    box-shadow: 0 -0.25rem 0.5rem 0.19rem rgba(0, 0, 0, 0.06),
      0 -0.07rem 0.19rem rgba(0, 0, 0, 0.12);
    button:first-of-type {
      width: max-content;
      border-color: transparent;
      margin: 1.8rem 1.8rem;
    }

    @media (max-width: 450px) {
      position: fixed;
      bottom: 0;
    }
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 2.2rem 2.2rem 2.2rem 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 0.07rem 0.19rem 0 var(--grayscale-3);
  position: relative;

  svg {
    cursor: pointer;
  }

  .titleModal {
    font-size: 28px;
    font-weight: 700;
    color: var(--secondary-main);
    margin-left: 1rem;
  }

  .closeButton {
    position: absolute;
    right: 2rem;
    top: 1.2rem;
  }
`;
