import { parseCookies } from 'nookies';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface BidsToAnimateProps {
  id: string;
  previousBidValue: Date;
}

interface UnitInfoProps {
  unitName: string;
  cnpj: string;
}

type AuctionsContextData = {
  auction: Auction | AuctionPack;
  setAuction: Dispatch<SetStateAction<Auction | AuctionPack>>;
  auctionPack: Auction | AuctionPack;
  setAuctionPack: Dispatch<SetStateAction<Auction | AuctionPack>>;
  auctions: Auction[];
  setAuctions: Dispatch<SetStateAction<Auction[]>>;
  refurbishedAuctions: Auction[];
  setRefurbishedAuctions: Dispatch<SetStateAction<Auction[]>>;
  expPurchasedAuctions: AuctionAll[];
  setExpPurchasedAuctions: Dispatch<SetStateAction<AuctionAll[]>>;
  myLeads: Auction[];
  auctionDelete: Auction;
  setAuctionDelete: Dispatch<SetStateAction<Auction>>;
  setMyLeads: Dispatch<SetStateAction<Auction[]>>;
  myLeadValue: number;
  setMyLeadValue: Dispatch<SetStateAction<number>>;
  actualPage: number;
  setActualPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  setTotalPages: Dispatch<SetStateAction<number>>;
  bidsToAnimate: BidsToAnimateProps[];
  setShowModalDeleteLead: Dispatch<SetStateAction<boolean>>;
  showModalDeleteLead: boolean;
  setBidsToAnimate: Dispatch<SetStateAction<BidsToAnimateProps[]>>;
  unitInfos: UnitInfoProps;
  setUnitInfos: Dispatch<SetStateAction<UnitInfoProps[]>>;
  actualTab: string;
  setActualTab: Dispatch<SetStateAction<string>>;
};

export const AuctionsContext = createContext({} as AuctionsContextData);

type AuctionsContextProviderProps = {
  children: ReactNode;
};

export const AuctionsProvider: React.FC<AuctionsContextProviderProps> = ({
  children,
}: AuctionsContextProviderProps) => {
  const cookies = parseCookies();

  const [auctions, setAuctions] = useState({} as Auction[]);
  const [auction, setAuction] = useState({} as Auction | AuctionPack);
  const [auctionPack, setAuctionPack] = useState({} as Auction | AuctionPack);
  const unitInfo =
    cookies['v4company.unitInfo'] || localStorage.getItem('v4company.unitInfo');
  const [unitInfos, setUnitInfos] = useState(
    (unitInfo !== undefined && JSON.parse(unitInfo)) || []
  );
  const [expPurchasedAuctions, setExpPurchasedAuctions] = useState<
    AuctionAll[]
  >([]);
  const [refurbishedAuctions, setRefurbishedAuctions] = useState<Auction[]>([]);
  const [myLeads, setMyLeads] = useState({} as Auction[]);
  const [myLeadValue, setMyLeadValue] = useState(0);
  const [auctionDelete, setAuctionDelete] = useState<Auction | null>(null);
  const [showModalDeleteLead, setShowModalDeleteLead] = useState(false);
  const [actualPage, setActualPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [bidsToAnimate, setBidsToAnimate] = useState<BidsToAnimateProps[]>([]);
  const [actualTab, setActualTab] = useState<string>('Todas as ofertas');

  return (
    <AuctionsContext.Provider
      value={{
        auction,
        setAuction,
        auctionPack,
        setAuctionPack,
        auctions,
        setAuctions,
        myLeads,
        setMyLeads,
        myLeadValue,
        setMyLeadValue,
        actualPage,
        setActualPage,
        totalPages,
        setTotalPages,
        refurbishedAuctions,
        setRefurbishedAuctions,
        expPurchasedAuctions,
        setExpPurchasedAuctions,
        bidsToAnimate,
        setBidsToAnimate,
        unitInfos,
        setUnitInfos,
        actualTab,
        setActualTab,
        showModalDeleteLead,
        setShowModalDeleteLead,
        auctionDelete,
        setAuctionDelete,
      }}
    >
      {children}
    </AuctionsContext.Provider>
  );
};

export const useAuctions = (): AuctionsContextData => {
  return useContext(AuctionsContext);
};
