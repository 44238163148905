import { federationApi as api } from '../api';

export async function deleteLead(id) {
  try {
    const response = await api.delete(`/leads/delete/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);

    return false;
  }
}
