import styled from 'styled-components';

export const Row = styled.div<{ disableRow?: boolean }>`
  width: 100%;
  border-bottom: 1px solid var(--terciary-light);
  :first-of-type {
    font-size: 16px;
    line-height: 21px;
  }
  display: ${({ disableRow }) => (disableRow ? 'none' : 'flex')};
`;

export const Field = styled.div`
  width: 30%;
  display: flex;
  padding: 1rem;
  align-items: center;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    width: 2rem !important;
    height: 2rem !important;
    width: auto;
    height: auto;
    background: var(--primary-dark);
    margin-right: 0.5rem;
  }

  svg {
    cursor: pointer;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
