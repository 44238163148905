import { federationApi as api } from '../api';

export interface UpdateStep {
  id?: string;
  step: string;
}

export async function updateStep(body: UpdateStep) {
  try {
    const response = await api.put(`/leads/update-steps`, body);
    return response.data;
  } catch (err) {
    console.error(err);

    return false;
  }
}
