import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FiXCircle, FiMapPin } from 'react-icons/fi';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { MdCall } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Dialog } from '@reach/dialog';
import { Link } from 'react-router-dom';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { MdPersonOutline } from 'react-icons/md';
import 'react-datepicker/dist/react-datepicker.css';
import FolderIcon from '../../../assets/FolderIcon.svg';

import '@reach/dialog/styles.css';

import { Input } from '../../Default/Forms/Input';
import { CalendarPicker } from '../../Default/Forms/DatePicker';
import * as S from './styles';
import { getValidationErrors } from '../../../utils/getValidationErrors';
import { InputMask } from '../../Default/InputMask';
import { RadioButton } from '../../Default/Forms/RadioButtons';
import { Checkbox } from '../../Default/Forms/Checkbox';
import { countriesMock, nextPaymentsMock, vigenceTimesMock } from './mocks';

import { createContract } from '../../../services/requests/leadbroker/auctions/createContract';
import { ModalLoading } from '../../Default/Loadings/ModalLoading';
import { Button } from '../../Default/Button';
import { ErrorModal } from '../../AtomicDesign/molecules/ErrorModal';
import { Select } from '../../Default/Select';

type ContractProps = {
  name: string;
  cpf: string;
  companyName: string;
  cnpj: string;
  country: string;
  state: string;
  city: string;
  cep: string;
  district: string;
  street: string;
  firstPaymentDate: string;
  contractType: string;
  fee: number;
  vigenceTime: string;
  nextPayment: string;
  mail: string;
  tel: string;
  radio: string;
  financialName?: string;
  financialEmail?: string;
  financialTel?: string;
  responsability1: string;
  responsability2: string;
};

export function ContractDetails() {
  const navigate = useNavigate();
  const myBidsNavigate = '/meus-leads';
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [countries, setCountries] = useState([{}]);
  const [states, setStates] = useState([{}]);
  const [isActiveFinancialContact, setIsActiveFinancialContact] = useState(
    false
  );
  const [isValidCpf, setIsValidCpf] = useState(true);
  const [isValidCnpj, setIsValidCnpj] = useState(true);
  const [financialContactError, setFinancialContactError] = useState('');
  const [responsabilityError1, setResponsabilityError1] = useState('');
  const [responsabilityError2, setResponsabilityError2] = useState('');
  const [firstPaymentDate, setFirstPaymentDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const [vigenceTime, setVigenceTime] = useState('');
  // const [firstPayment, setFirstPayment] = useState<Date>(new Date());
  const { id } = useParams<'id'>();

  const location = useLocation();

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const onDismiss = () => {
    if (location.state === null) {
      return navigate(myBidsNavigate);
    }

    return navigate(-1);
  };

  const verifyCpf = (value: string) => {
    setIsValidCpf(cpf.isValid(value));
  };

  const verifyCnpj = (value: string) => {
    setIsValidCnpj(cnpj.isValid(value));
  };

  const handleContract = useCallback(
    async (data: ContractProps) => {
      try {
        formRef.current?.setErrors({});
        verifyCpf(data.cpf);
        verifyCnpj(data.cnpj);

        const schema = Yup.object().shape({
          name: Yup.string().required('*Campo obrigatório'),
          cpf: Yup.string().required('*Campo obrigatório'),
          cnpj: Yup.string().required('*Campo obrigatório'),
          companyName: Yup.string().required('*Campo obrigatório'),
          // contractType: Yup.string().required('*Campo obrigatório'),
          // nextPayment: Yup.string().required('*Campo obrigatório'),
          // vigenceTime: Yup.string().required('*Campo obrigatório'),
          // country: Yup.string().required('*Campo obrigatório'),
          // state: Yup.string().required('*Campo obrigatório'),
          city: Yup.string().required('*Campo obrigatório'),
          district: Yup.string().required('*Campo obrigatório'),
          address: Yup.string().required('*Campo obrigatório'),
          number: Yup.string().required('*Campo obrigatório'),
          cep: Yup.string().required('*Campo obrigatório'),
          mail: Yup.string().required('*Campo obrigatório'),
          fee: Yup.string().required('*Campo obrigatório'),
          tel: Yup.string().required('*Campo obrigatório'),
        });

        if (financialContactError === '') {
          setFinancialContactError('error');
        }

        if (responsabilityError1 === '') {
          setResponsabilityError1('error');
        }

        if (responsabilityError2 === '') {
          setResponsabilityError2('error');
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        if (
          financialContactError === 'error' ||
          responsabilityError1 === 'error' ||
          responsabilityError2 === 'error'
        ) {
          return;
        }

        if (!isValidCpf) {
          return;
        }

        setLoading(true);

        const newContract = {
          id: id!,
          social_name: data.companyName,
          partner_email: data.mail,
          cnpj_cpf: data.cnpj,
          legal_partner: {
            cpf: data.cpf,
            name: data.name,
            contract_id: id!,
          },
          life_time: vigenceTime,
          first_payment_date: firstPaymentDate,
          contract_start: firstPaymentDate,
          payment_date: data.nextPayment,
          unit_email: 'teste@teste.com',
          product: data.vigenceTime,
          address: {
            cep: data.cep,
            country: data.country,
            state: data.state,
            city: data.city,
            district: data.district,
            street: data.street,
            number: '',
            contract_id: id!,
          },
          pipefy_title: 'string',
          fee: data.fee * 100,
        };
        const newCreateContract = await createContract(newContract);
        if (!newCreateContract) {
          setIsOpenErrorModal(true);
          return;
        }
        setIsOpenConfirmationModal(true);
        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [
      financialContactError,
      responsabilityError1,
      responsabilityError2,
      isValidCpf,
      id,
      firstPaymentDate,
      vigenceTime,
    ]
  );

  const setCountriesInfo = useCallback(() => {
    const newCountries = countriesMock.map(country => ({
      value: country.country,
      label: country.country,
    }));
    setCountries(newCountries);
  }, []);

  const handleState = useCallback((selectedCountry: string) => {
    const newStates = countriesMock.find(
      country => country.country === selectedCountry
    );
    const statesMaped = newStates?.states.map(stateItem => ({
      value: stateItem,
      label: stateItem,
    }));
    if (statesMaped) {
      setStates(statesMaped);
    }
  }, []);

  // const handleUpdateContractDate = (value: Date) => {
  //   setFirstPaymentDate(value);
  // };

  useEffect(() => {
    setCountriesInfo();
  }, [setCountriesInfo]);

  return (
    <Dialog
      aria-labelledby="label"
      onDismiss={() => setIsOpenCancelModal(true)}
      initialFocusRef={buttonRef}
      style={{
        width: '90vw',
        maxWidth: '74rem',
        padding: '0',
        borderRadius: '0.5rem',
        height: '92vh',
        margin: '4vh auto',
      }}
    >
      <S.Container>
        <S.ModalHeader>
          <RiArrowLeftSLine
            style={{
              marginLeft: '0.5rem',
              marginRight: '1rem',
              color: 'var(--grayscale-60)',
            }}
            onClick={() => setIsOpenCancelModal(true)}
          />
          <h2 style={{ marginRight: 'auto' }}>Solicitação de contrato</h2>
          <S.CloseModalButton onClick={() => setIsOpenCancelModal(true)} />
        </S.ModalHeader>
        {loading ? (
          <ModalLoading rows={5} />
        ) : (
          <Form
            placeholder=""
            onSubmit={handleContract}
            ref={formRef}
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              paddingLeft: '2rem',
              paddingRight: '1rem',
            }}
            onPointerEnterCapture={() => null}
            onPointerLeaveCapture={() => null}
          >
            <S.FormDescription>
              <p>Campos obrigatórios*</p>
              <h5>
                <MdPersonOutline />
                Dados pessoais do cliente
              </h5>
              <p style={{ color: 'var(--grayscale-20)' }}>
                (representante legal do CNPJ informado)
              </p>
            </S.FormDescription>
            {/* <S.Row /> */}
            <S.Row>
              <Input
                label="*Nome"
                name="name"
                placeholder="Digite o nome do cliente"
                containerStyle={{
                  maxWidth: '50rem',
                }}
              />
              <InputMask
                mask="999.999.999-99"
                label="*CPF"
                name="cpf"
                placeholder="xxx.xxx.xxx-xx"
                containerStyle={{ maxWidth: '50rem' }}
                className="short"
              />
              {!isValidCpf && (
                <small
                  style={{
                    color: 'var(--primary-main)',
                    marginLeft: '0.25rem',
                    marginTop: '-0.75rem',
                    marginBottom: '1rem',
                  }}
                >
                  Informe um cpf válido
                </small>
              )}
            </S.Row>
            <S.Row>
              <Input
                label="*Razão social da empresa"
                name="companyName"
                placeholder="Digite a razão social"
                containerStyle={{ maxWidth: '50rem' }}
              />
              <InputMask
                mask="99.999.999/9999-99"
                label="*CNPJ"
                name="cnpj"
                placeholder="xx.xxx.xxx/xxxx-xx"
                containerStyle={{ maxWidth: '50rem' }}
                className="short"
              />
              {!isValidCnpj && (
                <small
                  style={{
                    color: 'var(--primary-main)',
                    marginLeft: '0.25rem',
                    marginTop: '-0.75rem',
                    marginBottom: '1rem',
                  }}
                >
                  Informe um CNPJ válido
                </small>
              )}
            </S.Row>
            <S.FormDescription>
              <h5>
                <FiMapPin />
                Localização do cliente
              </h5>
            </S.FormDescription>
            <S.Row>
              <InputMask
                mask="99999-999"
                label="*CEP"
                name="cep"
                placeholder="Digite o CEP"
                containerStyle={{ maxWidth: '14rem' }}
              />
              <Select
                label="*País"
                name="country"
                placeholder="Selecione o país"
                onChange={newValue => {
                  const changeObject = newValue as { label: string };
                  handleState(changeObject.label);
                }}
                containerStyle={{
                  maxWidth: '14rem',
                  border: '1px solid #E6E6EF',
                  borderRadius: '0.25rem',
                  marginBottom: '1rem',
                }}
                options={countries}
              />
              <Select
                label="*Estado"
                name="state"
                placeholder="Selecione o estado"
                onChange={newValue => {
                  const changeObject = newValue as { label: string };
                  handleState(changeObject.label);
                }}
                containerStyle={{
                  maxWidth: '14rem',
                  border: '1px solid #E6E6EF',
                  borderRadius: '0.25rem',
                  marginBottom: '1rem',
                }}
                options={states}
              />
              <Input
                label="*Bairro"
                name="district"
                placeholder="Digite o bairro"
                containerStyle={{ maxWidth: '14rem' }}
              />
            </S.Row>
            <S.Row>
              <Input
                label="*Cidade"
                name="city"
                placeholder="Digite sua cidade"
                className="short"
              />
              <Input
                label="*Endereço"
                name="address"
                placeholder="Digite o endereço do cliente"
                containerStyle={{ minWidth: '23rem' }}
              />
              <Input
                label="*Número"
                name="number"
                placeholder="Digite o número do endereço"
                containerStyle={{ minWidth: '23rem' }}
              />
            </S.Row>

            <S.FormDescription>
              <h5>
                <MdCall />
                Contato do cliente
              </h5>
            </S.FormDescription>
            <S.Row>
              <Input
                label="*E-mail"
                name="mail"
                containerStyle={{ maxWidth: '50rem' }}
                placeholder="email@exemplo.com"
              />
              <InputMask
                mask="(99) 9 9999-9999"
                label="*Telefone"
                name="tel"
                placeholder="(xx) x xxxx-xxxx"
                containerStyle={{ maxWidth: '50rem' }}
                className="short"
              />
            </S.Row>

            <S.FormDescription>
              <h5>
                <img src={FolderIcon} alt="ícone folder" />
                Dados da negociação
              </h5>
            </S.FormDescription>
            <S.Row>
              <Input
                label="*Fee"
                name="fee"
                placeholder="R$00,00"
                containerStyle={{ minWidth: '23rem' }}
                className="short"
              />
            </S.Row>
            <S.Row>
              <CalendarPicker
                label="*Data de início de projeto"
                name="firstProject"
                selected={firstPaymentDate}
                onChange={(date: Date) => setFirstPaymentDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                placeholderText="Selecione uma data"
              />
              <Select
                label="*Subsequente"
                name="nextPayment"
                placeholder="Selecione subsequente"
                onChange={newValue => {
                  const changeObject = newValue as { label: string };
                  handleState(changeObject.label);
                }}
                containerStyle={{
                  width: '100%',
                  maxWidth: '17.5rem',
                  border: '1px solid #E6E6EF',
                  borderRadius: '0.25rem',
                  marginBottom: '1rem',
                }}
                options={nextPaymentsMock}
              />
              <Select
                label="*Tempo de vigência"
                name="vigenceTime"
                placeholder="Selecione o tempo"
                onChange={newValue => {
                  const changeObject = newValue as { label: string };
                  setVigenceTime(changeObject.label);
                }}
                containerStyle={{
                  width: '100%',
                  maxWidth: '17.5rem',
                  border: '1px solid #E6E6EF',
                  borderRadius: '0.25rem',
                  marginBottom: '1rem',
                }}
                options={vigenceTimesMock}
              />
              <CalendarPicker
                label="*Data do primeiro pagamento"
                name="firstPayment"
                selected={firstPaymentDate}
                onChange={(date: Date) => setFirstPaymentDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                placeholderText="Selecione uma data"
              />
            </S.Row>

            <S.InputContainer>
              <label htmlFor="yes" className={financialContactError}>
                *O contato para fins financeiros é o mesmo do representante
                legal?
              </label>
              <RadioButton
                text="Sim"
                name="radio"
                onClick={() => {
                  setFinancialContactError('selected');
                  setIsActiveFinancialContact(false);
                }}
              />
              <RadioButton
                text="Nao"
                name="radio"
                onClick={() => {
                  setFinancialContactError('selected');
                  setIsActiveFinancialContact(true);
                }}
              />
              {financialContactError === 'error' && (
                <small>Informe a resposta</small>
              )}
            </S.InputContainer>

            {isActiveFinancialContact && (
              <S.FinancialContainer>
                <Input
                  label="*Nome do contato financeiro"
                  name="financialName"
                  placeholder="Nome do contato"
                  containerStyle={{ maxWidth: '32.5rem' }}
                />
                <Input
                  label="E-mail do contato financeiro"
                  name="financialMail"
                  placeholder="email@example.com"
                  containerStyle={{ maxWidth: '32.5rem' }}
                />
                <InputMask
                  mask="(99) 99999-9999"
                  label="*Telefone para contato do financeiro com DDD"
                  name="financialTel"
                  placeholder="(99) 99999-9999"
                  containerStyle={{ maxWidth: '32.5rem' }}
                />
              </S.FinancialContainer>
            )}
            <S.RadioContainer>
              <label
                htmlFor="yes"
                className={`${responsabilityError1} ${responsabilityError2}`}
              >
                *Declaração de responsabilidade
              </label>
              <Checkbox
                text="Declaro, sob as penas da Lei, que sou o representante responsável pela contratante. "
                name="responsability1"
                onClick={() => {
                  setResponsabilityError1('selected');
                }}
              />
              <Checkbox
                text="Declaro, ainda, que as informações e dados fornecidos são verídicos e autênticos, ficando ciente da minha responsabilidade, sob as penas da Lei."
                name="responsability2"
                onClick={() => {
                  setResponsabilityError2('selected');
                }}
              />
              {(responsabilityError1 === 'error' ||
                responsabilityError2 === 'error') && (
                <small>Informe a resposta</small>
              )}
            </S.RadioContainer>
            <S.ButtonsContainer style={{ flex: 1, justifyContent: 'flex-end' }}>
              <S.Button
                onClick={onDismiss}
                style={{
                  marginLeft: '1rem',
                  color: 'var(--primary-dark)',
                  backgroundColor: 'var(--white)',
                  border: '2px solid var(--primary-dark)',
                  fontWeight: '700',
                  fontSize: '14px',
                }}
              >
                Cancelar
              </S.Button>
              <S.Button
                type="submit"
                style={{
                  marginLeft: '1rem',
                  color: 'var(--secondary-main)',
                  backgroundColor: 'var(--success-2)',
                  border: '0',
                  fontWeight: '700',
                  fontSize: '14px',
                }}
              >
                Enviar
              </S.Button>
            </S.ButtonsContainer>
          </Form>
        )}

        {isOpenConfirmationModal && (
          <Dialog
            aria-labelledby="label"
            onDismiss={onDismiss}
            initialFocusRef={buttonRef}
            style={{
              display: 'flex',
              padding: '2.5rem',
              width: '80vw',
              height: '90vh',
              minHeight: '55rem',
              minWidth: '55rem',
              borderRadius: '0.5rem',
              marginBottom: '2rem',
              marginTop: '2rem',
            }}
          >
            <S.ConfirmationModal>
              <h2>Formulário enviado com sucesso</h2>
              <S.ButtonsContainer>
                <Link to="/meus-leads">
                  <Button>Fechar</Button>
                </Link>
              </S.ButtonsContainer>
            </S.ConfirmationModal>
          </Dialog>
        )}
        {isOpenErrorModal && (
          <ErrorModal
            ref={buttonRef}
            closeModal={() => setIsOpenErrorModal(false)}
            title="Tivemos algum problema ao processar sua solicitação de contrato!"
            description="Atualize a página e tente novamente..."
          />
        )}
        {isOpenCancelModal && (
          <Dialog
            aria-labelledby="label"
            onDismiss={onDismiss}
            initialFocusRef={buttonRef}
            style={{
              marginTop: '20vh',
              width: '100%',
              borderRadius: '0.5rem',
              maxWidth: '43.75rem',
            }}
          >
            <S.ConfirmationModal>
              {/* <S.CloseModalButton /> */}
              <FiXCircle />
              <h2>
                Os dados preenchidos serão perdidos, deseja realmente sair?
              </h2>
              <S.ButtonsContainer>
                <Button
                  outline
                  onClick={() => setIsOpenCancelModal(false)}
                  style={{ width: '10.25rem', height: '2.5rem' }}
                >
                  Cancelar
                </Button>
                <Link to="/meus-leads">
                  <Button style={{ width: '10.25rem', height: '2.5rem' }}>
                    Sair
                  </Button>
                </Link>
              </S.ButtonsContainer>
            </S.ConfirmationModal>
          </Dialog>
        )}
      </S.Container>
    </Dialog>
  );
}

export default ContractDetails;
