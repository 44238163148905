import { User } from '../../../../types/user';
import api from '../api';

export async function getUsersByUnitId(unitId: string) {
  try {
    const response = await api.get(`/permissions/unit/${unitId}`);
    const users: User[] = response.data;

    return users;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os usuários, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}
