import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const Content = styled.div`
  align-items: center;
  padding: 0 2.4rem 5rem 2.4rem;

  h1 {
    font-size: 20px;

    :nth-child(1) {
      margin-bottom: 0.5rem;
    }
  }

  > section:nth-child(3) {
    grid-template-columns: repeat(2, 1fr);
  }

  > section:nth-child(4) {
    grid-template-columns: repeat(2, 1fr);
  }

  > section:nth-child(5) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1rem;
  }
`;

export const LowerCaseText = styled.p`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 1.5rem;
`;

export const Balances = styled.strong`
  color: var(--success-2);
  font-size: 26px;
`;

export const WithdrawalData = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0rem;
  align-items: center;

  .bankTitle {
    padding-top: 2rem;
  }

  .userName {
    max-width: 18rem;
    line-height: 1.5rem;
  }

  div {
    p {
      margin-top: 1.3rem;
    }

    .nameUser {
      max-width: 25ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .summaryTooltip {
      span {
        background-color: var(--white);
        width: 200px;
        box-shadow: var(--grayscale-20) 0.1em 0.1em 0.2em;
        color: var(--secondary-main);
        ::before {
          content: '';
          border-style: solid;
          border-color: var(--grayscale-20) transparent;
          border-width: 0.65rem 0.65rem 0 0.65rem;
          top: 100%;
          position: absolute;
          left: 20%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          filter: drop-shadow(0rem 0.1rem 0.063rem var(--grayscale-20));
        }
        ::after {
          content: '';
          border-style: solid;
          border-color: var(--white) transparent;
          border-width: 0.55rem 0.55rem 0 0.55rem;
          top: 100%;
          position: absolute;
          left: 20%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      }
    }
  }
`;

export const Footer = styled.footer`
  width: 100%;
  position: relative;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem;
  background: var(--white);
`;
