import { IconBaseProps } from 'react-icons';
import * as S from './styles';

interface ClipBoarProps extends IconBaseProps {
  active: boolean;
}

export function ClipBoardCopy({ active, ...rest }: ClipBoarProps) {
  return <S.ClipBoardCopyIcon active={active} {...rest} />;
}
