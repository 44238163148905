import { useMemo } from 'react';
import { FiCopy, FiX } from 'react-icons/fi';

import { convertCentsToBRL } from '../../../../utils/convertCentsToBRL';
import * as S from './styles';
import { useDeposit } from '../../../../contexts';
import { ModalLoading } from '../../../../components/Default/Loadings/ModalLoading';

interface Props {
  paymentDetails: PaymentDetails;
  handleToggleModal: () => void;
  loading?: boolean;
}

export function Payment({ paymentDetails, handleToggleModal, loading }: Props) {
  const { paymentType } = useDeposit();

  const totalPrice = useMemo(() => {
    return convertCentsToBRL(paymentDetails?.value);
  }, [paymentDetails]);

  return (
    <>
      <S.Container>
        {paymentType === 'all' && (
          <S.ModalHeader>
            <h4 className="titleModal">Depósito</h4>

            <FiX
              className="closeButton"
              color="var(--secondary-main)"
              size={19}
              onClick={handleToggleModal}
            />
          </S.ModalHeader>
        )}
        {loading && (
          <div style={{ marginBottom: '2rem' }}>
            <ModalLoading rows={5} />
          </div>
        )}

        {paymentDetails && (
          <S.AllPaymentContent>
            {paymentDetails.value > 0 && (
              <S.PriceCard>
                <h5>{totalPrice}</h5>
              </S.PriceCard>
            )}

            {(paymentType === 'bank_slip' || paymentType === 'all') && (
              <S.BoletoSection paymentType={paymentType}>
                <S.BoletoImage>
                  <img src={paymentDetails.bankSlip?.barcode} alt="barCode" />

                  <S.Digitableline>
                    <p>{paymentDetails.bankSlip?.digitableLine}</p>

                    <button
                      aria-label="button action"
                      type="button"
                      onClick={() => {
                        paymentDetails.bankSlip?.digitableLine &&
                          navigator.clipboard.writeText(
                            paymentDetails.bankSlip.digitableLine
                          );
                      }}
                    >
                      <FiCopy size={20} />
                    </button>
                  </S.Digitableline>
                </S.BoletoImage>

                <strong>
                  Copie e cole o número do ticket no seu banco on-line
                </strong>

                <p>
                  Esse é um boleto registrado. Por favor verifique se as
                  informações estão corretas. Nunca pague por boleto que não
                  tenha sido solicitado por você
                </p>
              </S.BoletoSection>
            )}

            {(paymentType === 'pix' || paymentType === 'all') && (
              <S.PixSection paymentType={paymentType}>
                <header>
                  <p style={{ fontWeight: 'bold' }}>
                    Digitalize o código PIX QR com seu celular.
                  </p>
                </header>

                {paymentDetails.pix?.qrcode && (
                  <img src={paymentDetails.pix?.qrcode} alt="qrcode" />
                )}
                <p style={{ marginBottom: '0.5rem' }}>
                  Você está tendo problemas para ler o código QR? Copie o código
                  abaixo e insira-o manualmente
                </p>

                {paymentDetails.pix?.qrcodeText && (
                  <S.PixCode>
                    <p style={{ marginBottom: '0.5rem' }}>
                      {paymentDetails.pix?.qrcodeText}
                    </p>

                    <button
                      type="button"
                      aria-label="button action"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          paymentDetails.pix.qrcodeText
                        );
                      }}
                    >
                      <FiCopy size={20} />
                    </button>
                  </S.PixCode>
                )}
              </S.PixSection>
            )}
          </S.AllPaymentContent>
        )}
      </S.Container>
    </>
  );
}
