import { FaRegCopy } from 'react-icons/fa';
import styled from 'styled-components';

export interface ClipBoardCopyProps {
  active: boolean;
  size?: string | number;
}

export const ClipBoardCopyIcon = styled(FaRegCopy)<ClipBoardCopyProps>`
  color: ${props =>
    props.active ? 'var(--success-1)' : 'var(--secondary-main)'};
  margin-left: 0.5rem;
  cursor: copy;
`;
