import styled from 'styled-components';

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 50rem;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--terciary-light);
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  border-bottom: 3px solid var(--tertiary-light);

  :first-of-type {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const Field = styled.div`
  width: 30%;
  display: flex;
  padding: 1rem;
  align-items: center;

  img {
    margin-right: 0.5rem;
  }

  svg {
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
`;

export const UnHappyPath = styled.div`
  align-self: center;
  margin-top: 1rem;
  text-align: center;
`;
