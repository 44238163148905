import { cloneElement } from 'react';
import * as hooksProviders from '../contexts';

const Providers = {
  ...hooksProviders,
};

const providers = Object.keys(Providers).reduce((acc, curr) => {
  if (!curr.includes('Provider')) return acc;
  const Component = Providers[curr];
  return [...acc, <Component key={`Context-key:${Math.random()}`} />];
}, []);

export const ContextProvider = ({ children: initial }) =>
  providers.reduce(
    (children, parent) => cloneElement(parent, { children }),
    initial
  );
