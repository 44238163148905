import { Button, SectionTitle } from 'v4components-react';
import { BiUser } from 'react-icons/bi';
import * as S from './styles';
import { accounts } from '../../mocks';
import { useWithDraw } from '../../../../contexts/withdraw';

interface AccountsPageProps {
  loading: boolean;
}

const Accounts: React.FC<AccountsPageProps> = ({ loading }) => {
  const { setPage } = useWithDraw();

  return (
    <S.Container>
      <SectionTitle text="Contas frequentes" />

      <S.AccountContents>
        <S.BoxAvatar>
          {accounts?.map(({ id, image, name }) => (
            <S.AvatarAccount key={id}>
              <img src={image} alt={name} />

              <S.LowerCaseText>{name}</S.LowerCaseText>
            </S.AvatarAccount>
          ))}

          <button type="button" onClick={() => setPage('info')}>
            <S.AvatarIcon>
              <BiUser size={16} />
            </S.AvatarIcon>

            <S.LowerCaseText>Nova conta</S.LowerCaseText>
          </button>
        </S.BoxAvatar>
      </S.AccountContents>

      <footer>
        <Button
          size="default"
          variant="primary"
          color="green"
          type="submit"
          onClick={() => ''}
          loading={loading}
          disabled={loading}
        >
          Seguir para o resumo da transação
        </Button>
      </footer>
    </S.Container>
  );
};

export default Accounts;
