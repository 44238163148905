import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  input {
    height: 1.5rem;
    margin-right: 0.5rem;
    color: var(--grayscale-06);
  }
`;
