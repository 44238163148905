import { useState, useEffect, useRef, useCallback } from 'react';

export const useIdleTimeout = (idleTimeout: number, resetTimeout: number) => {
  const [idle, setIdle] = useState(false);
  const visibility = useRef(true);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const resetIdRef = useRef<NodeJS.Timeout | null>(null);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden) {
      visibility.current = true;
      setIdle(false);
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      if (resetIdRef.current) {
        clearTimeout(resetIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        if (visibility.current) {
          setIdle(true);
        }
      }, idleTimeout);
    } else {
      visibility.current = false;
    }
  }, [idleTimeout, setIdle, visibility]);

  const handleIdle = useCallback(() => {
    clearTimeout(resetIdRef.current);
    resetIdRef.current = setTimeout(() => {
      setIdle(true);
    }, resetTimeout);
  }, [resetTimeout, setIdle]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('mousemove', handleIdle);
    window.addEventListener('keydown', handleIdle);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('mousemove', handleIdle);
      window.removeEventListener('keydown', handleIdle);
      clearTimeout(timeoutIdRef.current);
      clearTimeout(resetIdRef.current);
    };
  }, [handleIdle, handleVisibilityChange]);

  return idle;
};
