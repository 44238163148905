import Tippy from '@tippyjs/react';
import styled from 'styled-components';

export const StyledTippy = styled(Tippy)`
  background-color: transparent !important;

  .tippy-content {
    background-color: white;
    color: black;
    border: 1px solid #a7afb2;
    border-radius: 8px;
  }
`;
