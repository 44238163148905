import { Location, NavigateFunction } from 'react-router-dom';

const brokerManagerUrl = process.env.REACT_APP_BROKER_MANAGER_URL;
const transitionUrl = process.env.REACT_APP_TRANSITION_URL;
const leadBrokerUrl = process.env.REACT_APP_HB_LEADS_FRONT_URL;
const meetingBrokerUrl = process.env.REACT_APP_MEETINGBROKER_URL;
const mktlabUrl = process.env.REACT_APP_MKTLAB_URL;
const labFinanceiroUrl = process.env.REACT_APP_LABFINANCEIRO;

interface MenuOptions {
  unitId: string;
  lbUser: boolean | undefined;
  managerUser: boolean | undefined;
  isHeadquarter: boolean;
  transition: boolean;
  unitBlocked: boolean;
  navigate: NavigateFunction;
  location: Location;
}

export function menus({
  unitId,
  lbUser,
  isHeadquarter,
  transition,
  managerUser,
  unitBlocked,
  navigate,
  location,
}: MenuOptions) {
  return [
    {
      title: 'Broker',
      slot: 'products-v4',
      icon: 'request_quote',
      active:
        window.location.href.includes(leadBrokerUrl) ||
        window.location.href.includes(meetingBrokerUrl),
      conditional: lbUser,
      subMenus: [
        {
          title: 'LeadBroker',
          slot: 'products-v4',
          icon: 'show_chart',
          active: window.location.href.includes(leadBrokerUrl),
          conditional: lbUser,
          subMenus: [
            {
              title: 'Ofertas',
              icon: 'credit_card',
              active:
                window.location.href.includes(leadBrokerUrl) &&
                !(
                  window.location.href.includes('meus-leads') ||
                  window.location.href.includes('carteira')
                ),
              event: () => {
                if (!unitBlocked) navigate('/');
              },
            },
            {
              title: 'Meus leads',
              icon: 'group',
              active: location.pathname.includes('meus-leads'),
              event: () => {
                if (!unitBlocked) navigate('/meus-leads');
              },
            },
          ],
        },
        {
          title: 'MeetingBroker',
          slot: 'products-v4',
          icon: 'event',
          active: false,
          conditional: lbUser,
          subMenus: [
            {
              title: 'Ofertas',
              icon: 'credit_card',
              active: false,
              event: () => {
                if (!unitBlocked) window.location.href = meetingBrokerUrl;
              },
            },
            {
              title: 'Minhas Reuniões',
              icon: 'date_range',
              event: () => {
                window.location.href = `${meetingBrokerUrl}/minhas-reunioes`;
              },
              active: false,
            },
          ],
        },
        {
          title: 'Projetos para transição',
          slot: 'products-v4',
          icon: 'swap_horiz',
          active: false,
          conditional: transition,
          subMenus: [
            {
              title: 'Ofertas',
              icon: 'credit_card',
              active: false,
              event: () => {
                window.location.href = `${transitionUrl}`;
              },
            },
            {
              title: 'Meus interesses',
              icon: 'bookmark',
              active: false,
              event: () => {
                window.location.href = `${transitionUrl}/meus-interesses`;
              },
            },
            {
              title: 'Enviar para transição',
              icon: 'subdirectory_arrow_right',
              active: false,
              event: () => {
                window.location.href = `${transitionUrl}/nova-transicao`;
              },
            },
            {
              title: 'Transições recebidas',
              icon: 'task',
              active: false,
              event: () => {
                window.location.href = `${transitionUrl}/minhas-transicoes`;
              },
            },
          ],
        },
        {
          title: 'Broker Manager',
          slot: 'products-v4',
          icon: 'manage_accounts',
          active: false,
          conditional: managerUser,
          event: () => {
            window.location.href = brokerManagerUrl;
          },
        },
        {
          title: 'Carteira',
          slot: 'products-v4',
          icon: 'wallet',
          active: location.pathname.includes('carteira'),
          conditional: lbUser,
          event: () => {
            navigate('/carteira');
          },
        },
        {
          title: 'Extrato',
          slot: 'products-v4',
          icon: 'list',
          active: location.pathname.includes('extract'),
          conditional: lbUser,
          event: () => {
            navigate('/extract');
          },
        },
      ],
    },

    {
      title: 'MediaHub',
      slot: 'products-v4',
      icon: 'grid_view',
      active: false,
      event: () => {
        window.location.href = `${mktlabUrl}/clientes/mediahub`;
      },
      conditional: true,
    },
    {
      title: 'V4 Pay',
      slot: 'products-v4',
      icon: 'qr_code_scanner',
      active: false,
      event: () => {
        window.location.href = `${mktlabUrl}/gateway/headquarter`;
      },
      conditional: isHeadquarter,
    },
    {
      title: 'Central de dasboards',
      slot: 'products-v4',
      icon: 'bar_chart',
      active: false,
      event: () => {
        window.location.href = `${mktlabUrl}/dashboards`;
      },
      conditional: true,
    },
    {
      title: 'Lab Financeiro',
      slot: 'products-v4',
      icon: 'paid',
      active: false,
      event: () => {
        window.location.href = labFinanceiroUrl;
      },
      conditional: true,
    },
    {
      title: 'Headquarter',
      slot: 'rede-v4',
      icon: 'apartment',
      active: false,
      event: () => {
        window.location.href = `${mktlabUrl}/headquarter`;
      },
      conditional: isHeadquarter,
    },
    {
      title: 'Franquias',
      slot: 'rede-v4',
      icon: 'home_repair_service',
      active: false,
      event: () => {
        window.location.href = `${mktlabUrl}/unidades`;
      },
      conditional: isHeadquarter,
    },
    {
      title: 'Clientes',
      slot: 'rede-v4',
      icon: 'work',
      active: false,
      event: () => {
        window.location.href = `${mktlabUrl}/clientes`;
      },
      conditional: isHeadquarter,
    },
    {
      title: 'Minha Unidade',
      slot: 'rede-v4',
      icon: 'home_repair_service',
      active: false,
      conditional: !isHeadquarter && unitId,
      subMenus: [
        {
          title: 'Clientes',
          icon: 'work',
          event: () => {
            window.location.href = `${mktlabUrl}/clientes`;
          },
          conditional: true,
          active: false,
        },
        {
          title: 'Investidores',
          icon: 'person',
          event: () => {
            window.location.href = `${mktlabUrl}/unit/read/${unitId}`;
          },
          conditional: true,
          active: false,
        },
      ],
    },
  ];
}
