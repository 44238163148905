import { IDataHeader } from '../../../contexts';

export const headersMyLeads: IDataHeader[] = [
  {
    colKey: 'name_id',
    title: 'Nome do lead',
    type: 'text',
  },
  {
    colKey: 'phone',
    title: 'Telefone',
    type: 'text',
  },
  {
    colKey: 'revenue',
    title: 'Faturamento',
    type: 'text',
  },
  {
    colKey: 'statusLead',
    title: 'Status',
    type: 'badge',
  },
  {
    colKey: 'winner',
    title: 'Arrematador',
    type: 'text',
  },
  {
    colKey: 'type',
    title: 'Tipo de oferta',
    type: 'text',
  },
  {
    colKey: 'date',
    title: 'Data da compra',
    type: 'text',
  },
  {
    colKey: 'value',
    title: 'Valor',
    type: 'text',
  },
];

export type LeadStatus =
  | 'Lead de teste'
  | 'Já atendido pela Matriz'
  | 'Já atendido por outra unidade'
  | 'Lead já é cliente'
  | 'Lead duplicado'
  | 'Fatura pouco para o segmento'
  | 'Não tem urgência'
  | 'Engano / Sem necessidade'
  | 'Blacklist / Negócio ruim'
  | 'Adolescente / Criança'
  | 'Não consegui contato'
  | 'Numero errado'
  | 'Cadastro Spam'
  | 'Queria ser Franqueado'
  | 'Não era o dono e não justifica varias reuniões'
  | 'REJECTED'; // Adicione "REJECTED" ao tipo LeadStatus

export const headersRefund: IDataHeader[] = [
  {
    colKey: 'name_id',
    title: 'Nome do lead',
    type: 'text',
  },
  {
    colKey: 'reason',
    title: 'Motivo',
    type: 'text',
  },
  {
    colKey: 'date_refund',
    title: 'Data da solicitação',
    type: 'text',
  },
  {
    colKey: 'requester',
    title: 'Solicitante',
    type: 'text',
  },
  {
    colKey: 'value',
    title: 'Valor',
    type: 'text',
  },
  {
    colKey: 'status',
    title: 'Status reembolso',
    type: 'badge',
  },
];

export const statusLabel = {
  UNDER_ANALYSIS: 'Em análise',
  WAITING: 'Teste',
  REJECTED: 'Negado',
  APPROVED: 'Enviado',
};
