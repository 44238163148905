import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useAuth } from '../contexts/auth';
import api from '../services/requests/leadbroker/api';

type BalanceContextData = {
  balance: number;
  availableWithdraw: number;
  updateBalance: () => void;
  lastBalanceUpdate: Date;
  setBalance: Dispatch<SetStateAction<number>>;
  totalBonusAmount: number;
  financialAmount: number;
  bonusAmount: number;
  availableBonus: Record<string, unknown>[];
  expiredBonus: Record<string, unknown>[];
  financeBonus: Record<string, unknown>[];
};

export const BalanceContext = createContext({} as BalanceContextData);

export function BalanceProvider({ children }: { children: ReactNode }) {
  const [balance, setBalance] = useState<number>(0);
  const [availableWithdraw, setAvailableWithdraw] = useState<number>(0);
  const [totalBonusAmount, setTotalBonusAmount] = useState<number>(0);
  const [financialAmount, setFinancialAmount] = useState<number>(0);
  const [bonusAmount, setBonusAmount] = useState<number>(0);
  const [lastBalanceUpdate, setLastBalanceUpdate] = useState<Date>(new Date());
  const [expiredBonus, setExpiredBonus] = useState<Record<string, unknown>[]>(
    []
  );
  const [availableBonus, setAvailableBonus] = useState<
    Record<string, unknown>[]
  >([]);
  const [financeBonus, setFinanceBonus] = useState<Record<string, unknown>[]>(
    []
  );
  const { user } = useAuth();

  const updateBalance = useCallback(async () => {
    const response = await api.get<{
      available: number;
      totalBonusAmount: number;
      financialAmount: number;
      bonusAmount: number;
      availableBonus: Record<string, unknown>[];
      expiredBonus: Record<string, unknown>[];
      financeBonus: Record<string, unknown>[];
    }>(`/wallet/balance/${user?.unitId}?detailed=true`);

    const currency = response.data.available;

    setLastBalanceUpdate(new Date());
    setBalance(currency + (response.data.totalBonusAmount || 0));
    setAvailableWithdraw(currency);
    setTotalBonusAmount(response.data.totalBonusAmount || 0);
    setFinancialAmount(response.data.financialAmount || 0);
    setBonusAmount(response.data.bonusAmount || 0);
    setAvailableBonus(response.data.availableBonus || []);
    setExpiredBonus(response.data.expiredBonus || []);
    setFinanceBonus(response.data.financeBonus || []);
  }, [user]);

  return (
    <BalanceContext.Provider
      value={{
        balance,
        availableWithdraw,
        updateBalance,
        lastBalanceUpdate,
        setBalance,
        totalBonusAmount,
        financialAmount,
        bonusAmount,
        availableBonus,
        expiredBonus,
        financeBonus,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
}

export const useBalance = (): BalanceContextData => {
  return useContext(BalanceContext);
};
