import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import {
  Container,
  SectionContent,
  SectionTitle,
  Section,
} from 'v4components-react';
import {
  LabDsButton,
  LabDsTooltip,
  LabDsBreadcrumb,
  LabDsTable,
  LabDsTab,
} from 'v4web-components-react';
import { IData } from 'v4web-components';
import * as S from './styles';
import { getAuctions } from '../../services/requests/leadbroker/auctions/getAuctions';
import { useAuctions } from '../../contexts/auctions';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnHappyPath';
import { convertCentsToBRL } from '../../utils/convertCentsToBRL';
import { formatDate } from '../../utils/dateFunctions';
import { downloadMyLeadsCsv } from '../../services/requests/leadbroker/auctions/downloadMyLeadsCsv';
import { LeadStatus, headersMyLeads, headersRefund } from './utils/table';
import { getRefund } from '../../services/requests/leadbroker/auctions/refound';
import { useLeads } from '../../contexts';
import { useBalance } from '../../hooks/wallet';
import { leadStatus, statusRefund } from './utils/statusRefund';

const MyLeads: FC = () => {
  const [loading, setLoading] = useState(false);
  const { balance, totalBonusAmount } = useBalance();
  const sumBalance = balance + totalBonusAmount;

  const {
    myLeads,
    setMyLeads,
    actualPage,
    totalPages,
    setActualPage,
    setTotalPages,
  } = useAuctions();
  const {
    refundRequests,
    setRefundRequests,
    tab,
    setTab,
    headers,
    setHeaders,
  } = useLeads();

  const location = useLocation();
  const navigate = useNavigate();
  const arrayOfTotalPages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const setMyLeadsInfo = useCallback(
    async (page = 1) => {
      setLoading(true);
      if (tab === 'myLeads') {
        const newMyLeads = await getAuctions(`?won=true&page=${page}&limit=12`);

        if (newMyLeads) {
          setRefundRequests([]);
          setMyLeads(newMyLeads?.data);
          setActualPage(newMyLeads.page);
          setTotalPages(newMyLeads.totalPages);
        }
      }

      if (tab === 'refundRequests') {
        setMyLeads([]);
        const refund = await getRefund('50', '1');

        if (refund) {
          setRefundRequests(refund.data);
          setActualPage(refund.page);
          setTotalPages(refund.totalPages);
        }
      }

      setLoading(false);
    },
    [tab, setMyLeads, setActualPage, setTotalPages, setRefundRequests]
  );

  useEffect(() => {
    document.title = 'Meus Leads - V4 LB';
    setMyLeadsInfo();
  }, [setMyLeadsInfo, location]);

  const handleRowClick = useCallback(
    (id: string, status?: LeadStatus) => {
      if (tab === 'myLeads' || status === 'REJECTED') {
        navigate(`/lead/${id}`, {
          state: { backgroundLocation: location },
        });

        return;
      }

      if (tab === 'refundRequests') {
        navigate(`/refund/${id}`, {
          state: { backgroundLocation: location },
        });
      }
    },
    [tab, navigate, location]
  );

  const tableData = useMemo<IData>(() => {
    if (myLeads?.length > 0) {
      const data = myLeads?.map(auction => ({
        lead_id: {
          text: { title: `#${auction?._id}` },
          handleRow: () => {
            handleRowClick(auction._id);
          },
        },
        name_id: {
          text: { title: auction?.lead?.company },
          handleRow: () => {
            handleRowClick(auction._id);
          },
        },
        phone: {
          text: { title: auction?.lead?.tel },
          handleRow: () => {
            handleRowClick(auction._id);
          },
        },
        revenue: {
          text: { title: auction?.lead?.revenue },
          handleRow: () => {
            handleRowClick(auction._id);
          },
        },
        statusLead: {
          // TO DO: change to badge when status returns from API
          badge: {
            label: leadStatus[auction?.steps || 'LEAD_PURCHASED']?.label,
            state: leadStatus[auction?.steps || 'LEAD_PURCHASED']?.state,
          },
          handleRow: () => {
            handleRowClick(auction._id);
          },
        },
        winner: {
          text: {
            title: auction?.winner?.name,
            avatar: true,
            avatarImgSRC: auction?.winner?.picture,
          },
          handleRow: () => {
            handleRowClick(auction._id);
          },
        },
        type: {
          text: {
            title: auction.packId ? 'Oferta em pack' : 'Oferta unitária',
          },
          handleRow: () => {
            handleRowClick(auction._id);
          },
        },
        date: {
          text: {
            title: formatDate(auction?.winner?.boughtAt || auction?.expiresAt),
            handleRow: () => {
              handleRowClick(auction._id);
            },
          },
        },
        value: {
          text: { title: convertCentsToBRL(auction?.winner?.value) },
          handleRow: () => {
            handleRowClick(auction._id);
          },
        },
      }));
      return {
        headers,
        rows: data,
      };
    }
    if (refundRequests?.length > 0) {
      const data = refundRequests
        ?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        .map(refund => ({
          name_id: {
            text: { title: refund?.lead.name },
            handleRow: () => {
              handleRowClick(refund._id, refund?.refund?.status as LeadStatus);
            },
          },
          reason: {
            text: { title: refund?.refund?.reason },
            handleRow: () => {
              handleRowClick(refund._id, refund?.refund?.status as LeadStatus);
            },
          },
          date_refund: {
            text: { title: formatDate(refund?.createdAt) },
            handleRow: () => {
              handleRowClick(refund._id, refund?.refund?.status as LeadStatus);
            },
          },
          requester: {
            text: { title: refund?.refund?.whoRequest?.name },
            handleRow: () => {
              handleRowClick(refund._id, refund?.refund?.status as LeadStatus);
            },
          },
          value: {
            text: {
              title: convertCentsToBRL(refund?.winner?.value),
            },
            handleRow: () => {
              handleRowClick(refund._id, refund?.refund?.status as LeadStatus);
            },
          },
          status: {
            badge: {
              label: statusRefund[refund?.refund?.status as LeadStatus].label,
              state: statusRefund[refund?.refund?.status as LeadStatus].state,
            },
            handleRow: () => {
              handleRowClick(refund._id, refund?.refund?.status as LeadStatus);
            },
          },
        }));
      return {
        headers,
        rows: data,
      };
    }
    return { headers, rows: [] };
  }, [myLeads, refundRequests, headers, handleRowClick]);

  return (
    <Container>
      <LabDsBreadcrumb
        breadcrumbs={[
          {
            label: 'Lead Broker',
            link: '/',
            key: 'initial',
            event: () => {
              navigate('/');
            },
          },
          {
            label: 'Meus leads',
            link: '/meus-leads',
            key: 'myLeads',
            event: () => {
              navigate('/meus-leads');
            },
          },
        ]}
      />
      <Section>
        <SectionContent style={{ maxHeight: '80vh', overflow: 'auto' }}>
          <S.InformationContainer>
            <SectionTitle text="Meus Leads" />

            <S.Balance>
              <h4>Saldo:</h4>
              <strong>
                {sumBalance ? convertCentsToBRL(sumBalance) : 'R$ 0,00'}
              </strong>
            </S.Balance>
          </S.InformationContainer>

          <S.Row>
            <LabDsTab
              value={tab}
              tabs={[
                {
                  title: 'Meus leads',
                  key: 'myLeads',
                  event: () => {
                    setTab('myLeads');
                    setHeaders(headersMyLeads);
                  },
                },
                {
                  title: 'Solicitações de reembolso',
                  key: 'refundRequests',
                  event: () => {
                    setTab('refundRequests');
                    setHeaders(headersRefund);
                  },
                },
              ]}
            />
            <S.ContainerButtonDownloadCSV>
              <S.ButtonDownloadCSV>
                <LabDsTooltip
                  tooltipPosition="seven-hours"
                  label="Baixar planilha .CSV"
                >
                  <LabDsButton
                    leadingIcon="download"
                    variant="outlined"
                    size="medium"
                    onHandleButton={() => downloadMyLeadsCsv()}
                  />
                </LabDsTooltip>
              </S.ButtonDownloadCSV>
            </S.ContainerButtonDownloadCSV>
          </S.Row>
          <S.Table>
            <LabDsTable loading={loading} data={tableData} />
          </S.Table>
          {arrayOfTotalPages?.length > 0 && (
            <S.Pagination>
              <FaAngleLeft onClick={() => setMyLeadsInfo(1)} />
              {arrayOfTotalPages.map(page => (
                <button
                  key={page}
                  onClick={() => setMyLeadsInfo(page)}
                  className={page === actualPage ? 'isActive' : ''}
                  type="button"
                >
                  {page}
                </button>
              ))}
              <FaAngleRight
                onClick={() => setMyLeadsInfo(arrayOfTotalPages?.length)}
              />
            </S.Pagination>
          )}
          {!loading && !myLeads?.length && !refundRequests.length && (
            <S.UnHappyPath>
              <UnHappyPath title="Você ainda não possui Leads" assetIndex={3} />
            </S.UnHappyPath>
          )}
        </SectionContent>
      </Section>
    </Container>
  );
};

export default MyLeads;
