import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import BidCard from '../../../Default/BidCard';
import { CustomLoading } from '../../../Default/Loadings/CustomLoading';
import { UnHappyPath } from '../../atoms/UnHappyPath';
import * as S from './styles';
import { useAuctions } from '../../../../contexts';
import { getAuctionsUnitFederation } from '../../../../services/requests/leadbroker/auctions/getAuctionsFederation';

export const AuctionUnitContainer = ({
  isLastVisible,
}: {
  isLastVisible: boolean;
}) => {
  const { expPurchasedAuctions, actualTab } = useAuctions();

  const filterUnitaryAuctions = () => {
    return expPurchasedAuctions.filter(
      auc => auc.lead !== undefined
    ) as Auction[];
  };

  const { data, fetchNextPage, isLoading, refetch } = useInfiniteQuery({
    queryKey: ['unit-auctions'],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const response = ((await getAuctionsUnitFederation(
        pageParam
      )) as unknown) as AuctionAllResponse;

      return response;
    },
    getNextPageParam: lastPage => {
      return lastPage.data?.length > 0
        ? Number(lastPage?.page || 0) + 1
        : undefined;
    },
  });

  useEffect(() => {
    if (isLastVisible) {
      fetchNextPage();
    }
  }, [isLastVisible, fetchNextPage]);

  useEffect(() => {
    refetch();
  }, [refetch, actualTab]);

  const unhappyPath = useMemo(
    () => ({
      asset: 0,
      text: 'Ofertas ainda não disponíveis',
    }),
    []
  );

  return (
    <>
      {isLoading ? (
        <CustomLoading />
      ) : (
        <S.Container>
          <S.Bids>
            {filterUnitaryAuctions()?.map(auction => (
              <BidCard
                key={auction._id}
                auction={auction as Auction}
                expressPurchased
              />
            ))}
            {data?.pages?.map(page =>
              page?.data
                ?.sort((a, b) => (a.expiresAt < b.expiresAt ? -1 : 1))
                ?.map(auction => {
                  return (
                    <>
                      {auction?._id && (
                        <BidCard
                          key={auction._id}
                          auction={auction as Auction}
                        />
                      )}
                    </>
                  );
                })
            )}
          </S.Bids>
          {!isLoading &&
            data?.pages[0]?.data?.length === 0 &&
            !filterUnitaryAuctions()?.length && (
              <S.UnhappyPath>
                <UnHappyPath
                  title={unhappyPath.text}
                  assetIndex={unhappyPath.asset}
                />
              </S.UnhappyPath>
            )}
        </S.Container>
      )}
    </>
  );
};
