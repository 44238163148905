import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 1.38rem;
  color: var(--secondary-main);
  margin-bottom: 1rem;
`;

export const EventsWrapper = styled.div`
  height: 10rem;
  overflow-y: auto;
  font-size: 1.25rem;
`;

export const Event = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  box-sizing: border-box;
`;

export const EventDate = styled.span`
  color: var(--secondary-main);
  font-size: 1rem;
  font-family: Nunito Sans;
  font-weight: 700;
  margin-right: 2rem;
`;

export const EventMessage = styled.span`
  align-items: center;
  margin-right: 1.5rem;
  font-size: 1rem;
  font-family: Nunito Sans;
  font-weight: 700;
  display: flex;
  gap: 0.5rem;
  &.success {
    color: var(--secondary-main);
  }
  &.error {
    color: var(--primary-main);
  }
`;
