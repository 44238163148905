import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const FilterTabs = styled.div`
  display: none;
  padding: 1rem;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const Filter = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background: transparent;
  border: 2px solid var(--primary-dark);
  color: var(--primary-dark);
  font-weight: 700;
  border-radius: 0.25rem;
  margin-right: auto;

  svg {
    font-size: 1rem;
    color: var(--primary-dark);
    margin-right: 0.5rem;
  }
`;

export const SectionPackage = styled.div`
  display: flex;
  cursor: pointer;
  background: var(--white);
  border: 2px solid var(--tertiary-dark);
  box-shadow: 0px 1px 4px 0px var(--tertiary-dark);
  width: auto;
  border-radius: 0px 4px 4px 0px;
  padding: 1rem 1.5rem;
  height: auto;
  position: absolute;
  right: 0;
  top: 0px;
  width: 300px;
  transition: width 0.8s;
  height: 104px;
  &:hover {
    width: 450px;
    .info {
      opacity: 1;
      display: flex;
      flex-direction: column;
      margin-left: 26px;
    }
  }
`;
export const HeaderSwitch = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  padding-right: 1rem;
  margin-bottom: 0.6rem;
`;

export const Balance = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;

  h4 {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: var(--grayscale-60);
  }

  strong {
    font-size: 1.38rem;
    font-weight: 700;
    line-height: 2rem;
    font-family: 'Inter', sans-serif;
    color: var(--success-2);
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

export const SectionRefurbished = styled.div`
  display: flex;
  cursor: pointer;
  background: var(--white);
  border: 2px solid var(--tertiary-dark);
  box-shadow: 0px 1px 4px 0px var(--tertiary-dark);
  width: auto;
  border-radius: 0px 4px 4px 0px;
  padding: 1rem 1.5rem;
  height: auto;
  position: absolute;
  right: 0;
  top: 0px;
  width: 320px;
  transition: width 0.8s;
  height: 104px;
`;

export const ContainerPackage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    line-height: 24px;
    color: var(--secondary-main);
    font-weight: 700;
  }
`;

export const ContainerRefurbished = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  h5 {
    white-space: nowrap;
    margin-left: auto;
  }

  img {
    width: 24px;
    height: 24px;
  }

  p {
    line-height: 24px;
    color: var(--secondary-main);
    font-weight: 700;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  .flag {
    width: 8px;
    height: 104px;
    border-radius: 4px 0px 0px 4px;
    background: ${() => {
      const now = new Date();
      return now.getHours() >= 8 &&
        now.getHours() <= 9 &&
        (now.getHours() !== 9 || now.getMinutes() < 30)
        ? 'var(--warning)'
        : 'var(--success-2)';
    }};
    position: absolute;
    z-index: 99999;
    right: 296px;
    height: 104px;
    top: -1.5px;
    transition: right 0.8s;
  }
  &:hover {
    .flag {
      right: 448px;
    }
  }

  .avaiable-at {
    white-space: nowrap;
  }

  .refurbished-avaiable-at {
    white-space: nowrap;
  }
`;

export const RowRefurbished = styled.div`
  display: flex;
  align-items: center;
  .flag {
    width: 8px;
    height: 104px;
    border-radius: 4px 0px 0px 4px;
    background: ${() => {
      const now = new Date();
      return now.getHours() >= 8 &&
        now.getHours() <= 9 &&
        (now.getHours() !== 9 || now.getMinutes() < 30)
        ? 'var(--warning)'
        : 'var(--success-2)';
    }};
    position: absolute;
    z-index: 99999;
    right: 316px;
    height: 104px;
    top: -1.5px;
    transition: right 0.8s;
  }
  .refurbished-avaiable-at {
    white-space: nowrap;
    margin-left: auto;
  }
`;

export const TimeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h3 {
    font-size: 1.375rem;
  }
`;

export const Time = styled.span<{ updateToTimeUpdateColor?: boolean }>`
  transition: 0.4s;
  color: var(--error);
  margin-left: 5px;
`;

export const Icon = styled.img`
  width: 18px;
  color: white;
`;

export const Info = styled.div`
  opacity: 0;
  transition: opacity 1000ms;
  height: auto;
  width: auto;
  position: absolute;
  width: auto;
  height: auto;
  right: 16px;
  & {
    p {
      font-size: 12px;
      font-weight: 700px;
    }
    small {
      display: flex;
      flex-direction: row-reverse;
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: fit-content;
  width: fit-content;
  margin: 0 auto;
  background-color: var(--white);

  svg {
    font-size: 5rem;
    color: var(--primary-main);
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  button + button {
    margin-left: 2rem;
    background-color: var(--succcess-2);
    border: none;
    color: var(--white);
  }

  button {
    color: var(--succcess-2);
    border-color: var(--succcess-2);
    border-radius: 0.5rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export const ButtonLink = styled(Link)`
  height: 3.5rem;
  border-radius: 0.5rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  border: 0;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  background: var(--primary-dark);
  color: var(--white);
  margin-left: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: none;
  height: 2.5rem;
  &:hover {
    opacity: 0.5;
  }
`;
