import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MdOutlineFileDownload } from 'react-icons/md';

export const Table = styled.div`
  padding-top: 2rem;
`;

export const SeeMore = styled(Link)`
  display: flex;
  align-self: center;
  color: var(--primary-dark);
  font-weight: 500;
  border: none;
  background: transparent;
  text-decoration: none;

  svg {
    font-size: 1.25rem;
  }
`;

export const Pagination = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;

  svg {
    font-size: 0.625rem;
    color: var(--secondary-dark);
    margin: 0 0.25rem;
    cursor: pointer;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    color: var(--lab-ds-semantic-selectable-color-primary-default);
    background-color: transparent;
    font-size: 0.75rem;
    border: none;

    &.isActive {
      background-color: var(--lab-ds-semantic-selectable-color-primary-default);
      color: var(--white);
    }
  }

  button + button {
    margin-left: 0.2rem;
  }
`;

export const UnHappyPath = styled.div`
  align-self: center;
  margin-top: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerButtonDownloadCSV = styled.div`
  display: flex;
  justify-content: right;
  padding-right: 1rem;
  button {
    background-color: var(--white);
    border-style: none;
  }
  .tooltip {
    span {
      background-color: var(--white);
      left: -177%;
      width: 10.188rem;
      height: 3.375rem;
      border: 0.063rem solid var(--grayscale-20);
      border-color: var(--grayscale-20);
      color: var(--secondary-main);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: var(--grayscale-20) 0.1em 0.1em 0.2em;
      font-size: 0.75rem;
      ::before {
        content: '';
        border-style: solid;
        border-color: var(--grayscale-20) transparent;
        border-width: 0.65rem 0.65rem 0 0.65rem;
        top: 100%;
        position: absolute;
        left: 85%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        filter: drop-shadow(0rem 0.1rem 0.063rem var(--grayscale-20));
      }
      ::after {
        content: '';
        border-style: solid;
        border-color: var(--white) transparent;
        border-width: 0.55rem 0.55rem 0 0.55rem;
        top: 100%;
        position: absolute;
        left: 85%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
`;

export const ButtonDownloadCSV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconDownloadCSV = styled(MdOutlineFileDownload)`
  width: 1.563rem;
  height: 1.563rem;
  color: var(--primary-dark-2);
`;

export const InformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  padding-right: 1rem;
  margin-bottom: 0.6rem;
`;

export const Balance = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;

  h4 {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: var(--grayscale-60);
  }

  strong {
    font-size: 1.38rem;
    font-weight: 700;
    line-height: 2rem;
    font-family: 'Inter', sans-serif;
    color: var(--success-2);
  }
`;
