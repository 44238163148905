import { parseCookies } from 'nookies';
import apiFederation from '../apiFederation';

export async function getTransactions(
  flow: string,
  type?: string[],
  currentPage?: number
) {
  try {
    const cookies = parseCookies(undefined);
    const queryParams = type ? JSON.stringify(type) : '';

    const response = await apiFederation.get(
      `/transactions?flow=${flow}&type=${queryParams}&page=${currentPage}&perPage=10`,
      {
        headers: { Authorization: `Bearer ${cookies['v4company.token']}` },
      }
    );
    const wallet = response?.data;

    return wallet;
  } catch (err) {
    return err.response?.data;
  }
}
