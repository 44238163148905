import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string().required('Nome do cartao é obrigatório'),
  email: Yup.string()
    .typeError('Digite apenas número')
    .required('Número é obrigatório'),
  cpfCnpj: Yup.string().required('Obrigatório'),
  phone: Yup.string(),
});
