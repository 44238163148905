import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ContainerProps {
  hide?: boolean;
  deleted?: boolean;
  bidsPack?: number;
  valueColor?: string;
  expressPurchased?: boolean;
}

export const Icon = styled.img`
  width: 1.125rem;
`;

export const PriceContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TimeContent = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h3 {
    font-size: 1.375rem;
    margin-left: 0.2rem;
  }
`;

export const HeaderCard = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  top: 0.5rem;
`;

export const TopContent = styled.div`
  width: 100%;
  padding: 1rem 1rem 0 1rem;
`;

export const LeadType = styled.span<ContainerProps>`
  width: max-content;
  height: 1.5rem;
  border-radius: 2.688rem;
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-dark);
  padding: 4px 16px;
  display: flex;
  align-items: center;

  ${({ valueColor }) =>
    valueColor === 'yellow' &&
    css`
      background: var(--warning);
      color: var(--secondary-main);
    `};

  ${({ valueColor }) =>
    valueColor === 'green' &&
    css`
      background: var(--success-2);
      color: var(--secondary-main);
    `}

  ${({ valueColor }) =>
    valueColor === 'red' &&
    css`
      background: var(--error);
      color: var(--white);
    `}
`;

export const Container = styled(Link)<ContainerProps>`
  position: relative;
  width: 23rem;
  max-width: 23rem;
  min-height: 13.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--grayscale-10);
  border-radius: 17px;
  cursor: pointer;
  text-decoration: none;
  color: unset;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px 1px rgba(0, 0, 0, 0.06);

  ${({ valueColor }) =>
    valueColor === 'yellow' &&
    css`
      border-color: var(--warning);
    `};

  ${({ valueColor }) =>
    valueColor === 'green' &&
    css`
      border-color: var(--success-2);
    `}

  ${({ valueColor }) =>
    valueColor === 'red' &&
    css`
      border-color: var(--error);
    `}

  &:before {
    background: none;
    border-radius: 0.5rem;
    content: '';
    display: block;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    pointer-events: none;
  }
  hr {
    width: 100%;
    height: 1px;
    background-color: var(--grayscale-10);
    border: none;
  }

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s 2s, opacity 2s linear;
    `}

  ${({ deleted }) =>
    deleted &&
    css`
      display: none;
    `}

    transition: 300ms;
  &:hover {
    background-color: var(--grayscale-2);

    ${({ expressPurchased }) =>
      expressPurchased &&
      css`
        transform: scale(1.035);
      `}
  }

  .created-at {
    font-size: 11px;
    font-weight: 700;
    font-family: 'Nunito Sans';
    font-style: normal;

    color: ${({ valueColor }) =>
      valueColor === 'yellow' || valueColor === 'green'
        ? 'var(--secondary-main)'
        : 'var(--white)'};

    span {
      color: var(--white);
      text-transform: uppercase;

      ${({ valueColor }) =>
        valueColor === 'yellow' || valueColor === 'green'
          ? css`
              color: var(--secondary-main);
            `
          : css`
              color: var(--white);
            `}
    }
  }
`;

export const SaveBtn = styled.button`
  background-color: transparent;
  border: none;
  z-index: 1;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  small {
    display: flex;
    flex-direction: column;
    .invoicing {
      font-size: 16px;
      font-weight: 700;
    }
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12rem;
  }

  strong {
    color: var(--secondary-main);
    font-size: 0.83rem;
  }

  span {
    font-size: 0.83rem;
    font-weight: lighter;
    color: var(--secondary-main);
  }
  a {
    text-decoration: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const CardFooter = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  height: 3.44rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--success-3);
  border-bottom: 0;
  padding: 0.4rem 1.3rem 0 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  bottom: 0;

  ${({ valueColor }) =>
    valueColor === 'yellow' &&
    css`
      border: 1px solid var(--warning);
      background: var(--warning);
    `};

  ${({ valueColor }) =>
    valueColor === 'green' &&
    css`
      border: 1px solid var(--success-2);
      background: var(--success-2);
    `}

  ${({ valueColor }) =>
    valueColor === 'red' &&
    css`
      border: 1px solid var(--error);
      background: var(--error);
    `}

  h4 {
    font-size: 1.125rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12rem;
  }

  strong {
    color: var(--secondary-main);
    font-size: 14px;

    span {
      font-size: 12px;
    }
  }

  span {
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

interface valueProps {
  color: string;
  valueColor?: string;
}

export const Value = styled.div<valueProps>`
  display: flex;
  padding: 0.5rem 0rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  color: var(--secondary-main);
  font-weight: 700;
  font-size: 22px;
  min-height: 2.75rem;

  ${({ valueColor }) =>
    valueColor === 'yellow' &&
    css`
      color: var(--secondary-dark);
    `};

  ${({ valueColor }) =>
    valueColor === 'green' &&
    css`
      color: var(--secondary-dark);
    `}

  ${({ valueColor }) =>
    valueColor === 'red' &&
    css`
      color: var(--white);
    `}
`;

export const Status = styled.span<valueProps>`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-radius: 0.188rem;
  height: 1.5rem;
  margin-bottom: -1.3rem;

  span{
    font-size: 12px;
    font-weight: 700;
  }
  ${({ color }) =>
    color === `yellow` &&
    css`
      color: var(--secondary-main);
      background-color: var(--warning);
    `}

  ${({ color }) =>
    color === `red` &&
    css`
      color: var(--secondary-main);
      background-color: var(--error);
    `}
  ${({ color }) =>
    color === `green` &&
    css`
      color: var(--secondary-main);
      background-color: var(--success-2);
    `}
`;

export const SeeMore = styled.button`
  display: flex;
  align-items: center;
  color: var(--primary-main);
  border: none;
  background: transparent;

  svg {
    font-size: 1.25rem;
  }
`;

export const Time = styled.span<ContainerProps>`
  color: var(--primary-main);
  margin-left: 0.2rem;

  ${({ valueColor }) =>
    valueColor === 'yellow' &&
    css`
      color: var(--primary-main);
    `};

  ${({ valueColor }) =>
    valueColor === 'green' &&
    css`
      color: var(--secondary-main);
    `}

  ${({ valueColor }) =>
    valueColor === 'red' &&
    css`
      color: var(--white);
    `}
`;

export const TitleAndCreatedAtContainer = styled.div`
  display: grid;
`;

export const TooltipFixe = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  height: 2.2rem;
  background-color: var(--white);
  width: max-content;

  ${({ valueColor }) =>
    valueColor === 'yellow' &&
    css`
      background: var(--warning);
      border-radius: 4px;
      border: 1px solid var(--warning);
    `};

  ${({ valueColor }) =>
    valueColor === 'green' &&
    css`
      background-color: var(--success-2);
      border-radius: 4px;
      border: 1px solid var(--success-2);
    `}

  ${({ valueColor }) =>
    valueColor === 'red' &&
    css`
      background: var(--error);
      border-radius: 4px;
      border: 1px solid var(--error);
    `}
`;

export const TooltipArrowLeft = styled.div<ContainerProps>`
  margin-left: 0rem;
  width: max-content;
  height: 100%;
  align-items: center;
  display: flex;
  padding: 0.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-family: 'Nunito Sans';
  color: var(--secondary-main);
  border-radius: 0 0.25rem 0.25rem 0;

  ${({ bidsPack }) =>
    bidsPack &&
    css`
      border: 1px solid var(--primary-main);
    `}

  ${({ valueColor }) =>
    valueColor === 'yellow' &&
    css`
      background: var(--white);
      border: 0;
    `};

  ${({ valueColor }) =>
    valueColor === 'green' &&
    css`
      background: var(--white);
      border: 0;
    `}

  ${({ valueColor }) =>
    valueColor === 'red' &&
    css`
      background: var(--white);
      border: 0;
    `}
`;

export const CreatedAt = styled.strong<ContainerProps>`
  width: max-content;
  padding: 1rem;
  height: 100%;
  align-items: center;
  display: flex;
  color: var(--tertiary-light);
  border-radius: ${({ bidsPack }) =>
    bidsPack ? '0.25rem 0px 0px 0.25rem' : '0.25rem 0.25rem 0.25rem 0.25rem'};
  font-size: 14px;

  span {
    color: var(--tertiary-light);
  }

  p {
    font-size: 12px;
    padding-top: 3px;
    font-weight: 500;
  }
`;

export const ExpPurchaseOuterContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem;
  pointer-events: none;
`;

export const ExpPurchaseInnerContainer = styled.div`
  background-color: var(--error);
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Nunito Sans';
    color: var(--tertiary-light);
  }
`;
