import { parseCookies } from 'nookies';
import apiFederation from '../apiFederation';

export async function readNotification(id: string) {
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.get(`/leadbroker/notification/${id}`, {
      headers: { Authorization: `Bearer ${cookies['v4company.token']}` },
    });
    const notification = response.data;

    return notification;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao ler uma notificação, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}
