import { useState } from 'react';
import { LabDsButton, LabDsTextField } from 'v4web-components-react';
import * as S from './styles';
import { useAuctions, useDeposit, useWithDraw } from '../../../../contexts';

export function Amount() {
  const { setStageDeposit, amount, setAmount } = useDeposit();
  const { isVerify } = useWithDraw();
  const { unitInfos } = useAuctions();

  const [amountState, setAmountState] = useState(String(amount || ''));

  const onSubmit = () => {
    setAmount(amount);
    if (isVerify) {
      setStageDeposit('info');
      return false;
    }
    return setStageDeposit('firstTime');
  };

  return (
    <S.Container
      placeholder=""
      onSubmit={onSubmit}
      onPointerEnterCapture={() => null}
      onPointerLeaveCapture={() => null}
    >
      <section>
        {unitInfos.unitName && (
          <S.UnitContent>
            <S.CaseText>Depósito para a unidade</S.CaseText>

            <S.UnitCase>{unitInfos?.unitName}</S.UnitCase>
          </S.UnitContent>
        )}

        <LabDsTextField
          value={String(amountState) || ''}
          titleInput="Qual o valor do depósito*"
          label="00,00"
          mask="currency"
          onChangeInput={({ detail }) => {
            setAmountState(detail);
            setAmount(Number(detail.replace('.', '').replace(',', '.')));
          }}
          state={amount < 1 ? 'error' : 'default'}
          helperText={amount < 1 ? 'Valor inválido' : ''}
        />
      </section>

      <S.Footer>
        <LabDsButton
          type="submit"
          label="Seguinte"
          onHandleButton={() => onSubmit()}
          disabled={amount <= 0 || String(amount).replace(/[^0-9]/g, '') === ''}
        />
      </S.Footer>
    </S.Container>
  );
}
