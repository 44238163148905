import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MdOutlineTimer } from 'react-icons/md';
import * as S from './styles';
import { useAuth } from '../../../contexts/auth';
import { getAuctionStatus } from '../../../utils/getAuctionState';
import { useTicker } from '../../../hooks/useTicker';
import { convertCentsToBRL } from '../../../utils/convertCentsToBRL';
import { useAuctions } from '../../../contexts/auctions';

interface bidCardProps {
  auction: Auction;
  pack_id: string;
  // eslint-disable-next-line react/no-unused-prop-types
  hash?: string;
  pack: AuctionPack;
  expressPurchased?: boolean;
}

export const IconColor = {
  yellow: 'var(--primary-main)',
  green: 'var(--primary-main)',
  red: 'var(--white)',
};

const BidCardPack: FC<bidCardProps> = ({
  auction,
  pack_id,
  pack,
  expressPurchased = false,
}) => {
  const location = useLocation();
  const { user } = useAuth();
  const [isTimeFinish, setIsTimeFinish] = useState(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const { setAuctions, auctions } = useAuctions();

  const formatDate = (date: Date) => {
    const today = new Date();
    const newDate = new Date(date);

    if (
      newDate.getDate() === today.getDate() &&
      newDate.getMonth() === today.getMonth() &&
      newDate.getFullYear() === today.getFullYear()
    ) {
      return 'Hoje';
    }
    return newDate.toLocaleDateString();
  };
  const futureDate = new Date(pack.expiresAt);

  const { hours, minutes, seconds, isTimeUp } = useTicker(futureDate);

  const valueColor = useMemo(() => {
    const status = getAuctionStatus(pack, user!.unitId);

    if (status === 'available') return 'yellow';
    if (status === 'winner') return 'green';
    if (status === 'overcome') return 'red';
    return 'red';
  }, [pack, user]);

  const hideBidCardPack = useCallback(() => {
    isTimeUp &&
      setTimeout(() => {
        setIsTimeFinish(true);
        setTimeout(() => {
          setDeleteCard(true);
          const newAuctions =
            auctions?.length &&
            auctions.filter(value => value._id !== auction._id);
          setAuctions(newAuctions);
        }, 2500);
      }, 7000);
  }, [isTimeUp, auction?._id, setAuctions, auctions]);

  useEffect(() => {
    hideBidCardPack();
  }, [hideBidCardPack]);

  return (
    <S.Container
      to={!expressPurchased ? `/bid/pack/${pack_id}` : null}
      state={!expressPurchased ? { backgroundLocation: location } : null}
      hide={isTimeFinish}
      deleted={deleteCard}
      valueColor={valueColor}
      expressPurchased={!expressPurchased}
    >
      <S.TopContent>
        <S.HeaderCard>
          <S.TitleAndCreatedAtContainer>
            <S.TooltipFixe
              bidsPack={pack?.bids?.length}
              valueColor={valueColor}
            >
              <S.CreatedAt
                bidsPack={pack?.bids?.length}
                valueColor={valueColor}
              >
                <p className="created-at">
                  Gerado em:&nbsp;
                  <span>{formatDate(pack.createdAt)}</span>
                </p>
              </S.CreatedAt>
              {pack?.bids?.length ? (
                <S.TooltipArrowLeft
                  bidsPack={pack?.bids?.length}
                  valueColor={valueColor}
                >
                  {`${pack?.bids?.length} LANCES`}
                </S.TooltipArrowLeft>
              ) : null}
            </S.TooltipFixe>
          </S.TitleAndCreatedAtContainer>

          <S.LeadType valueColor={valueColor}>Pack</S.LeadType>
        </S.HeaderCard>

        <S.Row
          style={{
            alignItems: 'flex-end',
            marginTop: '0.5rem',
            marginBottom: '2.1rem',
            marginLeft: '0.1rem',
          }}
        >
          <S.Row style={{ flexWrap: 'wrap' }}>
            <S.Row style={{ marginBottom: '0.5rem' }}>
              <strong>Faturamento total</strong> <br />{' '}
            </S.Row>

            <S.Row style={{ marginBottom: '0.2rem' }}>
              <small>
                <span className="invoicing"> {pack.revenue} </span>
              </small>
            </S.Row>

            <S.Row style={{ marginBottom: '0.6rem' }}>
              <small>
                <strong>
                  Quantidade: <span> {pack.leads?.length} Leads </span>{' '}
                </strong>
              </small>
            </S.Row>
          </S.Row>
        </S.Row>
      </S.TopContent>

      <S.CardFooter valueColor={valueColor}>
        <S.TimeContent>
          <MdOutlineTimer color={IconColor[valueColor]} size={21} />
          <h3>
            <S.Time valueColor={valueColor}>
              {isTimeUp
                ? 'Finalizado'
                : `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
                    2,
                    '0'
                  )}:${String(seconds).padStart(2, '0')}`}
            </S.Time>
          </h3>
        </S.TimeContent>
        <S.PriceContent>
          <S.Value color={valueColor} valueColor={valueColor}>
            {convertCentsToBRL(pack.winner.value)}
          </S.Value>
        </S.PriceContent>
      </S.CardFooter>
      {expressPurchased && (
        <S.ExpPurchaseOuterContainer>
          <S.ExpPurchaseInnerContainer>
            <p>Arrematado pelo compre já</p>
          </S.ExpPurchaseInnerContainer>
        </S.ExpPurchaseOuterContainer>
      )}
    </S.Container>
  );
};

export default BidCardPack;
