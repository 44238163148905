export const errorsBySocket = {
  BID_LOWER: {
    title: 'Lance não realizado!',
    description: 'Seu lance é menor que o último lance dado.',
    type: 'error',
  },
  INSUFFICIENT_FUNDS: {
    title: 'Saldo insuficiente!',
    description: 'Seu saldo é insuficiente para realizar o lance.',
    type: 'error',
  },
  GENERIC_ERROR: {
    title: 'Erro!',
    description: 'Não foi possível realizar o lance.',
    type: 'error',
  },
  BUY_NOW_VALUE: {
    title: 'Compre já não realizado!',
    description:
      'Valor do compre já está incorreto ou ele já foi arrematado por outra unidade.',
    type: 'error',
  },
  AUCTION_EXPIRED: {
    title: 'Tempo expirado',
    description: 'Lance não realizado, tempo já expirado.',
    type: 'error',
  },
};
