import { useCallback } from 'react';
import { LabDsButton, LabDsIconNotSelectable } from 'v4web-components-react';
import { Dialog } from '@reach/dialog';
import { MdCheck } from 'react-icons/md';
import * as S from './styles';
import { useLeads } from '../../../../../contexts';

export function ModalRefund({
  setShowModalRefund,
  setIsShowLostForm,
  expiredDeadline,
  refundSent,
  handleSendRefund,
}: {
  setShowModalRefund: (boolean) => void;
  setIsShowLostForm: (boolean) => void;
  expiredDeadline: boolean;
  refundSent?: boolean;
  handleSendRefund: () => void;
}) {
  const { reason } = useLeads();

  const handleConfirmReason = useCallback(() => {
    handleSendRefund();

    // TO DO: add shipping to the backend when using is not eligible for refund
  }, [handleSendRefund]);

  const goBack = () => {
    if (!reason?.refundable) {
      setShowModalRefund(false);
      return;
    }
    setIsShowLostForm(false);
    setShowModalRefund(false);
  };

  return (
    <Dialog
      aria-labelledby="label"
      onDismiss={() => setShowModalRefund(false)}
      style={{ marginTop: '20vh', width: '70vw' }}
    >
      <S.Modal>
        <S.ModalBody>
          <S.Icon color="var(--lab-ds-semantic-selectable-color-bg-dark-error)">
            {refundSent ? (
              <MdCheck size="5rem" color="var(--lab-ds-core-color-green-50)" />
            ) : (
              <LabDsIconNotSelectable icon="cancel" size="x-large" />
            )}
          </S.Icon>
          <S.TitleModal>
            {refundSent
              ? 'Solicitação enviada com sucesso!'
              : 'Reembolso negado'}
          </S.TitleModal>
          {!refundSent && (
            <>
              {expiredDeadline ? (
                <S.TextModal>
                  O prazo para solicitação de reembolso é de 90 dias. Por este
                  motivo o reembolso não será realizado.
                </S.TextModal>
              ) : (
                <S.TextModal>
                  O motivo selecionado não contempla o reembolso. Caso continue,
                  o lead não estará mais disponível para sua unidade. Deseja
                  prosseguir?
                </S.TextModal>
              )}
              <S.TextModal>
                Qualquer duvida entre em contato com seu Gestor Matriz
              </S.TextModal>
            </>
          )}

          {refundSent && (
            <S.TextModal>
              Recebemos sua solicitação e estamos analisando. Em até 48 horas
              úteis você receberá retorno.
            </S.TextModal>
          )}
        </S.ModalBody>
        <S.ModalFooter>
          {!refundSent && (
            <LabDsButton
              variant="danger-outlined"
              label="Cancelar"
              onHandleButton={goBack}
            />
          )}
          <LabDsButton label="Confirmar" onClick={handleConfirmReason} />
        </S.ModalFooter>
      </S.Modal>
    </Dialog>
  );
}
