import styled from 'styled-components';

export const RadioContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 700;
  input {
    margin-right: 1rem;
    color: var(--grayscale-06);
  }

  input[type='radio']:after {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--grayscale-20);
    content: '';
    display: inline-block;
    visibility: visible;
    // border: 2px solid var(--white);
  }

  input[type='radio']:checked:after {
    width: 10px;
    height: 10px;
    border-radius: 1rem;
    top: -3px;
    left: 0px;
    position: relative;
    background-color: var(--secondary-main);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid var(--grayscale-20);
  }
`;
