import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  Section,
  SectionContent,
  SectionTitle,
} from 'v4components-react';
import { IData } from 'v4web-components';
import {
  LabDsIconSelectable,
  LabDsTab,
  LabDsTable,
} from 'v4web-components-react';
import * as S from './styles';
import { ListTabs } from '../../hooks/tabs';
import { getTransactions } from '../../services/requests/leadbroker/transactions';
import { convertCentsToBRL } from '../../utils/convertCentsToBRL';
import { getStatusTransaction } from './getBadgeStatus';
import { getTypeTransaction } from './getTypeTransactions';
import { TransactionsResponse } from './interfaces/payment-response';
import { headerExtract } from './tableHeaders';
import { ICON } from '../../assets';
import { typesFilter } from './mapQuery';

export function Extract() {
  const [currentTab, setCurrentTab] = useState<string>('ALL');
  const [filterSelectd, setFilterSelectd] = useState<string>('');
  const [transactions, setTransactions] = useState<TransactionsResponse[]>([]);
  const [pageParams, setPageParams] = useState({
    currentPage: 1,
    total: 1,
    lastPage: 1,
    perPage: 10,
  });

  const handlerSelectTab = (key: string) => {
    setCurrentTab(key);
    setFilterSelectd('');
  };

  const tabs = ListTabs({
    tabs: [
      {
        title: 'Todas',
        key: 'ALL',
        event: () => handlerSelectTab('ALL'),
      },
      {
        title: 'Entradas',
        key: 'INPUT',
        event: () => handlerSelectTab('INPUT'),
      },
      {
        title: 'Saídas',
        key: 'OUTPUT',
        event: () => handlerSelectTab('OUTPUT'),
      },
    ],
  });

  const handlerGetTransactions = useCallback(async () => {
    const result = (await getTransactions(
      currentTab,
      typesFilter[currentTab]?.find(item => item.key === filterSelectd)?.type,
      pageParams.currentPage
    )) as {
      items: TransactionsResponse[];
      total: number;
      perPage: number;
      lastPage: number;
      currentPage: number;
    };
    setPageParams({
      currentPage: result?.currentPage,
      total: result?.total,
      lastPage: result?.lastPage,
      perPage: result?.perPage,
    });

    setTransactions(result?.items);
  }, [currentTab, filterSelectd, pageParams.currentPage]);

  useEffect(() => {
    handlerGetTransactions();
  }, [handlerGetTransactions]);

  const arrayOfTotalPages = Array.from(
    { length: pageParams.lastPage },
    (_, i) => i + 1
  );

  const tableData = useMemo<IData>(() => {
    const rows: IData['rows'] = [];

    transactions?.forEach(item => {
      rows.push({
        id: {
          text: { title: item._id },
        },
        author: {
          text: {
            title: item.user.name,
            avatar: true,
            avatarImgSRC: item?.user?.picture
              ? item?.user?.picture
              : ICON.LogoV4,
          },
        },
        type: {
          text: { title: getTypeTransaction(item.type, item?.depositMethod) },
        },
        date: {
          text: { title: new Date(item.createdAt).toLocaleDateString() },
        },
        status: {
          badge: {
            label: getStatusTransaction(item.status)?.label,
            state: getStatusTransaction(item.status)?.state,
          },
        },
        amount: {
          text: { title: convertCentsToBRL(item.amount) },
        },
      });
    });

    return {
      headers: headerExtract,
      rows,
    };
  }, [transactions]);

  return (
    <Container>
      <Section>
        <SectionContent>
          <SectionTitle text="Extrato" />
          <S.ContentTabs>
            <LabDsTab value={currentTab} tabs={tabs} />
          </S.ContentTabs>
          <S.ContainerFilter>
            {typesFilter[currentTab]?.map(item => {
              return (
                <S.FilterSelect
                  isSelected={item.key === filterSelectd}
                  onClick={() =>
                    setFilterSelectd(item.key === filterSelectd ? '' : item.key)
                  }
                >
                  {item?.label}
                </S.FilterSelect>
              );
            })}
          </S.ContainerFilter>
          <LabDsTable loading={false} data={tableData} />
          {arrayOfTotalPages.length >= 0 && transactions?.length > 0 && (
            <S.Pagination>
              <LabDsIconSelectable
                icon="chevron_left"
                size="s-medium"
                onClick={() => {
                  if (pageParams.currentPage === 1) {
                    return;
                  }
                  setPageParams({
                    ...pageParams,
                    currentPage: pageParams.currentPage - 1,
                  });
                }}
              />
              {arrayOfTotalPages.map(page => (
                <button
                  key={page}
                  onClick={() =>
                    setPageParams({ ...pageParams, currentPage: page })
                  }
                  className={page === pageParams.currentPage ? 'isActive' : ''}
                  type="button"
                >
                  {page}
                </button>
              ))}
              <LabDsIconSelectable
                icon="chevron_right"
                size="s-medium"
                onClick={() => {
                  if (pageParams.currentPage === pageParams.lastPage) {
                    return;
                  }
                  setPageParams({
                    ...pageParams,
                    currentPage: pageParams.currentPage + 1,
                  });
                }}
              />
            </S.Pagination>
          )}
        </SectionContent>
      </Section>
    </Container>
  );
}
