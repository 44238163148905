import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { PayerDTO } from '../services/requests/leadbroker/wallet/interfaces/addFoundsInterface';

interface installementType {
  key: string;
  label: string;
  isChecked: boolean;
}

interface DepositContextData {
  handlerStageTitleDeposit(stage_title: string): void;
  creditCard: {
    zipCode: string;
    setZipCode: Dispatch<SetStateAction<string>>;
    street: string;
    setStreet: Dispatch<SetStateAction<string>>;
    numberHouse: string;
    setNumberHouse: Dispatch<SetStateAction<string>>;
    complement: string;
    setComplement: Dispatch<SetStateAction<string>>;
    city: string;
    setCity: Dispatch<SetStateAction<string>>;
    state?: string;
    setState?: Dispatch<SetStateAction<string>>;
    nameCard: string;
    setNameCard: Dispatch<SetStateAction<string>>;
    numberCard: string;
    setNumberCard: Dispatch<SetStateAction<string>>;
    month: string;
    setMonth: Dispatch<SetStateAction<string>>;
    year: string;
    setYear: Dispatch<SetStateAction<string>>;
    securityCode: string;
    setSecurityCode: Dispatch<SetStateAction<string>>;
    cnpj?: string;
    setCnpj?: Dispatch<SetStateAction<string>>;
    installement?: installementType;
    setInstallement?: Dispatch<SetStateAction<installementType>>;
    setDistrict: Dispatch<SetStateAction<string>>;
    district: string;
    payer: PayerDTO;
    setPayer: Dispatch<SetStateAction<PayerDTO>>;
    cardToken: string;
    setCardToken: Dispatch<SetStateAction<string>>;
  };
  handleSubmitDeposit(stage_title: string): void;
  stageDeposit: string;
  setStageDeposit: Dispatch<SetStateAction<string>>;
  paymentType: string;
  setPaymentType: Dispatch<SetStateAction<string>>;
  amount?: number;
  setAmount?: Dispatch<SetStateAction<number>>;
  checkGoBackDeposit(stage_title: string): void;
  checkArrowStage(stage_title: string): boolean;
  expireDate?: string;
  setExpireDate: Dispatch<SetStateAction<string>>;
  handlerStageDeposit(stage_title: string): string;
}

export const WalletDepositContext = createContext({} as DepositContextData);

type WalletDepositContextProviderProps = {
  children: ReactNode;
};

type Stages =
  | 'amount'
  | 'firstTime'
  | 'info'
  | 'fields'
  | 'summary'
  | 'payment'
  | 'adress'
  | 'card'
  | 'installment'
  | 'cardPayment';
type PaymentType = 'bank_slip' | 'pix' | 'all' | 'credit_card' | 'finish';

export const WalletDepositProvider: React.FC<WalletDepositContextProviderProps> = ({
  children,
}: WalletDepositContextProviderProps) => {
  const [stageDeposit, setStageDeposit] = useState<Stages>('amount');
  const [paymentType, setPaymentType] = useState<PaymentType>(null);
  const [amount, setAmount] = useState(0);
  const [expireDate, setExpireDate] = useState('');
  const [district, setDistrict] = useState<string>('');
  const [cnpj, setCnpj] = useState<string>('');
  const [installement, setInstallement] = useState<installementType>();
  const [zipCode, setZipCode] = useState();
  const [payer, setPayer] = useState<PayerDTO>();
  const [street, setStreet] = useState();
  const [numberHouse, setNumberHouse] = useState();
  const [complement, setComplement] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [nameCard, setNameCard] = useState();
  const [numberCard, setNumberCard] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const ableToArrow = ['amount', 'cardPayment', 'error'];
  const [securityCode, setSecurityCode] = useState();
  const [cardToken, setCardToken] = useState<string>('');

  const creditCard: DepositContextData['creditCard'] = {
    zipCode,
    setZipCode,
    street,
    setStreet,
    numberHouse,
    setNumberHouse,
    complement,
    setComplement,
    city,
    setCity,
    state,
    setState,
    nameCard,
    setNameCard,
    numberCard,
    setNumberCard,
    month,
    setMonth,
    year,
    setYear,
    securityCode,
    setSecurityCode,
    cnpj,
    setCnpj,
    installement,
    setInstallement,
    district,
    setDistrict,
    payer,
    setPayer,
    cardToken,
    setCardToken,
  };

  function getNextDepositStage(currentStage, Cnpj: string) {
    const stageMap = {
      info: 'amount',
      adress: 'info',
      card: 'adress',
      installment: 'card',
      fields: paymentType === 'credit_card' ? 'installment' : 'info',
      summary: Cnpj ? 'installment' : 'info',
      default: 'amount',
    };

    return stageMap[currentStage] || stageMap.default;
  }

  const handlerStageTitleDeposit = (stage_title: string) => {
    switch (stage_title) {
      case 'amount':
        return 'Seguinte';
      case 'fields':
        return 'Seguir para o resumo da transação';
      default:
        return 'Seguinte';
    }
  };

  const handleSubmitDeposit = (stage_title: string) => {
    if (stage_title === 'amount') {
      return setStageDeposit('info');
    }
    return false;
  };

  const checkArrowStage = (stage_title: string) => {
    if (ableToArrow.includes(stage_title)) {
      return false;
    }

    return true;
  };

  const checkGoBackDeposit = (state_title: string) => {
    const nextState = getNextDepositStage(state_title, cnpj);
    setStageDeposit(nextState);
  };

  const handlerStageDeposit = (stage_title: string) => {
    if (stage_title !== 'payment') {
      return 'Depósito';
    }

    switch (paymentType) {
      case 'pix':
        return 'Depositar com pix';

      case 'bank_slip':
        return 'Depositar com boleto';

      case 'credit_card':
        return 'Efetuar pagamento';

      default:
    }

    return 'Depósito';
  };

  return (
    <WalletDepositContext.Provider
      value={{
        creditCard,
        handlerStageTitleDeposit,
        handleSubmitDeposit,
        stageDeposit,
        setStageDeposit,
        paymentType,
        setPaymentType,
        amount,
        setAmount,
        checkGoBackDeposit,
        checkArrowStage,
        expireDate,
        setExpireDate,
        handlerStageDeposit,
      }}
    >
      {children}
    </WalletDepositContext.Provider>
  );
};

export const useDeposit = (): DepositContextData => {
  return useContext(WalletDepositContext);
};
