import { parseCookies } from 'nookies';
import apiFederation from '../apiFederation';

interface QueryPros {
  page?: number;
  limit?: number;
}

export async function getNotificationsByUser({ limit, page }: QueryPros) {
  try {
    const cookies = parseCookies(undefined);

    const response = await apiFederation.get(
      `/leadbroker/notification?page=${page}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${cookies['v4company.token']}` },
      }
    );
    const notifications = response.data;

    return notifications;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao buscar as notificações, atualize a página e tente novamente 😕`,
      err
    );
    return false;
  }
}
