/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Notifications } from 'react-push-notification';
import { QueryClientProvider as QueryClientProviderTanstack } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { queryClient } from './contexts/queryClient';
import GlobalStyle from './styles/global';
import 'v4design-tokens/dist/Internal-Domain/HQ_FC/css/variables.css';
import { AuthProvider } from './contexts/auth';
import { BalanceProvider } from './hooks/wallet';
import { CookiesAdvice } from './components/AtomicDesign/molecules/CookiesAdvice';

import Routes from './routes';
import 'antd/dist/antd.less';
import { ContextProvider } from './hooks/contextProvider';

function App() {
  useEffect(() => {
    // @ts-ignore
    Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID as string);
    // @ts-ignore
    Iugu.setup();
    // @ts-ignore
    Iugu.utils.isBlockedByAdBlock();
  }, []);

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={clientId}>
        <GlobalStyle />
        <CookiesAdvice />
        <Notifications position="bottom-right" />
        <AuthProvider>
          <QueryClientProviderTanstack client={queryClient}>
            <BalanceProvider>
              <ContextProvider>
                <Routes />
              </ContextProvider>
            </BalanceProvider>
          </QueryClientProviderTanstack>
        </AuthProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default App;
