import React, {
  InputHTMLAttributes,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useField } from '@unform/core';
// import { IconBaseProps } from 'react-icons';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

import * as S from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  errorStyle?: React.CSSProperties;
  // icon?: React.ComponentType<IconBaseProps>;
  labelStyle?: React.CSSProperties;
  label?: string;
  description?: string;
  userCanBid?: boolean;
}

export function Input({
  name,
  description,
  // icon: Icon,
  containerStyle,
  inputStyle,
  labelStyle,
  label,
  errorStyle,
  userCanBid,
  ...rest
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [registerField, fieldName]);

  return (
    <S.Container isErrored={!!error} isFilled={isFilled} style={containerStyle}>
      <label htmlFor={name} style={labelStyle}>
        {label}
      </label>
      {description && <p>{description}</p>}
      <S.InputContainer
        isErrored={!!error}
        className="inputContainer"
        isFilled={isFilled}
        isFocused={isFocused}
        style={userCanBid ? {} : { border: '2px solid var(--error)' }}
      >
        <input
          id={name}
          name={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          style={inputStyle}
          {...rest}
        />

        {!userCanBid && (
          <div className="icon">
            <AiOutlineExclamationCircle size={20} color="red" />
          </div>
        )}
      </S.InputContainer>

      {error && <small style={errorStyle}>{error}</small>}
    </S.Container>
  );
}
