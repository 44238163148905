import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  align-items: center;
  height: 38rem;
  margin: 2.5rem;

  h1 {
    font-size: 1.25rem;

    :nth-child(1) {
      margin-bottom: 0.5rem;
    }
  }

  > section:nth-child(4) {
  }

  > section:nth-child(5) {
    grid-template-columns: repeat(2, 0.4fr);
    margin-bottom: 1rem;
  }
`;

export const LowerCaseText = styled.p`
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const Balances = styled.strong`
  color: var(--success-2);
  font-size: 1.63rem;
`;

export const Notice = styled.p`
  width: 586px;
  margin-top: 0.625rem;
  font-size: 0.85rem;
`;

export const WithdrawalData = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0rem;
  align-items: center;

  div {
    margin-top: 2rem;

    p {
      margin-top: 1.3rem;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  padding-inline: 2rem;
  position: relative;
  bottom: 0;
`;
