import styled from 'styled-components';

export const Container = styled.div`
  z-index: 5;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 6rem);

  background: var(--white);
  padding: 2rem;
  border-radius: 0.5rem;
  margin: 3rem;
  margin-left: 5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

  section {
    > h3 {
      color: var(--primary-main);
      font-size: 2rem;
    }

    > p {
      font-size: 1.5rem;
      font-family: 'Nunito Sans', sans-serif;
      margin: 1rem 0;
    }

    > a {
      color: var(--primary-main);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    > div {
      margin-top: 2rem;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-width: 10rem;
  height: 100%;
  margin-left: 8rem;

  button:first-child {
    margin-bottom: 1rem;
  }
`;
