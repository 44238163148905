import { Dispatch, SetStateAction } from 'react';

export interface stepsProps {
  label: string;
  buttonLabel: string;
  buttonEnabled: boolean;
  step: number;
  event?: () => void;
  eventModal?: () => void;
}

// eslint-disable-next-line no-shadow
export enum LeadStatusLabels {
  AvisarPerda = 'Avisar perda',
  SolicitarReembolso = 'Solicitar Reembolso',
  ReembolsoJaSolicitado = 'Reembolso já solicitado',
}

interface stepsOptions {
  setShowModalAlterStatus: Dispatch<SetStateAction<boolean>>;
  setShowModalMeetingSchedule: Dispatch<SetStateAction<boolean>>;
  setHandleMeeting: Dispatch<SetStateAction<boolean>>;
  handleUpdateStep: (body: UpdateStep) => void;
  leadId: string;
}

export function steps({
  setShowModalAlterStatus,
  handleUpdateStep,
  leadId,
}: stepsOptions) {
  return [
    {
      label: 'Lead comprado',
      buttonLabel: 'Informar Prospecção',
      buttonEnabled: true,
      key: 'LEAD_PURCHASED',
      step: 1,
      eventModal: () => {
        handleUpdateStep({ step: 'IN_PROSPECTING' });
      },
      event: () => {
        setShowModalAlterStatus(true);
      },
    },
    {
      label: 'Em prospecção',
      buttonLabel: 'Informar Reunião',
      buttonEnabled: true,
      key: 'IN_PROSPECTING',
      step: 2,
      eventModal: () => {
        handleUpdateStep({ step: 'MEETING_SCHEDULED' });
      },
      event: () => {
        setShowModalAlterStatus(true);
      },
    },
    {
      label: 'Reunião marcada',
      buttonLabel: 'Solicitar Contrato',
      buttonEnabled: true,
      key: 'MEETING_SCHEDULED',
      step: 3,
      eventModal: () => {
        handleUpdateStep({ step: 'CONTRACT_SENT' });
        window.open(
          `https://v4company.my.salesforce-sites.com/contratosfechados?recordid=${leadId}`
        );
      },
      event: () => {
        setShowModalAlterStatus(true);
      },
    },
    {
      label: 'Reunião marcada',
      buttonLabel: 'Retornar Reunião',
      buttonEnabled: true,
      key: 'LOST_LEAD',
      step: 3,
      eventModal: () => {
        handleUpdateStep({ step: 'RETURN_MEETING' });
      },
      event: () => {
        setShowModalAlterStatus(true);
      },
    },
    {
      label: 'Reunião marcada',
      buttonLabel: 'Solicitar Contrato',
      buttonEnabled: true,
      key: 'RETURN_MEETING',
      step: 3,
      eventModal: () => {
        handleUpdateStep({ step: 'MEETING_SCHEDULED' });
        window.open(
          `https://v4company.my.salesforce-sites.com/contratosfechados?recordid=${leadId}`
        );
      },
      event: () => {
        setShowModalAlterStatus(true);
      },
    },
    {
      label: 'Contrato enviado',
      buttonLabel: 'Contrato Solicitado',
      buttonEnabled: false,
      key: 'CONTRACT_SENT',
      step: 4,
    },
  ];
}

export const optionsNotifyLoss = (checked: string) => {
  return [
    {
      label: 'Cliente oculto',
      key: '1',
      isChecked: checked === 'Cliente oculto',
    },
    {
      label: 'Sem timing - Tempo indeterminado',
      key: '2',
      isChecked: checked === 'Sem timing - Tempo indeterminado',
    },
    {
      label: 'Sem timing - Com data para retomada',
      key: '3',
      isChecked: checked === 'Sem timing - Com data para retomada',
    },
    {
      label: 'Lead tem faturamento adequado, mas achou caro',
      key: '4',
      isChecked: checked === 'Lead tem faturamento adequado, mas achou caro',
    },
    {
      label: 'Lead não tem faturamento adequado para venda',
      key: '5',
      isChecked: checked === 'Lead não tem faturamento adequado para venda',
    },
    {
      label: 'Fechou com outra empresa/Contratou internamente',
      key: '6',
      isChecked: checked === 'Fechou com outra empresa/Contratou internamente',
    },
    {
      label: 'Não consegui contato com stakeholder principal',
      key: '7',
      isChecked: checked === 'Não consegui contato com stakeholder principal',
    },
    {
      label: 'Reclame Aqui',
      key: '8',
      isChecked: checked === 'Reclame Aqui',
    },
    {
      label: 'Deixou de responder, cadência de follow finalizada',
      key: '9',
      isChecked:
        checked === 'Deixou de responder, cadência de follow finalizada',
    },
    {
      label:
        'Lead teve algum imprevisto grave (financeiro, pessoal, judicial, etc)',
      key: '10',
      isChecked:
        checked ===
        'Lead teve algum imprevisto grave (financeiro, pessoal, judicial, etc)',
    },
    {
      label: 'Empresa sem estrutura/MVP',
      key: '11',
      isChecked: checked === 'Empresa sem estrutura/MVP',
    },
    {
      label: 'Outro',
      key: '12',
      isChecked: checked === 'Outro',
    },
  ];
};

export const options = [
  {
    label: 'Blacklist',
    key: '1',
    isChecked: false,
    refundable: true,
    automaticReject: false,
  },
  {
    label: 'SPAM',
    key: '2',
    isChecked: false,
    refundable: true,
    automaticReject: false,
  },
  {
    label: 'Sem empresa',
    key: '3',
    isChecked: false,
    refundable: true,
    automaticReject: false,
  },
  {
    label: 'Duplicidade',
    key: '4',
    isChecked: false,
    refundable: true,
    automaticReject: false,
  },
  {
    label: 'Queria outro produto V4',
    key: '5',
    isChecked: false,
    refundable: true,
    automaticReject: false,
  },
  {
    label: 'Atendido por outra unidade',
    key: '6',
    isChecked: false,
    refundable: true,
    automaticReject: false,
  },
  {
    label: 'Atendido pela matriz',
    key: '7',
    isChecked: false,
    refundable: true,
    automaticReject: false,
  },
];
