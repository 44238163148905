import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
`;

export const CollaboratorImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  min-height: 16rem;
  min-width: 16rem;
  border-radius: 0.5rem;
  border: 2px solid var(--white);
  object-fit: cover;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 3px 8px rgba(0, 0, 0, 0.04),
    0px 0px 8px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    width: 8rem !important;
    height: 8rem !important;
    width: auto;
    height: auto;
    margin-bottom: 1rem;
    background: var(--primary-dark);
  }
`;

export const CollaboratorInfos = styled.div`
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-radius: 0.5rem;
  width: 100%;
  flex: 1;

  @media (max-width: 1000px) {
    margin-top: 1rem;
  }

  span {
    font-family: 'Nunito Sans';
    color: var(--secondary-light);
    font-weight: bold;
    text-transform: uppercase;
    margin: 0.5rem 0 0.25rem;
  }

  h5 {
    margin-bottom: 1rem;
    color: var(--secondary-dark);
  }

  strong {
    color: var(--secondary-dark);
    font-family: 'Nunito Sans';
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .contact-infos {
    display: flex;

    div {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 1050px) {
    .contact-infos {
      display: flex;
      flex-direction: column;

      div:last-child {
        margin-left: 0rem;
      }
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
`;

export const CollaboratorButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  button {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    color: var(--secondary-light);

    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const StartedDate = styled.div`
  display: flex;
  flex-direction: column;
`;
