import { useEffect } from 'react';
import { GoogleLogin, GoogleCredentialResponse } from '@react-oauth/google';
import Logo from '../../assets/sign-in-logo.svg';
import * as S from './styles';
import { useAuth } from '../../contexts/auth';
import { useToast } from '../../contexts';

function Login() {
  const { signIn, errorMessage } = useAuth();
  const { addToast } = useToast();

  const handleLogin = async (e: GoogleCredentialResponse) => {
    const { credential } = e as GoogleCredentialResponse;

    if (!credential) return;

    await signIn({ tokenId: credential });
  };

  const handleLoginFailed = () => {
    addToast({
      type: 'error',
      description: 'Falha ao realizar o login',
    });
  };

  useEffect(() => {
    document.title = 'Login - V4 LB';
  }, []);

  return (
    <S.Container>
      <S.Login>
        <img src={Logo} alt="logo" style={{ marginBottom: '1rem' }} />

        <GoogleLogin
          onSuccess={credentialResponse => {
            handleLogin(credentialResponse);
          }}
          useOneTap
          onError={() => {
            handleLoginFailed();
          }}
          text="signin_with"
        />

        <p>{errorMessage} </p>
      </S.Login>
      <S.Background />
    </S.Container>
  );
}

export default Login;
