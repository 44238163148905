import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const convertIsoToDate = (iso: string) => {
  const date = new Date(iso);
  const formatedDate = format(new Date(date), 'dd/MM/yyyy', {
    locale: ptBR,
  }).toString();

  return formatedDate;
};
