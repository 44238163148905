import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string()
    .required('Nome do cartao é obrigatório')
    .matches(/^[aA-zZ\s]+$/, 'Apenas letras'),
  number: Yup.string()
    .typeError('Digite apenas número')
    .required('Número é obrigatório'),
  month: Yup.string()
    .typeError('Apenas números')
    .required('Obrigatório')
    .min(2, 'No mínimo 2 números')
    .max(2, 'No máximo 2 números')
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      'Apenas números'
    ),
  year: Yup.string()
    .typeError('Apenas números')
    .required('Obrigatório')
    .min(2, 'No mínimo 2 números')
    .max(4, 'No máximo 4 números')
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      'Apenas números'
    ),
  code: Yup.string()
    .typeError('Digite apenas número')
    .required('Código é obrigatório')
    .min(3)
    .max(4)
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      'Apenas números'
    ),
});
