import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  max-width: 42.5rem !important;
  header {
    display: none;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    max-width: 35rem;
    margin: 1.5rem 0;
  }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
  }

  footer {
    display: flex;
    a {
      text-decoration: none;
    }

    button {
      width: auto;
      padding: 0 1rem;
      font-weight: bold;
    }

    button {
      min-width: 10.7rem !important;
    }

    button:first-child {
      margin-right: 1rem;
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  background: transparent;
  border: 0;
`;
