import { IDataHeader } from '../../interfaces/table';

export const headerExtract: IDataHeader[] = [
  {
    colKey: 'id',
    title: 'ID',
    type: 'text',
  },
  {
    colKey: 'type',
    title: 'Tipo',
    type: 'text',
  },
  {
    colKey: 'author',
    title: 'Autor',
    type: 'text',
  },
  {
    colKey: 'date',
    title: 'Data',
    type: 'text',
  },
  {
    colKey: 'status',
    title: 'Status',
    type: 'badge',
  },
  {
    colKey: 'amount',
    title: 'Valor',
    type: 'text',
  },
];
