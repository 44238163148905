import {
  LabDsButton,
  LabDsIconNotSelectable,
  LabDsSelect,
  LabDsTextArea,
} from 'v4web-components-react';
import { Dialog } from '@reach/dialog';
import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as S from './styles';
import { useLeads } from '../../../../../contexts';
import { optionsNotifyLoss } from '../../utils';

export function ModalNotifyLoss({
  onDismiss,
  buttonRef,
  handleNotifyLoss,
}: {
  onDismiss: () => void;
  handleNotifyLoss: (notifyloss: string, description: string) => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
}) {
  interface ReasonData {
    reasonLoss: string;
    otherReason: string;
  }

  const schema = Yup.object().shape({
    reasonLoss: Yup.string().required('Selecione o motivo da perda'),
    otherReason: Yup.string().when('reasonLoss', {
      is: 'Outro',
      then: Yup.string().required('Digite o motivo da perda'),
    }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    getValues,
  } = useForm<ReasonData>({
    resolver: yupResolver(schema),
  });
  const watchReasonLoss = watch('reasonLoss');
  const { setShowModalNotifyLoss } = useLeads();
  const [optionsReasons] = useState(optionsNotifyLoss(getValues('reasonLoss')));

  const onSubmit = async (data: ReasonData) => {
    await schema.validate(data, {
      abortEarly: false,
    });
    await handleNotifyLoss(data.reasonLoss, data.otherReason);
  };

  return (
    <Dialog
      aria-labelledby="label"
      onDismiss={onDismiss}
      initialFocusRef={buttonRef}
      style={{
        width: '70vw',
        overflowX: 'auto',
        minHeight: '78vh',
      }}
    >
      <form onSubmit={() => handleSubmit(onSubmit)}>
        <S.Modal>
          <S.ModalBody>
            <S.Icon color="#7D5E15">
              <LabDsIconNotSelectable icon="Warning" size="x-large" />
            </S.Icon>
            <S.TitleModal>Avisar perda</S.TitleModal>

            <S.TextModal>
              Selecione abaixo o motivo da perda do lead
            </S.TextModal>

            <S.Inputs>
              <Controller
                name="reasonLoss"
                control={control}
                render={({ field }) => {
                  return (
                    <LabDsSelect
                      label="xxxxxx"
                      titleInput="Motivo da  perda*"
                      size="medium"
                      type="radioButton"
                      avatar={false}
                      isSearchable={false}
                      options={optionsReasons}
                      helperText={errors.reasonLoss?.message}
                      state={errors.reasonLoss ? 'error' : 'default'}
                      onChangeInputOptions={({ detail }) => {
                        field.onChange(detail[0].label);
                      }}
                    />
                  );
                }}
              />
            </S.Inputs>

            {watchReasonLoss === 'Outro' && (
              <S.Inputs>
                <Controller
                  name="otherReason"
                  control={control}
                  render={({ field }) => {
                    return (
                      <LabDsTextArea
                        label="Descreva a perda"
                        titleInput="Descreva o motivo da perda*"
                        maxLength={80}
                        rows={6}
                        cols={200}
                        value={field.value}
                        helperText={errors.otherReason?.message}
                        state={errors.otherReason ? 'error' : 'default'}
                        onChangeInput={({ detail }) => {
                          field.onChange(detail);
                        }}
                      />
                    );
                  }}
                />
              </S.Inputs>
            )}
          </S.ModalBody>
        </S.Modal>
      </form>
      <S.ModalFooter>
        <LabDsButton
          variant="danger-outlined"
          label="Cancelar"
          onHandleButton={() => {
            setShowModalNotifyLoss(false);
          }}
        />
        <LabDsButton
          label="Confirmar"
          disabled={false}
          type="submit"
          onHandleButton={() => {
            handleSubmit(onSubmit)();
          }}
        />
      </S.ModalFooter>
    </Dialog>
  );
}
